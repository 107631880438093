import {asyncApiRequest} from "./apiRequest";

const ProductType = {
  Download: 0,
  Event: 1,
  Service: 2,
  Link: 3,
}

const deserializeDownloadMetadata = (response) => {
  const metadata = JSON.parse(response.download)[0];
  return {
    title: metadata["download_title"],
    price: Number(metadata["amount"]),
    thumbUrl: metadata["thumb_url"],
  };
};

const deserializeEventMetadata = (response) => {
  const metadata = JSON.parse(response.event)[0];
  return {
    title: metadata["title"],
    price: Number(metadata["amount"]),
    thumbUrl: metadata["thumb_url"],
  };
};

class ProductSpec {
  constructor(type, id) {
    this.type = type;
    this.id = id;
  }

  get actionName() {
    switch (this.type) {
      case ProductType.Download:
        return "Download";
      case ProductType.Event:
        return "Join";
      default:
        return "Become";
    }
  }

  deserializeMetadata(response) {
    switch (this.type) {
      case ProductType.Download:
        return deserializeDownloadMetadata(response);
      case ProductType.Event:
        return deserializeEventMetadata(response);
    }
  }

  fetchMetadata = () => {
    let endpoint = undefined;
    switch (this.type) {
      case ProductType.Download:
        endpoint = "/get_user_download";
        break;
      case ProductType.Event:
        endpoint = "/get_user_event";
        break;
      default:
        console.error("unknown product type");
        return;
    };
    return asyncApiRequest(endpoint, "POST", {id: this.id});
  }
}

export {ProductType, ProductSpec};
