import React from 'react';
import PropTypes from 'prop-types';
import BackGround1 from '../../../assets/images/about_you.png'
import BackGround2 from '../../../assets/images/your_background.png'
import BackGround3 from '../../../assets/images/finishing_touches.png'

const OnboardingProgress = ({stages, stageIndex}) => (
	<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 60, marginBottom: 60 }}>
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 150 }}>
			<div className={stageIndex === 0 ? 'selectedOnboardCirle' : 'onboardCircle'} style={{ backgroundImage: `url("${BackGround1}")` }} />
			<p className="onboarding_steps_label">{stages[0].title}</p>
		</div>
		<div style={{ width: 100, height: 1, backgroundColor: '#C1A9FE', marginBottom: 20 }} />
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 150 }}>
			<div className={stageIndex === 1 ? 'selectedOnboardCirle' : 'onboardCircle'} style={{ backgroundImage: `url("${BackGround2}")` }} />
			<p className="onboarding_steps_label">{stages[1].title}</p>
		</div>
		<div style={{ width: 100, height: 1, backgroundColor: '#C1A9FE', marginBottom: 20 }} />
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 150 }}>
			<div className={stageIndex === 2 ? 'selectedOnboardCirle' : 'onboardCircle'} style={{ backgroundImage: `url("${BackGround3}")` }} />
			<p className="onboarding_steps_label">{stages[2].title}</p>
		</div>
	</div>
);

export default OnboardingProgress;
