import React from "react";

import ClientLandingPage from "./ClientLandingPage";

import ErrorBoundary from "../components/ErrorBoundary";

import BackgroundLoginPhoto from "../../../assets/images/background_login.png";
import Logo from "../../../assets/images/logo_purple.png";

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";

function InnerContainer(props) {
  const containerClasses = (
    "tw-p-4 tw-w-full tw-box-border tw-flex tw-flex-col tw-items-center tw-justify-center " +
    "tw-grow"
  );

  return <div className={
      "tw-w-screen tw-min-h-screen light-accent-background tw-flex " +
      "tw-flex-col tw-items-center client_container_background"
    }
      style={{
        backgroundImage: `url(${BackgroundLoginPhoto})`,
      }}
  >
    <div className={containerClasses}>
      <Outlet />
    </div>
    <div
      className="tw-flex tw-w-screen tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-p-2"
      style={{
        background: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <img style={{cursor: "pointer"}} onClick={() => window.location.assign(process.env.WEB_URL)}
        src={Logo} className="tw-h-12 tw-mt-auto" />
      <p>
        <a className="tw-font-semibold dark-accent-text" href={`${process.env.WEB_URL}/crisis-resources`}>Crisis Resources</a>{
          " | "
        }<a className="tw-font-semibold dark-accent-text" href={`${process.env.WEB_URL}/privacy`}>Privacy Policy</a>
      </p>
    </div>
  </div>;
}

export default function ClientAppContainer(props) {
  const {currentUser, showThanks} = props;

  const router = createBrowserRouter(
    [{
      path: "/clients",
      element: <InnerContainer />,
      children: [{
        path: "/clients",
        element: <ErrorBoundary><ClientLandingPage {...props} /></ErrorBoundary>,
      }, {
        path: "/clients/search",
        element: <ErrorBoundary><ClientLandingPage thanks={true} /></ErrorBoundary>,
      }, {
        path: "/clients/create_user",
        element: <ErrorBoundary><ClientLandingPage thanks={true} /></ErrorBoundary>,
      }],
    }]
  );

  return <RouterProvider router={router} />
}
