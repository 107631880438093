import React, {useState, useEffect, useRef} from "react";

import Prompt from "../forms/Prompt";
import Sidebar from "../components/Sidebar";

export const formPadding = "tw-gap-4";

export const textInputClasses = "tw-min-w-0 tw-grow tw-rounded-lg tw-bg-white tw-p-2 thick-light-purple-border";

export function TextInput(props) {
  const {
    label, subLabel, defaultValue, placeholder,
    onChange, classes, useTextArea, disabled
  } = props;
  return <div key={label} className={`tw-flex tw-flex-col ${formPadding}`}>
    {!!label && (<p className="tw-font-semibold text-16">{label}</p>)}
    {!!subLabel && (<p className="tw-font-light text-12">{subLabel}</p>)}
    {useTextArea && 
    <textarea
      disabled={disabled}
      className={`${textInputClasses} ${classes}`}
      defaultValue={defaultValue}
      value={props.value}
      placeholder={placeholder}
      onChange={x => onChange(x.target.value)}
    />}
    {!useTextArea && <input
      disabled={disabled}
      className={`${textInputClasses} ${classes}`}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={x => onChange(x.target.value)}
    />}
  </div>;
}

export default function Editor(props) {
  const [selectedSection, setSelectedSection] = useState(0);

  const {changesMade, changesRecentlySaved, saveChanges, sections, renderPreview} = props;

  const renderSaveButton = () => {
    return <button
      className={changesMade ? "dark-accent-button" : "dark-accent-button-disabled"}
      disabled={!changesMade}
      onClick={saveChanges}
    >{changesRecentlySaved && !changesMade ? "Saved" : "Save changes"}</button>
  };

  const scrollToSection = (i) => {
    document.getElementById(`section${i}`).scrollIntoView({
      behavior: "smooth", block: "center" /* or center, end, nearest */,
    });
  };

  const renderSection = (section, index) => {
    return <div
      key={index}
      id={`section${index}`}
      onMouseEnter={() => setSelectedSection(index)}
    >
      <p className="tw-text-xl tw-font-bold tw-mb-2">{section.title}</p>
      {section.render()}
      <hr style={{margin: "2rem 0"}} className="light-purple-divider" />
    </div>;
  }

  const prompt = useRef();

  const preventUnload = (event) => {
    if (changesMade) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);

    window.removeEventListener('beforeunload', preventUnload);
  }, [changesMade]);

  return (
    <>
    <Prompt innerRef={prompt} when={changesMade} message="You have unsaved changes. Leave anyway?" />
    <p className="tw-text-xl tw-font-bold tw-m-4">{props.title}</p>
    <div className="tw-w-full tw-flex tw-justify-between tw-px-6 tw-gap-16 tw-box-border">
      {sections.length > 1 && <Sidebar
        sections={sections} selectedSection={selectedSection}
        setSelectedSection={(i) => {
          setSelectedSection(i);
          scrollToSection(i);
        }}
      />}
      <div className="tw-flex tw-flex-col tw-grow tw-max-w-full lg:tw-max-w-[50%]">
        {sections.map(renderSection)}
        {renderSaveButton()}
      </div>
      <div className={
        "tw-hidden lg:tw-flex tw-flex-col tw-w-80"
      }>
        {renderPreview()}
      </div>
    </div>
    </>
  );
};
