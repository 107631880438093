import React from "react";

import {amountCentsToStringUSD} from "../../utils/currency";
import {getDefaultPhoto} from "../../utils/defaultPhotos";

import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parseISO, format} from "date-fns";

const LARGE_CARD_H_PX = 300;
const LARGE_CARD_W_PX = 300;

const MED_CARD_H_PX = 120;
const MED_CARD_W_PX = 300;

const SMALL_CARD_H_PX = 65;

function onClick(user, shortProductType, productUrl, newWindow = false) {
  return () => {
    if (!user) {
      return;
    }
    const url = `//${user.slug}.${process.env.ROOT_DOMAIN}/${shortProductType}/${productUrl}`;
    if (newWindow) {
      window.open(url, "_blank");
    }
    window.location.assign(url);
  };
}

function PriceCircle({price, positioning, stripCents}) {
  const renderPrice = () => {
    if (!price) {
      return <FontAwesomeIcon
        icon={faArrowRight}
        style={{color: "#ffffff"}}
      />;
    }
    return <p style={{fontSize: "16px", color: "white",}}>{
      price ? amountCentsToStringUSD(price, false, stripCents) : "Free"
    }</p>
  };

  const radius = 1.5;
  return <div
    className={
      "dark-purple-background big-dark-shadow"
    }
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      position: "relative",
      height: `${radius * 2}rem`,
      width: `${radius * 2}rem`,
      borderRadius: `${radius}rem`,
      ...positioning(radius),
    }}
  >
    {renderPrice()}
  </div>;
}

function ContentCardL({title, thumbUrl, price, link}) {
  const widthClasses = "tw-w-[300px]"
  const circlePositioning = (radius) => ({
    position: "relative",
    "top": `-${radius}rem`,
    marginBottom: `-${radius * 2}rem`,
    marginRight: `-${radius}rem`,
    alignSelf: "flex-end",
  });
  return (
    <a href={link}>
      <div
        className={
          "tw-h-[300px] big-dark-shadow tw-border-box tw-mr-4 " +
          `${widthClasses} tw-flex tw-flex-col tw-rounded-lg tw-cursor-pointer `
        }
      >
        <img className="tw-w-full" style={{
          aspectRatio: 4/3.0, objectFit: "cover",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }} src={thumbUrl} />
        <PriceCircle price={price} stripCents={true} positioning={circlePositioning} />
        <p className="tw-text-bold tw-text-xl tw-text-bold tw-p-4">{title}</p>
      </div>
    </a>
  );
};

function DatePreview({date}) {
  const radiusPx = "45";
  const dayOfWeek = format(date, "eee");
  const dayOfMonth = format(date, "dd");
  const month = format(date, "MMM");

  return (
    <div
      className={
        "tw-rounded-lg tw-flex tw-flex-col tw-justify-center tw-items-center"
      }
      style={{
        height: `${MED_CARD_H_PX}px`,
        width: `${MED_CARD_H_PX}px`,
        background: "#f3eeff",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <div
        className="tw-flex tw-flex-col tw-justify-center tw-items-center thick-dark-border"
        style={{
          height: `${radiusPx * 2}px`,
          width: `${radiusPx * 2}px`,
          margin: "5px",
          borderRadius: `${radiusPx}px`,
          background: "white",
        }}
      >
        <p style={{fontSize: "12px"}}>{dayOfWeek}</p>
        <p style={{fontSize: "28px"}}>{dayOfMonth}</p>
        <p style={{fontSize: "14px"}}>{month}</p>
      </div>
    </div>
  );
}

function ContentCardM({title, previewRenderer, price, onClick}) {
  const circlePositioning = (radius) => ({
    "top": `-${radius}rem`,
    right: `-${radius}rem`,
    marginBottom: `-${radius * 2}rem`,
    "float": "right",
  });

  return (
    <div style={{marginTop: "2rem", marginRight: "2rem", cursor: "pointer"}} onClick={onClick}>
      <PriceCircle price={price} positioning={circlePositioning} />
      <div
        style={{
          height: `${MED_CARD_H_PX}px`,
          width: `${MED_CARD_W_PX}px`,
        }}
        className={
          "big-dark-shadow tw-rounded-lg thick-dark-border"
        }
      >
        <div className="tw-flex tw-h-full tw-min-w-full tw-items-center">
          {previewRenderer()}
          <div className="tw-grow tw-max-h-full tw-flex tw-flex-col tw-p-4 tw-box-border tw-overflow-clip">
            <p className="tw-min-w-0 tw-max-h-full tw-text-ellipsis tw-leading-[1.05] tw-text-lg tw-text-bold">{title}</p>
          </div>
        </div>
      </div>
    </div>
    )
};

function ContentCardS({title, subtitle, price, onClick}) {
  const circlePositioning = (radius) => ({
    top: `${radius}rem`,
    marginBottom: `-30px`,
    right: `-${radius}rem`,
    "float": "right",
    boxShadow: "2px 4px 0px #0a2175",
  });

  return (
    <div style={{
      marginRight: "2rem", cursor: "pointer", boxSizing: "border-box"
    }} onClick={onClick}>
      <PriceCircle price={price} positioning={circlePositioning} />
      <div
        className={
          "tw-w-full thick-dark-border dark-shadow tw-p-2 tw-box-border " +
          "tw-justify-center tw-items-start tw-flex tw-flex-col tw-rounded-lg"
        }
        style={{height: `${SMALL_CARD_H_PX}px`}}
        onClick={onClick}
      >
        <p className="tw-font-bold text-16 tw-pr-4">{title}</p>
        <p className="text-12 tw-pr-4">{subtitle}</p>
      </div>
    </div>
  );
};

export function ServiceContentCard({sv, user, link}) {
  const thumbPreviewRenderer = () => <img
    src={sv.thumb_url || getDefaultPhoto(sv.id)}
    className="tw-rounded-lg tw-w-full"
    style={{
      objectFit: "cover",
    }}
  />;
  return <ContentCardL
    title={sv.name} thumbUrl={sv.cover_photo_url || getDefaultPhoto(sv.id)}
    price={sv.cost} link={link}
  />;
}

export function DownloadContentCard({dl, user}) {
  const thumbPreviewRenderer = () => <img
    src={dl.thumb_url || getDefaultPhoto(dl.id)}
    className="tw-rounded-lg"
    style={{
      height: `${MED_CARD_H_PX}px`,
      width: `${MED_CARD_H_PX}px`,
      objectFit: "cover",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }}
  />;
  return <ContentCardM
    title={dl.download_title} previewRenderer={thumbPreviewRenderer}
    price={dl.amount} onClick={onClick(user, "d", dl.download_url)}
  />
};

export function EventContentCard({ev, user}) {
  const datePreviewRenderer = () => <DatePreview date={parseISO(ev.date_start_time)} />;
  return <ContentCardM
    title={ev.title} previewRenderer={datePreviewRenderer}
    price={ev.amount} onClick={onClick(user, "e", ev.event_url)}
  />
};

export function LinkContentCard({li, user}) {
  return <ContentCardS
    title={li.title} subtitle={li.details}
    onClick={onClick(user, "l", li.id, true)}
  />
};
