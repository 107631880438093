import ReactOnRails from 'react-on-rails';

import AdminContainer from "../bundles/admin/AdminContainer";
import AuthZoom from "../bundles/profile/AuthZoom";
import ClientAppContainer from "../bundles/client/ClientAppContainer";
import TherapistDashboardContainer from "../bundles/therapist_dashboard/DashboardContainer";
import UserDashboardContainer from "../bundles/user_dashboard/DashboardContainer";
import UserTypeContainer from "../bundles/user_type/UserTypeContainer";
import TherapistOnboardContainer from "../bundles/therapist_onboard/TherapistOnboardContainer";
import ProviderProfileContainerHandle from "../bundles/profile/ProviderProfileContainerHandle";
import ZoomDocumentation from "../bundles/zoom/ZoomDocumentation";
import ZoomSupport from "../bundles/zoom/ZoomSupport";

import $ from "expose-loader?exposes=$,jQuery!jquery"
import "stylesheets/tailwind.scss";
import {} from "jquery-ujs";

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

ReactOnRails.register({
  AdminContainer,
  AuthZoom,
  ClientAppContainer,
  TherapistDashboardContainer,
  UserDashboardContainer,
  UserTypeContainer,
  TherapistOnboardContainer,
  ProviderProfileContainerHandle,
  ZoomDocumentation,
  ZoomSupport,
});
