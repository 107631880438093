import React, {useState} from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons'

export default function MultiForm({
  entries, maxCount, renderForm, deleteFn,
  addButtonClassName, addButtonText,
}) {
  const [count, setCount] = useState(entries?.length || 1);

  const decreaseCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  }

  const increaseCount = () => {
    if (count + 1 <= maxCount) {
      setCount(count + 1);
    }
  }

  const renderRemoveButton = (i) => {
    return (
      <div className="multiFormRemoveButton"
        onClick={() => {
          if (i < entries.length) {
            deleteFn(i);
          }
          decreaseCount();
        }}
      >
        <FontAwesomeIcon
          icon={faTimes}
        />
      </div>
    );
  };

  const renderAddButton = () => {
    const emptyEntriesPresent = count > entries.length;
    if (count === maxCount || emptyEntriesPresent) {
      return null;
    }
    return (
      <div className={addButtonClassName || "multiFormAddButton"}
        onClick={increaseCount}
      >
        <FontAwesomeIcon
          style={{color: "#733dff"}}
          icon={faPlus}
        />
        <p className="dark-accent-text">{addButtonText || "Add"}</p>
      </div>
    );
  };

  let forms = [];
  for (let i = 0; i < count; i++) {
    forms.push(
      <div className="multiFormContainer" key={i}>
        {renderForm(i)}
        {renderRemoveButton(i)}
      </div>
    );
  }

  return (
    <>
      {forms}
      {renderAddButton()}
    </>
  );
};
