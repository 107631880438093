import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


class DropdownSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false
    }

    this.renderOptions = this.renderOptions.bind(this)
    this.selectOption = this.selectOption.bind(this)
  }

  selectOption(item) {
    this.setState({
      dropdownOpen: false
    })
    this.props.selectOption(item, this.props.attribute)
  }

  renderOptions() {
    return this.props.options.map((item, i) => {
      return <p
        key={i} onClick={() => this.selectOption(item)}
        className="text-16 tw-py-1"
      >{item[this.props.optionName]}</p>;
    })

  }

  render() {
    const placeholder = this.props.placeholder || "(select an option)";
    return(
      <div>
        <div
          className="tw-flex tw-justify-between light-accent-border tw-p-2"
          onClick={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
            <p
              className="dark-text font-16"
            >{this.props.selected || placeholder}
            </p>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="dark-text"
            />
        </div>
        {this.state.dropdownOpen &&
          <div className="dropdownSelectContainer">
          {this.renderOptions()}
          </div>
        }
      </div>
    )
  }

}

export default DropdownSelect;
