import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import { useLocation } from "react-router-dom";
import ProviderProfileContainer from "./ProviderProfileContainer";
import DownloadsContainer from "./DownloadsContainer";
import EventsContainer from "./EventsContainer";
import Service from "./Service";
import { apiBase } from "../../utils/apiRequest";

class ProviderProfileContainerHandle extends React.Component {
  async loadUser() {
    const userResponse = await apiBase("/get_user", "POST", {
      user_id: this.props.id,
    });

    const currentUserResponse = await apiBase("/get_current_user", "GET");

    this.setState({
      user: JSON.parse(userResponse.data.user),
      activeSubscription: JSON.parse(userResponse.data.active_subscription),
      isCurrentUser: JSON.parse(userResponse.data.isCurrentUser),
      currentUser: JSON.parse(currentUserResponse?.data?.user),
      loaded: true,
    });
  }

  async componentDidMount() {
    await this.loadUser();
  }

  render() {
    if (this.props.isPrivate) {
      return <div className="light-purple-background tw-rounded-lg tw-flex tw-items-center tw-m-8">
        <p className="tw-text-xl tw-text-bold">This profile is private.</p>
      </div>
    }

    return (
      <ErrorBoundary>
      <BrowserRouter>
        {this?.state?.user && (
          <Routes>
            <Route
              path={`/`}
              element={
                <ProviderProfileContainer
                  id={this.props.id}
                  liveProfile={this.props.liveProfile}
                  user={this.state.user}
                  currentUser={this.state.currentUser}
                />
              }
            />
            <Route
              path={`/edit_profile`}
              element={
                <ProviderProfileContainer
                  id={this.props.id}
                  liveProfile={this.props.liveProfile}
                  user={this.state.user}
                  currentUser={this.state.currentUser}
                />
              }
            />
            <Route
              path={`/downloads`}
              element={
                <DownloadsContainer
                  user={this.state}
                  location={() => useLocation()}
                  isCurrentUser={this.state?.isCurrentUser}
                />
              }
            />
            <Route
              path={`/d/${this.props?.downloadUrl}`}
              element={
                <DownloadsContainer
                  user={this.state}
                  location={() => useLocation()}
                  isCurrentUser={this.state?.isCurrentUser}
                  downloadUrl={this.props?.downloadUrl}
                />
              }
            />
            <Route
              path={`/e/${this.props?.eventUrl}`}
              element={
                <EventsContainer
                  user={this.state}
                  location={() => useLocation()}
                  isCurrentUser={this.state?.isCurrentUser}
                  eventUrl={this.props?.eventUrl}
                />
              }
            />
            <Route
              path={`/sv/${this.props.serviceSlug}`}
              element={
                <Service
                  user={this.state.user}
                  currentUser={this.state.currentUser}
                  location={() => useLocation()}
                  isCurrentUser={this.state.isCurrentUser}
                  sv={this.state.user.user_services.find(x => (
                    (x.availability & 1) && x.slug == this.props.serviceSlug
                  ))}
                />
              }
            />
          </Routes>
        )}
      </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

export default ProviderProfileContainerHandle;
