import React, {useState, useEffect} from "react";

import TableView from "../components/TableView";

import Logo from "../../../assets/images/logo_purple.png";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {format, parseISO} from "date-fns";

const formatStamp = (stamp) => stamp ? format(parseISO(stamp), "MMM dd, yyyy") : "";

const COMMON_FIELDS = [{
  title: "ID",
  classes: "tw-w-4",
  value: u => u.id,
}, {
  title: "Email",
  value: u => u.email,
}, {
  title: "Name",
  value: u => `${u.first_name || ""} ${u.last_name || ""}`,
}, {
  title: "Specialties",
  value: u => u.specialties?.map(s => s.name).join(", "),
}, {
  title: "Updated",
  value: u => formatStamp(u.updated_at),
}];

const PAGES = [{
  title: "Clients",
  render: (props) => <TableView
    fixedWidth={true}
    content={JSON.parse(props.clients).reverse() || []}
    rowLinkFn={i => {}}
    fields={[
      ...COMMON_FIELDS,
      {
        title: "Max $",
        value: u => u.client_max_cost,
      },
      {
        title: "Ins Pref",
        value: u => u.client_insurance_preference,
      },
      {
        title: "Address",
        value: u => u.client_location && u.client_location["formatted_address"],
      },
    ]} />
}, {
  title: "Providers",
  render: (props) => {
    const providers = JSON.parse(props.providers).reverse() || [];
    return <TableView
      fixedWidth={true}
      rowLinkFn={i => window.location.assign(
        `//${providers[i].slug}.${process.env.ROOT_DOMAIN}`
      )}
      content={providers}
      fields={[
        ...COMMON_FIELDS,
      ]} />;
    }
}];

function InnerContainer(props) {
  const [pageId, setPageId] = useState(0);

  const containerClasses = (
    "tw-p-2 md:tw-p-8 tw-box-border tw-flex tw-flex-col tw-items-center tw-justify-center " +
    "tw-grow"
  );

  const tabClasses = "tw-rounded-lg light-accent-border tw-p-2";
  const selectedTabClasses = `${tabClasses} light-accent-background`;

  const getTab = (page, id) => {
    return <button
      key={id}
      className={pageId === id ? selectedTabClasses : tabClasses}
      onClick={() => setPageId(id)}
    >{page.title}</button>
  };

  const tabBar = <div className="tw-w-full tw-flex tw-gap-2 tw-p-2 tw-box-border tw-bg-white">
    <div className={`${tabClasses} tw-mr-auto tw-flex tw-items-center`}>
      <img onClick={() => window.location.assign("/")} src={Logo} className="tw-h-5" />
    </div>
    {PAGES.map(getTab)}
  </div>;

  return <div className={
    "tw-w-full tw-min-h-screen light-accent-background tw-flex " +
    "tw-flex-col tw-items-center"
  }>
    {tabBar}
    <div className={containerClasses}>
      {PAGES[pageId].render(props)}
    </div>
  </div>;
}

export default function AdminContainer(props) {
  const router = createBrowserRouter(
    [{
      path: "/admin",
      element: <InnerContainer {...props} />,
    }]
  );

  return <RouterProvider router={router} />
}
