import React, { useState, useEffect } from "react";
import TheGoodsContent from "../components/TheGoodsContent";
import TheDeetsContent from "../components/TheDeetsContent";
import TheSparkleContent from "../components/TheSparkleContent";
import StepsIndexContext from "../context/StepsIndexContext";
import UserDownloadContext from "../context/UserDownloadContext";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faExternalLinkAlt,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import SubMenuModal from "../components/SubMenuModal";
import ErrorBoundary from "../components/ErrorBoundary";

function CreateDownloadContainer(props) {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [userDownload, setUserDownload] = useState();
  const [openSubMenu, setOpenSubMenu] = useState();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const steps = [
    {
      id: 0,
      title: "The Goods",
      content: (
        <TheGoodsContent
          updateUserDownload={(field, value) =>
            updateUserDownload(field, value)
          }
          user={props.user}
        />
      ),
    },
    {
      id: 1,
      title: "The Deets",
      content: (
        <TheDeetsContent
          updateUserDownload={(field, value) =>
            updateUserDownload(field, value)
          }
          user={props.user}
        />
      ),
    },
    {
      id: 2,
      title: "The Sparkle",
      content: (
        <TheSparkleContent
          updateUserDownload={(field, value) =>
            updateUserDownload(field, value)
          }
          user={props.user}
        />
      ),
    },
  ];

  const handleDelete = (download_id) => {
    $.ajax({
      url: `/delete_user_download`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: download_id,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        window.location.assign("/dashboard/list-download");
        return data;
      },
      error: (xhr, status, err) => {
        setMessage(JSON.parse(xhr?.responseText)?.message || "Internal error");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      },
    });
  };

  const handleArchive = (download_id) => {
    $.ajax({
      url: `/update_user_download`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: download_id,
        download_status: "DRAFT",
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        window.location.assign("/dashboard/list-download");
      },
      error: (xhr, status, err) => {
        return err;
      },
    });
  };

  const getUserDownload = (id) => {
    $.ajax({
      url: `/get_user_download`,
      dataType: "JSON",
      type: "POST",
      data: { id: id, user_id: props.user.id },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        const [{ ...download }] = JSON.parse(data.download);
        setUserDownload(download);
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const updateUserDownload = (field, value) => {
    if (userDownload?.id) {
      let data = {};
      (data.id = userDownload?.id), (data[field] = value);

      $.ajax({
        url: `/update_user_download`,
        dataType: "JSON",
        type: "POST",
        data: data,
        header: { "Content-Type": "application/json" },
        success: (data) => {
          return data;
        },
        error: (xhr, status, err) => {
          return err;
        },
      });
    } else {
      let data = {};
      data[field] = value;
      data.download_status = "DRAFT";
      $.ajax({
        url: `/create_user_download`,
        dataType: "JSON",
        type: "POST",
        data: data,
        header: { "Content-Type": "application/json" },
        success: (data) => {
          setUserDownload(data.data.downloads);
        },
        error: (xhr, status, err) => {
          return err;
        },
      });
    }
  };

  const renderSubMenu = (download) => {
    const item = {
      id: download.id,
      status: download.download_status,
      income_received: download.income_received,
      mouseX: download.mouseX,
      mouseY: download.mouseY,
    };
    return (
      <SubMenuModal
        item={item}
        handleDelete={handleDelete}
        handleArchive={handleArchive}
        handleClose={() => setOpenSubMenu(null)}
      />
    );
  };

  useEffect(() => {
    if (id && !userDownload) {
      getUserDownload(id);
    }
  }, [id]);

  useEffect(() => {
    props.onFinish();
  }, []);

  return (
    <div className="createContainer">
      {openSubMenu && renderSubMenu(openSubMenu)}
      <div className="createHeaderContainer">
        <div className="stackableContainerStyle">
          <h1>Create a download</h1>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {userDownload?.download_status === "PUBLISHED" && (
            <div
              className="view--live--button"
              onClick={() => {
                const url = `//${props.user?.slug}.${process.env.ROOT_DOMAIN}/d/${userDownload?.download_url}`;
                window.open(url, "_blank");
              }}
            >
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ color: "#733DFF" }}
              />
              <span>View live</span>
            </div>
          )}
          <FontAwesomeIcon
            icon={faEllipsisH}
            style={{ color: "#733dff", marginRight: "15px" }}
            onMouseDown={(e) => {
              setOpenSubMenu({
                ...userDownload,
                mouseX: e.clientX,
                mouseY: e.clientY,
              });
            }}
          />
          <div
            className="xButton"
            onClick={() => {
              navigate("/dashboard/list-download");
              props.refreshUserData();
            }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ color: "#fff" }} />
          </div>
        </div>
      </div>
      <div className="createDownloadContent">
        <StepsIndexContext.Provider value={[step, setStep]}>
          <UserDownloadContext.Provider value={[userDownload, setUserDownload]}>
            <div className="createDownloadContentSteps">
              {steps.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className="stepTitles">
                      <p>{item.title}</p>
                      <div
                        className={
                          index <= step
                            ? "stepMarker stepMarkerDone"
                            : "stepMarker"
                        }
                        onClick={() => {
                          setStep(index);
                          document
                            .getElementById(`step${index}`)
                            .scrollIntoView({
                              behavior: "smooth",
                            });
                        }}
                      ></div>
                    </div>
                    <div
                      className={
                        index <= step - 1
                          ? "separator separatorDone"
                          : "separator"
                      }
                    ></div>
                  </div>
                );
              })}
            </div>
            <div className="stepcontents">
              {steps?.map((step, index) => {
                return <div key={index}>{step.content}</div>;
              })}
            </div>
          </UserDownloadContext.Provider>
        </StepsIndexContext.Provider>
      </div>
    </div>
  );
}
export default function CreateDownloadContainerEB(props) {
  return <ErrorBoundary>
    <CreateDownloadContainer {...props} />
  </ErrorBoundary>;
}
