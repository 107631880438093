import React from "react";
import Logo from "../../../assets/images/logo_purple.png";

export default function NavBarDownlodas() {
  return (
    <header className="downloadsHeader">
      <div className="header--logo">
        <a href="/dashboard">
          <img src={Logo} className="navLogo" />
        </a>
      </div>
      <div className="header--buttons">
        {/* <a href="/find-care" className="hide--mobile">
          <h2>Find Care</h2>
        </a> */}
        <a href="{process.env.WEB_URL}/providers" className="hide--mobile">
          <h2>For Professionals</h2>
        </a>
        <div className="pipe" />
        <a href={`${process.env.APP_URL}/login`}>
          <h2>Login</h2>
        </a>
        <button
          className="button--join"
          onClick={() => window.location.assign(`${process.env.APP_URL}/signup`)}
        >
          Join early!
        </button>
      </div>
    </header>
  );
}
