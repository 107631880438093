import React, { useState } from "react";

const AutoCompleteInput = (props) => {
  const { options, handleSelectedValue } = props;
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState("");

  return (
    <div>
      <input
        placeholder={props.placeholder || "Type or select topics"}
        onClick={() => setDisplay(!display)}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          const { key } = e;
          const trimmedInput = search.trim();
          const nextKeys = [",", "Tab", "Enter"];

          if (nextKeys.includes(key) && trimmedInput.length) {
            setSearch("");
            e.preventDefault();
            handleSelectedValue(trimmedInput);
          }
        }}
      />
      {display && (
        <div className="auto--container">
          {options
            .filter((item) => {
              if (search === "") return true;
              return item.name?.toLowerCase().includes(search.toLowerCase());
            })
            .map((value, index) => {
              return (
                <div
                  className="auto--container--options"
                  key={index}
                  onClick={() => {
                    setDisplay(false);
                    handleSelectedValue(value?.name);
                    setSearch("");
                  }}
                >
                  <span>{value?.name}</span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default AutoCompleteInput;
