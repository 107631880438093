import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAudio,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faPen,
  faFileAlt,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {amountCentsToStringUSD} from "../../utils/currency";
import { Link } from "react-router-dom";
import Notice from "./Notice";

const DownloadRow = (props) => {
  const { downloads } = props;
  const [scrollx, setscrollx] = useState(0);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const renderBuyOrDownloadButton = (item) => {
    let myCopy = props?.myDownloads?.find((download) => {
      return download.id === item.id;
    });
    if (myCopy) {
      return (
        <div>
          <div
            className="handle--download--button"
            onClick={() => {window.open(myCopy.file_url);}}
          >
            <span>Download</span>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div
          className="handle--download--button"
          onClick={() => {props.handleBuy(item);}}
        >
          {item.amount > 0 ? (
            <span>Buy for {amountCentsToStringUSD(item.amount)}</span>
          ) : (
            <span>Download for free!</span>
          )}
        </div>
      </div>
    );
  }

  const getPreview = (thumbnail) => {
    const file = dataURLtoFile(thumbnail, "thumbnail_preview");

    return URL.createObjectURL(file);
  };

  const FileInfo = ({
    file_size,
    kind_content,
    number_of_pages,
    time_duration,
  }) => {
    if (kind_content === "Audio")
      return (
        <div>
          {time_duration && <span>{time_duration} minutes · </span>}
          {file_size && <span>{file_size} KB</span>}
        </div>
      );
    
      return (
        <div>
          {number_of_pages && <span>{number_of_pages} pages · </span>}
          {file_size && <span>{file_size} KB</span>}
        </div>
      );
  };

  const renderTooltip = () => {
    setShowTooltip(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  const handleLeftArrow = () => {
    let x = scrollx + Math.round(windowSize);
    if (x > 0) {
      x = 0;
    }

    setscrollx(x);
    props.handleBackButton();
  };


  const handleRightArrow = () => {
    let x = scrollx - Math.round(windowSize);
    let listW = downloads.length * windowSize;

    if (windowSize - listW > x) {
      x = windowSize - listW - 60;
    }
    setscrollx(x);
    props.handleNextButton();
  };

  return (
    <div className="downloadRow">
      {showAlert && <Notice message={message} />}
      <div className="downloadRow--listarea">
        <div
          className="downloadRow--list"
          style={{
            marginLeft: scrollx,
            width: downloads.length * windowSize,
          }}
        >
          {downloads.length > 0 &&
            downloads.map((item, key) => (
              <div key={key} className="downloadRow--item">
                <div className="featured--download--content">
                  <div className="featured--file--info">
                    <div className="featured--kind--content">
                      {item.kind_content === "PDF" && (
                        <FontAwesomeIcon
                          className="icon-cog"
                          icon={faFileAlt}
                          style={{ cursor: "default" }}
                        />
                      )}
                      {item.kind_content === "Audio" && (
                        <FontAwesomeIcon
                          className="icon-cog"
                          icon={faFileAudio}
                          style={{ cursor: "default" }}
                        />
                      )}
                      <p>{item.kind_content}</p>
                    </div>
                    <FileInfo
                      file_size={item?.file_size}
                      kind_content={item?.kind_content}
                      number_of_pages={item?.number_of_pages}
                      time_duration={item?.time_duration}
                    />
                    <h2>{item.download_title}</h2>
                    {props.isCurrentUser && (
                      <div style={{ display: "flex" }}>
                        <div
                          className="handle--download--button"
                          onClick={() =>
                            window.location.replace(
                              `/dashboard/create/download?id=${item.id}`
                            )
                          }
                        >
                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                              marginRight: "15px",
                            }}
                            icon={faPen}
                          />
                          <span>Edit details</span>
                        </div>
                        <div
                          className="handle--download--button--secondary"
                          onClick={() => renderTooltip()}
                        >
                          {showTooltip && (
                            <div className="tooltip">Link copied!</div>
                          )}
                          <FontAwesomeIcon
                            style={{
                              color: "#733dff",
                              marginRight: "15px",
                            }}
                            icon={faCopy}
                          />
                          <span>Copy share link</span>
                        </div>
                      </div>
                    )}
                    {!props.isCurrentUser && renderBuyOrDownloadButton(item)}
                  </div>
                  <div className="thumbnail--preview">
                    {scrollx !== 0 ? (
                      <div
                        className="downloadRow--left"
                        onClick={handleLeftArrow}
                      >
                        <FontAwesomeIcon
                          className="icon-cog"
                          icon={faArrowAltCircleLeft}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "52px" }} className="hide--mobile" />
                    )}
                    <div className="feature--thumbnail">
                      {item.thumb_url && (
                        <img src={item.thumb_url} alt="thumbnail" />
                      )}
                    </div>
                    <div className="whithe--separator" />
                    {scrollx + (downloads.length * windowSize - windowSize) !==
                    0 ? (
                      <div
                        className="downloadRow--right"
                        onClick={handleRightArrow}
                      >
                        <FontAwesomeIcon
                          className="icon-cog"
                          icon={faArrowAltCircleRight}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "52px" }} className="hide--mobile" />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DownloadRow;
