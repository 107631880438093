import React, {useEffect, useState} from "react";

import {TextInput, formPadding, textInputClasses} from "../components/Editor";
import NormalSelect from "./NormalSelect";

import countries from "../utils/coutries";

import {faCar, faCouch, faLaptop, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function EditLocation(props) {
  const {location, changeLocation} = props;
  const l = location;

  const renderIconSelection = () => {
    const icons = [{
      field: "virtually",
      icon: faLaptop,
    }, {
      field: "my_office",
      icon: faCouch,
    }, {
      field: "their_location",
      icon: faCar,
    }];

    const selectedStyle = {
      background: "rgba(115, 61, 255, 0.10)",
      border: "2px solid #733dff",
    };

    const deselectedStyle = {
      border: "2px solid #f3eeff",
    };

    return <div className="tw-flex tw-gap-2">
      {icons.map((icon, i) => (
        <div key={i}
          onClick={() => changeLocation(icon.field, !l[icon.field])}
          className={
            "tw-flex tw-flex-col tw-justify-center tw-items-center " +
            "tw-gap-2 tw-rounded-lg tw-w-[100px] tw-h-[100px]"
          }
          style={l[icon.field] ? selectedStyle : deselectedStyle}
        >
          <FontAwesomeIcon
            icon={icon.icon}
            size="2x"
            style={{color: "#c1a9fe"}}
          />
          <p className="text-12 tw-font-semibold">{icon.field.replace("_", " ")}</p>
        </div>
      ))}
    </div>;
  };

  const renderTextInput = (fieldName, placeholder, label, subLabel = null) => {
    return <TextInput
      label={label}
      subLabel={subLabel}
      key={fieldName}
      defaultValue={l[fieldName]}
      onChange={x => changeLocation(fieldName, x)}
      placeholder={placeholder}
    />;
  };

  const growAndShrink = {
    flex: "1 1 auto",
    width: 0,
  };

  const renderOfficeLocation = () => {
    if (!l.my_office) {
      return null;
    }

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      {renderTextInput("business_name", "Business name", "Business name (optional)")}
      {renderTextInput("street_adress", "Street address", "Street address")}
      {renderTextInput("unit", "e.g., Apt B", "Unit (optional)")}
      <div className={`tw-flex ${formPadding}`}>
        <div style={growAndShrink}>
          {renderTextInput("city", "City", "City")}
        </div>
        <div style={growAndShrink}>
          {renderTextInput("state", "State or province", "State or province")}
        </div>
      </div>
      <div className={`tw-flex ${formPadding}`}>
        <div style={growAndShrink}>
          {renderTextInput("zipcode", "12345", "Zip or postal code")}
        </div>
        <div style={growAndShrink}
          className={`tw-grow tw-flex tw-flex-col ${formPadding} tw-box-border`}
        >
          <p className="tw-font-semibold text-16">Country or region</p>
          <NormalSelect
            options={countries}
            value={l.region ? [{
              value: `${l.region}`,
              label: `${l.region}`
            }] : ""}
            placeholder="Country"
            onChange={x => changeLocation("region", x.label)}
          />
        </div>
      </div>
    </div>;
  }

  const renderTheirLocation = () => {
    if (!l.their_location) {
      return null;
    }

    return <div className={`tw-flex ${formPadding} tw-items-end tw-box-border`}>
      <div style={growAndShrink}>
        {renderTextInput(
          "zipcode_client", "12345", "Where are you willing to travel for clients?",
          "Zip code, please!"
        )}
      </div>
      <div style={growAndShrink}>
        {renderTextInput(
          "max_distance", "", "Max distance", "in miles"
        )}
      </div>
    </div>;
  }

  return (
    <div className={`tw-flex tw-flex-col ${formPadding} tw-box-border ${props.containerStyles || ""}`}>
      <p className="text-16 tw-font-semibold">How do you meet with clients?</p>
      <p className="text-12 tw-font-light">Select as many as apply.</p>
      {renderIconSelection()}
      {renderOfficeLocation()}
      {renderTheirLocation()}
    </div>
  );
}
