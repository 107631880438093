import React from "react"
import PropTypes from "prop-types"
import TextInput from '../forms/TextInput';

class CredentialsOnboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
      credentials: '',
      timeout: null
    }
    this.updateUser = this.updateUser.bind(this)
  }

  componentDidMount() {
    this.setState({
      credentials: this.props.currentUser.credentials === null ? '' : this.props.currentUser.credentials
    })
  }

  updateUser(key, value) {
    this.setState({
      credentials: value
    })

    const { timeout } = this.state;
      clearTimeout(timeout);
      this.setState({
        timeout: setTimeout(() => {
          let data = {
            credentials: value
          }
          this.props.updateUser(data)
        }, 500)
      })
  }

  render () {
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{paddingBottom: 10}}>What is your professional title or titles?</h3>
          <h4 className="standard_label" style={{paddingBottom: 40, opacity: .5}}>ex. LCSW, MPH, MD, PsyD, CST</h4>
          <TextInput
            type="text"
            autofocus={true}
            value={this.state.credentials}
            attribute={'credentials'}
            handleChange={this.updateUser}
             />
        </div>
      </React.Fragment>
    );
  }
}

export default CredentialsOnboard
