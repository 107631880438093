import React from "react";
import MultiSelect from "../profile_forms/MultiSelect";

class SpecialtiesOnboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      specialties: [],
      userSpecialties: [],
      options: [],
      user: [],
      loaded: false,
      timeout: null,
      error: "",
    };
    this.queryClient = props.queryClient;
    this.loadSpecialties = this.loadSpecialties.bind(this);
    this.createSpecialtiesArray = this.createSpecialtiesArray.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderChoices = this.renderChoices.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getSelectedOptions = this.getSelectedOptions.bind(this);
  }

  loadSpecialties() {
    $.ajax({
      url: `../get_specialties`,
      dataType: "JSON",
      type: "GET",
      data: {},
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        this.setState(
          {
            specialties: JSON.parse(data.data.specialties),
            options: JSON.parse(data.data.specialties).filter(x => !x.private).map(
              (specialty) => ({
              value: specialty.id,
              label: specialty.name,
            })),
            loaded: true,
          },
          () => this.createSpecialtiesArray()
        );
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  }

  createSpecialtiesArray() {
    return this.props.currentUser.user_specialties.map((user_specialty, i) => {
      let array = this.state.userSpecialties;
      if (!array.includes(user_specialty.specialty.id)) {
        array.push(user_specialty.specialty.id);
        this.setState({
          userSpecialties: array,
        });
      }
    });
  }

  componentDidMount() {
    this.loadSpecialties();
    this.createSpecialtiesArray();
  }

  handleClick(value) {
    console.log(`handle click ${value}`);
    this.setState({
      error: "",
    });
    let data = {
      specialty_id: value,
      primary: true,
    };
    if (this.state.userSpecialties.includes(value)) {
      //remove user_specialty
      $.ajax({
        url: `../remove_user_specialty`,
        dataType: "JSON",
        type: "POST",
        data: data,
        header: {
          "Content-Type": "application/json",
        },
        success: (data) => {
          //this.props.updateUser()
        },
        error: function (xhr, status, err) {
          console.log(err);
        }.bind(this),
      });
      //remove from array
      let array = this.state.userSpecialties;
      let index = array.indexOf(value);
      array.splice(index, 1);
      this.setState({
        userSpecialties: array,
      });
    } else {
      if (this.state.userSpecialties.length > 4) {
        this.setState({
          error: "You can only have 5 primary specialties.",
        });
        return;
      }
      //create user_specialty
      $.ajax({
        url: `../create_user_specialty`,
        dataType: "JSON",
        type: "POST",
        data: data,
        header: {
          "Content-Type": "application/json",
        },
        success: (_) => {
          this.queryClient.invalidateQueries({queryKey: ["current_user"]});
        },
        error: function (xhr, status, err) {
          console.log(err);
        }.bind(this),
      });
      //add to array
      let array = [...this.state.userSpecialties, value];
      this.setState({
        userSpecialties: array,
      });
    }
  }

  createNewSpecialty(value) {
    const data = {
      specialty_name: value,
      primary: true,
    };
    $.ajax({
      url: `../create_user_specialty`,
      dataType: "JSON",
      type: "POST",
      data: data,
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        this.loadSpecialties();
        this.props.updateUser();
        const array = [...this.state.userSpecialties, data.id];
        this.setState({
          userSpecialties: array,
        });
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  }

  getSelectedOptions() {
    const selectedOptions = [];
    for (let i = 0; i < this.state.userSpecialties?.length; i++) {
      let option = this.state.options.find(
        (item) => item.value == this.state.userSpecialties[i]
      );
      if (option) {
        selectedOptions.push(option);
        console.log(`selected ${this.state.userSpecialties[i]}`);
      } else {
        console.log(`${this.state.userSpecialties[i]} not found in options`);
      }
    }
    return selectedOptions;
  }

  handleChange(newValue, actionMeta) {
    if (actionMeta.action == "create-option") {
      if (this.state.userSpecialties.length > 4) {
        this.setState({
          error: "You can only have 5 primary specialties.",
        });
        return;
      }
      this.createNewSpecialty(newValue.slice(-1)[0].value);
      return;
    }

    if (actionMeta.action == "select-option") {
      this.handleClick(newValue.slice(-1)[0].value);
    } else if (
      actionMeta.action == "remove-value" ||
      actionMeta.action == "pop-value"
    ) {
      // compare two arrays to get the object removed
      const removedObject = this.getSelectedOptions().filter(
        ({ value: id1 }) => !newValue.some(({ value: id2 }) => id2 === id1)
      );
      this.handleClick(removedObject[0].value);
    }
  }

  renderChoices() {
    return (
      <div>
        <MultiSelect
          options={this.state.options}
          onChange={this.handleChange}
          value={this.getSelectedOptions()}
          placeholder="Type or select specialties"
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{ paddingBottom: 10 }}>
            What are your specialties?
          </h3>
          <h4
            className="standard_label"
            style={{ paddingBottom: 40, opacity: 0.5 }}
          >
            Please select up to 5 specialties to help potential clients
            understand your expertise
          </h4>
          <p className="orange">{this.state.error}</p>
          {this.renderChoices()}
        </div>
      </React.Fragment>
    );
  }
}

export default SpecialtiesOnboard;
