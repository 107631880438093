import ph0 from "../../assets/images/default_photos/purple_swurple.png";
import ph1 from "../../assets/images/default_photos/pink_zebra.png";
import ph2 from "../../assets/images/default_photos/sweet_floral_raindrops.png";
import ph3 from "../../assets/images/default_photos/blobs_of_jupiter.png";

const default_photos = [
  ph0, ph1, ph2, ph3,
];

const random = (seed) => {
  var x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export const getDefaultPhoto = (seed) => {
  const index = Math.round(random(seed) * (default_photos.length - 1));
  return default_photos[index];
};
