import React, {useState, useEffect} from "react";

import CreateSubMenu from "../components/CreateSubMenu";

import Logo from "../../../assets/images/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faClipboardList,
  faPenFancy,
  faCog,
  faChalkboard,
  faWallet,
  faTimes,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  Link,
  useLocation,
} from "react-router-dom";

const isSelected = (path, menuItem) => {
  if (menuItem.route === "/dashboard")
    return path === "/dashboard";
  return path.startsWith(menuItem.route);
};

const menu = [
  {
    id: 0,
    name: "Overview",
    icon: faClipboardList,
    route: "/dashboard",
  },
  {
    id: 7,
    name: "Profile",
    icon: faUser,
    route: "/dashboard/edit-profile",
    subMenu: [
      {
        title: "Intro",
        route: "/dashboard/edit-profile/intro",
      },
      {
        title: "About",
        route: "/dashboard/edit-profile/about",
      },
      {
        title: "Links",
        route: "/dashboard/edit-profile/links",
      },
    ],
  },
  {
    id: 1,
    name: "Create",
    icon: faPenFancy,
    route: "/dashboard/create",
    subMenu: [
      {
        title: "Services",
        route: "/dashboard/create/list-services",
      },
      {
        title: "Downloads",
        subtitle: "BETA",
        route: "/dashboard/create/list-downloads",
      },
      {
        title: "Events",
        subtitle: "BETA",
        route: "/dashboard/create/list-events",
      },
    ],
  },
  {
    id: 2,
    name: "Contacts",
    icon: faUsers,
    route: "/dashboard/contacts",
  },
  {
    id: 3,
    name: "Income",
    icon: faWallet,
    route: "/dashboard/income",
  },
  {
    id: 4,
    name: "My collection",
    icon: faChalkboard,
    route: "/dashboard/my-collection",
  },
  {
    id: 6,
    name: "Settings",
    icon: faCog,
    route: "/dashboard/settings",
  },
];

function SubMenu(props) {
  const path = useLocation().pathname;

  const selectionIndex = props.subMenu.findIndex(x => isSelected(path, x));

  const getLineStyle = (item, index) => {
    let style = {
      borderLeft: "3px solid white",
      marginLeft: "24px",
      width: "24px",
    }
    if (selectionIndex < 0) {
      return style;
    }

    if (selectionIndex === index) {
      style.borderBottom = "3px solid white";
      style.marginBottom = "21px";
    } else if (selectionIndex < index) {
      style.visibility = "hidden";
    }
    return style;
  };

  return (
    <div className="createSubMenu tw-flex tw-flex-col tw-justify-center tw-my-2" style={{width: "90%"}}>
      {props.subMenu.map((item, index) => {
        return (
          <div className="tw-flex tw-w-full" key={item.route}>
            <div
              style={getLineStyle(item, index)}
            />
            <Link to={item.route} className="tw-w-full">
              <div
                className={
                  "tw-p-2 tw-my-1 tw-w-full tw-flex tw-items-center tw-gap-2 tw-rounded-lg buttonHover " + (
                    isSelected(path, item)
                    ? "tw-bg-white"
                    : ""
                  )
                }
                onClick={() => {
                  props.setNavigationMenuOpen(false);
                }}
              >
                <p className="tw-font-bold font-16">{item.title}</p>
                {!!item.subtitle && <p
                  className="dark-text text-10 tw-px-2 tw-rounded-full dark-border tw-bg-white">{item.subtitle}</p>}
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default function SideNav({navigationMenuOpen, setNavigationMenuOpen}) {
  const path = useLocation().pathname;

  const menuList = <div className="sideNaveMenuList">
    {menu.map((item) => (
      <div key={item.id}>
        <Link to={item.route}>
          <div
            className={
              isSelected(path, item)
                ? "sideNavItem sideNavItemSelected"
                : "sideNavItem"
            }
            onClick={() => setNavigationMenuOpen(false)}
          >
            <FontAwesomeIcon
              icon={item.icon}
              size="lg"
              style={{ maxWidth: "21.33px" }}
            />
            <span>{item.name}</span>
            {!!item.subtitle && <p
              className="dark-text text-10 tw-px-2 tw-rounded-full dark-border tw-bg-white">{item.subtitle}</p>}
          </div>
        </Link>

        {item.subMenu && <SubMenu subMenu={item.subMenu}
          setNavigationMenuOpen={setNavigationMenuOpen}
        />}
      </div>
    ))}
  </div>;

  return <div
    className={navigationMenuOpen ? "sideNav sideNav--open cleanScroll" : "sideNav cleanScroll"}
  >
    <div className="sideNav--header">
      <img src={Logo} />
      <div
        className="x--mark"
        onClick={(prev) => setNavigationMenuOpen(!prev)}
      >
        <FontAwesomeIcon icon={faTimes} style={{ color: "#fff" }} />
      </div>
    </div>
    <Link to="/dashboard">                      
      <img src={Logo} className="sideNavLogo" />
    </Link>
    {menuList}
  </div>;
}
