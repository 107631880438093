import React, { useState, useEffect } from "react"
import states from "../../utils/states"
import TextInput from "../../forms/TextInput"
import NormalSelect from "../../profile_forms/NormalSelect"
import moment from "moment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/free-solid-svg-icons"

export default function LicensureDetails(props) {
  const { userLicense } = props
  const [payload, setPayload] = useState({})

  useEffect(() => {
    setPayload(userLicense)
  }, [userLicense])

  function sendRequest(payload, reqType) {
    let URL = ''
    if (reqType === 'create') {
      URL = '../create_user_license'
    } else if (reqType === 'update') {
      URL = '../update_user_license'
    } else {
      URL = '../delete_user_license'
    }

    $.ajax({
      url: URL,
      dataType: 'JSON',
      type: 'POST',
      data: payload,
      header: { 'Content-Type': 'application/json' },
      success: () => { props.loadUser() },
      error: (xhr, status, err) => { console.log(err) }
    });
  }

  function handleChange(key, value) {
    setPayload(prevState => ({ ...prevState, [key]: value }))
  }

  function handleBlur(field, value) {
    const requestData = { [field]: value }
    Number.isInteger(payload.id) ? sendRequest({ ...requestData, id: payload.id }, 'update') : sendRequest(requestData, 'create')
  }

  function handleDelete() {
    Number.isInteger(payload.id) ? sendRequest({ id: payload.id }, 'delete') : props.removeLicense(payload.id)
  }

  return (
    <div className="LicensureDetails">
      <div className="LicensureDetails-input-container">
        <div className="myAccountSection myAccountInputSection">
          <div className="myAccountInputBox">
            <p className="myAccountTitle">State license</p>
            <NormalSelect
              className="teste"
              options={states}
              value={payload.license_state ? [{ value: `${payload.license_state}`, label: `${payload.license_state}` }] : ''}
              placeholder=""
              onChange={(type) => {
                handleChange('license_state', type.value)
                handleBlur('license_state', type.value)
              }}
            />
          </div>
          <div className="myAccountInputBox">
            <p className="myAccountTitle">License number (or Pre-license/NPI)</p>
            <TextInput
              attribute={'license_number'}
              placeholder=""
              type="text"
              value={payload.license_number ? payload.license_number : ''}
              handleChange={handleChange}
              handleBlur={handleBlur}
              className="myAccountInput"
              style={{ width: '-webkit-fill-available' }}
            />
          </div>
        </div>
        <div className="myAccountSection myAccountInputSection">
          <div className="myAccountInputBox">
            <p className="myAccountTitle">License type</p>
            <TextInput
              attribute={'license_type'}
              placeholder=""
              type="text"
              value={payload.license_type ? payload.license_type : ''}
              handleChange={handleChange}
              handleBlur={handleBlur}
              className="myAccountInput"
              style={{ width: '-webkit-fill-available' }}
            />
          </div>
          <div className="myAccountInputBox">
            <p className="myAccountTitle">Expiration Date</p>
            <TextInput
              attribute={'expiration_date'}
              style={{ width: '-webkit-fill-available' }}
              className="myAccountInput"
              type="date"
              value={payload.expiration_date ? moment.utc(payload.expiration_date).format('YYYY-MM-DD') : ''}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div className="LicensureDetails-icon-container">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={handleDelete}
        />
      </div>
    </div >
  )
}