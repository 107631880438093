import React, { useEffect } from "react";
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

function Prompt(props) {
  const block = props.when;

  let blocker = useBlocker(() => {
    if (block) {
      return !window.confirm(props.message);
    }
    return false;
  });

  // Reset the blocker if the user cleans the form
  React.useEffect(() => {
    if (blocker.state === "blocked" && !block) {
      blocker.reset();
    }
  }, [blocker, block]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (block) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handleUnload = () => {
      if (block) {
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [block]);

  return (
    <div key={block} />
  );
}

export default Prompt;
