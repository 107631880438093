import React from "react"
import PropTypes from "prop-types"

const TextArea = props => (
  <textarea
    className={props.className ? props.className : "onboard_text_field"}
    placeholder={props.placeholder}
    style={props.style}
    type={props.type}
    rows={5}
    autoFocus={props.autofocus ? "autofocus" : ""}
    value={props.value}
    onChange={(event) => props.handleChange(props.attribute, event.target.value)}
    onBlur={props.handleBlur ? () => props.handleBlur() : () => {}}
  ></textarea>
)

export default TextArea;
