import React from "react";
import PropTypes from "prop-types";

import Button from "../../../forms/Button";
import TextArea from "../../../forms/TextArea";
import NormalSelect from "../../../profile_forms/NormalSelect";
import TextInput from "../../../forms/TextInput";
import ReCAPTCHA from "react-google-recaptcha";

const csrf = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");


class SendMessageProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      user: [],
      sendSuccess: false,
      userName: "",
      userEmail: "",
      userPhone: "",
      message: "",
    };

    this.renderServicesName = this.renderServicesName.bind(this);
    this.updateField = this.updateField.bind(this);
    this.renderCurrentUserName = this.renderCurrentUserName.bind(this);
  }

  componentDidMount() {
    this.setState({
      userName: this.renderCurrentUserName(),
      userEmail: this.props.currentUser ? this.props.currentUser.email : "",
    });
  }

  renderCurrentUserName() {
    if (this.props.currentUser) {
      const { first_name, last_name } = this.props.currentUser;
      return last_name == null || last_name == ""
        ? `${first_name}`
        : `${first_name} ${last_name}`;
    } else {
      return "";
    }
  }

  updateField(attribute, text) {
    this.setState({
      [attribute]: text,
    });
  }

  renderServicesName() {
    const { user_services } = this.props.user;
    return user_services.map(({ id, name }) => ({ value: id, label: name }));
  }

  render() {
    return (
      <React.Fragment>
        {this.state.sendSuccess && (
          <div style={{ textAlign: "center" }}>
            <h3 style={{ marginBottom: 20 }}>Sent!</h3>
            <h5>Your provider will follow up with you via email.</h5>
          </div>
        )}
        {!this.state.sendSuccess && (
          <div className="message--profile">
              <form method="post" action="/message_therapist"
                className="tw-self-center tw-flex tw-flex-col tw-gap-2 tw-items-start"
              >
              <div style={{ marginBottom: 20 }}>
                <p
                  className="standard_label"
                  style={{ margin: "0 5px 10px 5px" }}
                >
                  Message
                </p>
                <p
                  className="standard_label"
                  style={{ margin: "0 5px 10px 5px", fontWeight: 400 }}
                >
                  {this.props.placeholder || (
                    "ex. Hi [name], my name is [your name] and I am looking for a " +
                    "therapist that specializes in [specialty] and [specialty]. Are " +
                    "you available for an intro phone call this week? Mornings work " +
                    "best for me. I look forward to hearing from you!"
                  )}
                </p>
                <TextArea
                  type="text"
                  className="profile_text_field"
                  attribute={"message"}
                  value={this.state.message}
                  handleChange={this.updateField}
                  style={{ width: "-webkit-fill-available", resize: "none" }}
                />
              </div>
              <p
                className="standard_label"
                style={{ margin: "0 5px 10px 5px" }}
              >
                Name
              </p>
              <TextInput
                className="profile_text_field"
                style={{ width: "-webkit-fill-available" }}
                type="text"
                attribute={"userName"}
                value={this.state.userName}
                handleChange={this.updateField}
                placeholder="Name"
              />
              <div className="modalMessageFieldContainer">
                <div>
                  <p
                    className="standard_label"
                    style={{ margin: "0 5px 10px 5px" }}
                  >
                    Email address
                  </p>
                  <TextInput
                    className="profile_text_field"
                    style={{ width: "-webkit-fill-available" }}
                    type="email"
                    attribute={"userEmail"}
                    value={this.state.userEmail}
                    handleChange={this.updateField}
                    placeholder="Email"
                  />
                </div>
              </div>
              <p
                className="standard_label"
                style={{
                  fontSize: 12,
                  margin: "0 5px 20px 5px",
                  fontWeight: 400,
                }}
              >
                This message will be sent by e-mail, which is not a secure means
                of communication. Sending an email using
                this page does not guarantee that the recipient will receive,
                read or respond to your email. No licensed medical
                professional/patient relationship is created by submitting this
                form.
              </p>
              <input type="hidden" key="authenticity_token" name="authenticity_token" value={csrf} />
              <input type="hidden" key="message" name="message" value={this.state.message} />
              <input type="hidden" key="user_id" name="user_id" value={this.props.user.id} />
              <input type="hidden" key="userName" name="userName" value={this.state.userName} />
              <input type="hidden" key="userEmail" name="userEmail" value={this.state.userEmail} />
              <ReCAPTCHA
                sitekey={process.env.RECAPTCHA_PUBLIC_KEY} />
              <button
                type="submit"
                className="dark-accent-button">
                Send message
              </button>
            </form>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SendMessageProfile;
