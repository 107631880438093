export default function formatCareType(careType, withArticle = false) {
  let type = "";
  const isDoctor = careType.care_type.name === "doctor";
  const subtypePresent = careType.care_subtype_id != null;
  if (subtypePresent) {
    type += careType.care_subtype.name;
    if (!isDoctor) {
      type += " ";
    }
  }
  // hide "doctor" for subtypes of doctor; e.g., "gynecologist"
  if (!(isDoctor && subtypePresent)) {
    type += `${careType.care_type.name}`;
  }

  if (withArticle) {
    let article = "a";
    if (type[0].match(/[aeiouAEIOU]/)) {
      article = "an";
    }
    return `${article} ${type}`;
  }

  return type;
};
