import React, { useState } from "react";
import Logo from "../../../assets/images/logo_purple.png";
import defaultPhoto from "../../../assets/images/default_photo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faTachometerAlt,
  faSmileBeam,
  faSlidersH,
  faSignOutAlt,
  faStream,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CreateSomethingModal from "./CreateSomethingModal";

export default function NavBar(props) {
  const { user } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleLogout() {
    const link = document.createElement("a");

    link.setAttribute("href", "/sign_out");
    link.setAttribute("rel", "nofollow");
    link.setAttribute("data-method", "delete");
    document.body.appendChild(link);
    link.click();
  }

  const profileUrl = props.user?.slug && `//${props.user?.slug}.${process.env.ROOT_DOMAIN}`;

  if (!user) {
    return null;
  }

  const showMarketplaceButton = !user.marketplace_subscription_active;

  return (
    <div className="navSection">
      <nav className="navBar">
        {props.changeStateNavigationMenuOpen && (
          <div className="nav--bar--menu" style={{cursor: "pointer"}}>
            <FontAwesomeIcon
              icon={faStream}
              onClick={() => props.changeStateNavigationMenuOpen()}
            />
          </div>
        )}
        <div>
          {!props.isDashPage && (
            <img
              onClick={() => window.location.assign("/dashboard")}
              src={Logo}
              className="navLogo"
            />
          )}
        </div>
        <div className="dropdownHeaderContainer">
          {showMarketplaceButton && <button
            className="dark-accent-button"
            onClick={() => window.location.assign("/dashboard/settings?page=upgrade")}
          >{"✨ Join the marketplace ✨"}</button>}
          <img
            onClick={() => {
              setIsMenuOpen((prevState) => !prevState);
            }}
            src={user?.avatar_url || defaultPhoto}
          />
        </div>
      </nav>
      {isMenuOpen && (
        <div className="dropdownContainer--position">
          <div className="dropdownContainer">
            <div
              className="dropdownHeaderContentBar"
              onClick={() => window.location.replace("/dashboard")}
            >
              <FontAwesomeIcon icon={faTachometerAlt} />
              <p>Dashboard</p>
            </div>
            {user.user_type != "CLIENT" && (
            <div
              className="dropdownHeaderContentBar"
              onClick={() => window.location.replace(profileUrl)}
            >
              <FontAwesomeIcon icon={faSmileBeam} />
              <p>My Profile</p>
            </div>
            )}
            <div
              className="dropdownHeaderContentBar"
              onClick={() => window.location.replace("/dashboard/settings")}
            >
              <FontAwesomeIcon icon={faSlidersH} />
              <p>Settings</p>
            </div>
            <div
              className="dropdownHeaderContentBar"
              onClick={() => handleLogout()}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
              <p>Log out</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
