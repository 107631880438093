import React from "react"

import ImageUpload from "../components/ImageUpload";

const ASPECT = 0.909;

class PhotoOnboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
      timeout: null
    }
    this.onFileChange = this.onFileChange.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.removeImage = this.removeImage.bind(this)
  }

  uploadImage(image) {
    let formData = new FormData();
    formData.append("avatar", image);
    return $.ajax({
      url: "/update_user_avatar",
      dataType: 'JSON',
      type: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      success: (_) => {
        this.props.updateUser();
      },
    });
  }

  removeImage() {
    $.ajax({
      url: "/remove_user_avatar",
      dataType: "JSON",
      type: "POST",
      success: (_) => {
        this.props.updateUser()
      },
    });
  }

  onFileChange(event) {
    let image = event.target.files[0]
    if(this.state.avatar != image) {
      this.setState({
        avatar: image
      }, () => this.uploadImage(image))
    }
  }

  componentDidUpdate() {
    this.props.blockNextButton(!this.props.currentUser.avatar_url);
  }

  componentDidMount() {
    this.props.blockNextButton(!this.props.currentUser.avatar_url);
  }

  render () {
    const avatarUrl = this.props.currentUser.avatar_url;
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{paddingBottom: 40}}>Show us your smile...</h3>
            <div style={{
              margin: 'auto',
              width: 300,
              height: 300 / ASPECT,
              backgroundColor: !avatarUrl && '#c4b5f7',
              borderRadius: "10px",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <ImageUpload
                key={`imageUpload_${avatarUrl}`}
                thumbUrl={avatarUrl}
                uploadFn={this.uploadImage}
                deleteFn={this.removeImage}
                aspectRatio={ASPECT}
              />
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PhotoOnboard;
