import React from "react"
import PropTypes from "prop-types"

const Modal = props => (
  <div style={{ margin: 0}}>

    <div style={{
      padding: 20,
      backgroundColor: 'white',
      position: 'fixed',
      zIndex: 100,
      top: '10%',
      left: 0,
      right: 0,
      maxWidth: 700,
      margin: 'auto',
      borderRadius: '20px'
      }}>
        {props.children}
    </div>
    <div
     onClick={() => props.onClose()}
     style={{
       top: 0,
       left: 0,
       position: 'absolute',
       width: '100%',
       height: '100%',
       display: 'flex',
       justifyContent: 'center',
       zIndex: 40,
       backgroundColor: '#0A2175',
       opacity: '0.8',
       backdropFilter: 'blur(4px)'}}>
     </div>
  </div>
)

export default Modal;

//<ScrollToPosition />
