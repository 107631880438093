import React from "react";
import EditIcon from "../../../components/EditIcon";

class Education extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
    }

    this.toggleEditModal = this.toggleEditModal.bind(this)
    this.renderEducation = this.renderEducation.bind(this)
  }

  toggleEditModal(id) {
    if (!this.props.liveProfile) {
      this.props.toggleEditModal(id)
    }
  }

  renderEducation() {
    return (
        this.props.user.user_educations.map((education, i) => {
          return <li key={i}>{education.school}{education.degree_type_id != null ? `, ${education.degree_type.name}` : ''}</li>
        })
    )
  }

  render() {
    if (this.props.liveProfile && this.props.user.user_educations.length <= 0) {
      return null
    }

    return (
      <div onClick={() => this.toggleEditModal('education')}>
        <h5 className="detailsSectionTitle">Education</h5>
        <ul className="detailList">
          {this.renderEducation()}
        </ul>
        {!this.props.liveProfile && <EditIcon />}
      </div>
    )
  }
}

export default Education
