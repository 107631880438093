import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import UploadImage from "./UploadImage";
import TextInput from "../forms/TextInput";
import { apiBase } from "../../utils/apiRequest";

class UserSettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      loaded: false,
      timeout: null,
      first_name: "",
      last_name: "",
    };

    this.loadUser = this.loadUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  updateField(key, value) {
    this.setState({
      [key]: value,
    });

    const { timeout } = this.state;
    clearTimeout(timeout);
    this.setState({
      timeout: setTimeout(() => {
        let data = {
          [key]: value,
        };
        this.updateUser(data);
      }, 300),
    });
  }

  updateUser(data) {
    $.ajax({
      url: `../update_user`,
      dataType: "JSON",
      type: "POST",
      data: data,
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        this.loadUser();
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  }

  async loadUser() {
    const response = await apiBase("/get_current_user", "GET");

    const user = JSON.parse(response?.data?.user);
    this.setState({
      user: user,
      loaded: true,
      first_name: user.first_name != null ? user.first_name : "",
      last_name: user.last_name != null ? user.last_name : "",
    });
  }

  componentDidMount() {
    this.loadUser();
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }
    return (
      <div style={{ maxWidth: 500, margin: "auto" }}>
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <FontAwesomeIcon
            onClick={this.props.toggleSettings}
            icon={faChevronLeft}
            style={{
              fontSize: 12,
              padding: 10,
              height: 15,
              width: 15,
              color: "white",
              backgroundColor: "#0A2175",
              borderRadius: 100,
              marginLeft: 15,
              marginBottom: -30,
              cursor: "pointer",
            }}
          />
          <h3 style={{ textAlign: "center" }}>My account</h3>
        </div>
        <UploadImage updateUser={this.updateUser} user={this.state.user} />
        <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
          <h5 style={{ width: 200, marginRight: 30 }}>First Name</h5>
          <TextInput
            value={this.state.first_name}
            attribute={"first_name"}
            handleChange={this.updateField}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5 style={{ width: 200, marginRight: 30 }}>Last Name</h5>
          <TextInput
            value={this.state.last_name}
            attribute={"last_name"}
            handleChange={this.updateField}
          />
        </div>
      </div>
    );
  }
}

export default UserSettingsContainer;
