const apiBase = (endpoint, method, body) => {
  return new Promise((resolve) => {
    $.ajax({
      url: endpoint,
      dataType: "JSON",
      type: method,
      data: body,
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        resolve(data);
      },
      error: function (xhr, status, err) {
        console.log(err);
      },
    });
  });
};

const defaultErrorHandler = (_xhr, status, err) => {
  console.error(`XHR failed with status ${status}: ${err}`);
}

const asyncApiRequest = (endpoint, method, body, onSuccess, onError) => {
  onError = onError || defaultErrorHandler;

  return $.ajax({
    url: endpoint,
    dataType: "JSON",
    type: method,
    data: body,
    header: {
      "Content-Type": "application/json",
    },
    success: onSuccess,
    error: onError,
  });
}

export { apiBase, asyncApiRequest };
