import React, { useState, useEffect } from "react";
import zoom from "../../../../assets/images/zoom.png";
import stripe from "../../../../assets/images/stripe.png";
import Button from "../../forms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {getZoomInfo} from "../../../utils/zoom";

export default function Integragions(props) {
  const [stripeUrl, setStripeUrl] = useState();
  const [zoomPollInterval, setZoomPollInterval] = useState();
  const [zoomInfo, setZoomInfo] = useState(getZoomInfo());
  const [zoomUrl, setZoomUrl] = useState();
  const [waitingZoom, setWaitingZoom] = useState(false);

  useEffect(() => {
    $.ajax({
      url: `../connect_stripe_user`,
      dataType: "JSON",
      type: "GET",
      header: { "Content-Type": "application/json" },
      success: (data) => setStripeUrl(data.stripeUrl),
      error: (xhr, status, err) => {
        console.log(err);
      },
    });

    $.ajax({
      url: `../connect_zoom_user`,
      dataType: "JSON",
      type: "GET",
      header: { "Content-Type": "application/json" },
      success: (data) => setZoomUrl(data.zoomUrl),
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  });

  const checkForZoomInfo = () => {
    const zoomInfo = getZoomInfo();
    if (zoomInfo) {
      setZoomInfo(zoomInfo);
      setWaitingZoom(false);
    }
  }

  useEffect(() => {
    if (waitingZoom) {
      setZoomPollInterval(setInterval(checkForZoomInfo, 1000));
    } else {
      clearInterval(zoomPollInterval);
    }
    return () => {
      clearInterval(zoomPollInterval);
    }
  }, [waitingZoom]);

  function handleClick(url) {
    window.location.replace(url);
  }

  function handleZoomClick(url) {
    window.open(url, "_blank");
    setWaitingZoom(true);
  }

  return (
    <div className="Integrations">
      <div className="Integrations-title">Connect with your favorite apps</div>
      <div className="Integrations-cards-container">
        <div className="Integrations-card">
          <img src={stripe} style={{ maxHeight: 40 }} />
          <div className="Integrations-card-content">
            <h3>Stripe</h3>
            <p>Accept credit card and ACH payments</p>
          </div>
          {!props.user.stripe_user_id && (
            <Button
              buttonClass="Integrations-btn"
              text="Connect"
              handleClick={() => handleClick(stripeUrl)}
            />
          )}

          {props.user.stripe_user_id && (
            <Button
              buttonClass="Integrations-btn-connected"
              text={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon icon={faCheckCircle} /> <p>Connected</p>
                </div>
              }
            />
          )}
        </div>
        <div className="Integrations-card">
          <div className="Integrations-zoom-img">
            <img src={zoom} />
          </div>
          <div className="Integrations-card-content">
            <h3>Zoom</h3>
            <p>Auto-create Zoom meetings and events</p>
          </div>
          {!zoomInfo && (
            <Button
              buttonClass="Integrations-btn"
              text={
                waitingZoom ? (
                  <div className="loading--ring--small">
                    <div className="lds-dual-ring"></div>
                  </div>
                ) : (
                  "Connect"
                )
              }
              handleClick={() => {
                if (!waitingZoom) return handleZoomClick(zoomUrl);
              }}
            />
          )}

          {zoomInfo && (
            <Button
              buttonClass="Integrations-btn-connected"
              text={
                <div style={{ display: "flex", alignItems: "center" }} handleClick={() => {
                  localStorage.removeItem("tabu/zoom_token");
                  setZoomToken(null);
                }}>
                  <FontAwesomeIcon icon={faCheckCircle} /> <p>Connected</p>
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
