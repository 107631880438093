import React from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

//buttonClass
//button_primary_outline
//button_primary dark_purple
//button_secondary
class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        width: "-webkit-fill-available",
        border: "1px solid #F3EEFF",
        borderRadius: "4px",
        color: "#0A2175",
        margin: "0 5px",
      }),
      multiValue: () => ({
        backgroundColor: "#F3EEFF",
        borderRadius: "100px",
        display: "flex",
        fontSize: "14px",
        padding: "4px",
        fontFamily: "Averta",
        color: "#0A2175",
        margin: "0 2px",
        alignItems: "center",
        margin: "1px",
      }),
      multiValueLabel: () => ({
        color: "#0A2175",
        fontSize: "14px",
        padding: "4px",
      }),
      placeholder: () => ({
        color: "#0A2175",
        fontSize: "14px",
        fontFamily: "Averta",
        color: "rgba(10, 33, 117, 0.5)",
      }),
      menuList: () => ({
        color: "#0A2175",
        fontSize: "14px",
        fontFamily: "Averta",
        maxHeight: "250px",
        overflowY: "scroll",
      }),
      valueContainer: () => ({
        padding: "5px 8px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }),
      multiValueRemove: () => ({
        height: "100%",
        color: "white",
        backgroundColor: "#0A2175",
        textAlign: "center",
        padding: "1px",
        borderRadius: "100%",
        cursor: "pointer",
        display: "flex",
        margin: "3px",
      }),
    };

    const transform = this.props.transform || ((a) => a);

    return (
      <CreatableSelect
        components={{ DropdownIndicator: null }}
        isClearable={false}
        isMulti
        styles={customStyles}
        options={transform(this.props.options || [])}
        onChange={this.props.onChange}
        isValidNewOption={this.props.isValidNewOption}
        placeholder={this.props.placeholder}
        value={transform(this.props.value || [])}
        defaultValue={transform(this.props.defaultValue || [])}
      />
    );
  }
}

export default MultiSelect;
