import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faLaptop } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../components/EditIcon";

class Locations extends React.Component {
  constructor(props) {
    super(props);

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.renderAdress = this.renderAdress.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
  }

  toggleEditModal(id) {
    if (!this.props.liveProfile) {
      this.props.toggleEditModal(id);
    }
  }

  verifyField = function (field) {
    const { user_locations } = this.props.user;

    if (user_locations[0].hasOwnProperty(field)) {
      if (user_locations[0][field] == "" || user_locations[0][field] == null) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  renderAdress() {
    const { user_locations } = this.props.user;

    let adress = "";

    if (this.verifyField("street_adress")) {
      adress += user_locations[0].street_adress;
      if (this.verifyField("unit")) {
        adress += ` ${user_locations[0].unit}`;
      }
    }

    return adress;
  }

  renderLocation() {
    const { user_locations } = this.props.user;

    let location = "";

    if (this.verifyField("city")) {
      location += user_locations[0].city;
    }
    if (this.verifyField("state")) {
      location += `, ${user_locations[0].state}`;
      if (this.verifyField("zipcode")) {
        location += ` ${user_locations[0].zipcode}`;
      }
    } else {
      if (this.verifyField("zipcode")) {
        location += `, ${user_locations[0].zipcode}`;
      }
    }
    if (this.verifyField("region")) {
      location += `, ${user_locations[0].region}`;
    }

    return location;
  }

  render() {
    const { user_locations } = this.props.user;

    if (
      (this.props.liveProfile && user_locations.length <= 0) ||
      (this.props.liveProfile &&
        user_locations.length > 0 &&
        !user_locations[0].virtually &&
        !user_locations[0].my_office)
    ) {
      return null;
    }

    return (
      <div onClick={() => this.toggleEditModal("locations")}>
        <h5 className="detailsSectionTitle">Location(s)</h5>
        {user_locations.length > 0 &&
          user_locations[0].hasOwnProperty("my_office") &&
          user_locations[0].my_office && (
            <div className="locationContainer">
              <div className="icon">
                <FontAwesomeIcon icon={faBuilding} size="xs" />
              </div>
              <ul className="locationList">
                <li>{this.renderAdress()}</li>
                <li>{this.renderLocation()}</li>
              </ul>
            </div>
          )}
        {user_locations.length > 0 &&
          user_locations[0].hasOwnProperty("virtually") &&
          user_locations[0].virtually && (
            <div className="locationContainer">
              <div className="icon">
                <FontAwesomeIcon icon={faLaptop} size="xs" />
              </div>
              <ul className="locationList">
                <li>offers virtual appts</li>
              </ul>
            </div>
          )}
        {!this.props.liveProfile && <EditIcon />}
      </div>
    );
  }
}

export default Locations;
