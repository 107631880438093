import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

// buttonClass
// button_primary_outline
// button_primary dark_purple
// button_secondary

const renderIcon = (iconName, spin) => {
  const icon = fas[iconName];

  return (
    <FontAwesomeIcon
      icon={icon}
      style={{ marginRight: 10 }}
      className={spin ? "fa-spin" : ""}
    />
  );
};

const Button = (props) => (
  <button
    id={props.id ? props.id : ""}
    style={props.style}
    className={props.buttonClass}
    onClick={() => props.handleClick()}
    disabled={props.disabled}
  >
    {props.icon && renderIcon(props.icon, props.spin)}
    {props.text}
  </button>
);

export default Button;
