import React, { useState, useContext, useEffect } from "react";
import FloatingButton from "./FloatingButton";
import StepsIndexContext from "../context/StepsIndexContext";
import CurrencyInput from "./CurrencyInput";
import FileContext from "../context/FileContext";
import ImageDragAndDrop from "./ImageDragAndDrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import EventContext from "../context/EventContext";
import DateTimePicker from "./DateTimePicker";
import {amountCentsToStringUSD, stringUSDToAmountCents} from "../../utils/currency";
import { format, isDate, parseISO } from "date-fns";
import { apiBase } from "../../utils/apiRequest";
import { isEventUsingZoom } from "../../utils/zoom";

const ENABLE_SELLING_TIME_CONTROLS = false;

export default function EventTheSparkleContent(props) {
  const [event, setEvent] = useContext(EventContext);
  const [publishBlockers, setPublishBlockers] = useState([]);
  const [step, setStep] = useContext(StepsIndexContext);
  const [amount, setAmount] = useState("");
  const [classLimit, setClassLimit] = useState("");
  const [file, setFile] = useState(null);
  const [initialStartSellDate, setInitialStartSellDate] = useState("");
  const [initialStopSellDate, setInitialStopSellDate] = useState("");
  const [startSellingImmediately, setStartSellingImmediately] = useState(true);
  const [stopSellingOnStart, setStopSellingOnStart] = useState(true);

  const maskInt = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  }

  const updateThumb = async (file, download_id) => {
    var formData = new FormData();
    formData.append("thumb", file);
    formData.append("id", download_id);

    $.ajax({
      url: "/update_user_event",
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        handleUpdateEvent("thumb_url", JSON.parse(data.event)?.thumb_url);
      },
    });
  };

  const handleUpdateEvent = (field, value) => {
    setEvent((previousValue) => {
      return {
        ...previousValue,
        [field]: value,
      };
    });
    props.updateUserEvent(field, value);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleStartTime = (value, timeAmPm) => {
    const start_selling_date = isDate(event?.start_selling_date)
      ? event?.start_selling_date
      : parseISO(event?.start_selling_date);
    const dateStr = format(start_selling_date, "yyyy-MM-dd");
    const dateTimeString = `${dateStr}T${value}:00`;

    const addHours = timeAmPm === "PM" ? 12 : 0;

    const date = parseISO(dateTimeString);

    date.setHours(date.getHours() + addHours);

    handleUpdateEvent("start_selling_date", date);
  };

  const handleStopTime = (value, timeAmPm) => {
    const start_selling_date = isDate(event?.start_selling_date)
      ? event?.start_selling_date
      : parseISO(event?.start_selling_date);
    const dateStr = format(start_selling_date, "yyyy-MM-dd");
    const dateTimeString = `${dateStr}T${value}:00`;

    const addHours = timeAmPm === "PM" ? 12 : 0;

    const date = parseISO(dateTimeString);

    date.setHours(date.getHours() + addHours);

    handleUpdateEvent("stop_selling_date", date);
  };

  useEffect(() => {
    const stripDollarSign = true;
    if (event?.amount !== amount) setAmount(amountCentsToStringUSD(event?.amount || 0,
      stripDollarSign));
    if (event?.event_limit && event?.event_limit !== classLimit)
      setClassLimit(event?.event_limit);
    if (event?.start_selling_date !== initialStartSellDate)
      setInitialStartSellDate(event?.start_selling_date);
    if (event?.stop_selling_date !== initialStopSellDate)
      setInitialStopSellDate(event?.stop_selling_date);

    if (event) {
      const blockers = getPublishBlockers(event);
      setPublishBlockers(blockers);
    } else {
      setPublishBlockers([]);
    }
  }, [event]);

  useEffect(() => {
    if (initialStartSellDate) {
      setStartSellingImmediately(false);
    }
  }, [initialStartSellDate]);

  useEffect(() => {
    if (initialStopSellDate) {
      setStopSellingOnStart(false);
    }
  }, [initialStopSellDate]);

  useEffect(() => {
    const getFile = async () => {
      updateThumb(file, event?.id);
    };

    if (file) getFile();
  }, [file]);

  const getPublishBlockers = (e) => {
    let blockers = [];
    if (!e.date_start_time || !e.date_end_time) {
      blockers.push("missing start / end time");
    }
    if (e.date_end_time < e.date_start_time) {
      blockers.push("end time is before start time");
    }
    if (e.stop_selling_date && e.start_selling_date &&
        e.stop_selling_date < e.start_selling_date) {
      blockers.push("'stop selling' time is before 'start selling' time");
    }
    if (!e.title) {
      blockers.push("missing title");
    }
    if (!e.thumb_url) {
      blockers.push("missing image");
    }
    if (!e.summary) {
      blockers.push("missing summary blurb");
    }
    if (!e.info) {
      blockers.push("missing long-form description");
    }
    if (!e.event_url) {
      blockers.push("missing URL");
    }
    if (!e.event_place?.title) {
      blockers.push("missing location / link");
    } else {
      if (e.event_place["title"] === "Online") {
        if (isEventUsingZoom(e?.event_place)) {
          if (!(e.event_place["zoomConfigApi"] && e.event_place["zoomConfigApi"]["join_url"])) {
            blockers.push("missing Zoom URL");
          }
        } else if (!e.event_place["url"]) {
          blockers.push("missing join URL");
        }
      } else if (e.event_place["title"] === "IRL") {
        if (!(
          e.event_place["location_address"] &&
          e.event_place["location_address"]["formatted_address"])
        ) {
          blockers.push("missing location address");
        }
      }
    }

    return blockers;
  }

  const renderPublishBlockers = (blockers) => {
    if (!blockers || blockers.length === 0) {
      return null;
    }

    return (
      <div className="publishBlockers">
        <p>{"this event isn't ready quite yet!"}</p>
        {blockers.map((blocker, i) => (
          <p key={"blocker_" + i} className="publishBlocker">{"• " + blocker}</p>
        ))}
      </div>
    );
  }

  const renderSellingTimeControls = () => {
    if (!ENABLE_SELLING_TIME_CONTROLS) {
      return null;
    }

    return (
      <>
      <h2>When can people register?</h2>
      <p>Start selling</p>
      <div className="start--selling">
        <div className="start--selling--immediately">
          <div
            className="checkbox--outside"
            onClick={() => {
              setStartSellingImmediately(true);
              handleUpdateEvent("start_selling_date", null);
            }}
          >
            <div className={startSellingImmediately ? "checked" : ""} />
          </div>
          <p>Immediately</p>
        </div>

        <div className="start--selling--collum">
          <div className="start--selling--specific--date">
            <div
              className="checkbox--outside"
              onClick={() => {
                setStartSellingImmediately(false);
              }}
            >
              <div className={!startSellingImmediately ? "checked" : ""} />
            </div>
            <p>Specific date/time</p>
          </div>
          {!startSellingImmediately && (
            <DateTimePicker
              onStartTimeChange={(time) => handleUpdateEvent("start_selling_date", time)}
              onEndTimeChange={(time) => {}}
              initialStartStamp={initialStartSellDate}
            />
          )}
        </div>
      </div>
      <p>Stop selling</p>
      <div className="start--selling">
        <div className="start--selling--immediately">
          <div
            className="checkbox--outside"
            onClick={() => {
              setStopSellingOnStart(true);
              handleUpdateEvent("stop_selling_date", null);
            }}
          >
            <div className={stopSellingOnStart ? "checked" : ""} />
          </div>
          <p>When event starts</p>
        </div>

        <div className="start--selling--collum">
          <div className="start--selling--specific--date">
            <div
              className="checkbox--outside"
              onClick={() => {
                setStopSellingOnStart(false);
              }}
            >
              <div className={!stopSellingOnStart ? "checked" : ""} />
            </div>
            <p>Specific date/time</p>
          </div>
          {!stopSellingOnStart && (
            <DateTimePicker
              onStartTimeChange={(time) => handleUpdateEvent("stop_selling_date", time)}
              onEndTimeChange={(time) => {}}
              initialStartStamp={initialStopSellDate}
            />
          )}
        </div>
      </div>
      </>
    );
  }

  return (
    <div className="eventsStepContent">
      <h2 id="step2">How much does the event cost?</h2>
      <div className="amountInput">
        <CurrencyInput
          value={amount}
          placeholder="$ 0.00"
          className="inputAmount"
          onChange={(e) => setAmount(e.target.value)}
          onBlur={() => handleUpdateEvent("amount", stringUSDToAmountCents(amount))}
        />
      </div>
      <h2>How many people can attend your event?</h2>
      <div className="classLimitInput">
        <p>#</p>
        <input
          placeholder="(unlimited)"
          className="inputLimit"
          value={classLimit}
          onChange={(e) => setClassLimit(maskInt(e.target.value))}
          onBlur={() => handleUpdateEvent("event_limit", classLimit)}
        />
      </div>
      {renderSellingTimeControls()}
      <h2>Add an image for your event</h2>
      <p>
        Make it pop! Be sure to use a high quality image.<br/>
        We recommend 1080px x 1080px (1:1 ratio)
      </p>
      <FileContext.Provider value={[file, setFile, event?.thumb_url]}>
        <div className="picture--row">
          <ImageDragAndDrop thumbUrl={event?.thumb_url} />
          {event?.thumb_url && (
            <FontAwesomeIcon
              className="icon-cog"
              icon={faTrash}
              onClick={() => {
                apiBase("../delete_event_thumb_url", "POST", {
                  id: event.id,
                });

                handleUpdateEvent("thumb_url", null);
              }}
            />
          )}
        </div>
      </FileContext.Provider>
      <div className="buttons">
        <FloatingButton
          title="Publish!"
          disabled={publishBlockers?.length > 0}
          onClick={() => {
            if (!event.amount) handleUpdateEvent("amount", 0);
            handleUpdateEvent("status", "PUBLISHED");
            window.location.assign(
              `//${props.user?.slug}.${process.env.ROOT_DOMAIN}/e/${event.event_url}?celebration=true`
            );
          }}
        />
        {renderPublishBlockers(publishBlockers)}
      </div>
    </div>
  );
}
