import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";

export default function CheckoutForm(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setLoading(true);
    setErrorMessage();
    event.preventDefault();

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const result = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${props.buyerInfo.firstName} ${props.buyerInfo.lastName}`,
        },
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
      setLoading(false);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        setLoading(false);
        props.handleSuccess();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      {errorMessage && <small className="error--message">{errorMessage}</small>}
      <button
        className={
          stripe
            ? "button--payment button--payment--enabled"
            : "button--payment"
        }
        disabled={!stripe}
      >
        {loading ? (
          <div className="loading--ring--payment">
            <div className="lds-dual-ring--payment"></div>
          </div>
        ) : (
          <p>Pay</p>
        )}
      </button>
    </form>
  );
}
