import React from "react";

import Button from "../../forms/Button";

import CredentialsOnboard from "../../therapist_onboard/CredentialsOnboard";
import PronounOnboard from "../../therapist_onboard/PronounOnboard";
import PreferredNameOnboard from "../../therapist_onboard/PreferredNameOnboard";
import ProfessionOnboard from "../../therapist_onboard/ProfessionOnboard";
import AdjectivesOnboard from "../../therapist_onboard/AdjectivesOnboard";

import formatCareType from "../../../utils/care_types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const INTRO_P_STYLES = "tw-font-bold tw-text-lg lg:tw-text-xl";

export const formatName = (user) => {
  const { first_name, last_name, display_name } = user;
  return display_name == null || display_name == ""
    ? `${first_name.trim()} ${last_name.trim()}`
    : `${display_name}`;
}

export const formatCredentials = (user) => {
  const { credentials } = user;
  return credentials == null || credentials == "" ? "" : `, ${credentials}`;
}

export default function IntroContainer(props) {
  const { user } = props;

  const renderPreferredName = () => {
    const { preferred_name, last_name } = props.user;
    if (preferred_name != null && preferred_name.length > 0) {
      return `${preferred_name}`;
    } else if (last_name != null) {
      return `Dr. ${last_name}`;
    } else {
      return false;
    }
  }

  const renderType = (userCareTypes) => {
    let type = "";
    let length = userCareTypes.length - 1;
    userCareTypes.map((care_type, i) => {
      if (i === length && i > 0) {
        type += " and ";
      } else if (i > 0) {
        type += ", ";
      }
      type += formatCareType(care_type);
    });
    return type;
  }

  const renderPronouns = () => {
    let pronouns = "";

    let pronounList = [...props.user.pronouns];
    if (props.user.additional_pronoun) {
      pronounList.push({name: props.user.additional_pronoun});
    }

    pronounList.map((pronoun, i) => {
      if (i > 0) {
        pronouns += ", ";
      }
      pronouns += `${pronoun.name}`;
    });

    return pronouns;
  }

  const transformToArray = (arr) => {
    let adjectivesArray = [];
    if (!Array.isArray(arr)) {
      adjectivesArray = JSON.parse(props.user.adjectives);
      return adjectivesArray;
    } else {
      return arr;
    }
  }

  const renderAdjectives = () => {
    let adjectives = "";
    let adjectivesArray = transformToArray(props.user.adjectives);

    // Removing empty indexes
    let cleanArray = adjectivesArray.filter((i) => i);

    cleanArray.map((adjective, i) => {
      if (i === cleanArray.length - 1 && i > 0) {
        adjectives += ", and ";
      } else if (i > 0) {
        adjectives += ", ";
      }
      adjectives += adjective;
    });
    return adjectives;
  }

  const adjectivesArray =
    user.adjectives !== null &&
    user.adjectives !== "" &&
    user.adjectives.length > 0
      ? transformToArray(user.adjectives)
      : [];

  const renderIntroCareTypes = () => {
    let userCareTypes = user.user_caretypes.filter(x => !!x);
    const type = user.custom_intro_text || renderType(userCareTypes);
    let article = "a";
    if (type && type[0].match(/[aeiouAEIOU]/)) {
      article = "an";
    }

    return (
      <p className={INTRO_P_STYLES}>
        {`I'm ${article} `}
        <span className="orange-text" id="introText">{type}</span>
      </p>
    );
  };

  const renderName = () => {
    return (
      <p className={INTRO_P_STYLES}>
        {`Hi! I'm `}
        <span className="orange-text">
          {formatName(user)}
          {formatCredentials(user)}
        </span>
      </p>
    );
  };

  const renderIntro = () => {
    return (
      <div className="intro--container">
        <div>
          {renderName()}
          {user.user_caretypes.length > 0 && renderIntroCareTypes()}
          {user.preferred_name !== ("" || null) && (
            <p className={INTRO_P_STYLES}>
              {`My clients call me `}
              <span className="orange-text">{renderPreferredName()}</span>
            </p>
          )}
          {adjectivesArray &&
            adjectivesArray?.length > 0 &&
            !adjectivesArray.every((i) => i == "") && (
              <p className={INTRO_P_STYLES}>
                {`They would describe me as `}
                <span className="orange-text">{renderAdjectives()}</span>
              </p>
            )}
          {(user.pronouns.length > 0 || user.additional_pronoun) && (
            <p className={INTRO_P_STYLES}>
              {`My pronouns are `}
              <span className="orange-text">{renderPronouns()}</span>
            </p>
          )}
        </div>
      </div>
    );
  };

  let containerStyle = props.onboardingPreview && {
    width: "100%",
    padding: 0,
  };

  return (
    <div className="introContainer" style={containerStyle}>
      {renderIntro()}
    </div>
  );
};
