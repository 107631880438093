import React, {useState, useEffect} from "react";

import UserCard from "../components/UserCard";
import NavBar from "../components/NavBar";
import ModalForm from '../profile_forms/ModalForm'
import PublicServicesProfile from "./sections/Modals/PublicServicesProfile";

import {amountCentsToStringUSD} from "../../utils/currency";
import {getDefaultPhoto} from "../../utils/defaultPhotos";

const padding = "tw-p-8"

export default function Service(props) {
  const {sv, user, currentUser, isCurrentUser} = props;

  const params = new URLSearchParams(window.location.search);
  const messageModal = params.get("message_modal") === "1";
  const [showServicesModal, setShowServicesModal] = useState(messageModal);
  const [copiedURL, setCopiedURL] = useState(false);

  if (!sv) {
    return <p>Service not found!</p>;
  }

  const formatSessions = () => {
    if (!(sv.n_sessions && sv.duration && sv.duration_type)) {
      return "";
    }
    if (sv.n_sessions > 1) {
      return `for ${sv.n_sessions} ${sv.duration}-${sv.duration_type} sessions`;
    }
    return `${sv.duration} ${sv.duration_type}s`;
  };

  const workWithMe = () => {
    if (!props.isPreview) {
      setShowServicesModal(true);
    }
  };

  const copyURL = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopiedURL(true);
  };

  const renderButtons = () => {
    if (!isCurrentUser || props.isPreview) {
      return <button
        className="dark-accent-button tw-flex tw-items-center tw-justify-center tw-gap-4"
        onClick={workWithMe}
        style={{width: "100%"}}>
        <p className="tw-font-semibold text-18 tw-text-white">Work with me</p>
      </button>;
    }

    return <div className="tw-flex tw-gap-2">
      <button
        className="dark-accent-button tw-flex tw-items-center tw-justify-center tw-gap-4 tw-grow"
      >
        <a href={`/dashboard/create/service?id=${sv.id}`}>
          <p className="tw-font-semibold text-18 tw-text-white">Edit service</p>
        </a>
      </button>
      <button
        className="dark-accent-white-button tw-flex tw-items-center tw-justify-center tw-gap-4 tw-grow"
        onClick={copyURL}
      >
        <p className="tw-font-semibold dark-accent-text text-18 tw-text-white">{
          (copiedURL ? "Copied!" : "Copy URL")
        }</p>
      </button>
    </div>;
  }

  const renderHeader = () => {
    const formattedCost = sv.cost != null && amountCentsToStringUSD(sv.cost);

    return <div className="tw-flex tw-flex-col">
      <img className="tw-w-full" src={sv.cover_photo_url || getDefaultPhoto(sv.id)} style={{
        aspectRatio: 4/3.0, objectFit: "cover",
      }} />
      <div className={`tw-flex tw-flex-col tw-gap-4 light-accent-background ${padding}`}>
        <p className="tw-font-semibold text-22">{sv.name}</p>
        <div className="tw-flex tw-gap-2">
          <p className="tw-font-bold text-20">{formattedCost}</p>
          <p className="text-16">{" | "}</p>
          <p className="text-16 tw-font-light">{formatSessions()}</p>
        </div>
        {!!sv.sliding_scale && <p className="text-16 tw-font-light">sliding-scale provided</p>}
        {renderButtons()}
      </div>
    </div>;
  };

  const renderSpecialties = () => {
    if (!sv.specialties?.length) {
      return null;
    }

    return <div className="tw-flex tw-flex-col tw-gap-4">
      <hr className="light-purple-divider" />
      <p className="text-20">{"Let's talk about"}</p>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {sv.specialties.map((sp, i) => <p
          key={i}
          className="tw-rounded-lg dark-shadow tw-px-2 tw-py-1 dark-border"
          style={{
            backgroundColor: "rgba(193, 169, 254, 0.5)",
          }}
        >{sp.name}</p>)}
      </div>
    </div>;
  };

  const renderTreatmentTechniques = () => {
    if (!sv.treatment_techniques?.length) {
      return null;
    }

    return <div className="tw-flex tw-flex-col tw-gap-4">
      <hr className="light-purple-divider" />
      <p className="text-20">Treatment techniques</p>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {sv.treatment_techniques.map((tt, i) => <p
          key={i}
          className="tw-rounded-lg dark-shadow tw-px-2 tw-py-1 dark-border"
          style={{
            border: "1px solid #C1A9FE",
            boxShadow: "2px 2px 0px 0px #C1A9FE",
          }}
        >{tt.name}</p>)}
      </div>
    </div>;
  };

  const renderInsurances = () => {
    if (!sv.insurances?.length) {
      return null;
    }

    return <div className="tw-flex tw-flex-col tw-gap-4">
      <hr className="light-purple-divider" />
      <p className="text-20">Insurances accepted</p>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {sv.insurances.map((ins, i) => <p
          key={i}
          className="text-12 tw-font-semibold"
        >{ins.name}</p>)}
      </div>
    </div>;
  };

  const renderBody = () => {
    return <div className={`tw-flex tw-flex-col tw-gap-2 ${padding}`}>
      {sv.description?.split("\n").map((line, i) => 
        <p key={i} className="text-16">{line}</p>
      )}
      {renderSpecialties()}
      {renderTreatmentTechniques()}
      {renderInsurances()}
      <UserCard user={user} style="service--therapist--card" />
    </div>;
  };

  const navBarClearanceMargin = currentUser ? "tw-mt-[80px]" : "";
  const containerClasses = `${props.classes || ""} tw-flex tw-flex-col tw-items-center tw-w-full ${navBarClearanceMargin}`;

  const placeholder = `e.g., Hi ${user.first_name}, I'm inquiring about your ${sv.name} service.`;

  if (showServicesModal) {
    return (
      <ModalForm onClose={() => setShowServicesModal(false)}>
        <PublicServicesProfile
          placeholder={placeholder}
          redirectServiceId={sv.id}
          user={user}
          isCurrentUser={user === currentUser}
          currentUser={currentUser}
          onClose={() => setShowServicesModal(false)}
        />
      </ModalForm>
    );
  }

  return <div className={containerClasses}>
    {currentUser && !props.isPreview && <NavBar user={currentUser} />}
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-m-auto md:tw-max-w-lg">
      {renderHeader()}
      {renderBody()}
    </div>
  </div>;
}
