import React, {useState, useEffect} from "react";

function SectionLollipop({i, highlighted, onSelect}) {
  const background = highlighted ? "purple-background" : "light-purple-background";
  
  return <div
      className={
        "tw-flex tw-flex-col tw-items-center " +
        (i > 0 ? "tw-min-h-40 tw-h-full" : "")
      }
      style={{width: "40px"}}
    >
    {i > 0 && <div
      className={"tw-grow " + background} style={{
        // stick
        width: "2px",
        minHeight: "40px",
        maxHeight: "126px",
      }} />}
    <div onClick={() => onSelect(i)} className={background} style={{
      // circle
      height: "40px",
      width: "40px",
      borderRadius: "20px",
    }} />
  </div>;
}

export default function Sidebar({sections, selectedSection, setSelectedSection}) {
  return (
    <div className="tw-hidden lg:tw-flex tw-sticky tw-self-start" style={{top: "80px"}}>
      <div className="tw-flex tw-flex-col tw-max-h-[50vh]">
        {sections.map((section, i) => (
          <div className="tw-flex tw-grow tw-justify-end tw-gap-4 tw-items-end" key={i}>
            <p className="tw-font-bold tw-text-right" style={{height: "30px"}}>{section.title}</p>
            <SectionLollipop i={i} highlighted={i <= selectedSection} onSelect={
              setSelectedSection
            } />
          </div>
        ))}
      </div>
    </div>
  )
};
