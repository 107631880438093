import React, { useEffect, useState } from "react";

import NavBar from "../components/NavBar";
import NavBarDownlodas from "../components/NavBarDownlodas";
import {EventLocation, EventTime} from '../components/EventComponents';
import PaymentModal from "../payment_modal/PaymentModal";
import Modal from "../components/Modal";
import Notice from "../components/Notice";
import UserCard from "../components/UserCard";
import Celebration from "../components/Celebration";

import {apiBase} from "../../utils/apiRequest";
import {isEventUsingZoom} from "../../utils/zoom";
import {ProductType, ProductSpec} from "../../utils/product";
import {
  getSellingStatus, getStartStamp, getEndStamp, getStatusMessage,
} from "../../utils/events";
import {formatRelativeTime} from "../../utils/time";

import {AddToCalendarButton} from 'add-to-calendar-button-react';
import {format, intervalToDuration, differenceInDays, parseISO} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faLaptop,
  faMapMarkerAlt,
  faPen,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import parseHtml from "html-react-parser";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";

const STARTING_SOON_DURATION_MS = 60000 * 60;

export default function EventsContainer(props) {
  const [currentUser, setCurrentUser] = useState([]);
  const [event, setEvent] = useState();
  const [attending, setAttending] = useState(false);
  const [myEvents, setMyEvents] = useState(undefined);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const celebration = query.get("celebration");
  const [celebrationState, setCelebrationState] = useState(celebration);
  const [showTooltip, setShowTooltip] = useState(false);
  const now = new Date().getTime();
  const startStamp = getStartStamp(event);
  const endStamp = getEndStamp(event);
  const startingSoon = startStamp - now < STARTING_SOON_DURATION_MS;
  
  useEffect(() => {
    setAttending(event && !!myEvents?.length &&
      myEvents.filter((item) => item.id === event.id)?.length > 0);
  }, [event, myEvents]);

  const renderTooltip = () => {
    setShowTooltip(true);
    let publicUrl = window.location.href;
    let queryStringIndex = publicUrl.indexOf("?");
    if (queryStringIndex) {
      publicUrl = publicUrl.slice(0, queryStringIndex);
    }
    navigator.clipboard.writeText(publicUrl);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  const getUserEvent = () => {
    const endpoint = "/get_user_event_by_name";
    $.ajax({
      url: endpoint,
      dataType: "JSON",
      type: "POST",
      data: {
        user_id: props.user?.user?.id,
        eventUrl: props?.eventUrl,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        setEvent(JSON.parse(data.events));
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const loadCurrentUser = async () => {
    const response = await apiBase("/get_current_user", "GET");
    setCurrentUser(JSON.parse(response?.data?.user));
  };

  const getEvents = () => {
    $.ajax({
      url: `../get_my_events`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        if (data && data.events) {
          setMyEvents(JSON.parse(data.events))
        };
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  };

  useEffect(() => {
    loadCurrentUser();
    getUserEvent();
    getEvents();

    if (celebrationState) {
      setTimeout(() => {
        setCelebrationState(false);
      }, 5000);
    }
  }, []);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const renderTags = (eventTags) => {
    if (eventTags) {
      return (
        <div className="tags--content">
          <h2>Topics</h2>
          {eventTags?.map((tag, i) => {
            return (
              <p
                className="tags--event"
                style={{ marginBottom: 8, fontSize: "14px" }}
                key={i}
              >
                {tag}
              </p>
            );
          })}
        </div>
      );
    }
  };

  const getEventLocation = (eventPlace) => {
    if (!event) {
      return;
    }

    if (eventPlace.title === "IRL") {
      return eventPlace.location_address.formatted_address;
    } else if (eventPlace.title === "Online") {
      if (isEventUsingZoom(eventPlace)) {
        return eventPlace.zoomConfigApi["join_url"];
      }
      return eventPlace.url;
    }
    return;
  }

  const renderAddToCalendarButton = () => {
    if (!event) {
      return;
    }

    let organizer = undefined;
    if (props.user.user) {
      organizer = (`${props.user.user.first_name} ${props.user.user.last_name}|` +
                     `${props.user.user.email}`);
    }
    const startDate = startStamp.toISOString().substring(0, 10);
    const startTime = startStamp.toISOString().substring(11, 16);
    const endDate = endStamp.toISOString().substring(0, 10);
    const endTime = endStamp.toISOString().substring(11, 16);

    // add-to-calendar-button uses a subset of HTML tags with square brackets in
    // the 'description' field; replace "<>" with "[]" in the ReactQuill "info."
    let description = event.info.replaceAll("<", "[").replaceAll(">", "]");
    return (
      <AddToCalendarButton
       name={event.title}
       organizer={organizer}
       location={getEventLocation(event.event_place)}
       startDate={startDate}
       startTime={startTime}
       endDate={endDate}
       endTime={endTime}
       description={description}
       options={['Apple', 'Google', 'Yahoo', 'iCal']}
       timeZone="UTC"  // stored in UTC
      >
      </AddToCalendarButton>
    );
  }

  const sellingStatus = getSellingStatus(event);

  const renderClientButtons = () => {
    let text = undefined; 
    let enabled = undefined;
    if (attending) {
      text = startingSoon ? "Join event" : "Signed up!";
      enabled = startingSoon;
    } else {
      text = sellingStatus.message;
      enabled = sellingStatus.forSale;
    }
    const onClick = enabled ? () => {
      window.scrollTo(0, 0);
      attending ? setShowJoinModal(true) : setShowPaymentModal(true);
    } : undefined;
    return (
      <div
        className={"handle--event--buy" + (enabled ? "" : " disabled")}
        onClick={onClick}
      >
        <span>{text}</span>
      </div>
    );
  }

  const renderPlace = (eventPlace) => {
    if (!eventPlace) {
      return;
    }
    if (eventPlace.title === "Online") {
      if (eventPlace.using_zoom && eventPlace.zoomConfigApi) {
        let url = props.isCurrentUser ? eventPlace.zoomConfigApi.start_url : eventPlace.zoomConfigApi.join_url;
        return (
          <div className="eventPlace">
            <h2>Join via Zoom</h2>
            <a href={url}><u>meeting link</u></a>
          </div>
        );
      } else {
        return (
          <div className="eventPlace">
            <h2>Join via this link</h2>
            <a href={eventPlace.url}><u>{eventPlace.url}</u></a>
          </div>
        );
      }
    }
    if (eventPlace.title === "IRL") {
      if (!eventPlace.location_address) {
        return (
          <span>Address unknown</span>
        );
      }

      return (
        <div className="eventPlace">
          <h2>Join IRL:</h2>
          <div style={{display: "flex", flexDirection: "row"}}>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ color: "#0A2175", marginRight: "10px" }}
            /><p>{eventPlace.location_address.formatted_address}</p>
          </div>
          {eventPlace.live_stream && (
            <p>or <a href={eventPlace.live_stream}>watch the live stream!</a></p>
          )}
        </div>
      );
    }
  }

  const renderPaymentModal = () => {
    const productSpec = new ProductSpec(ProductType.Event, event.id);

    return (
      <>
        <PaymentModal
          productSpec={productSpec}
          currentUser={currentUser}
          renderModalFinale={renderAddToCalendarButton}
          onClose={() => {
            setShowPaymentModal(false);
            loadCurrentUser();
          }}
          onSuccess={(_) => setAttending(true)}
        />
      </>
    );
  }

  const renderJoinModalContent = () => {
    return (
      <div className="modalContent tw-my-8">
        {renderPlace(event.event_place)}
        <hr style={{
          width: "100%",
          border: 0,
          height: 0,
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
          margin: "1em 0em",
        }}/>
        {renderAddToCalendarButton()}
      </div>
    );
  }

  const renderJoinModal = () => {
    return (
      <Modal
        disableScroll={true}
        renderContent={renderJoinModalContent}
        onClose={() => setShowJoinModal(false)}
      />
    );
  };

  const renderHostButtons = () => {
    let primaryButton = null;
    if (startingSoon && isEventUsingZoom(event.event_place)) {
      primaryButton = (
        <div
          className="handle--event--buy"
        >
          <FontAwesomeIcon
            style={{
              color: "#fff",
              marginRight: "15px",
            }}
            icon={faLaptop}
          />
          <span><a
            target="_blank"
            style={{color: "white"}}
            href={event.event_place.zoomConfigApi["start_url"]}>Host on Zoom</a>
          </span>
        </div>
      );
    } else {
      primaryButton = (
        <div
          className="handle--event--buy"
          onClick={() =>
            window.location.replace(
              `/dashboard/create/event?id=${event.id}`
            )
          }
        >
          <FontAwesomeIcon
            style={{
              color: "#fff",
              marginRight: "15px",
            }}
            icon={faPen}
          />
          <span>Edit details</span>
        </div>
      );
    }
    return (
      <div style={{ display: "flex" }}>
        {primaryButton}
        <div
          className="handle--event--buy--secondary"
          onClick={() => renderTooltip()}
        >
          {showTooltip && <div className="tooltip">Link copied!</div>}
          <FontAwesomeIcon
            style={{
              color: "#733dff",
              marginRight: "15px",
            }}
            icon={faCopy}
          />
          <span>Copy share link</span>
        </div>
      </div>
    );
  };

  const renderAttendingBanner = () => {
    const hasEnded = endStamp > now;
    const hasStarted = startStamp < now;
    if (hasEnded) {
      return null;
    }

    const formatted = formatRelativeTime(startStamp, now);
    if (hasStarted) {
      return (
        <p className={classes}
        >{`You're registered! Event started ${formatted}`}</p>
      );
    }
    return (
      <p className={classes}
      >{`You're registered! Event starts ${formatted}`}</p>
    );
  };

  const attendingBannerClasses = "tw-bg-white tw-rounded-md tw-font-semibold tw-mt-4 lg:tw-mb-4 tw-p-3 tw-text-lg purple-border";

  const renderHeader = () => {
    if (!event) {
      return;
    }

    return (
      <div className="event--header">
        <div className="event--info">
          {attending && (
            <p className={attendingBannerClasses}
            >{`You're registered! ${getStatusMessage(event, new Date())}`}</p>
          )}
          <EventTime time={event.date_start_time}/>
          <EventLocation eventPlace={event.event_place}/>
          <h2>{event.title}</h2>
          <p>{event.summary}</p>
          {props.isCurrentUser && renderHostButtons()}
          {!props.isCurrentUser && renderClientButtons()}
        </div>
        <div className="event--thumbnail">
          <img src={event.thumb_url} alt="thumbnail" />
        </div>
      </div>
    );
  }

  const renderHostInfo = () => {
    if (!event || !props.user.user) {
      return;
    }

    return (
      <div className="event--hosted--by">
        <h2>Hosted by</h2>
        <Link to={`/`}>
          <UserCard user={props.user.user}/>
        </Link>
      </div>
    );
  }

  return (
    <div className="event--content--body">
      <div className="event--page">
        {celebrationState && <Celebration />}
        {showAlert && <Notice message={message} />}
        {currentUser && <NavBar user={currentUser} />}
        {showPaymentModal && renderPaymentModal()}
        {showJoinModal && renderJoinModal()}
        {renderHeader()}
        <div className="more--info">
          <div className="event--content">
            <h2>Description</h2>
            {event?.info && parseHtml(event?.info)}
            {event?.topics &&
              renderTags(
                typeof event.topics === "string"
                  ? JSON.parse(event.topics)
                  : event.topics
              )}
          </div>
        {renderHostInfo()}
        </div>
      </div>
      <div className="event--footer">{!currentUser && <NavBarDownlodas />}</div>
    </div>
  );
}
