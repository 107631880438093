import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import DropdownSelect from "../forms/DropdownSelect";
import MultiForm from '../forms/MultiForm';
import NormalSelect from "../profile_forms/NormalSelect";
import TextInput from "../forms/TextInput";

import {getDegreeTypes} from "../../queries/user_attributes";
import {mutateCurrentUserEducation} from "../../queries/user";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

const MAX_EDUCATIONS = 3;

export default function EducationOnboard(props) {
  const userEducations = props.currentUser?.user_educations;
  const queryClient = useQueryClient();
  const queryDegreeTypes = useQuery({
    queryKey: ["degree_types"],
    queryFn: getDegreeTypes,
  });
  const userEducationsMutation = useMutation({
    mutationFn: mutateCurrentUserEducation,
    onSuccess: () => {
      queryClient.invalidateQueries(
        {queryKey: ["current_user"]}
      );
    },
  });

  const loading = !userEducations || queryDegreeTypes.isLoading;
  if (loading) { return <p>Loading...</p>; }

  if (queryDegreeTypes.isError) {
    return <h1>Error fetching degree types: {queryDegreeTypes.error.message}</h1>
  }

  const degreeTypes = JSON.parse(queryDegreeTypes.data.data.degree_types)?.map(
    ({id, name}) => ({
       value: id,
       label: name,
     }));

  const handleTextInput = (index, text) => {
    userEducationsMutation.mutate({
      action: "update", payload: {
        education: {
          id: userEducations[index]?.id,
          school: text,
        },
      }
    });
  };

  const updateDegreeType = (selection, index) => {
    userEducationsMutation.mutate({
      action: "update", payload: {
        education: {
          id: userEducations[index]?.id,
          school: userEducations[index]?.school,
          degree_type_id: selection.value,
        },
      }
    });
  };

  const renderDegreeTypeDropdown = (i) => {
    let value = undefined;
    let degreeTypeId = userEducations[i]?.degree_type_id;
    if (degreeTypeId) {
      value = degreeTypes.find(x => x.value === degreeTypeId);
    }
    return (
      <div style={{ marginTop: 40 }}>
        <NormalSelect
          options={degreeTypes}
          optionName={"name"}
          attribute={i}
          placeholder={"Type of degree"}
          onChange={(selection) => updateDegreeType(selection, i)}
          value={value}
        />
      </div>
    );
  };

  const renderEducationForm = (i) => {
    return (
        <div className="education_onboarding_container" key={`userEducationFormContainer${i}`}>
          <div style={{ width: "100%" }}>
            <h3 className="standard_label" style={{ paddingBottom: 40 }}>
              What school did you attend?
            </h3>
            <TextInput
              id={`schoolName${i}`}
              defaultValue={userEducations[i]?.school}
              attribute={i}
              debounceDelayMs={500}
              handleChange={handleTextInput}
              placeholder={"Name of school or program"}
            />
            <div style={{ marginTop: 20 }}>{renderDegreeTypeDropdown(i)}</div>
          </div>
        </div>
      );
  };

  return (
    <React.Fragment>
      <div className="onboard_field_section dropdown_container" style={{
        display: "flex",
        flexDirection: "column",
      }}>
        <MultiForm
          entries={userEducations}
          maxCount={MAX_EDUCATIONS}
          renderForm={renderEducationForm}
          deleteFn={(i) => {
            userEducationsMutation.mutate({
              action: "delete", payload: {
                id: userEducations[i].id,
              },
            });
            let input = $(`#schoolName${i}`);
            if (input) {
              input.val("");
            }
          }}
        />
      </div>
    </React.Fragment>
  );
};
