import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import bread from "../../../assets/images/bread.png";
import emoji from "../../../assets/images/emoji.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import IncomeTimelineChart from "../components/IncomeTimelineChart";

export default function IncomeContainer(props) {
  const { onFinish, user } = props;
  const [stripeUrl, setStripeUrl] = useState();
  const [charges, setCharges] = useState();

  function handleConnectStripe() {
    window.location.replace(stripeUrl);
  }

  const roundAmount = (charge) => {
    if (!charge) {
      return 0;
    }
    return Math.round(charge) / 100.0;
  }

  const getIncome = () => {
    $.ajax({
      url: `../get_income`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        setCharges(data.charges.sort((x, y) => {
          const dateX = parseISO(x.created_at);
          const dateY = parseISO(y.created_at);
          if (dateX > dateY) {
            return -1;
          } else if (dateX < dateY) {
            return 1;
          }
          return 0;
        }));
      },
      error: function (xhr, status, err) {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    $.ajax({
      url: `../connect_stripe_user`,
      dataType: "JSON",
      type: "GET",
      header: { "Content-Type": "application/json" },
      success: (data) => setStripeUrl(data.stripeUrl),
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
    onFinish();
    getIncome();
  }, []);

  const getDigitalDownloadIncomeFromMonth = (numOfMonths) => {
    return getIncomeFromMonth(1, numOfMonths);
  }

  const getEventIncomeFromMonth = (numOfMonths) => {
    return getIncomeFromMonth(2, numOfMonths);
  }

  const getIncomeFromMonth = (chargeType, numOfMonths) => {
    const now = new Date();
    const monthCharges = charges?.filter(
      (charge) => 
        ((parseISO(charge?.created_at).getMonth() === now.getMonth() - numOfMonths) &&
          charge?.type === chargeType)
    );

    return roundAmount(monthCharges?.reduce((n, { sale_amount, application_fee_amount }) =>
      n + sale_amount - application_fee_amount, 0));
  };

  const renderThisMonthEarnings = () => {
    const thisMonthEarnings = getDigitalDownloadIncomeFromMonth(0) + getEventIncomeFromMonth(0);
    return thisMonthEarnings.toLocaleString("en-US", {type: "currency", currency: "USD"});
  };

  const renderLastMonthEarnings = () => {
    const lastMonthEarnings = getDigitalDownloadIncomeFromMonth(1) + getEventIncomeFromMonth(1);
    return lastMonthEarnings.toLocaleString("en-US", {type: "currency", currency: "USD"});
  };

  return (
    <div className="incomeContainer">
      <div className="createHeaderContainer">
        <div className="stackableContainerStyle">
          <h1>Look at you, {user.first_name}!</h1>
          <div />
        </div>
      </div>
      {!!charges?.length && (
        <div className="income--charges--content">
          <div className="income--charges--cards">
            <div className="income--card">
              <h2>This month’s earnings</h2>
              <div className="amount--row">
                <h1>{renderThisMonthEarnings()}</h1>
                <span>digital sales before taxes</span>
              </div>
            </div>
            <div className="income--card">
              <h2>Last month’s earnings</h2>
              <div className="amount--row">
                <h1>{renderLastMonthEarnings()}</h1>
                <span>digital sales before taxes</span>
              </div>
            </div>
          </div>
          <h2>Income timeline</h2>
          <IncomeTimelineChart
            getDigitalDownloadIncomeFromMonth={getDigitalDownloadIncomeFromMonth}
            getEventIncomeFromMonth={getEventIncomeFromMonth}
          />
          <h2>Your earnings</h2>
          <div className="earnings--table">
            <table className="listTable">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Price</th>
                  <th>income after fees</th>
                </tr>
                {charges && (
                  <>
                    {charges.map((charge) => {
                      return (
                        <tr key={charge?.id} className="body--tr">
                          <td>{charge?.title}</td>
                          {charge?.type === 1 && <td><p>DOWNLOAD</p></td>}
                          {charge?.type === 2 && <td><p>EVENT</p></td>}
                          <td>
                            {format(
                              parseISO(charge?.created_at),
                              "MMM dd, yyyy"
                            )}
                          </td>
                          <td>${roundAmount(charge?.sale_amount)}</td>
                          <td>
                            $
                            {roundAmount(charge?.sale_amount -
                              charge?.application_fee_amount)}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </thead>
            </table>
          </div>
        </div>
      )}
      {!charges?.length && (
        <div className="income--content">
          <div className="introduction--card">
            <div>
              <img src={bread} className="cardImage" />
            </div>
            <h2>Ready to make some dough?</h2>
            <p>
              Income, but make it passive. There is so much value in your
              expertise. We can’t wait to see what you create!{" "}
            </p>
            <div className="introduction--card--button">
              <Link to="/dashboard/create">
                <div className="primaryButton">
                  <span>Start creating</span>
                </div>
              </Link>
              {/* <div className="secondaryButton">
              <span>Get inspired</span>
            </div> */}
            </div>
          </div>

          {!user.stripe_user_id && (
            <div className="introduction--card">
              <div>
                <img src={emoji} className="cardImage" />
              </div>
              <h2>Show me the money!</h2>
              <p>
                Before you can start earning money, you’ll need to connect with
                Stripe so you can accept payments.
              </p>
              <div className="introduction--card--button">
                <div
                  className="primaryButton"
                  onClick={() => handleConnectStripe()}
                >
                  <span>Connect Stripe</span>
                </div>
              </div>
            </div>
            )}
        </div>
      )}
    </div>
  );
}
