import React, {useState, useRef, useEffect} from "react";

import Notice from "./Notice";
import Modal from "./Modal";
import FloatingButton from "./FloatingButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

function CropModal({imageUri, onSave, mime, aspectRatio, onCancel}) {
  const getCroppedImg = (image, pixelCrop, blob) => {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = Math.floor(pixelCrop.width * scaleX)
    canvas.height = Math.floor(pixelCrop.height * scaleY)

    const ctx = canvas.getContext('2d');
    const cropX = pixelCrop.x * scaleX
    const cropY = pixelCrop.y * scaleY
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.translate(-cropX, -cropY)
    ctx.translate(centerX, centerY)
    ctx.translate(-centerX, -centerY)
   
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    mime = mime || "image/png";

    if (blob) {
      return new Promise((resolve, reject) => {
       canvas.toBlob(file => {
         file.name = `profile.png`;
         resolve(file);
       }, mime);
      });
    }
    // use base64 string
    return canvas.toDataURL(mime);
  };

  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState();
  const imageRef = useRef();

  useEffect(() => {
    if (!completedCrop) {
      return;
    }
  }, [completedCrop]);

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  const renderModalContent = () => {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
      }}>
      <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspectRatio}
          minHeight={200}
        >
          <img
            ref={imageRef}
            src={imageUri}
            style={{
              width: "400px",
            }}
            onLoad={(e) => {
              const { width, height } = e.currentTarget
              setCrop(centerAspectCrop(width, height, aspectRatio))
            }}
          />
      </ReactCrop>
      <FloatingButton
        title="Crop & Save"
        onClick={async () => {
          const blob = await getCroppedImg(imageRef.current, completedCrop, true);
          onSave(blob);
        }}
        disabled={!completedCrop}
      />
      </div>
    );
  };

  return <Modal onClose={onCancel} renderContent={renderModalContent} />
};

function ImageUpload(props) {
  const {thumbUrl, uploadFn, deleteFn} = props;

  const maxUploadSizeM = Number(props.maxUploadSizeM) || 5;

  const [message, setMessage] = useState("");
  const [pendingImage, setPendingImage] = useState();
  const [previewUrl, setPreviewUrl] = useState(thumbUrl);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/jpeg,image/png",
    onDrop: (acceptedFiles) => {
      setPendingImage(acceptedFiles[0]);
      setPreviewUrl(URL.createObjectURL(acceptedFiles[0]));
    },
    onDropRejected: (rejectedFiles) => {
      setMessage("The selected format is invalid.");
      setTimeout(() => setMessage(""), 3000);
    },
    maxSize: maxUploadSizeM * 1024 * 1024,
  });

  let classes = "tw-flex tw-w-64 tw-items-center tw-rounded-lg ";
  let style = {};
  if (!thumbUrl) {
    classes += "tw-flex-col tw-p-8 ";
    style.border = "2px dashed #f3eeff";
  }

  return (
    <div className={classes} style={style}>
      {message && <Notice message={message} />}
      {pendingImage && (
        <CropModal imageUri={previewUrl} aspectRatio={props.aspectRatio} onSave={(image) => {
            uploadFn(image).then(() => setPendingImage(null));
          }}
          onCancel={() => setPendingImage(null)}
        />
      )}
      <div {...getRootProps()} className={
        "ImageDragAndDrop tw-flex tw-flex-col tw-items-center tw-justify-center " +
        (!!thumbUrl ? "" : "tw-gap-3")
      }>
        <input {...getInputProps()} />
        {thumbUrl ? (
          <img
            className="tw-w-full" style={{objectFit: "cover", aspectRatio: props.aspectRatio}}
            src={thumbUrl} alt="thumbnail"
          />
        ) : (<>
          <button
            className="dark-accent-button tw-flex tw-items-center tw-justify-center tw-gap-2"
          >
            <FontAwesomeIcon icon={faUpload} size="sm" style={{color: "white"}}/>
            <p className="tw-font-semibold text-18 tw-text-white">Upload</p>
          </button>
          <p style={{fontSize: "20px"}}>or drag a photo</p>
        </>)}
      </div>
      {thumbUrl && (
        <FontAwesomeIcon
          className="icon-cog"
          icon={faTimes}
          onClick={deleteFn}
        />
      )}
    </div>
  );
};

export default ImageUpload;
