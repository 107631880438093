import React from "react";
import EditIcon from "../../../components/EditIcon";

class Experience extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
    }

    this.toggleEditModal = this.toggleEditModal.bind(this)
    this.renderExperience = this.renderExperience.bind(this)
  }

  toggleEditModal(id) {
    if (!this.props.liveProfile) {
      this.props.toggleEditModal(id)
    }
  }

  renderExperience() {
    return (
      this.props.user.user_caretypes.map((caretype, i) => {
        if (caretype.practicing_since == ("" || null || undefined)) {
          return null
        } else {
          let year = new Date().getFullYear();
          return (
            <li key={i}>
              {year - caretype.practicing_since < 1 ? '< 1 as ' : `${year - caretype.practicing_since} as `}
              {caretype.hasOwnProperty('care_subtype') && caretype.care_subtype.hasOwnProperty('name') ? `${caretype.care_subtype.name} ` : ''}
              {caretype.hasOwnProperty('care_type') && caretype.care_type.hasOwnProperty('name') ? caretype.care_type.name : ''}
            </li>
          )
        }
      })
    )
  }

  render() {
    if (this.props.liveProfile && this.renderExperience().every(i => i == null)) {
      return null
    }

    return (
      <div onClick={() => this.toggleEditModal('experience')}>
        <h5 className="detailsSectionTitle">Years of experience</h5>
        <ul className="detailList">
          {this.renderExperience()}
        </ul>
        {!this.props.liveProfile && <EditIcon />}
      </div>
    )
  }
}

export default Experience
