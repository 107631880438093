import React from "react";
import EditIcon from "../../../components/EditIcon";

class Languages extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
      timeout: null,
    }

    this.toggleEditModal = this.toggleEditModal.bind(this)
    this.renderLanguages = this.renderLanguages.bind(this)
  }

  toggleEditModal(id) {
    if (!this.props.liveProfile) {
      this.props.toggleEditModal(id)
    }
  }

  renderLanguages() {
    return (
      this.props.user.languages.map((language, i) => {
        return <li key={i} style={{ marginTop: 5 }}>{language.name}</li>
      })
    )
  }

  render() {
    if (this.props.liveProfile && this.props.user.languages.length <= 0) {
      return null
    }

    return (
      <div onClick={() => this.toggleEditModal('languages')}>
        <h5 className="detailsSectionTitle">Language(s)</h5>
        <ul className="detailList">
          {this.renderLanguages()}
        </ul>
        {!this.props.liveProfile && <EditIcon />}
      </div>
    )
  }
}

export default Languages
