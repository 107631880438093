import React, {useState, useEffect} from "react";

import Editor, {TextInput, formPadding} from "../components/Editor";
import SlugChanger from "../components/SlugChanger";
import ImageUpload from "../components/ImageUpload";
import ProfessionForm from "../forms/ProfessionForm";
import EditPronouns from "./EditPronouns";
import {ProfileIntro} from "../profile/ProviderProfileContainer";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faInstagram, faTiktok, faTwitter, faFacebook,
  faPinterest, faYoutube, faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import {saveUserChanges} from "../../queries/user";
import {uploadAvatar, removeAvatar} from "../../queries/user";

const val = (x) => x.target.value;

const SOCIALS = [
  {
    icon: faInstagram,
    fieldName: "instagram",
    placeholder: "instagram username",
  },
  {
    icon: faTiktok,
    fieldName: "tiktok",
    placeholder: "tiktok username",
  },
  {
    icon: faTwitter,
    fieldName: "twitter",
    placeholder: "twitter handle",
  },
  {
    icon: faFacebook,
    fieldName: "facebook",
    placeholder: "facebook handle",
  },
  {
    icon: faPinterest,
    fieldName: "pinterest",
    placeholder: "pinterest username",
  },
  {
    icon: faYoutube,
    fieldName: "youtube",
    placeholder: "youtube username",
    prefix: "youtube.com/",
  },
  {
    icon: faSpotify,
    fieldName: "spotify",
    placeholder: "spotify link",
    prefix: "open.spotify.com/",
  },
  {
    icon: faGlobe,
    fieldName: "website",
    placeholder: "personal website",
    prefix: "https://"
  },
];

export default function EditProfileIntro(props) {
  const [user, setUser] = useState(props.user);
  const [userChanges, setUserChanges] = useState({});
  const [userChangesRecentlySaved, setUserChangesRecentlySaved] = useState(false);
  const [newCareTypeCount, setNewCareTypeCount] = useState(0);
  const [changingSlug, setChangingSlug] = useState(false);

  const userChangesMade = Object.keys(userChanges).length > 0;

  const saveChanges = async () => {
    if (!userChangesMade) {
      return;
    }
    await saveUserChanges(userChanges);
    setUserChanges({});
    setUserChangesRecentlySaved(true);
  };

  useEffect(() => {
    if (userChangesRecentlySaved) {
      setTimeout(() => setUserChangesRecentlySaved(false), 3000);
    }
  }, [userChangesRecentlySaved]);

  const textInputClasses = "tw-min-w-0 tw-grow tw-rounded-lg tw-bg-white tw-p-2 thick-light-purple-border";

  const changeField = (fieldName, value) => {
    setUser((prevUser) => {
      let u = {...prevUser};
      u[fieldName] = value;
      return u;
    });
    setUserChanges((prevChanges) => {
      let changes = {...prevChanges};
      changes[fieldName] = value;
      return changes;
    });
  };

  const renderTextInput = (label, fieldName, placeholder = null) => {
    return <div key={fieldName} className={`tw-flex tw-flex-col ${formPadding}`}>
      {!!label && (<p className="tw-font-semibold">{label}</p>)}
      <input
        className={textInputClasses}
        defaultValue={user[fieldName]}
        placeholder={placeholder}
        onChange={x => changeField(fieldName, val(x))}
      />
    </div>;
  }

  const renderPhoto = () => {
    const avatarUrl = user.avatar_url;
    const ASPECT = 0.909;

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="text-12">{
        "Time for your close-up. Say cheese! 🧀\n"
      }<br />{
        "We'll crop it to a ratio of 10:11."
      }</p>
      <ImageUpload
        key={`imageUpload_${avatarUrl}`}
        thumbUrl={avatarUrl}
        uploadFn={(image) => uploadAvatar(image, (avatarUrl) => {
          changeField("avatar_url", avatarUrl);
        })}
        deleteFn={() => removeAvatar(() => changeField("avatar_url", null))}
        aspectRatio={ASPECT}
      />
    </div>;
  };

  const renderProfessionEditor = () => {
    const userCareTypesMutation = {mutate: ({action, payload}) => {
      let u = {...user};
      let ct = u.user_caretypes;

      switch (action) {
        case "create":
          ct.push({
            care_type: {
              id: `new_${newCareTypeCount}`,
              name: payload.care_type_name
            },
            care_type_id: payload.care_type_id,
          });
          setNewCareTypeCount(newCareTypeCount + 1);
          break;
        case "update":
          {
            let careType = ct.find(x => x.id === payload.id);
            if (payload.care_subtype_id) {
              careType.care_subtype = {
                id: `new_${newCareTypeCount}`,
                name: payload.care_subtype_name,
              };
              careType.care_subtype_id = payload.care_subtype_id;
            } else if (payload.care_type_id) {
              careType.care_subtype = undefined;
              careType.care_subtype_id = null;
              careType.care_type_id = payload.care_type_id;
              careType.care_type = {
                id: `new_${newCareTypeCount}`,
                name: payload.care_type_name,
              };
            }
            setNewCareTypeCount(newCareTypeCount + 1);
          }
          break;
        case "delete":
          {
            let i = ct.findIndex(x => x.id === payload.id);
            ct.splice(i, 1);
          }
          break;
      }

      setUser(u);
      setUserChanges({...userChanges, user_caretypes: ct});
    }};

    return <div>
      <ProfessionForm userCareTypes={user.user_caretypes}
        userCareTypesMutation={userCareTypesMutation}
        abbreviatedForm="abbreve this please"
      />
    </div>
  };

  const renderAdjectivesEditor = () => {
    const getAdjectives = () => {
      let adj = user.adjectives || [];
      if (!Array.isArray(adj)) {
        return JSON.parse(adj);
      }
      return adj;
    };

    const changeAdjective = (i, value) => {
      let adjectives = getAdjectives();
      adjectives[i] = value;
      changeField("adjectives", adjectives);
    };

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="tw-font-semibold">Adjectives to describe me</p>
      <div className={`tw-flex ${formPadding}`}>
        {[0, 1, 2].map((i) => 
          <input
            key={i}
            className={`${textInputClasses} tw-w-full`}
            defaultValue={getAdjectives()[i]}
            onChange={x => changeAdjective(i, val(x))}
          />
        )}
      </div>
    </div>
  }

  const renderPronounsEditor = () => {
    const setCustomPronoun = (pronoun) => {
      changeField("additional_pronoun", pronoun);
    };

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="tw-font-semibold">Pronouns</p>
        <EditPronouns
          user={user}
          setPronouns={pronouns => changeField("pronouns", pronouns)}
          setCustomPronoun={setCustomPronoun}
        />
      </div>
  };

  const renderGreeting = () => {
    return (
      <div className={`tw-flex tw-flex-col tw-items-stretch ${formPadding}`}>
        <TextInput
          label="Preferred name"
          defaultValue={user["display_name"]}
          placeholder={`${user.first_name} ${user.last_name}`}
          onChange={x => changeField("display_name", x)}
        />
        {renderTextInput("Professional title", "credentials", "LMFT")}
        {renderProfessionEditor()}
        {renderTextInput("Clients call me", "preferred_name")}
        {renderAdjectivesEditor()}
        {renderPronounsEditor()}
      </div>
    );
  };

  const renderCallToAction = (i) => {
    const elements = <div className={`tw-flex tw-flex-col tw-items-stretch ${formPadding}`}>
      <p className="tw-font-semibold">{"Let potential clients know if you're available!"}</p>
      <p className="tw-text-sm tw-font-light"
      >ex. Join my mailing list!, Join the waitlist, Not accepting clients, etc.</p>
      <input
        key={i}
        className={textInputClasses}
        defaultValue={user.tagline}
        onChange={x => {
          let u = {...user};
          u.tagline = val(x);
          setUser(u);
          let changes = {...userChanges};
          changes.tagline = u.tagline;
          setUserChanges(changes);
        }}
      />
    </div>;

    return elements;
  };

  const renderEmailList = () => {
    const setMailingListFlag = x => {
      let u = {...user};
      u.mailing_list = x;
      setUser(u);
      let changes = {...userChanges};
      changes.mailing_list = u.mailing_list;
      setUserChanges(changes);
    };
    const radioClass = "dark-accent-text";
    const radioStyle = {};
    const elements = <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="tw-font-semibold">Do you want to include a mailing list subscription form?</p>
      <p className="tw-text-sm tw-font-light"
      >This will collect email contacts to help you stay in touch with your community!</p>
      <div className={`tw-flex ${formPadding}`}>
        <input
          type="radio"
          name="mailing_list"
          className={radioClass}
          style={radioStyle}
          defaultChecked={user.mailing_list}
          onChange={() => setMailingListFlag(true)}
        />
        <p>Yes</p>
        <input
          type="radio"
          name="mailing_list"
          className={radioClass}
          style={radioStyle}
          defaultChecked={!user.mailing_list}
          onClick={() => setMailingListFlag(false)}
        />
        <p>No</p>
      </div>
    </div>;

    return elements;
  };

  const renderSocial = (platform, i) => {
    const prefix = platform.prefix || "@";

    return <div key={i} className={
        "tw-flex light-purple-background tw-rounded-lg tw-p-3 " +
        "tw-items-center "
      }
      style={{maxWidth: "350px"}}
    >
      <FontAwesomeIcon
        icon={platform.icon} size="sm"
        alt={platform.fieldName}
        style={{color: "#0a2175", marginRight: "10px"}}
      />
      {!user[platform.fieldName] && <p
        style={{fontSize: "16px", marginRight: "5px"}}
      >{prefix}</p>}
      <input
        className="tw-w-full"
        defaultValue={user[platform.fieldName]}
        placeholder={platform.placeholder || "username or handle"}
        onChange={x => changeField(platform.fieldName, val(x))}
      />
    </div>
  }

  const renderSocialLinks = () => {
    const elements = <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="tw-font-semibold">Stay connected on your other channels!</p>
      <p className="tw-text-sm tw-font-light"
      >Include as many or as few platforms as you like.</p>
      <div className="tw-flex tw-flex-col tw-gap-3">
        {SOCIALS.map(renderSocial)}
      </div>
    </div>;
    return elements;
  };

  const renderSlugChanger = () => {
    return <SlugChanger
     slug={user.slug}
     onError={() => {}}
     onSuccess={() => {}}
    />
  };

  const renderCustomUrl = () => {
    const elements = <div className={`tw-flex ${formPadding}`}>
      {(changingSlug ?
        renderSlugChanger() :
        <>
          <p className="text-16">
            {user.slug}
            <span className="tw-font-semibold">.{process.env.ROOT_DOMAIN}</span>
          </p>
          <p className="text-12 dark-purple-text" onClick={() => setChangingSlug(true)}>Change URL</p>
        </>
      )}
    </div>;

    return elements;
  };

  const SECTIONS = [
    {
      title: "Photo",
      render: renderPhoto,
    },
    {
      title: "Greeting",
      render: renderGreeting,
    },
    {
      title: "Call to action",
      render: renderCallToAction,
    },
    {
      title: "Email list",
      render: renderEmailList,
    },
    {
      title: "Social links",
      render: renderSocialLinks,
    },
    {
      title: "Custom URL",
      render: renderCustomUrl,
    },
  ];

  if (!user) {
    return <p>Loading...</p>;
  }

  return <Editor
    title="Your intro"
    changesMade={userChangesMade}
    changesRecentlySaved={userChangesRecentlySaved}
    saveChanges={saveChanges}
    sections={SECTIONS}
    renderPreview={() => <ProfileIntro user={user} currentUser={user} />}
  />;
};
