import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { apiBase } from "../../utils/apiRequest";
import AutoSave from "../components/AutoSave";

export default function Scheduling(props) {
  const { onFinish } = props;
  const [simplePractice, setSimplePractice] = useState("");
  const [calendly, setCalendly] = useState("");
  const [isSavingCalendly, setIsSavingCalendly] = useState(false);
  const [isSavingSimplePractice, setIsSavingSimplePractice] = useState(false);

  const updateUser = async (data) => {
    const response = await apiBase("../update_user", "POST", data);

    setIsSavingCalendly(false);
    setIsSavingSimplePractice(false);
  };

  useEffect(() => {
    onFinish();
  }, []);

  return (
    <div className="myCollectionContainer">
      <div className="createHeaderContainer">
        <div className="stackableContainerStyle">
          <h1>Scheduling</h1>
        </div>
      </div>
      <div className="scheduling--content">
        <p>Let potential clients book an intro call with you!</p>
        <span>SimplePractice</span>
        <small>
          Embed your SimplePractice <b>booking widget</b> for potential clients
          to book an intro call
        </small>
        <textarea
          className="input--enbed--code"
          placeholder="Code"
          value={simplePractice}
          onChange={(e) => setSimplePractice(e.target.value)}
          onBlur={() => {
            setIsSavingSimplePractice(true);
            updateUser({ simple_practice: simplePractice });
          }}
          type="textarea"
          maxLength={500}
          disabled={!!calendly}
        />
        <div className="auto--save">
          {isSavingSimplePractice && <AutoSave saving={true} />}
        </div>
        <span>Calendly</span>
        <small>
          Embed your <b>Calendly</b> for potential clients to book an intro call
        </small>
        <textarea
          className="input--enbed--code"
          placeholder="Code"
          value={calendly}
          onChange={(e) => setCalendly(e.target.value)}
          onBlur={() => {
            setIsSavingCalendly(true);
            updateUser({ calendly: calendly });
          }}
          type="textarea"
          maxLength={500}
          disabled={!!simplePractice}
        />
        <div className="auto--save">
          {isSavingCalendly && <AutoSave saving={true} />}
        </div>
        <span>
          Note: You can only embed one booking platform at this time. Hopefully,
          that is simpler for you to manage anyway!
        </span>
      </div>
    </div>
  );
}
