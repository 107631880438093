import {asyncApiRequest} from "../utils/apiRequest";

const getCareTypes = async () => {
  return asyncApiRequest("/get_caretypes", "GET");
};

const getDegreeTypes = async () => {
  return asyncApiRequest("/get_degree_types", "GET");
};

const getPronouns = async () => {
  return asyncApiRequest("/get_pronouns", "POST");
};

const getGenders = async () => {
  return asyncApiRequest("/get_genders", "POST");
};

export const getTaglines = async () => {
  return asyncApiRequest("/get_all_tagline", "GET");
};

export const getSpecialties = async () => {
  return asyncApiRequest("/get_specialties", "GET");
};

export const getEthnicities = async () => {
  return asyncApiRequest("/get_ethnicities", "POST");
};

export const getIdentityExpertises = async () => {
  return asyncApiRequest("/get_identity_expertises", "GET");
};

export const getLanguages = async () => {
  return asyncApiRequest("/get_languages", "GET");
};

export const getInsurances = async () => {
  return asyncApiRequest("/get_insurances", "GET");
};

export const getTreatmentTechniques = async () => {
  return asyncApiRequest("/get_treatment_techniques", "GET");
};

export const getApproachStyles = async () => {
  return asyncApiRequest("/get_approach_styles", "GET");
};

export {
  getCareTypes,
  getDegreeTypes,
  getPronouns,
  getGenders,
};
