import React, {useState, useEffect} from "react";

export default function ButtonSelectForm(props) {
  const {
    label,
    options,
    col,
  } = props;

  const gap = props.gap || "4";
  const gapClass = `tw-gap-${gap}`;

  const selectedClass = "tw-px-5 tw-py-2 tw-rounded-lg accent-border light-accent-background accent-shadow";
  const deselectedClass = "tw-px-5 tw-py-2 tw-rounded-lg light-accent-border light-light-accent-shadow";

  return <div className={`tw-flex tw-flex-col ${gapClass}`}>
    {!!label && <p className="text-16 tw-font-semibold">{label}</p>}
    <div className={`tw-flex ${col ? "tw-flex-col" : ""} ${gapClass}`}>
      {options.map((option, i) => {
        const {buttonLabel, getter, setter} = option;
        const selected = getter();
        const classes = selected ? selectedClass : deselectedClass;
        return <button
          key={i} className={classes}
          onClick={() => setter(i)}>
          <p className="text-16 tw-font-semibold">{buttonLabel}</p>
        </button>;
      })}
    </div>
  </div>;
}
