import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


class DropDownSelectForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false
    }

    this.renderOptions = this.renderOptions.bind(this)
    this.selectOption = this.selectOption.bind(this)
  }

  selectOption(item) {
    this.setState({
      dropdownOpen: false
    })
    this.props.selectOption(item, this.props.attribute)
  }

  renderOptions() {
    return this.props.options.map((item, i) => {
      return (<p key={i} onClick={() => this.selectOption(item)}>{item[this.props.optionName]}</p>)
    })

  }

  render() {
    return(
      <div>
        <div
          className="profile_text_field"
          style={this.props.style}
          onClick={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
          <p className={this.props.selected === '' ? "halfPurple" : ''}>{this.props.selected != '' ? this.props.selected : this.props.placeholder ? this.props.placeholder : 'Type or select an option'}</p>
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{color: '#0A2175'}}
            />
        </div>
        {this.state.dropdownOpen &&
          <div className="dropdownProfileSelectContainer">
          {this.renderOptions()}
          </div>
        }
      </div>
    )
  }

}

export default DropDownSelectForm;
