import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const SubMenuModal = ({
  item: { id, status, income_received, mouseX, mouseY },
  handleDelete,
  handleArchive,
  handleClose,
}) => {
  const left = `${mouseX - 176}px`;
  const top = `${mouseY - 5}px`;

  return (
    <div
      className="sub--menu--modal--outside"
      onClick={() => {
        handleClose();
      }}
    >
      <div className="action--modal" style={{ left: left, top: top }}>
        {status === "PUBLISHED" && (
          <div className="action--modal--row" onClick={() => handleArchive(id)}>
            <FontAwesomeIcon icon={faArchive} style={{ color: "#733DFF" }} />
            <p>Archive (Hide)</p>
          </div>
        )}
        {Number(income_received) <= 0 && (
          <div
            className="action--modal--row"
            onClick={() => {
              handleDelete(id);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} style={{ color: "#733DFF" }} />
            <p>Delete</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubMenuModal;
