import React, {useState, useEffect} from "react";
import TextInput from '../forms/TextInput';

export default function PreferredNameOnboard(props) {
  const user = props.currentUser; // props.queryCurrentUser.data;
  const [preferredName, setPreferredName] = useState(user?.preferred_name);
  const [updateTimeout, setUpdateTimeout] = useState();

  useEffect(() => {
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }

    if (preferredName === user?.preferred_name) {
      return;
    }

    const timeout = setTimeout(
      () => props.updateUser({preferred_name: preferredName}), 500
    )
    setUpdateTimeout(timeout);

    return () => {clearTimeout(updateTimeout); setUpdateTimeout(null);}
  }, [preferredName]);

  if (!user) {
    return <p>Loading...</p>;
  }
 
  return (
    <React.Fragment>
      <div className="onboard_field_section">
        <h3
          className="standard_label" style={{paddingBottom: 10}}
        >What do you want clients to call you?</h3>
        <h4
          className="standard_label" style={{paddingBottom: 40, opacity: .5}}
        >e.g., {user.first_name}, Dr {user.last_name}</h4>
        <TextInput
          type="text"
          autofocus={true}
          defaultValue={preferredName}
          attribute={'preferred_name'}
          handleChange={(_, name) => setPreferredName(name)}
        />
      </div>
    </React.Fragment>
  );
};
