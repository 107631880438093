import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export default function EditIcon() {
  return (
    <FontAwesomeIcon
      style={{
        backgroundColor: "white",
        border: "1px solid #733dff",
        boxShadow: "2px 4px 0px #733dff",
        borderRadius: "10px",
        padding: "10px",
        color: "#733dff",
        marginRight: "2px",
        cursor: "pointer",
      }}
      icon={faPencilAlt}
    />
  );
}
