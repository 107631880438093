import React, {useEffect, useState, useRef} from "react";

import { apiBase, asyncApiRequest } from "../../utils/apiRequest";
import { UserStatus } from "../../utils/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck, faCogs, faEnvelopeOpen, faEye, faEyeSlash, faLock, faTimes, faUser
} from "@fortawesome/free-solid-svg-icons";

const csrf = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

const BuyerInfo = ({
  // buyer's info, populated if logged in
  currentUser,
  // called when info is changed, and the result is valid
  onChange,
  // error callback, called with format {prefix: "failed", err: e}
  onError,
  // whether to show 'continue' button below form
  // (the button forces an 'onChange' callback)
  showContinueButton,
  // initial buyer info
  // (may be populated from a previous instance of this component)
  initialInfo,
}) => {
  const [buyerInfo, setBuyerInfo] = useState(initialInfo || {
    email: currentUser?.email,
    prevStatus: currentUser ? UserStatus.User : UserStatus.Unknown,
    status: currentUser ? UserStatus.User : UserStatus.Unknown,
    firstName: currentUser?.first_name,
    lastName: currentUser?.last_name,
  });
  const [password, setPassword] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [buyerInfoBlockers, setBuyerInfoBlockers] = useState(currentUser ? [] : [
    "missing email", "missing first name", "missing last name"
  ]);
  const [validationTimeout, setValidationTimeout] = useState(undefined);
  const guestFormContainer = useRef();
  const loginFormContainer = useRef();

  const validateEmail = () => {
    var re = /\S+@\S+\.\S{2,}/;
    return re.test(buyerInfo.email);
  };

  const getGuestInfoBlockers = () => {
    let blockers = [];
    if (!validateEmail()) {
      blockers.push("invalid email");
    }
    // user is a guest, and must fill in first & last name using the form
    if (!buyerInfo.firstName) {
      blockers.push("missing first name");
    } if (!buyerInfo.lastName) {
      blockers.push("missing last name");
    }
    return blockers;
  }

  const toggleFormVisibility = (prevUserStatus, userStatus) => {
    switch (prevUserStatus) {
      default:
      case UserStatus.Unknown:
        switch (userStatus) {
          case UserStatus.User:
            $(loginFormContainer.current).slideToggle("slow");
            break;
          case UserStatus.Guest:
          case UserStatus.New:
            $(guestFormContainer.current).slideToggle("slow");
            break;
          default:
            break;
        }
        break;
      case UserStatus.Guest:
      case UserStatus.New:
        switch (userStatus) {
          case UserStatus.User:
            $(loginFormContainer.current).slideToggle("slow");
          case UserStatus.Unknown:
            $(guestFormContainer.current).slideToggle("slow");
          default:
            break;
        }
        break;
      case UserStatus.User:
        switch (userStatus) {
          case UserStatus.Guest:
          case UserStatus.New:
            $(guestFormContainer.current).slideToggle("slow");
          case UserStatus.User:
            break;
          default:
          case UserStatus.Unknown:
            $(loginFormContainer.current).slideToggle("slow");
            break;
        }
        break;
    }
  };

  const emailLookup = async () => {
    if (!validateEmail()) {
      setBuyerInfoBlockers(["invalid email"]);
      return;
    }
    setBuyerInfoBlockers(["unknown email"]);
    const emailStatus = await apiBase("/email_lookup", "POST", {email: buyerInfo.email});
    if (emailStatus?.status) {
      if (emailStatus.status === UserStatus.User) {
        setBuyerInfoBlockers(["authentication required"]);
      }
      setBuyerInfo({
        email: buyerInfo.email,
        status: emailStatus.status,
        firstName: buyerInfo.firstName,
        lastName: buyerInfo.lastName,
      })
    } else {
      console.log("email lookup returned nothing");
    }
  };

  const onBuyerInfoChange = () => {
    // show or hide the login form and guest forms based on the change in the user status
    toggleFormVisibility(buyerInfo.prevStatus, buyerInfo.status);
    buyerInfo.prevStatus = buyerInfo.status;

    switch (buyerInfo.status) {
      default:
      case UserStatus.Unknown:
        emailLookup();
        break;
      case UserStatus.New:
      case UserStatus.Guest:
        const blockers = getGuestInfoBlockers();
        setBuyerInfoBlockers(blockers);
        if (!showContinueButton && !blockers.length) {
          onChange(buyerInfo);
        }
        break;
      case UserStatus.User:
        if (!showContinueButton) {
          onChange(buyerInfo);
        }
        break;
    };
  }

  useEffect(() => {
    if (validationTimeout) {
      clearTimeout(validationTimeout);
    }
    setValidationTimeout(setTimeout(onBuyerInfoChange, 500));

    return () => {
      clearTimeout(validationTimeout);
    }
  }, [buyerInfo]);

  const onEmailChange = (e) => {
    setBuyerInfo({
      ...buyerInfo,
      email: e.target.value.toLowerCase(),
      status: UserStatus.Unknown,
    });
  }

  const renderGuestForm = () => {
    return (
      <div ref={guestFormContainer} className="initially--hidden--form--container">
        <div className="input--row">
          <div className="input--icon--container">
            <FontAwesomeIcon
              icon={faUser}
              size="xs"
            />
            <input
              placeholder="First name"
              defaultValue={buyerInfo.firstName}
              onChange={(e) => setBuyerInfo({...buyerInfo, firstName: e.target.value})}
            />
          </div>
          <div className="input--container">
            <input
              placeholder="Last name"
              defaultValue={buyerInfo.lastName}
              onChange={(e) => setBuyerInfo({...buyerInfo, lastName: e.target.value})}
            />
          </div>
        </div>
      </div>
    );
  }

  const login = async () => {
    let errorPrefix = "couldn't find that username and password combination";
    try {
      await $.ajax({
        url: "../../login",
        dataType: "text",
        type: "POST",
        data: JSON.stringify({
          user: {
            email: buyerInfo.email,
            password: password,
          },
          authenticity_token: csrf,
          payment_modal: true,
        }),
        headers: {
          "X-CSRFToken": csrf,
          "Content-Type": "application/json",
        },
      });

      errorPrefix = "couldn't fetch user info";
      let response = await apiBase("/get_current_user", "GET");
      if (response?.data?.user) {
        const user = JSON.parse(response?.data?.user);
        let newBuyerInfo = {
          status: UserStatus.User,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
        };
        setBuyerInfo(newBuyerInfo);
        setBuyerInfoBlockers([]);
        onChange(newBuyerInfo);
      }
    } catch (e) {
      onError({
        prefix: errorPrefix,
      });
    }
  };

  const renderLoginForm = () => {
    return (
      <div ref={loginFormContainer} className="initially--hidden--form--container">
        <div className="input--row">
          <div className="input--icon--container">
            <FontAwesomeIcon
              icon={faLock}
              size="xs"
            />
            <input
              type={showPassword ? "text" : "password"}
              attribute={"userPassword"}
              name={"password"}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required={true}
              style={{flexGrow: 1}}
            />
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash}
                size="xs"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContinueButton = () => {
    if (!showContinueButton) {
      return null;
    }

    const showLoginButton = (
      buyerInfo.status === UserStatus.User &&
      !!buyerInfoBlockers.length
    );
    const disabled = showLoginButton ? false : !!buyerInfoBlockers.length;

    if (!showLoginButton && !showContinueButton) {
      return null;
    }

    return (
      <button
        className={
          disabled ?
            "button--payment"
            : "button--payment button--payment--enabled"
        }
        onClick={() => {
          if (validationTimeout) {
            clearTimeout(validationTimeout);
          }

          onError({prefix: undefined, err: undefined});

          if (showLoginButton) {
            login();
          } else {
            onBuyerInfoChange();
            if (!buyerInfoBlockers.length) {
              onChange(buyerInfo);
            }
          }
        }}
        disabled={disabled}
      >
        <p>{showLoginButton ? "Login" : "Continue"}</p>
      </button>
    );
  }

  const getEmailIcon = () => {
    if (buyerInfo.email && buyerInfoBlockers.includes("invalid email")) {
      return {
        icon: faTimes,
        color: "#fcc"
      };
    }
    if (buyerInfo.status === UserStatus.Unknown) {
      return {icon: faCogs};
    }
    return {icon: faCheck};
  }

  const emailIcon = getEmailIcon();

  const renderEmailInput = () => {
    const loggedIn = (
      buyerInfo.status === UserStatus.User && !buyerInfoBlockers.length
    );
    if (loggedIn) {
      return null;
    }
    return (
      <div className="input--row">
        <div className="input--icon--container">
          <FontAwesomeIcon
            icon={faEnvelopeOpen}
            size="xs"
          />
          <input
            style={{flexGrow: 1}}
            placeholder="Email"
            defaultValue={buyerInfo.email}
            onChange={onEmailChange}
          />
          {buyerInfo.email && (
            <FontAwesomeIcon
            icon={emailIcon.icon}
            style={emailIcon.color && {color: emailIcon.color}}
            size="xs"/>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="guest--info--container">
      {renderEmailInput()}
      {renderLoginForm()}
      {renderGuestForm()}
      {renderContinueButton()}
    </div>
  );
};

export default BuyerInfo;
