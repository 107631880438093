import React, { useState, useContext, useEffect } from "react";
import FloatingButton from "./FloatingButton";
import StepsIndexContext from "../context/StepsIndexContext";
import ImageDragAndDrop from "./ImageDragAndDrop";
import UserDownloadContext from "../context/UserDownloadContext";
import FileContext from "../context/FileContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {amountCentsToStringUSD, stringUSDToAmountCents} from "../../utils/currency";
import CurrencyInput from "./CurrencyInput";
import { apiBase } from "../../utils/apiRequest";

export default function TheSparkleContent(props) {
  const navigate = useNavigate();
  const [step, setStep] = useContext(StepsIndexContext);
  const [amount, setAmount] = useState("");
  const [userDownload, setUserDownload] = useContext(UserDownloadContext);
  const [thumbFile, setThumbFile] = useState(null);
  const [publish, setPublish] = useState(false);

  const canPublishDownload = () => {
    if (!userDownload?.thumb_url) {
      // thumbnail required
      return false;
    }
    if (!userDownload?.download_file_title || !userDownload?.file_size) {
      return false;
    }
    return true;
  }

  const updateThumb = async (thumbFile, download_id) => {
    var formData = new FormData();
    formData.append("thumb", thumbFile);
    formData.append("id", download_id);

    $.ajax({
      url: "/update_user_download",
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        const [{ ...download }] = JSON.parse(data.download);

        setUserDownload((prevUserDownload) => {
          return {
            ...prevUserDownload,
            thumb_url: download?.thumb_url,
          };
        });
      },
    });
  };

  useEffect(() => {
    const getFile = async () => {
      updateThumb(thumbFile, userDownload?.id);
    };

    if (thumbFile && thumbFile.preview !== userDownload?.preview) {
      getFile();
    }
  }, [thumbFile]);

  useEffect(() => {
    if (publish) {
      userDownload.download_status = "PUBLISHED";
      props.updateUserDownload("download_status", "PUBLISHED");
      if (!userDownload.amount) props.updateUserDownload("amount", 0);

      window.location.assign(
        `//${props.user?.slug}.${process.env.ROOT_DOMAIN}/d/${userDownload.download_url}?celebration=true`
      );
    }
  }, [publish, userDownload]);

  useEffect(() => {
    const stripDollarSign = true;
    setAmount(amountCentsToStringUSD(userDownload?.amount || 0, stripDollarSign));
  }, [userDownload?.amount]);

  async function handlePublishButton() {
    await setUserDownload((prevUserDownload) => {
      return {
        ...prevUserDownload,
        amount: amount,
      };
    });

    setPublish(true);
  }

  return (
    <>
    <div className="stepContent">
      <h2 id="step2">How much does this download cost?</h2>
      <div className="theSparkleContentBody">
        <div className="amountInput">
          <CurrencyInput
            value={amount}
            placeholder="$0.00"
            className="inputAmount"
            onChange={(e) => setAmount(e.target.value)}
            onBlur={(e) => props.updateUserDownload(
              "amount", stringUSDToAmountCents(amount)
            )}
          />
        </div>
        <h2>Add a thumbnail image!</h2>
        <p>Make it pop! Be sure to use a high quality image.</p>
        <p>We recommend 1280px x 720px (16:9 ratio)</p>
        <FileContext.Provider value={[thumbFile, setThumbFile]}>
          <div className="picture--row">
            <ImageDragAndDrop thumbUrl={userDownload?.thumb_url} />
            {userDownload?.thumb_url && (
              <FontAwesomeIcon
                className="icon-cog"
                icon={faTrash}
                onClick={() => {
                  apiBase("../delete_download_thumb_url", "POST", {
                    id: userDownload.id,
                  });
                  setUserDownload((prevUserDownload) => {
                    return {
                      ...prevUserDownload,
                      thumb_url: null,
                    };
                  });
                }}
              />
            )}
          </div>
        </FileContext.Provider>
      </div>
    </div>
    <div className="buttons">
      {/* <FloatingButton
        title="Back"
        onClick={() => setStep(1)}
        secondaryButton={true}
      /> */}
      <FloatingButton
        title="Publish!"
        onClick={() => handlePublishButton()}
        disabled={!canPublishDownload()}
      />
    </div>
    </>
  );
}
