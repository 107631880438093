import React from "react"
import TextInput from '../forms/TextInput';

import {
  useQueryClient,
} from '@tanstack/react-query';

export default function BirthdayOnboard(props) {
  const queryClient = useQueryClient();

  return (
    <React.Fragment>
      <div className="onboard_field_section">
        <h3 className="standard_label" style={{paddingBottom: 10}}>What is your birthday?</h3>
        <h4 className="standard_label" style={{paddingBottom: 40, opacity: .5}}>Optional, helps potential clients filter and us to celebrate you!</h4>
        <TextInput
          type="date"
          autofocus={true}
          debounceDelayMs={1000}
          defaultValue={props.currentUser.birthday || ""}
          attribute={'birthday'}
          handleChange={(_, bday) => {
            props.updateUser({birthday: bday})
          }}
        />
      </div>
    </React.Fragment>
  );
}
