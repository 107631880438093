import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { parseISO, format } from "date-fns";

export default function DateTimePicker(props) {
  const {
    onStartTimeChange,
    onEndTimeChange,
    initialStartStamp,
    initialEndStamp,
    endTime,
  } = props;
  
  let initialStartDate = "";
  let initialStartTime = "";
  let initialStartTimeAmPm = "";
  let initialEndTime = "";
  let initialEndTimeAmPm = "";

  if (initialStartStamp) {
    initialStartDate = parseISO(initialStartStamp);
    console.log("initial start date", initialStartDate);

    const array = format(parseISO(initialStartStamp), "hh:mm aaaaa").split(
      " "
    );
    console.log("initial start time", array);
    initialStartTime = (array[0] || "00:00");
    initialStartTimeAmPm = (array[1] === "p" ? "PM" : "AM");
  }

  if (initialEndStamp) {
    const array = format(parseISO(initialEndStamp), "hh:mm aaaaa").split(
      " "
    );
    console.log("initial end time", array);
    initialEndTime = (array[0] || "00:00");
    initialEndTimeAmPm = (array[1] === "p" ? "PM" : "AM");
  }

  const [startDate, setStartDate] = useState(initialStartDate);
  const [scheduledTime, setScheduledTime] = useState(initialStartTime);
  const [scheduledTimeAmPm, setScheduledTimeAmPm] = useState(initialStartTimeAmPm || "AM");
  const [scheduledEndTime, setScheduledEndTime] = useState(initialEndTime);
  const [scheduledEndTimeAmPm, setScheduledEndTimeAmPm] = useState(initialEndTimeAmPm || "AM");

  const formatCharsScheduledTime = {
    1: "[0-1]",
    2: scheduledTime[0] === "1" ? "[0-2]" : "[0-9]",
    3: "[0-5]",
    4: "[0-9]",
  };
  const formatCharsScheduledEndTime = {
    1: "[0-1]",
    2: scheduledEndTime[0] === "1" ? "[0-2]" : "[0-9]",
    3: "[0-5]",
    4: "[0-9]",
  };

  const renderAmPmScheduledTime = () => {
    return (
      <p
        onClick={() => {
          setScheduledTimeAmPm((prevState) => {
            if (prevState === "AM") {
              return "PM";
            }
            return "AM";
          });
        }}
      >
        {scheduledTimeAmPm}
      </p>
    );
  };

  const renderAmPmScheduledEndTime = () => {
    return (
      <p
        onClick={() => {
          setScheduledEndTimeAmPm((prevState) => {
            if (prevState === "AM") {
              return "PM";
            }
            return "AM";
          });
        }}
      >
        {scheduledEndTimeAmPm}
      </p>
    );
  };

  const parseTimestamp = (date, time, amPm) => {
    const dateFmt = "yyyy-MM-dd";
    let dateString = undefined;

    try {
      dateString = format(date, dateFmt);
    } catch (e) {
      console.log("failed to parse date '" + date + "' as '" + dateFmt + "'.");
      return undefined;
    }
    const stampString = `${dateString}T${time}:00`;

    let stamp = undefined;
    try {
      stamp = parseISO(stampString);
    } catch (e) {
      console.log("failed to parse timestamp '" + stampString + "'.");
      return undefined;
    }

    const addHours = amPm === "PM" ? 12 : 0;
    stamp.setHours(stamp.getHours() + addHours);
    return stamp;
  }

  const onStartTimestampChange = () => {
    let stamp = parseTimestamp(startDate, scheduledTime, scheduledTimeAmPm);
    if (stamp && stamp instanceof Date && !isNaN(stamp)) {
      console.log("on start timestamp change:", stamp);
      onStartTimeChange(stamp);
    }
  }

  const onEndTimestampChange = () => {
    let stamp = parseTimestamp(startDate, scheduledEndTime, scheduledEndTimeAmPm);
    if (stamp && stamp instanceof Date && !isNaN(stamp)) {
      console.log("on end timestamp change:", stamp);
      onEndTimeChange(stamp);
    }
  }

  useEffect(onStartTimestampChange, [startDate, scheduledTime, scheduledTimeAmPm]);
  useEffect(onEndTimestampChange, [startDate, scheduledEndTime, scheduledEndTimeAmPm]);

  return (
    <div className="date--time--picker">
      <div className="date--time--picker--inputs">
        <div className="date--picker">
          <span>Date</span>
          <div className="date--picker--input">
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              onBlur={onStartTimestampChange}
            />
          </div>
        </div>
        <div className="time--picker">
          <span>Start time</span>
          <div className="time--picker--input">
            <InputMask
              placeholder="00:00"
              mask="12:34"
              value={scheduledTime}
              formatChars={formatCharsScheduledTime}
              onChange={(e) => {
                setScheduledTime(e.target.value);
              }}
              onBlur={onStartTimestampChange}
              disabled={!startDate}
            ></InputMask>
            {renderAmPmScheduledTime()}
          </div>
        </div>
        {endTime && (
          <div className="time--picker">
            <span>End time</span>
            <div className="time--picker--input">
              <InputMask
                placeholder="00:00"
                mask="12:34"
                value={scheduledEndTime}
                formatChars={formatCharsScheduledEndTime}
                onChange={(e) => {
                  setScheduledEndTime(e.target.value);
                }}
                onBlur={onEndTimestampChange}
              ></InputMask>
              {renderAmPmScheduledEndTime()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
