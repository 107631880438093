import React, { useState, useEffect } from "react";
import Download from "../../../assets/images/Download.png";
import Event from "../../../assets/images/event.png";
import NavBar from "../components/NavBar";
import OverviewContainer from "./OverviewContainer";
import ErrorBoundary from "../components/ErrorBoundary";
import SideNav from "./SideNav";
import CreateContainer from "./CreateContainer";
import CreateDownloadContainer from "./CreateDownloadContainer";
import EditProfileIntro from "./EditProfileIntro";
import EditProfileAbout from "./EditProfileAbout";
import EditProfileLinks from "./EditProfileLinks";
import ListDownloadContainer from "./ListDownloadContainer";
import MyCollectionContainer from "./MyCollectionContainer";
import IncomeContainer from "./IncomeContainer";
import SettingsContainer from "./SettingsContainer";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import CreateEventContainer from "./CreateEventContainer";
import ServiceEditor from "./ServiceEditor";
import ListServices from "./ListServices";
import ListEventContainer from "./ListEventContainer";
import ContactsContainer from "./ContactsContainer";
import SendMessageContainer from "./SendMessageContainer";
import SchedulingContainer from "./SchedulingContainer";

import {getCurrentUser, updateCurrentUser} from "../../queries/user";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  }
});

const subMenu = [
  {
    id: 0,
    title: "Downloads",
    desc: "Give your worksheets, guides, and ebooks a home.",
    icon: Download,
    route: "/dashboard/create/download",
  },
  {
    id: 1,
    title: "Events",
    desc: "Sell tickets to your next workshop, webinar, or class!",
    icon: Event,
    route: "/dashboard/create/event",
  },
];

function Dashboard(props) {
  return <div className="dashboard">
    {props.sideNav}
    {props.navBar}
    <div className="dashboardContent">
      {props.children}
      <Outlet />
    </div>
  </div>;
}

function DashboardContainer(props) {
  const [navigationMenuOpen, setNavigationMenuOpen] = useState(false);
  const queryClient = useQueryClient();

  const queryCurrentUser = useQuery({queryKey: ["current_user"], queryFn: getCurrentUser})
  const mutateCurrentUser = useMutation({
    mutationFn: updateCurrentUser,
    onSuccess: () => {
      // invalidate and reload
      queryClient.invalidateQueries({queryKey: ["current_user"]});
    },
  });
  
  if (queryCurrentUser.isLoading) {
    return;
  }

  const user = queryCurrentUser.data;

  const updateUser = (payload) => {
    if (payload) {
      mutateCurrentUser.mutate(payload);
    } else {
      queryClient.invalidateQueries({queryKey: ["current_user"]});
    }
  };

  const loadUser = () => {
    queryClient.invalidateQueries({queryKey: ["current_user"]});
  }

  const changeStateNavigationMenuOpen = () => {
    setNavigationMenuOpen((prevState) => !prevState);
  };

  const sideNav = <SideNav
    navigationMenuOpen={navigationMenuOpen} setNavigationMenuOpen={setNavigationMenuOpen}
  />;

  const navBar = <NavBar
    user={user}
    isDashPage={true}
    changeStateNavigationMenuOpen={changeStateNavigationMenuOpen}
  />;

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Dashboard sideNav={sideNav} navBar={navBar} />,
        children: [{
          path: "/",
          element: <ErrorBoundary><OverviewContainer user={user} onFinish={() =>{} } /></ErrorBoundary>,
        }, {
          path: "/dashboard",
          element: <ErrorBoundary><OverviewContainer user={user} onFinish={() =>{} } /></ErrorBoundary>,
        }, {
          path: "/dashboard/create",
          element: <ErrorBoundary><CreateContainer
            cards={subMenu}
            onFinish={() => { }}
          /></ErrorBoundary>,
        }, {
          path: "/dashboard/contacts",
          element: <ErrorBoundary><ContactsContainer
            onFinish={() => { }}
            user={user}
          /></ErrorBoundary>,
        }, {
          path: "/dashboard/income",
          element: <ErrorBoundary><IncomeContainer
            onFinish={() => { }}
            user={user}
          /></ErrorBoundary>,
        }, {
          path: "/dashboard/my-collection",
          element: <ErrorBoundary><MyCollectionContainer
              onFinish={() => {}}
            /></ErrorBoundary>,
        }, {
          path: "/dashboard/scheduling",
          element: <SchedulingContainer
              onFinish={() => { }}
            />,
        }, {
          path: "/dashboard/settings",
          element: <SettingsContainer
              user={user}
              updateUser={updateUser}
              loadUser={loadUser}
              onFinish={() => {}}
            />,
        }, {
          path: "/dashboard/create/download",
          element: <CreateDownloadContainer
              user={user}
              onFinish={() => { }}
              refreshUserData={() => getUser()}
            />,
        }, {
          path: "/dashboard/create/list-downloads",
          element: <ListDownloadContainer
              onFinish={() => { }}
              user={user}
            />,
        }, {
          path: "/dashboard/create/event",
          element: <CreateEventContainer
              user={user}
              onFinish={() => { }}
              refreshUserData={() => getUser()}
            />,
        }, {
          path: "/dashboard/create/list-services",
          element: <ListServices
              user={user}
            />,
        }, {
          path: "/dashboard/create/service",
          element: <ServiceEditor
              user={user}
              services={user.user_services /* TODO: use react-query */}
            />,
        }, {
          path: "/dashboard/create/list-events",
          element: <ListEventContainer
              onFinish={() => { }}
              user={user}
            />,
        }, {
          path: "/dashboard/send-message",
          element: <SendMessageContainer user={user} />,
        }, {
          path: "/dashboard/edit-profile/",
          element: <EditProfileIntro user={user} />,
        }, {
          path: "/dashboard/edit-profile/intro",
          element: <EditProfileIntro user={user} />,
        }, {
          path: "/dashboard/edit-profile/about",
          element: <EditProfileAbout user={user} />,
        }, {
          path: "/dashboard/edit-profile/links",
          element: <EditProfileLinks user={user} />,
        }],
      }
    ]
  );

  return <RouterProvider router={router}>
  </RouterProvider>;
};

export default function DashboardContainerQC(props) {
  return <QueryClientProvider client={queryClient}>
    <DashboardContainer {...props} />
  </QueryClientProvider>;
}
