import React from "react";

import ProfessionForm from "../forms/ProfessionForm";

import {mutateCurrentUserCareType} from "../../queries/user";

import {
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

export default function ProfessionOnboard(props) {
  const queryClient = useQueryClient();
  const userCareTypesMutation = useMutation({
    mutationFn: mutateCurrentUserCareType,
    onSuccess: () => {
      queryClient.invalidateQueries(
        {queryKey: ["current_user"]}
      );
    },
  });

  return (
    <React.Fragment>
      <div className="onboard_field_section" style={{
        display: "flex",
        gap: "20px",
        width: "100%",
      }}>
        <div style={{width: "100%"}}>
          <ProfessionForm
            userCareTypes={props.currentUser?.user_caretypes}
            userCareTypesMutation={userCareTypesMutation}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
