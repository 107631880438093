import React from "react";
import Button from "../forms/Button";

export default function DashboardCards(props) {
  const { icon, title, content, button, action, disabled } = props;
  return (
    <div className="card">
      <div>
        <img src={icon} className="cardImage" />
      </div>
      <div className="cardContent">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
      <div className="cardButton">
        <Button
          buttonClass={
            disabled
              ? "profile_overview_button_primary_less profile_overview_button--disabled"
              : "profile_overview_button_primary_less"
          }
          text={button}
          handleClick={action}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
