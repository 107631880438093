import React from "react";

export default function ZoomDocumentation() {
  return (
    <div className="zoom--info--container">
      <div className="zoom--info">
        <h1>Zoom Documentation</h1>
        <hr />
        <h2>Connecting Zoom</h2>
        <ol>
          <li><a href="/login">Log in</a> to your Tabu Wellness account.</li>
          <li>Navigate to the <a href="/dashboard/settings">settings page</a> and
              select <b>Integrations</b> from the left-hand-side menu.</li>
          <li>Click "Connect" underneath the Zoom logo to authorize your account.</li>
          <img src="/img/zoom/connect_zoom.png"/>
        </ol>
        <hr />
        <h2>Creating a Zoom Webinar</h2>
        <ol>
          <li>Navigate to the <a href="/dashboard/create/event">event creation page</a>.</li>
          <li>Fill in your event start and end time.</li>
          <li>Select "Online" for the event location.</li>
          <img src="/img/zoom/event_online.png"/>
          <li>Select "Add Zoom Details" - which will:
            <ul style={{listStyle: "disc"}}>
              <li>Create a new meeting using your Zoom account for the specified event time</li>
              <li>Attach it to the Tabu Wellness event</li>
              <li>Open the Zoom details pane</li>
            </ul>
          </li>
          <li>Modify meeting details as desired:</li>
          <img src="/img/zoom/details.png"/>
          <li>Complete and publish your event!</li>
        </ol>
        <p>The event page will reveal the Zoom joining info for your attendees upon registration.</p>
        <hr />
        <h2>Removing the App</h2>
        <ol>
          <li>Login to your Zoom Account and navigate to the Zoom App Marketplace.</li>
          <li>{'Click Manage >> Added Apps or search for the "Tabu Wellness" app.'}</li>
          <li>{'Click the "Tabu Wellness" app.'}</li>
          <li>Click Remove.</li>
        </ol>
        <p>If you run into issues, contact
           our <a href={`mailto:zoom@${process.env.ROOT_DOMAIN}`}>support line</a> via email.</p>
      </div>
    </div>
  );
}
