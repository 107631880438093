import {formatRelativeTime} from "./time";
import {parseISO} from "date-fns";
import {amountCentsToStringUSD} from "./currency";

// 1 hour
const STARTING_SOON_DURATION_MS = 60000 * 60;

const getSellingStatus = (ev) => {
  if (!ev) {
    return {
      forSale: false,
      message: "Loading...",
    }
  }

  if (ev.start_selling_date && now < ev.start_selling_date) {
    return {
      forSale: false,
      message: "Available on " + ev.start_selling_date
    }
  }
  if (ev.stop_selling_date && now > ev.stop_selling_date) {
    return {
      forSale: false,
      message: "No longer available",
    }
  }
  if (ev.event_limit && ev.attendee_count >= ev.event_limit) {
    return {
      forSale: false,
      message: "Sold out!",
    }
  }
  return {
    forSale: true,
    message: ev.amount > 0 ? `Join for ${amountCentsToStringUSD(ev.amount)}!` : "Join the event!",
  }
}

const getStartStamp = (ev) => {
  if (!ev) {
    return null;
  }

  return parseISO(ev.date_start_time);
}

const getEndStamp = (ev, hourDurationIfUnspecified=null) => {
  if (!ev) {
    return null;
  }

  if (!ev.date_end_time) {
    if (!hourDurationIfUnspecified) {
      return null;
    }
    return addHours(getStartStamp(ev), hourDurationIfUnspecified);
  }

  return parseISO(ev.date_end_time)
}

const EventStatus = {
  Upcoming: 0,
  StartingSoon: 1,
  Ongoing: 2,
  Over: 3,
}

const getStatus = (ev, now) => {
  const startStamp = getStartStamp(ev);
  const endStamp = getEndStamp(ev, 24);

  if (now < startStamp) {
    const startingSoon = startStamp - now < STARTING_SOON_DURATION_MS;
    return startingSoon ? EventStatus.StartingSoon : EventStatus.Upcoming;
  }

  if (now < endStamp) {
    return EventStatus.Ongoing;
  }

  return EventStatus.Over;
}

const getStatusMessage = (ev, now) => {
  if (!ev) {
    return "";
  }

  switch (getStatus(ev, now)) {
    case EventStatus.Upcoming:
      const formatted = formatRelativeTime(getStartStamp(ev), now);
      return `Event starts ${formatted}`;
    case EventStatus.StartingSoon:
      return "Event starting soon!";
    case EventStatus.Ongoing:
      return "Event happening now!";
    case EventStatus.Over:
      return "This event already happened";
    default:
      return "";
  }
};

export {EventStatus, getSellingStatus, getStartStamp, getEndStamp, getStatus, getStatusMessage};
