import React from "react"
import PropTypes from "prop-types"

class UploadImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
      avatar_url: '',
      timeout: null
    }
    this.onFileChange = this.onFileChange.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.fileInput = React.createRef() // ref to fileInput

  }

  componentDidMount() {
    this.setState({
      avatar_url: this.props.user.avatar_url,
    })
  }

  uploadImage(image) {
    let data = {
      avatar: image
    }

    var formData = new FormData();
    formData.append('avatar', image, `${this.props.user.first_name}_${this.props.user.last_name}`);
    $.ajax({
      url: `../update_user_avatar`,
      dataType: 'JSON',
      type: 'POST',
      header: {
        'Content-Type': 'application/json'
      },
      processData: false,
      contentType: false,
      data: formData,
      success: (data) => {
        this.props.updateUser()
      },
      error: function(xhr, status, err) {
        console.log(err)
      }.bind(this)
    });
  }

  componentDidUpdate() {
    if(this.props.user.avatar_url != this.state.avatar_url) {
      this.setState({
        avatar_url: this.props.user.avatar_url
      })
    }
  }

  onFileChange(event) {
    let image = event.target.files[0]
    if(this.state.avatar != image) {
      this.setState({
        avatar: image
      }, () => this.uploadImage(image))
    }

  }

  triggerInputFile = () => {
      if (this.fileInput.current != undefined && this.fileInput.current.click != undefined)
          this.fileInput.current.click()
  }


  render () {
    return (
      <React.Fragment>
        <div style={{margin: 'auto', display: 'flex', flexDirection: 'column'}}>
            <img
              style={{margin: 'auto', marginBottom: 20}}
              className="userImage"
              src={this.state.avatar_url}  />
            <button
                className="lightPurple"
                onClick={() => this.triggerInputFile()}
            >
                Change photo
            </button>
            <input
                ref={this.fileInput}
                type='file'
                hidden
                onChange={this.onFileChange}
            />
        </div>
      </React.Fragment>
    );
  }
}

export default UploadImage
