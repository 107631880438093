import React from "react";
import EditIcon from "../../../components/EditIcon";

class Certifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      user_certifications: this.props.user.user_certifications.filter(
        (item) => item.name.trim().length !== 0
      ),
      loaded: false,
      timeout: null,
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.renderExpertise = this.renderExpertise.bind(this);
  }

  toggleEditModal(id) {
    if (!this.props.liveProfile) {
      this.props.toggleEditModal(id);
    }
  }

  renderExpertise() {
    return this.props.user.user_certifications.map((certification, i) => {
      return <li key={i}>{certification.name}</li>;
    });
  }

  render() {
    if (this.props.liveProfile && this.state.user_certifications.length <= 0) {
      return null;
    }

    return (
      <div onClick={() => this.toggleEditModal("certifications")}>
        <h5 className="detailsSectionTitle">Certification</h5>
        <ul className="detailList">{this.renderExpertise()}</ul>
        {!this.props.liveProfile && <EditIcon />}
      </div>
    );
  }
}

export default Certifications;
