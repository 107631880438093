import React, {useState, useEffect} from "react";

import icon from "../../../assets/images/icon.png";
import defaultPhoto from "../../../assets/images/default_photo.png";
import BioPrompts from "./sections/BioPrompts";
import ContentSection from "./sections/ContentSection";
import IntroContainer, {formatName, formatCredentials} from "./sections/IntroContainer";
import MailingListContainer from "./sections/MailingListContainer";
import SocialContainer from "./sections/SocialContainer";
import DetailsContainer from "./sections/DetailsContainer";
import ModalForm from '../profile_forms/ModalForm'
import PublicServicesProfile from "./sections/Modals/PublicServicesProfile";
import NavBar from "../components/NavBar";
import {
  DownloadContentCard, EventContentCard, LinkContentCard, ServiceContentCard
} from "../components/ContentCard";

import {faBuilding, faLaptop, faHandshake, faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parseISO} from "date-fns";

import {asyncApiRequest} from "../../utils/apiRequest";
import formatCareType from "../../utils/care_types";

function TabBar({sections, selectedSection, setSelectedSection, currentUser}) {
  if (sections.length < 2) {
    return;
  }

  const deselectedClasses = "tw-cursor-pointer text-12 tw-p-2 tw-border-[100px]";
  const deselectedStyles = {border: "1px solid #ffffff00", borderRadius: "100px"};
  const selectedStyles = {borderRadius: "100px"};
  const selectedClasses = `${deselectedClasses} dark-border accent-background`;

  const topClass = currentUser ? "tw-top-[80px]" : "tw-top-0";

  return <div id="tabbar"
      className={
        "tw-flex tw-w-full tw-box-border tw-overflow-x-auto light-accent-background tw-p-4 " +
        `tw-gap-4 tw-justify-center cleanScroll tw-sticky ${topClass}`
      }
      style={{zIndex: 1000}}
    >
    {sections.map((title, i) => {
      if (!title) {
        return null;
      }
      return <button
        key={i}
        onClick={() => setSelectedSection(title)}
        style={title === selectedSection ? selectedStyles : deselectedStyles}
        className={(title === selectedSection ? selectedClasses : deselectedClasses)}
      >
        {title}
      </button>;
    })}
  </div>;
}

function RegisterFooterButton(_) {
  return <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
    <button
      className="tw-flex tw-my-5 tw-gap-2 tw-items-center dark-accent-shadow dark-accent-border"
      style={{
        borderRadius: "40px",
        padding: "2px",
        cursor: "pointer",
      }}
      onClick={() => window.location.assign(process.env.APP_URL + "/signup")}
    >
      <img src={icon} style={{height: "30px"}} />
      <p style={{fontSize: "10px"}} className="tw-font-semibold dark-text">Claim your free landing page!</p>
      <FontAwesomeIcon icon={faArrowRight} className="dark-accent-text" size="sm"
        style={{paddingRight: "4px"}}
      />
    </button>
  </div>;
}

const renderSpecialties = (user) => {
  const specialties = user.primary_specialties;
  if (specialties.length === 0) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-wrap">
      {specialties.map((user_specialty, i) => {
        return (
          <p className="primarySpecialty" key={i}>
            {user_specialty.name}
          </p>
        )
      })}
    </div>
  );
};

const renderConcerns = (user) => {
  const concerns = user.extra_specialties;
  if (concerns.length === 0) {
    return null;
  }

  let formatted = concerns.map(concern => concern.name).join(", ");

  return <p className="dark-accent-text text-16 tw-font-semibold"
    >{formatted}</p>;
}

const renderIdentityExpertise = (user) => {
  const expertiseEntries = user.identity_expertises || [];
  if (expertiseEntries.length === 0) {
    return null;
  }

  const list = expertiseEntries.map((expertise) => <p key={expertise.name} className="font-16">{expertise.name}</p>);

  return <div className="tw-flex tw-flex-col tw-gap-1">{list}</div>;
}

const renderName = (user) => {
  return `${formatName(user)}${formatCredentials(user)}`;
};

export function ProfileIntro(props) {
  const {user, currentUser, px} = props;
  const widthClasses = "md:tw-max-w-md tw-w-full "

  return (
    <>
    <div className={widthClasses + "tw-mb-4"}>
      <img
        className="tw-block tw-w-full md:tw-mt-4 md:tw-rounded-lg"
        src={user.avatar_url || defaultPhoto}
      />
    </div>
    <div
      className={
        "tw-flex tw-flex-col " + widthClasses +
        `tw-pb-4 tw-gap-5 tw-items-center tw-w-full ${px || "tw-px-5"} ` +
        "md:tw-pt-4"
      }
      style={{
        boxSizing: "border-box",
      }}
    >
      {user.tagline && <span
        className={
          "tw-rounded-lg tw-m-1 black-border tw-py-4 tw-px-6 tw-text-center " +
          "tw-w-fit tw-font-bold dark-shadow"
        }
        style={{background: "#dcfff9"}}
      >{user.tagline}</span>}
      <SocialContainer user={user} />
      <IntroContainer user={user} />
      {user.mailing_list && <MailingListContainer userId={user.id} currentUser={currentUser} />}
    </div>
    </>
  );
}

export function ProfileAbout({user, currentUser, onViewServices}) {
  const bio = user.bio || {};

  const sections = [{
    title: "Specialties",
    content: renderSpecialties(user),
  }, {
    title: "Additional concerns I treat",
    content: renderConcerns(user),
  }, {
    title: "Identity or experience expertise",
    content: renderIdentityExpertise(user),
  }, {
    content: <BioPrompts bio={bio} />,
  }, {
    content: <DetailsContainer user={user} liveProfile={true} onViewServices={onViewServices} />,
  }];

  const dividerStyle = {margin: "0.5rem"};

  return <div className="md:tw-max-w-md tw-w-full tw-flex tw-flex-col tw-gap-2">
    <p key="name" className="tw-text-center tw-font-semibold text-18 tw-mb-2">
      {renderName(user)}
    </p>
    <img
      className="tw-block tw-w-full md:tw-mt-4 md:tw-rounded-lg"
      src={user.cover_photo_url || defaultPhoto}
    />
    <hr className="light-purple-divider" style={dividerStyle} />
    {bio.brief && <p key="bioBrief"
      className="text-26 tw-font-semibold tw-leading-snug tw-mx-4"
      >{bio.brief}</p>}
    <div className="tw-w-full tw-px-4 tw-box-border">
      <button
        className="dark-accent-button tw-flex tw-items-center tw-justify-center tw-gap-4"
        onClick={onViewServices}
        style={{width: "100%"}}>
        <FontAwesomeIcon icon={faHandshake} size="sm" style={{color: "white"}}/>
        <p className="tw-font-semibold text-18 tw-text-white">Work with me!</p>
      </button>
    </div>
    <hr className="light-purple-divider" style={dividerStyle} />
    {sections.map((section, i) => {
      const content = section.content;
      if (!content) { return null; }
      return <div key={i} className="tw-flex tw-flex-col tw-gap-2 tw-m-4">
        {section.title && <p key={i} className="tw-font-semibold text-16">{section.title}</p>}
        {content}
      </div>;
    })}
  </div>;
}

export default function ProviderProfileContainer({user, currentUser}) {
  const [showAboutPage, setShowAboutPage] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const messageModal = params.get("message_modal") === "1";
  const [showServicesModal, setShowServicesModal] = useState(messageModal);
  const [selectedTabSection, setSelectedTabSection] = useState();

  const [downloads, setDownloads] = useState();
  const [events, setEvents] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAboutPage]);

  useEffect(() => {
    (async () => {
      getDownloads();
      getEvents();
    })();
  }, []);

  const scrollToSection = (i) => {
    document.getElementById(i).scrollIntoView({
      behavior: "smooth", block: "center" /* or center, end, nearest */,
    });
  };

  const getDownloads = async () => {
    const downloadsJSON = await asyncApiRequest("/get_user_downloads", "POST", {
      user_id: user.id,
    });
    const downloads = JSON.parse(downloadsJSON.downloads).filter((dl) => (
      dl.download_status === "PUBLISHED" && dl.download_url
    ));
    setDownloads(downloads);
  };

  const getEvents = async () => {
    const eventsJSON = await asyncApiRequest("/get_user_events", "POST", {
      user_id: user.id,
    });
    const events = JSON.parse(eventsJSON.events).filter((ev) => (
      ev["status"] === "PUBLISHED" && ev.event_url && ev.date_start_time
    ));
    setEvents(events);
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  const renderServices = (services) => {
    return <ContentSection
      onMouseEnter={() => setSelectedTabSection("Services")}
      key="Services"
      title="Services"
      flexDirection="row"
      cardRenderer={sv => <ServiceContentCard
          key={sv.id} sv={sv} link={`/sv/${sv.slug}`}
        />}
      content={services}
    />;
  };

  const renderDownloads = () => {
    return <ContentSection
      title="Downloads"
      flexDirection="row"
      onMouseEnter={() => setSelectedTabSection("Downloads")}
      cardRenderer={(dl) => <DownloadContentCard key={dl.download_url} dl={dl} user={user} />}
      content={downloads}
      user={user}
      key="Downloads"
    />
  };

  const renderEvents = () => {
    return <ContentSection
      title="Events"
      flexDirection="row"
      onMouseEnter={() => setSelectedTabSection("Events")}
      filters={[{
        name: "Upcoming",
        fn: (ev => new Date().getTime() < parseISO(ev.date_start_time)),
        emptyState: <p className="text-16 tw-font-semibold tw-my-6">No upcoming events at this time</p>,
      }, {
        name: "Past",
        fn: (ev => new Date().getTime() >= parseISO(ev.date_start_time)),
        emptyState: <p className="text-16 tw-font-semibold tw-my-6">No past events... yet!</p>,
      }]}
      cardRenderer={(ev) => <EventContentCard key={ev.event_url} ev={ev} user={user} />}
      content={events}
      user={user}
      key="Events"
    />
  };

  const renderLinks = () => {
    if (!user.user_profile_links?.length) {
      return null;
    }

    const linkSectionTitle = user.profile_links_section_title || "My links";

    return <div key="linksSection" className="md:tw-max-w-lg tw-w-full">
      <ContentSection
        title={linkSectionTitle}
        onShow={() => onShow(3, "Links")}
        classes="tw-pr-6 md:tw-pr-12"
        flexDirection="column"
        onMouseEnter={() => setSelectedTabSection("Links")}
        cardRenderer={li => <LinkContentCard key={li.id} li={li} user={user} />}
        content={user.user_profile_links}
        id="Links"
        key="Links"
      />
    </div>;
  };

  const renderCareTypes = () => {
    return <div className="tw-flex tw-gap-2">
      {user.user_caretypes.map((item, i) => (
        <p
          className="tw-text-sm tw-text-bold" key={`care_type_${i}`}
        >
        {item.care_type && formatCareType(item, false)}</p>
      ))}
    </div>
  };

  const formatState = (state) => {
    if (!state) {
      return "";
    }
    return `, ${state.substr(0, 2).toUpperCase()}`;
  }

  const renderLocation = () => {
    let formattedState = formatState(user.user_locations[0]?.state);

    return (
      <div style={{ display: "flex" }}>
        {user.user_locations?.length > 0 && (
          <span style={{ marginRight: "5px" }}>
            {user.user_locations[0]?.city}{formattedState}
          </span>
        )}
        {user.user_locations?.length > 0 &&
          user.user_locations[0].hasOwnProperty(
            "my_office"
          ) &&
          user.user_locations[0].my_office && (
            <div
              className="locationContainer"
              style={{ marginRight: "5px" }}
            >
              <FontAwesomeIcon icon={faBuilding} size="xs" />
            </div>
          )}
        {user.user_locations?.length > 0 &&
          user?.user_locations[0].hasOwnProperty(
            "virtually"
          ) &&
          user?.user_locations[0].virtually && (
            <div
              className="locationContainer"
              style={{ marginRight: "5px" }}
            >
              <FontAwesomeIcon icon={faLaptop} size="xs" />
            </div>
          )}
      </div>
    );
  };

  const renderAboutSection = () => {
    return <div
        className="tw-w-full light-purple-background tw-flex tw-flex-col tw-mt-4"
        onClick={() => setShowAboutPage(true)}
        key="about"
      >
      <div className="tw-flex tw-w-full tw-items-center tw-p-4 tw-justify-between tw-box-border">
        <p className="tw-text-bold tw-text-xl tw-text-bold">About</p>
        <FontAwesomeIcon icon={faArrowRight} className="dark-accent-text" size="sm" />
      </div>
      <div className="tw-flex tw-px-4 tw-pt-4 tw-gap-2">
        <img src={user.cover_photo_url || defaultPhoto} style={{
          height: "100px",
          width: "100px",
          borderRadius: "50px",
          objectFit: "cover",
        }} />
        <div className="tw-flex tw-flex-col tw-p-4 tw-gap-2">
          <p className="tw-font-semibold">{renderName(user)}</p>
          {renderLocation()}
          {renderCareTypes()}
          {renderSpecialties(user)}
        </div>
      </div>
    </div>
  };

  const renderContentSections = () => {
    const availableServices = user.user_services.filter(x => x.availability & 1);

    const sections = [{
      title: "Services",
      content: renderServices(availableServices),
      isShown: availableServices.length,
    }, {
      title: "Downloads",
      content: renderDownloads(downloads),
      isShown: downloads?.length,
    }, {
      title: "Events",
      content: renderEvents(),
      isShown: events?.length,
    }, {
      title: "Links",
      content: renderLinks(),
      isShown: user.user_profile_links?.length,
    }];

    let rendered = [];

    for (let i = 0; i < sections.length; i++) {
      rendered.push(sections[i].content);

      if (i + 1 < sections.length && sections[i].isShown && sections[i + 1].isShown) {
        // add dividers between non-empty sections
        rendered.push(<hr key={`divider_${i}`} className="light-purple-divider" />);
      }
    }

    rendered.unshift(<TabBar
      key="tabbar"
      sections={sections.filter(s => s.isShown).map(s => s.title)}
      selectedSection={selectedTabSection}
      setSelectedSection={(i) => {
        scrollToSection(i);
        setSelectedTabSection(i);
      }}
      currentUser={currentUser}
    />);

    return <div className="tw-w-full">{rendered}</div>
  };

  const renderAboutPage = () => {
    return <div
      className="tw-w-full tw-box-border tw-p-4"
    >
      <button
        className="light-accent-background dark-accent-shadow dark-accent-border tw-mb-6"
        style={{
          height: "40px", width: "40px", borderRadius: "20px", cursor: "pointer"
        }}
        onClick={() => setShowAboutPage(false)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="dark-accent-text" size="sm" />
      </button>
      <div className="tw-flex tw-justify-center">
        <ProfileAbout
          user={user} currentUser={currentUser}
          onViewServices={() => setShowServicesModal(true)}
        />
      </div>
    </div>
  };

  const renderProfile = () => {
    return <div key="profile">
      <div
        style={currentUser && navBarClearanceMargin}
        className="tw-flex tw-flex-col tw-items-center tw-w-full tw-box-border tw-mb-0"
        key="introAndContent"
      >
        <ProfileIntro user={user} currentUser={currentUser} px="tw-px-12" />
        {renderContentSections()}
      </div>
      {renderAboutSection()}
    </div>;
  };

  const navBarClearanceMargin = {
    marginTop: "80px",
  };

  if (showServicesModal) {
    return (
      <ModalForm key="servicesModal" onClose={() => setShowServicesModal(false)}>
        <PublicServicesProfile
          user={user}
          isCurrentUser={user === currentUser}
          currentUser={currentUser}
          onClose={() => setShowServicesModal(false)}
        />
      </ModalForm>
    );
  }

  const navBar = currentUser && <NavBar key="navbar" user={currentUser} />;
  const footerButton = !currentUser && (<div key="footer">
    <RegisterFooterButton />
  </div>);

  return (
    <div>
      {showAboutPage ? renderAboutPage() : [
        navBar,
        renderProfile(),
        footerButton, 
      ]}
    </div>
  );
}
