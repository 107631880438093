import React, { Component } from 'react';

import {asyncApiRequest} from "../../utils/apiRequest";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlushed} from "@fortawesome/free-solid-svg-icons";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      showDetails: false,
      error: null,
      errorInfo: null,
      errorReported: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });

    // You can log the error to the server or any external service here
    this.logErrorToBackend(error, errorInfo);
  }

  logErrorToBackend = async (error, errorInfo) => {
    // Replace the following with your backend API endpoint to report errors
    asyncApiRequest('/report_frontend_error',
      'POST',
      {
        error: error.toString(),
        stack: errorInfo.componentStack,
      },
      () => this.setState({ errorReported: true }),
      () => console.error('Error reporting to backend:', error)
    );
  };

  renderDetails() {
    if (!this.state.showDetails) {
      return <p
        onClick={() => this.setState({showDetails: true})}
        className="tw-bg-gray text-12">
          Click to show details
      </p>;
    }
    return <div className="tw-bg-gray">
      <p key="error" style={{fontFamily: "Courier", fontSize: "10px"}}>{this.state.error}</p>
      {this.state.errorInfo.componentStack.split("\n").map((x, i) => (
        <p key={i} style={{fontFamily: "Courier", fontSize: "10px"}}>{x}</p>
      ))}
    </div>;
  }

  render() {
    if (this.state.hasError) {
      return this.state.errorReported ? (
        <div className="tw-flex tw-flex-col tw-p-4 tw-items-center light-accent-border tw-gap-4 tw-m-4">
        <FontAwesomeIcon icon={faFlushed} style={{color: "#733dff"}} size="4x" />
          <p className="text-20 tw-font-semibold">Oops! Something went wrong.</p>
          <p>An error has been reported. We apologize for the inconvenience.</p>
          <p>Try refreshing the page. If this happens repeatedly, reach out to <a href="mailto:dev@tabuwellness.com">dev@tabuwellness.com</a>.</p>
          {this.renderDetails()}
        </div>
      ) : (
        <div>
          <p className="text-20 tw-font-semibold">Oops! Something went wrong.</p>
          <p>Sending error report...</p>
        </div>
      );
    }

    return this.props.children;
  }
}

class ErrorReportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      description: '',
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // You can add additional logic here to send the error report to the backend
    console.log('Error reported:', this.props.error, this.props.errorInfo, this.state);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </label>
        <br />
        <label>
          Description of the issue:
          <textarea
            name="description"
            value={this.state.description}
            onChange={this.handleInputChange}
            required
          />
        </label>
        <br />
        <button type="submit">Submit Error Report</button>
      </form>
    );
  }
}

export default ErrorBoundary;
