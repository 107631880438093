import React from "react";
import Select from "react-select";

class NormalSelect extends React.Component {
  constructor(props) {
    super(props);
    this.style = props.style || {};
  }

  render() {
    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        display: "flex",
        width: "-webkit-fill-available",
        border: "2px solid #F3EEFF",
        borderRadius: "0.5rem",
        color: "#0A2175",
        height: "39px",
        ...this.style,
      }),
      placeholder: () => ({
        color: "#0A2175",
        fontSize: "14px",
        fontFamily: "Averta",
        color: "rgba(10, 33, 117, 0.5)",
      }),
      menuList: () => ({
        color: "#0A2175",
        fontSize: "14px",
        fontFamily: "Averta",
        overflow: "visible",
        maxHeight: "250px",
        overflowY: "auto",
      }),
      singleValue: () => ({
        color: "#0A2175",
        fontSize: "14px",
      }),
      indicatorsContainer: () => ({
        color: "#0A2175",
      }),
      indicatorSeparator: () => ({}),
      valueContainer: () => ({
        webkitAlignItems: "center",
        webkitBoxAalign: "center",
        msFlexAlign: "center",
        alignItems: "center",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        webkitFlex: "1",
        msFlex: "1",
        flex: "1",
        webkitBoxFlexWrap: "inherit",
        webkitFlexWrap: "inherit",
        msFlexWrap: "inherit",
        flexWrap: "inherit",
        padding: "2px 8px",
        webkitOverflowScrolling: "touch",
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
      }),
    };

    return (
      <div>
        <Select
          isClearable={false}
          styles={customStyles}
          options={this.props.options}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          value={this.props.value}
          className={this.props.className}
        />
      </div>
    );
  }
}

export default NormalSelect;
