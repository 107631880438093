import React from "react";

export default function CreateCards(props) {
  const { icon, title, click, desc } = props;
  return (
    <div
      className="createCard"
      onClick={() => {
        click();
      }}
    >
      <div>
        <img src={icon} className="createCardImage" />
      </div>
      <div className="createCardContent">
        <h2>{title}</h2>
        <span>{desc}</span>
      </div>
    </div>
  );
}
