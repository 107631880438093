import React, {useEffect, useState} from "react";

import TypeOrSelectForm from "../forms/TypeOrSelectForm";
import ButtonSelectForm from "../forms/ButtonSelectForm";
import SearchLocationInput from "../components/SearchLocationInput";
import {ClientThanks} from "./ClientSearch";
import ReCAPTCHA from "react-google-recaptcha";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEnvelope,
  faArrowLeft,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import SearchAnimation from "../../../assets/images/search_animation.gif";

import {
  getSpecialties,
  getGenders,
  getIdentityExpertises,
  getEthnicities,
  getInsurances,
} from "../../queries/user_attributes";

const csrf = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");


const inputClasses = "tw-grow light-accent-border dark-text tw-p-2 tw-flex tw-items-center tw-gap-2";
const inputStyles = {
  borderRadius: "4px",
  margin: "0 5px",
  fontSize: "14px",
  padding: "5px 8px",
};

const validateEmail = (email) => {
  var re = /\S+@\S+\.\S{2,}/;
  return re.test(email);
};

function EmailIcon({email}) {
  const getIcon = () => {
    if (!email) {
      return {
        icon: faTimes,
        color: "rgba(0, 0, 0, 0)",
      }
    }
    if (!validateEmail(email)) {
      return {
        icon: faTimes,
        color: "#fcc"
      };
    }
    return {icon: faCheck, color: "rgba(0, 128, 0, 0.3)"};
  };

  const icon = getIcon();

  return <FontAwesomeIcon
    icon={icon.icon}
    style={icon.color && {color: icon.color}}
    size="xs"
  />;
}

function TypeOrSelect({
  options,
  selections,
  placeholder,
  setSelections,
}) {
  return <TypeOrSelectForm
    options={options}
    selections={selections}
    placeholder={placeholder || "Type or select topics"}
    setSelections={setSelections}
    isValidNewOption={_ => false}
    transforms={{
      to: (x => ({value: x.id, label: x.name})),
      from: (x => ({id: x.value, name: x.label})),
    }}
    maxEntries={3}
  />;
}

function BackButton({onClick}) {
  return <button
    className="light-accent-background dark-accent-shadow dark-accent-border tw--ml-4 tw--mt-4"
    style={{
      height: "40px", width: "40px", borderRadius: "20px", cursor: "pointer"
    }}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faArrowLeft} className="dark-accent-text" size="sm" />
  </button>;
}

export default function ClientLandingPageContainer(props) {
  // selection options
  const [concerns, setConcerns] = useState();
  const [genders, setGenders] = useState();
  const [ethnicities, setEthnicities] = useState();
  const [identityExpertises, setIdentityExpertises] = useState();
  const [insurances, setInsurances] = useState();

  // form state
  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(parseInt(params.get("page")) || 0);
  const [selectedConcerns, setSelectedConcerns] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedEthnicities, setSelectedEthnicities] = useState([]);
  const [selectedIdentityExpertises, setSelectedIdentityExpertises] = useState([]);
  const [maxCost, setMaxCost] = useState(0);
  const [preferInsurance, setPreferInsurance] = useState();
  const [selectedInsurance, setSelectedInsurance] = useState([]);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [userLocation, setUserLocation] = useState();
  useEffect(() => {
    (async () => {
      const resp = await getSpecialties();
      setConcerns(JSON.parse(resp.data.specialties).filter(s => !s["private"]));
    })();
    (async () => {
      const resp = await getGenders();
      setGenders(JSON.parse(resp.data.genders).filter(s => !s["private"]));
    })();
    (async () => {
      const resp = await getEthnicities();
      setEthnicities(JSON.parse(resp.data.ethnicities).filter(s => !s["private"]));
    })();
    (async () => {
      const resp = await getIdentityExpertises();
      setIdentityExpertises(JSON.parse(resp.data.identity_expertises).filter(s => !s["private"]));
    })();
    (async () => {
      const resp = await getInsurances();
      setInsurances(JSON.parse(resp.data.insurances).filter(s => !s["private"]));
    })();
  }, []);

  useEffect(() => {
    // search animation delay
    if (page === 3) {
      setTimeout(() => setPage(page + 1), 4000)
    }
  }, [page]);

  const renderHiddenInputs = () => {
    const insuranceId = (preferInsurance && selectedInsurance.length) ?
      selectedInsurance[0].id : 0;
    const data = {
      concern_ids: selectedConcerns.map(c => c.id).join(","),
      gender_ids: selectedGenders.map(g => g.id).join(","),
      ethnicity_ids: selectedEthnicities.map(e => e.id).join(","),
      identity_expertise_ids: selectedIdentityExpertises.map(ie => ie.id).join(","),
      location: JSON.stringify(userLocation || {}),
      payment_preference: JSON.stringify({
        insurance_id: insuranceId,
        max_cost: maxCost,
      }),
      name: userName,
      email: email,
    };
    return <>
      <input type="hidden" name="authenticity_token" value={csrf} />
      {Object.keys(data).map(key => <input
        key={key}
        type="hidden"
        name={key}
        value={btoa(data[key])}
      />)}
    </>;
  }

  const headingStyles = "tw-text-4xl tw-text-wrap tw-font-bold dark-text";
  const labelStyles = "tw-font-semibold text-14 dark-text";
  const subLabelStyles = "text-12 dark-text";

  const renderIntroPage = () => {
    if (!concerns) {
      return;
    }
    const enableContinue = !!concerns && !!userLocation;

    return <div className="tw-flex tw-flex-col tw-gap-5">
      <p className={headingStyles}>Ready to <span className="tw-text-wrap accent-text">hop off the struggle bus?</span></p>
      <p className="text-18 tw-font-semibold tw-mb-2">Find the sex-positive care you deserve.</p>
      <p className={labelStyles}>What areas of your life do you want the most support with right now?</p>
      <TypeOrSelect
        options={concerns}
        selections={selectedConcerns}
        setSelections={setSelectedConcerns}
        placeholder="Type or select concerns"
      />
      <div className="tw-flex tw-flex-col tw-gap-2">
        <p className={labelStyles}>Where are you located?</p>
        <p className={subLabelStyles}>{
          "Whether you're looking for virtual or IRL care, we need to know who's licensed to work with you."
        }</p>
      </div>
      <SearchLocationInput
        style={inputStyles}
        className={inputClasses}
        placeholder="Location"
        onSelect={setUserLocation}
      />
      <button
        onClick={() => setPage(1)}
        disabled={!enableContinue}
        style={{marginTop: "1rem", padding: "0 1rem"}}
        className={enableContinue ? "dark-accent-button" : "dark-accent-button-disabled"}
      >Continue</button>
      <hr className="light-purple-divider" style={{margin: "0"}} />
      <p className="font-12 dark-text"
      >Are you a provider? <a className="dark-accent-text" href={`${process.env.WEB_URL}/providers`}
        >Join our network!</a>
      </p>
    </div>;
  };

  const renderDetailsPage = () => {
      if (!identityExpertises || !genders || !ethnicities) {
        return;
      }

    return <div className="tw-flex tw-flex-col tw-gap-3">
      <BackButton onClick={() => setPage(page - 1)} />
      <p className={headingStyles}>{"Let's get into some details about your ideal provider."}</p>
      <p className={labelStyles}>{
        "Any preference for your provider's gender identity?"
      }</p>
      <TypeOrSelect
        options={genders}
        selections={selectedGenders}
        setSelections={setSelectedGenders}
        placeholder="Select all that apply (or none! if you don't have a preference)"
      />
      <p className={labelStyles}>{
        "Any preference for your provider's racial identity?"
      }</p>
      <TypeOrSelect
        options={ethnicities}
        selections={selectedEthnicities}
        setSelections={setSelectedEthnicities}
        placeholder="Select all that apply (or none! if you don't have a preference)"
      />
      <p className={labelStyles}>{
        "Any particular area of expertise that's important to you?"
      }</p>
      <TypeOrSelect
        options={identityExpertises}
        selections={selectedIdentityExpertises}
        setSelections={setSelectedIdentityExpertises}
        placeholder="Select all that apply (or none! if you don't have a preference)"
      />
      <button
        onClick={() => setPage(page + 1)}
        style={{marginTop: "1rem", padding: "0 1rem"}}
        className="dark-accent-button">Continue</button>
    </div>;
  };

  const renderPaymentPage = () => {
    if (!insurances) {
      return;
    }

    return <div className="tw-flex tw-flex-col tw-gap-3">
      <BackButton onClick={() => setPage(page - 1)} />
      <p className={headingStyles}>{
        "Let's talk about payment."
      }</p>
      <p className={labelStyles}>{
        "How much are you willing to spend for therapy?"
      }</p>
      <p className="font-12 dark-text">{
        "Remember, you don’t necessarily have to go weekly if that’s not feasible for you right now."
      }</p>
      <div className={inputClasses}>
        <p className="text-16 dark-text">$</p>
        <input
          type="number"
          placeholder="max number"
          onChange={x => setMaxCost(Number(x.target.value) || 0)}
        />
      </div>
      <p className={labelStyles}>{
        "Are you committed to using insurance for therapy?"
      }</p>
      <ButtonSelectForm col={true} options={[{
          buttonLabel: "No, I will pay for the best fit",
          getter: () => preferInsurance === false,
          setter: () => {
            setPreferInsurance(false);
          },
        }, {
          buttonLabel: "Yes, I refuse to pay out-of-pocket",
          getter: () => preferInsurance === true,
          setter: () => {
            setPreferInsurance(true);
          }
      }]} />
      {!!preferInsurance && <>
        <p className={labelStyles}>{
          "What insurance do you hope to use?"
        }</p>
        <TypeOrSelect
          options={insurances}
          selections={selectedInsurance}
          setSelections={(sel) => {
            if (sel.length === 0) {
              setSelectedInsurance(sel);
            } else {
              setSelectedInsurance([sel[sel.length - 1]]);
            }
          }}
          placeholder="Type or select insurance provider"
        />
      </>}
      <button
        onClick={() => setPage(page + 1)}
        style={{marginTop: "1rem", padding: "0 1rem"}}
        className="dark-accent-button">Continue</button>
    </div>;
  };

  const renderSearchPage = () => {
    return <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <p className={labelStyles}>Finding your ideal match!</p>
      <img className="tw-w-full tw-m-8 md:tw-m-16 tw-mt-0" src={SearchAnimation} />
    </div>;
  };

  const renderResultsPage = () => {
    return <div className="tw-flex tw-flex-col tw-gap-3">
      <BackButton onClick={() => setPage(0) /* back to beginning of quiz */} />
      <p style={{alignSelf: "center", fontSize: "3rem", lineHeight: "normal"}}>{"🕵🏽"}</p>
      <p className={headingStyles} style={{alignSelf: "center"}}>{
        "Hang tight!"
      }</p>
      <p className="dark-text text-22 tw-leading-snug tw-text-center" style={{alignSelf: "center"}}>{
        "There aren't any providers matching your search–yet! " +
        "This is where our team comes in."
      }</p>
      <hr className="light-purple-divider" style={{margin: "0"}} />
      <p className={labelStyles} style={{alignSelf: "center"}}>{
        "How can we reach you once we've found a match?"
      }</p>
      <p className={labelStyles}>{
        "Your name"
      }</p>
      <div className={inputClasses}>
        <FontAwesomeIcon
          icon={faAddressCard}
          className="dark-text"
          size="xs"
        />
        <input
          style={{flexGrow: 1}}
          placeholder="Your name"
          onChange={x => setUserName(x.target.value)}
        />
      </div>

      <p className={labelStyles}>{
        "Your email address"
      }</p>
      <div className={inputClasses}>
        <FontAwesomeIcon
          icon={faEnvelope}
          className="dark-text"
          size="xs"
        />
        <input
          style={{flexGrow: 1}}
          placeholder="Email"
          onChange={x => setEmail(x.target.value)}
        />
        <EmailIcon email={email} />
      </div>

      <form method="post" action="/clients/create_user"
        className="tw-self-center tw-flex tw-flex-col tw-gap-2 tw-items-center"
      >
        {renderHiddenInputs()}
        <ReCAPTCHA
          sitekey={process.env.RECAPTCHA_PUBLIC_KEY} />
        <button
          type="submit"
          className="dark-accent-button">
          Keep me posted!
        </button>
      </form>
    </div>;
  };

  const renderQuiz = () => {
    switch (page) {
      default:
      case 0:
        return renderIntroPage();
      case 1:
        return renderDetailsPage();
      case 2:
        return renderPaymentPage();
      case 3:
        return renderSearchPage();
      case 4:
        return renderResultsPage();
    }
  };

  const containerClasses = (
    "tw-rounded-lg tw-bg-white tw-w-full md:tw-max-w-md tw-p-8 tw-flex " +
    "tw-flex-col tw-gap-3 tw-box-border "
  );

  return <div className={containerClasses}>
    {props.thanks ? <ClientThanks /> : renderQuiz()}
  </div>
}
