import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import AllMyMemberships from "../memberships/AllMyMemberships";
import UserSettingsContainer from "../settings/UserSettingsContainer";
import { apiBase } from "../../utils/apiRequest";
import defaultPhoto from "../../../assets/images/default_photo.png";
import userAvatar from "../../../assets/images/userAvatar.png";

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      user: [],
      memberships: [],
      showSettings: false,
    };

    this.getUser = this.getUser.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    this.getUser();
  }

  handleLogout() {
    const link = document.createElement("a");
    link.setAttribute("href", "../sign_out");
    link.setAttribute("rel", "nofollow");
    link.setAttribute("data-method", "delete");
    document.body.appendChild(link);
    link.click();
  }

  async getUser() {
    const response = await apiBase("/get_current_user", "GET");
    this.setState({
      user: JSON.parse(response?.data?.user),
      loaded: true,
    });
  }

  toggleSettings() {
    this.setState({
      showSettings: !this.state.showSettings,
    });
  }

  render() {
    return (
      <div style={{ margin: "auto", width: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src={this.props.logo}
            style={{ maxWidth: "30%", maxHeight: 50 }}
          />
        </div>
        {this.state.showSettings && (
          <UserSettingsContainer toggleSettings={this.toggleSettings} />
        )}
        {!this.state.showSettings && (
          <div style={{ margin: "auto", marginTop: 30, maxWidth: 500 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={this.state.user.avatar_url || userAvatar}
                  className="userImage"
                />
              </div>
              <div
                style={{
                  marginLeft: 30,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <div>
                  <h2 className="bold">{this.state.user.first_name}</h2>
                  <h2 className="thin" style={{ marginTop: 10 }}>
                    {this.state.user.last_name}
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={this.toggleSettings}
                >
                  <h4>Settings</h4>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      fontSize: 12,
                      padding: 10,
                      height: 15,
                      width: 15,
                      color: "white",
                      backgroundColor: "#733DFF",
                      borderRadius: 100,
                      marginLeft: 15,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              <AllMyMemberships />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 50,
                cursor: "pointer",
                alignItems: "center",
              }}
              onClick={this.handleLogout}
            >
              <FontAwesomeIcon
                icon={faSignOutAlt}
                style={{
                  fontSize: 12,
                  padding: 10,
                  height: 15,
                  width: 15,
                  color: "white",
                  backgroundColor: "#733DFF",
                  borderRadius: 100,
                  marginRight: 15,
                }}
              />
              <h4>Logout</h4>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DashboardContainer;
