import React from "react";

import formatCareType from "../../utils/care_types";

import defaultPhoto from "../../../assets/images/default_photo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faLaptop } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default function UserCard({user, style}) {
  if (!user) {
    return null;
  }

  const renderSpecialties = () => {
    return user.user_specialties?.map((user_specialty, i) => {
      if (user_specialty.primary) {
        return (
          <p
            className="userCardPrimarySpecialty"
            style={{ marginBottom: 8, fontSize: "10px" }}
            key={i}
          >
            {user_specialty.specialty.name}
          </p>
        );
      }
    });
  };

  const renderName = () => {
    const { first_name, last_name, display_name } = user;
    return display_name == null || display_name == ""
      ? `${first_name.trim()} ${last_name.trim()}`
      : `${display_name}`;
  }

  return (
    <Link to={"/"}>
    <div className={style ? style : "therapist--card"}>
      <div className="therapist--card--picture">
        <img src={user.avatar_url || defaultPhoto} />
      </div>
      <div className="therapist--card--info">
        <p>{renderName()}</p>
        <div style={{ display: "flex" }}>
          {user.user_locations?.length > 0 && (
            <span style={{ marginRight: "5px" }}>
              {user.user_locations[0]?.city ? user.user_locations[0].city + ", " : ""}
              {user.user_locations[0]?.state}
            </span>
          )}
          {user.user_locations?.length > 0 &&
            user.user_locations[0].hasOwnProperty(
              "my_office"
            ) &&
            user.user_locations[0].my_office && (
              <div
                className="locationContainer"
                style={{ marginRight: "5px" }}
              >
                <FontAwesomeIcon icon={faBuilding} size="xs" />
              </div>
            )}
          {user.user_locations?.length > 0 &&
            user?.user_locations[0].hasOwnProperty(
              "virtually"
            ) &&
            user?.user_locations[0].virtually && (
              <div
                className="locationContainer"
                style={{ marginRight: "5px" }}
              >
                <FontAwesomeIcon icon={faLaptop} size="xs" />
              </div>
            )}
        </div>
        {user?.user_caretypes.map((item, i) => {
          return <small key={i}>{item.care_type && formatCareType(item)}</small>;
        })}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {renderSpecialties()}
        </div>
      </div>
    </div>
    </Link>
  );
}
