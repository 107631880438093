import {format, parseISO, intervalToDuration, differenceInDays} from "date-fns";
import {useEffect, useRef} from 'react'

const isInFuture = (utcIsoStamp) => {
  const time = parseISO(utcIsoStamp);
  const now = new Date();
  return now < time;
}

const isInPast = (utcIsoStamp) => {
  return !isInFuture(utcIsoStamp);
}

const getTimeZone = (date) => {
  let zone = format(date, "OOO")
  switch (zone) {
    case "GMT-4":
      return "EST";
    case "GMT-5":
      return "CST";
    case "GMT-7":
      return "PST";
    default:
      return zone;
  }
};

function formatRelativeTime(start, now) {
  const duration = intervalToDuration({start: start, end: now});
  const days = Math.abs(differenceInDays(start, now));

  let formatParts = [];
  if (days) {
    formatParts.push(`${days} day` + (days > 1 ? "s" : ""));
  }
  if (duration.hours) {
    let hours = duration.hours;
    if (duration.minutes >= 30) {
      hours++;
    }
    formatParts.push(`${hours} hour` + (hours > 1 ? "s" : ""));
  }

  // add "minutes" only if the duration is less than an hour away
  if (!days && !duration.hours) {
    if (duration.minutes) {
      formatParts.push(`${duration.minutes} minute` + (duration.minutes > 1 ? "s" : ""));
    } else {
      return start < now ? "moments ago" : "momentarily";
    }
  }

  if (start < now) {
    return `${formatParts.join(", ")} ago`;
  }
  return `in ${formatParts.join(", ")}`;
};

function useTimeout(callback, delay) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export { formatRelativeTime, isInPast, isInFuture, getTimeZone, useTimeout, useInterval };
