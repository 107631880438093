import React, {useEffect, useState} from "react";

import MultiSelectForm from "../forms/MultiSelectForm";

import {mutateCurrentUserPronouns} from "../../queries/user";
import {getPronouns} from "../../queries/user_attributes";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'


export default function PronounOnboard(props) {
  const userPronouns = props.currentUser?.pronouns;
  const queryClient = useQueryClient();
  const queryPronouns = useQuery({
    queryKey: ["pronoun_choices"],
    queryFn: getPronouns,
  });
  const userPronounsMutation = useMutation({
    mutationFn: mutateCurrentUserPronouns,
    onSuccess: () => {
      queryClient.invalidateQueries(
        {queryKey: ["current_user"]}
      );
    },
  });

  const loading = !userPronouns || queryPronouns.isLoading;
  if (loading) {
    return <p>Loading...</p>;
  }

  if (queryPronouns.isError) {
    return (
      <h1>Error fetching pronouns: {queryPronouns.error.message}</h1>
    );
  }

  const pronouns = JSON.parse(queryPronouns.data.data.pronouns);

  return (
    <div className="onboard_field_section">
      <h3 className="standard_label" style={{paddingBottom: 10}}>What are your pronouns?</h3>
      <h4 className="standard_label" style={{paddingBottom: 40, opacity: .5}}>Select as many as apply</h4>
      <MultiSelectForm
        choices={pronouns}
        selections={userPronouns}
        onSelect={(id, selected) => {
          userPronounsMutation.mutate({
            action: selected ? "delete" : "create",
            payload: {pronoun_id: id},
          })
        }}
        onCustomEntry={(pronoun) => {
          props.updateUser({additional_pronoun: pronoun});
        }}
        customEntry={props.currentUser.additional_pronoun}
        placeholder="somethin' else"
      />
    </div>
  );
}
