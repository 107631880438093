import React, { useState, useContext, useEffect, useRef } from "react";
import FloatingButton from "./FloatingButton";
import StepsIndexContext from "../context/StepsIndexContext";
import UserDownloadContext from "../context/UserDownloadContext";
import ReactQuill from "react-quill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faTimes } from "@fortawesome/free-solid-svg-icons";
import { apiBase } from "../../utils/apiRequest";
import AutoCompleteInput from "./AutoCompleteInput";

export default function TheDeetsContent(props) {
  const [step, setStep] = useContext(StepsIndexContext);
  const [title, setTitle] = useState("");
  const [downloadNameUrl, setDownloadNameUrl] = useState("");
  const [downloadInfo, setDownloadInfo] = useState(null);
  const [userDownload, setUserDownload] = useContext(UserDownloadContext);
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [urlInUse, setUrlInUse] = useState(false);

  useEffect(() => {
    setTitle(userDownload?.download_title || "");
  }, [userDownload?.download_title]);

  useEffect(() => {
    if (!!userDownload?.download_url) {
      setDownloadNameUrl(userDownload?.download_url);
    }
  }, [userDownload?.download_url]);

  useEffect(() => {
    if (userDownload?.download_tags) {
      if (typeof userDownload?.download_tags === "string")
        return setTags(JSON.parse(userDownload?.download_tags));

      return setTags(userDownload?.download_tags);
    }
  }, [userDownload?.download_tags]);

  useEffect(() => {
    if (tags.length > 0 && userDownload?.download_tags !== tags) {
      props.updateUserDownload("download_tags", tags);
      handleOnChangeValue(tags, "download_tags");
    }
  }, [tags]);

  useEffect(() => {
    if (userDownload) {
      const newDownloadInfo = userDownload?.download_info || "";
      if (!downloadInfo && newDownloadInfo) {
        setDownloadInfo(newDownloadInfo);
      }
    }
  }, [userDownload]);

  async function handleNextButton() {
    setUserDownload((prevUserDownload) => {
      return {
        ...prevUserDownload,
        download_title: title,
        download_info: downloadInfo,
        download_tags: tags,
      };
    });

    setStep(2);
  }

  const handleOnChangeValue = (value, target) => {
    setUserDownload((prevUserDownload) => {
      return {
        ...prevUserDownload,
        [target]: value,
      };
    });
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter((item) => item !== tag));
  };

  const handleUrl = async (value) => {
    const response = await apiBase(
      `/check_url_downloads?download_url=${value}`,
      "GET"
    );

    if (response?.download?.id && response?.download?.id !== userDownload?.id) {
      return setUrlInUse(true);
    }

    setUrlInUse(false);
    props.updateUserDownload("download_url", value);
  };

  return (
    <div className="stepContent">
      <h2 id="step1">What is the name of your download?</h2>
      <div className="theDeetsContentBody">
        <input
          placeholder="Descriptive title"
          className="inputNameDownload"
          value={title}
          onChange={(e) =>
            handleOnChangeValue(e.target.value, "download_title")
          }
          onBlur={(e) =>
            props.updateUserDownload("download_title", e.target.value)
          }
        />
        <h2>Tell us more about your download</h2>
        <div
          onBlur={() => {
            handleOnChangeValue(downloadInfo, "download_info");
            props.updateUserDownload("download_info", downloadInfo);
          }}
          className="textEditor"
        >
          <ReactQuill
            theme="snow"
            className="textEditor"
            key={`quill${!!downloadInfo}`}
            defaultValue={downloadInfo}
            onChange={setDownloadInfo}
            onBlur={(e) =>
              props.updateUserDownload("download_info", downloadInfo)
            }
          />
        </div>
        <div className="download--tags">
          <h2>What topics are addressed?</h2>
          <small>
            Please select up to 5 topics to help potential customers discover
            your download
          </small>
          <div className="container--tags">
            {tags?.map((tag, i) => (
              <div className="tag" key={i}>
                <p>{tag}</p>
                <div className="x--mark" onClick={() => handleRemoveTag(tag)}>
                  <FontAwesomeIcon icon={faTimes} style={{ color: "#fff" }} />
                </div>
              </div>
            ))}
            {tags.length <= 4 && (
              <AutoCompleteInput
                options={[]}
                placeholder={"Enter topics"}
                handleSelectedValue={(value) => {
                  if (!tags.includes(value))
                    setTags((prevState) => [...prevState, value]);
                }}
              />
            )}
          </div>
        </div>

        <h2>Where can people find your download?</h2>
        <div className="download--url">
          <p>{props.user.slug}.{process.env.ROOT_DOMAIN}/d/</p>
          <input
            className={urlInUse ? "url--in--use--input" : ""}
            placeholder="my_download"
            value={downloadNameUrl}
            onChange={(e) =>
              handleOnChangeValue(e.target.value, "download_url")
            }
            onBlur={(e) => handleUrl(e.target.value)}
          />
          {urlInUse && (
            <small className="url--in--use">This URL is already in use</small>
          )}
        </div>
      </div>
      {/* <div className="buttons">
        <FloatingButton
          secondaryButton={true}
          title="Back"
          onClick={() => setStep(0)}
        />
        <FloatingButton
          title={
            userDownload?.download_status === "PUBLISHED" ? "Publish!" : "Next"
          }
          onClick={() => {
            if (userDownload?.download_status === "PUBLISHED")
              return window.location.assign(
                `//${props.user?.slug}.{process.env.ROOT_DOMAIN}/d/${userDownload.download_url}`
              );

            handleNextButton();
          }}
          disabled={!title || !downloadInfo}
        />
      </div> */}
    </div>
  );
}
