import React, {useEffect, useState} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye, faEyeSlash, faLock,
} from "@fortawesome/free-solid-svg-icons";

const RegistrationForm = ({
  buyerInfo,
  onComplete,
  onError,
}) => {
  const [password, setPassword] = useState(undefined);
  const [confirmedPassword, setConfirmedPassword] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");

  const renderSignUpButton = () => {
    const disabled = !password || password !== confirmedPassword;
    return (
      <button
        className={
          "button--payment " + (disabled ? "" : "button--payment--enabled")
        }
        onClick={register}
        disabled={disabled}
      >
        <p>Sign up</p>
      </button>
    );
  };

  const register = async () => {
    onError({prefix: undefined, err: undefined});
    try {
      await $.ajax({
        url: "/save_user_password",
        dataType: "JSON",
        type: "POST",
        data: JSON.stringify({
          email: buyerInfo.email,
          password: password,
          authenticity_token: csrf,
        }),
        headers: {
          "X-CSRFToken": csrf,
          "Content-Type": "application/json",
        },
      });
      await $.ajax({
        url: "/login",
        dataType: "text",
        type: "POST",
        data: JSON.stringify({
          user: {
            email: buyerInfo.email,
            password: password,
          },
          authenticity_token: csrf,
          payment_modal: true,
        }),
        headers: {
          "X-CSRFToken": csrf,
          "Content-Type": "application/json",
        },
      });
      onComplete();
    } catch (e) {
      onError({prefix: "Failed to save user details", err: e});
    }
  };

  return (
    <div className="registrationFormContainer">
      <p><b>Create a password</b></p>
      <div className="input--row">
        <div className="input--icon--container">
          <FontAwesomeIcon
            icon={faLock}
            size="xs"
          />
          <input
            type={showPassword ? "text" : "password"}
            attribute={"userPassword"}
            name={"password"}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required={true}
            style={{flexGrow: 1}}
          />
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              size="xs"
            />
          </div>
        </div>
      </div>
      <p><b>Confirm password</b></p>
      <div className="input--row">
        <div className="input--icon--container">
          <FontAwesomeIcon
            icon={faLock}
            size="xs"
          />
          <input
            type={showPassword ? "text" : "password"}
            attribute={"userPassword"}
            name={"password"}
            onChange={(e) => setConfirmedPassword(e.target.value)}
            placeholder="Password confirmation"
            required={true}
            style={{flexGrow: 1}}
          />
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon
              icon={showPassword ? faEye : faEyeSlash}
              size="xs"
            />
          </div>
        </div>
      </div>
      {renderSignUpButton()}
    </div>
  );
};

export default RegistrationForm;
