import React from "react";
import PropTypes from "prop-types";

import Button from "../../../forms/Button";
import TextInput from "../../../forms/TextInput";
import Logo from "../../../../../assets/images/logo_purple.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpen,
  faEye,
  faEyeSlash,
  faLock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const csrf = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

class LoginServicesProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginModal: true,
      registerModal: false,
      userName: "",
      userLastName: "",
      userEmail: "",
      userPassword: "",
      showPassword: "",
      showPasswordConfirmation: "",
      isProvider: false,
    };

    this.updateField = this.updateField.bind(this);
    this.renderLoginModal = this.renderLoginModal.bind(this);
    this.renderRegisterModal = this.renderRegisterModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
  }

  componentDidMount() {
    this.renderModal();
  }

  renderModal() {
    if (window.location.href.includes("login_erro=1")) {
      this.setState({
        loginModal: true,
        registerModal: false,
      });
    } else {
      if (window.location.href.includes("register_erro=1")) {
        this.setState({
          registerModal: true,
          loginModal: false,
        });
      }
    }
  }

  updateField(attribute, text) {
    this.setState({
      [attribute]: text,
    });
  }

  renderLoginModal() {
    return (
      <form action="/login" method="POST">
        <div className="loginContainter">
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <img
              src={Logo}
              style={{ width: 100, height: 50, marginBottom: 20 }}
            />
            <h5>Must log in to continue</h5>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <p className="standard_label" style={{ margin: "0 5px 10px 5px" }}>
              Email
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelopeOpen}
                size="xs"
                style={{ color: "#C1A9FE", position: "absolute", left: 20 }}
              />
              <TextInput
                className="profile_text_field"
                style={{ width: "-webkit-fill-available", paddingLeft: 45 }}
                type="text"
                attribute={"userEmail"}
                name={"user[email]"}
                value={this.state.userEmail}
                handleChange={this.updateField}
                placeholder="Email"
                required={true}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <p className="standard_label" style={{ margin: "0 5px 10px 5px" }}>
              Password
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                icon={faLock}
                size="xs"
                style={{ color: "#C1A9FE", position: "absolute", left: 20 }}
              />
              <TextInput
                className="profile_text_field"
                style={{ width: "-webkit-fill-available", paddingLeft: 45 }}
                type={this.state.showPassword ? "text" : "password"}
                attribute={"userPassword"}
                name={"user[password]"}
                value={this.state.userPassword}
                handleChange={this.updateField}
                placeholder="Password"
                required={true}
              />
              <div
                style={{ position: "absolute", right: 20, cursor: "pointer" }}
                onClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
              >
                <FontAwesomeIcon
                  icon={this.state.showPassword ? faEye : faEyeSlash}
                  size="xs"
                  style={{ color: "#C1A9FE" }}
                />
              </div>
            </div>
          </div>
          <input type="hidden" name="authenticity_token" value={csrf} />
          <input type="hidden" name="user_slug" value={this.props.user.slug} />
          <input type="hidden" name="redirect_service_id" value={this.props.redirectServiceId} />
          <div
            style={{ width: "100%", marginBottom: 20 }}
            onClick={() => window.location.replace("/password/new")}
          >
            <p
              style={{
                color: "#7843FF",
                cursor: "pointer",
                margin: "0 5px 10px 5px",
                fontWeight: 600,
              }}
            >
              Can’t quite remember?
            </p>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Button
              buttonClass="button_primary"
              text="Log in"
              handleClick={() => {}}
              style={{ width: "-webkit-fill-available" }}
            />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p>
              Haven’t yet joined?{" "}
              <span
                onClick={() =>
                  this.setState({ loginModal: false, registerModal: true })
                }
                style={{ color: "#7843FF", fontWeight: 600, cursor: "pointer" }}
              >
                Become an early member - it’s free!
              </span>
            </p>
          </div>
        </div>
      </form>
    );
  }

  renderRegisterModal() {
    return (
      <form action="/" method="POST">
        <div className="loginprofile">
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <img
              src={Logo}
              style={{ width: 100, height: 50, marginBottom: 20 }}
            />
          </div>
          <div
            style={{
              width: "100%",
              marginBottom: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "47%" }}>
              <p
                className="standard_label"
                style={{ margin: "0 5px 10px 5px" }}
              >
                First name
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  size="xs"
                  style={{ color: "#C1A9FE", position: "absolute", left: 20 }}
                />
                <TextInput
                  className="profile_text_field"
                  style={{ width: "-webkit-fill-available", paddingLeft: 45 }}
                  type="text"
                  attribute={"userName"}
                  name={"user[first_name]"}
                  value={this.state.userName}
                  handleChange={this.updateField}
                  placeholder="First name"
                />
              </div>
            </div>
            <div style={{ width: "47%" }}>
              <p
                className="standard_label"
                style={{ margin: "0 5px 10px 5px" }}
              >
                Last name
              </p>
              <TextInput
                className="profile_text_field"
                style={{ width: "-webkit-fill-available" }}
                type="text"
                attribute={"userLastName"}
                name={"user[last_name]"}
                value={this.state.userLastName}
                handleChange={this.updateField}
                placeholder="Last name"
              />
            </div>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <p className="standard_label" style={{ margin: "0 5px 10px 5px" }}>
              Email
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelopeOpen}
                size="xs"
                style={{ color: "#C1A9FE", position: "absolute", left: 20 }}
              />
              <TextInput
                className="profile_text_field"
                style={{ width: "-webkit-fill-available", paddingLeft: 45 }}
                type="text"
                attribute={"userEmail"}
                name={"user[email]"}
                value={this.state.userEmail}
                handleChange={this.updateField}
                placeholder="Email"
              />
            </div>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <p className="standard_label" style={{ margin: "0 5px 10px 5px" }}>
              Password
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                icon={faLock}
                size="xs"
                style={{ color: "#C1A9FE", position: "absolute", left: 20 }}
              />
              <TextInput
                className="profile_text_field"
                style={{ width: "-webkit-fill-available", paddingLeft: 45 }}
                type={this.state.showPassword ? "text" : "password"}
                attribute={"userPassword"}
                name={"user[password]"}
                value={this.state.userPassword}
                handleChange={this.updateField}
                placeholder="Password"
              />
              <div
                style={{ position: "absolute", right: 20, cursor: "pointer" }}
                onClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
              >
                <FontAwesomeIcon
                  icon={this.state.showPassword ? faEye : faEyeSlash}
                  size="xs"
                  style={{ color: "#C1A9FE" }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <p className="standard_label" style={{ margin: "0 5px 10px 5px" }}>
              Password
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                icon={faLock}
                size="xs"
                style={{ color: "#C1A9FE", position: "absolute", left: 20 }}
              />
              <TextInput
                className="profile_text_field"
                style={{ width: "-webkit-fill-available", paddingLeft: 45 }}
                type={this.state.showPasswordConfirmation ? "text" : "password"}
                attribute={"userPasswordConfirmation"}
                name={"user[password_confirmation]"}
                value={this.state.userPasswordConfirmation}
                handleChange={this.updateField}
                placeholder="Password confirmation"
              />
              <div
                style={{ position: "absolute", right: 20, cursor: "pointer" }}
                onClick={() =>
                  this.setState({
                    showPasswordConfirmation:
                      !this.state.showPasswordConfirmation,
                  })
                }
              >
                <FontAwesomeIcon
                  icon={
                    this.state.showPasswordConfirmation ? faEye : faEyeSlash
                  }
                  size="xs"
                  style={{ color: "#C1A9FE" }}
                />
              </div>
            </div>
          </div>
          {/* <div style={{ width: "100%", marginBottom: 20 }}>
            <p className="standard_label" style={{ margin: "0 5px" }}>
              Are you a wellness professional?
            </p>
            <p
              className="standard_label"
              style={{ margin: "0 0 10px 5px", fontSize: 13, fontWeight: 100 }}
            >
              Selecting yes will create a professional account
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                style={{
                  height: 25,
                  width: 25,
                  margin: "0 10px 0 0",
                  appearance: "none",
                  border: "2px solid #733DFF",
                  borderRadius: "50%",
                  backgroundClip: "content-box",
                  padding: 3,
                  marginLeft: 5,
                }}
                type="checkbox"
                id="provider_label"
                name="user[is_provider]"
                value={this.state.isProvider}
                onChange={() =>
                  this.setState({ isProvider: !this.state.isProvider })
                }
              />
              <label
                className="standard_label"
                style={{ fontSize: 14, fontWeight: 100 }}
              >
                Yes, I am!
              </label>
            </div>
          </div> */}
          <input type="hidden" name="authenticity_token" value={csrf} />
          <input type="hidden" name="user_slug" value={this.props.user.slug} />
          <input type="hidden" name="redirect_service_id" value={this.props.redirectServiceId} />
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Button
              buttonClass="button_primary"
              text="Create account"
              handleClick={() => {}}
              style={{ width: "-webkit-fill-available" }}
            />
          </div>
          <div style={{ width: "100%", marginBottom: 20, textAlign: "center" }}>
            <p>
              Already joined?{" "}
              <span
                onClick={() =>
                  this.setState({ loginModal: true, registerModal: false })
                }
                style={{ color: "#7843FF", fontWeight: 600, cursor: "pointer" }}
              >
                Log in!
              </span>
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid #F3EEFF",
              width: "100%",
              padding: "20px 0",
              textAlign: "center",
            }}
          >
            <p style={{ fontSize: 10 }}>
              By clicking the button above, you agree to our{" "}
              <span style={{ color: "#7843FF", cursor: "pointer" }}>
                Terms of Service
              </span>{" "}
              and{" "}
              <span style={{ color: "#7843FF", cursor: "pointer" }}>
                Privacy Policy
              </span>
            </p>
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loginModal && this.renderLoginModal()}
        {this.state.registerModal && this.renderRegisterModal()}
      </React.Fragment>
    );
  }
}

export default LoginServicesProfile;
