import React, {useEffect, useState} from "react";

import MultiSelect from "../profile_forms/MultiSelect";

import {
  useQuery,
} from '@tanstack/react-query';

import {getPronouns} from "../../queries/user_attributes";

export default function EditPronouns({
  user, setPronouns, setCustomPronoun
}) {
  const queryPronouns = useQuery({
    queryKey: ["pronoun_choices"],
    queryFn: getPronouns,
  });

  const loading = queryPronouns.isLoading;
  if (loading) {
    return <p>Loading...</p>;
  }

  if (queryPronouns.isError) {
    return (
      <h1>Error fetching pronouns: {queryPronouns.error.message}</h1>
    );
  }

  const pronouns = JSON.parse(queryPronouns.data.data.pronouns);

  const onChange = (newValue, actionMeta) => {
    let pronouns = newValue.map(x => {return {id: x.value, name: x.label}});
    let custom = pronouns.find(x => x.id === x.name);
    if (custom) {
      pronouns = pronouns.filter(x => x.id !== x.name);
      setCustomPronoun(custom.name);
    } else {
      setCustomPronoun(null);
    }
    setPronouns(pronouns);
  };

  const isValidNewOption = (inputValue, value, options, accessors) => {
    return (!user.additional_pronoun) && !!inputValue;
  };

  const userPronouns = [...user.pronouns];
  if (user.additional_pronoun) {
    userPronouns.push({id: user.additional_pronoun, name: user.additional_pronoun});
  }

  return <MultiSelect
    transform={a => {
      if (a?.map) {
        return a.map(x => {return {value: x.id, label: x.name}})
      }
      console.log("pronoun editor transform called on non-array", a);
    }}
    options={pronouns}
    value={userPronouns || []}
    defaultValue={userPronouns || []}
    onChange={onChange}
    onCustomEntry={(pronoun) => {
      setCustomPronoun(pronoun);
    }}
    isValidNewOption={isValidNewOption}
    customEntry={user.additional_pronoun}
    placeholder="Type or select pronouns"
  />;
};
