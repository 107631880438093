import React, {useState, useEffect} from "react";

export const YES_NO = "YES_NO";

export default function RadioSelectForm(props) {
  const gap = props.gap || "4";
  const gapClass = `tw-gap-${gap}`;

  let {
    label,
    options,
  } = props;

  if (options === YES_NO) {
    options = [{
      buttonLabel: "Yes",
      getter: props.getter,
      setter: () => props.onChange(true),
    }, {
      buttonLabel: "No",
      getter: () => !props.getter(),
      setter: () => props.onChange(false),
    }];
  }

  return <div className={`tw-flex tw-flex-col tw-my-2 ${gapClass}`}>
    <p className="text-16 tw-font-semibold">{label}</p>
    <div className={`tw-flex ${gapClass}`}>
      {options.map((option, i) => {
        const {buttonLabel, getter, setter} = option;
        return <label key={i} className="text-16 tw-font-semibold tw-flex tw-gap-2">
          {buttonLabel}
          <input
            type="radio"
            key={getter()}
            className="dark-accent-text"
            defaultChecked={getter()}
            onChange={setter}
          />
        </label>;
      })}
    </div>
  </div>;
}
