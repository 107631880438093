import React, {useEffect, useState} from "react";

const TextInput = (props) => {
  const [updateTimeout, setUpdateTimeout] = useState();
  const handleBlur = (attribute, value, index) => {
    return props.handleBlur
      ? props.handleBlur(attribute, value, index)
      : () => {};
  };

  const handleChangeDebounced = (event) => {
    if (!props.debounceDelayMs) {
      return handleChange(event);
    }

    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }

    const timeout = setTimeout(
      () => handleChange(event), props.debounceDelayMs
    )
    setUpdateTimeout(timeout);
  };

  useEffect(() => {
    return () => {clearTimeout(updateTimeout); setUpdateTimeout(null);}
  }, []);

  const handleChange = (event) => {
    if (props.index) {
      return props.handleChange(
        props.attribute,
        event.target.value,
        index.toString()
      );
    }
    return props.handleChange(props.attribute, event.target.value)
  }

  return (
    <input
      style={props.style}
      id={props.id}
      placeholder={props.placeholder}
      className={props.className ? props.className : "onboard_text_field"}
      type={props.type}
      name={props.name}
      disabled={props.disabled}
      defaultValue={props.defaultValue}
      autoFocus={props.autofocus ? "autofocus" : ""}
      value={props.value}
      onChange={handleChangeDebounced}
      onBlur={(event) =>
        props.index
          ? handleBlur(props.attribute, event.target.value, index.toString())
          : handleBlur(props.attribute, event.target.value)
      }
      readOnly={props.readOnly}
      required={props.required}
      password={props.password}
    ></input>
  );
};

export default TextInput;
