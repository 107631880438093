import React, { useEffect, useState } from "react";
import MyAccount from "./Settings/MyAccount";
import UpdatePassword from "./Settings/UpdatePassword";
import Integragions from "./Settings/Integrations";
import ErrorBoundary from "../components/ErrorBoundary";
import Upgrade from "./Settings/Upgrade";

import {
  useQueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

export default function SettingsContainer(props) {
  const queryClient = useQueryClient();

  const { onFinish } = props;
  const params = new URLSearchParams(window.location.search);
  const initialPage = params.get("page") || "account";
  const [menu, setMenu] = useState([
    {
      id: 0,
      name: "My account",
      isSelected: initialPage === "account",
    },
    {
      id: 1,
      name: "Change my password",
      isSelected: initialPage === "password",
    },
    {
      id: 2,
      name: "Integrations",
      isSelected: initialPage === "integrations",
    },
    {
      id: 3,
      name: "✨Upgrade✨/Plans",
      isSelected: initialPage === "upgrade",
    }
  ]);

  useEffect(() => {
    onFinish();
  }, []);

  function handleMenuSelect(id) {
    setMenu((prevMenu) =>
      prevMenu.map((item) =>
        item.id === id
          ? { ...item, isSelected: true }
          : { ...item, isSelected: false }
      )
    );
  }

  function renderSideNav() {
    return (
      <div className="settings--row">
        {menu.map((item) => (
          <div
            key={item.id}
            className={
              item.isSelected
                ? "settingsItem settingsItemSelected"
                : "settingsItem"
            }
            onClick={() => handleMenuSelect(item.id)}
          >
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="settingsContainer">
      <div className="settingsSideNav">{renderSideNav()}</div>
      <QueryClientProvider client={queryClient}>
        <div className="settingsContent">
          {menu[0].isSelected && (
            <MyAccount
              user={props.user}
              updateUser={props.updateUser}
              loadUser={props.loadUser}
              changeUserData={props.changeUserData}
            />
          )}
          {menu[1].isSelected && <UpdatePassword user={props.user} />}
          {menu[2].isSelected && <Integragions user={props.user} />}
          {menu[3].isSelected && <Upgrade user={props.user} />}
        </div>
      </QueryClientProvider>
    </div>
  );
}
