import React, {useState} from "react";

import MultiSelect from "../profile_forms/MultiSelect";

export default function TypeOrSelectForm(props) {
  const [error, setError] = useState();

  const {
    options, placeholder, selections, setSelections, transforms, maxEntries
  } = props;

  if (!options) {
    return null;
  }

  const onChange = (newSelections, _) => {
    if (maxEntries && newSelections.length > maxEntries) {
      setError(`You may only select ${maxEntries}.`);
      return;
    }
    setError(null);
    setSelections(newSelections.map(x => transformFromOption(x)));
  };

  const transformToOption = transforms?.to || (x => x);
  const transformFromOption = transforms?.from || (x => x);

  return <div className="tw-flex tw-flex-col">
      <MultiSelect
      transform={a => {
        if (!a?.map) {
          return null;
        }
        return a.map(x => transformToOption(x))
      }}
      isValidNewOption={props.isValidNewOption}
      options={options}
      value={selections}
      defaultValue={selections}
      onChange={onChange}
      placeholder={placeholder}
    />
    {error && <p className="text-12 orange">{error}</p>}
  </div>;
}
