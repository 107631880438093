import React, { useState, useContext, useEffect } from "react";
import FloatingButton from "./FloatingButton";
import StepsIndexContext from "../context/StepsIndexContext";
import DateTimePicker from "react-datetime-picker";
import DropDownSelectForm from "../profile_forms/DropDownSelectForm";
import EventContext from "../context/EventContext";
import { format, parseISO, toDate, isDate } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AutoCompleteInput from "./AutoCompleteInput";
import { apiBase } from "../../utils/apiRequest";
import { getTimeZone } from "../../utils/time";

export default function EventTheBasicsContent(props) {
  const [event, setEvent] = useContext(EventContext);
  const [step, setStep] = useContext(StepsIndexContext);
  const [eventName, setEventName] = useState(event?.title);
  const [eventSummary, setEventSummary] = useState(event?.summary);
  const [eventNameUrl, setEventNameUrl] = useState(event?.event_url);
  const [initialStartDate, setInitialStartDate] = useState("");
  const [initialEndDate, setInitialEndDate] = useState("");
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [urlInUse, setUrlInUse] = useState(false);

  const handleUpdateEvent = (field, value) => {
    setEvent((previousValue) => {
      return {
        ...previousValue,
        [field]: value,
      };
    });
    props.updateUserEvent(field, value);
  };

  const handleEndTime = (value, timeAmPm) => {
    const dateStr = format(parseISO(event?.date_start_time), "yyyy-MM-dd");
    const dateTimeString = `${dateStr}T${value}:00`;
    const addHours = timeAmPm === "PM" ? 12 : 0;

    const date = parseISO(dateTimeString);

    date.setHours(date.getHours() + addHours);

    handleUpdateEvent("date_end_time", date);
  };

  const maskString = (value) => {
    return value.toLowerCase().replace(/[^a-z0-9-]/g, "-");
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter((item) => item !== tag));
  };

  const handleUrl = async (value) => {
    const response = await apiBase(
      `/check_url_events?event_url=${value}`,
      "GET"
    );

    if (response?.event?.id && response?.event?.id !== event?.id) {
      return setUrlInUse(true);
    }

    setUrlInUse(false);
    handleUpdateEvent("event_url", value);
  };

  useEffect(() => {
    if (event?.title && eventName !== event?.title) setEventName(event?.title);
    if (event?.summary && eventSummary !== event?.summary) {
      setEventSummary(event?.summary);
    }
    if (event?.event_url && eventNameUrl !== event?.event_url)
      setEventNameUrl(event?.event_url);
    if (event?.date_start_time && initialStartDate !== event?.date_start_time)
      setInitialStartDate(event?.date_start_time);
    if (event?.date_end_time && initialEndDate !== event?.date_end_time)
      setInitialEndDate(event?.date_end_time);
  }, [event]);

  useEffect(() => {
    if (event?.topics) {
      setTags(
        typeof event?.topics === "string"
          ? JSON.parse(event?.topics)
          : event?.topics
      );
    }
  }, [event?.topics]);

  useEffect(() => {
    if (tags.length > 0) {
      if (!event?.topics) return handleUpdateEvent("topics", tags);

      const topics =
        typeof event?.topics === "string"
          ? JSON.parse(event?.topics)
          : event?.topics;

      if (topics !== tags) return handleUpdateEvent("topics", tags);
    }
  }, [tags]);

  return (
    <div className="eventsStepContent">
      <h2 id="step0">What is the name of your event?</h2>
      <input
        placeholder="Descriptive event name"
        className="inputEventNameDownload"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
        onBlur={() => handleUpdateEvent("title", eventName)}
      />
      <h2>When is your event?</h2>
      <div className="eventStartEndTimeContainer">
        <DateTimePicker
          disableClock={true}
          onChange={async (time) => {
            const isoTime = time.toISOString();
            handleUpdateEvent("date_start_time", isoTime);
          }}
          value={event?.date_start_time}
        />
        <p><small>til</small></p>
        <DateTimePicker
          disableClock={true}
          onChange={(time) => {
            const isoTime = time.toISOString();
            handleUpdateEvent("date_end_time", isoTime)
          }}
          value={event?.date_end_time}
        />
      </div>
      <h2>What is your event about?</h2>
      <p>Write a quick blurb about your event (2 sentences).</p>
      <textarea
        className="input--summary--event"
        placeholder="Tell us about your event!"
        value={eventSummary}
        onChange={(e) => setEventSummary(e.target.value)}
        onBlur={() => handleUpdateEvent("summary", eventSummary)}
        type="textarea"
        maxLength={500}
      />
      <div className="event--topics">
        <h2>What topics will you cover?</h2>
        <p>
          Please select up to 5 topics to help potential attendees discover your
          event
        </p>
        <div className="container--tags">
          {tags?.map((tag, i) => (
            <div className="tag" key={i}>
              <p>{typeof(tag) === "object" ? tag.name : tag}</p>
              <div className="x--mark" onClick={() => handleRemoveTag(tag)}>
                <FontAwesomeIcon icon={faTimes} style={{ color: "#fff" }} />
              </div>
            </div>
          ))}
          {tags.length <= 4 && (
            <AutoCompleteInput
              options={[]}
              placeholder={"Enter topics"}
              handleSelectedValue={(value) => {
                if (!tags.includes(value))
                  setTags((prevState) => [...prevState, value]);
              }}
            />
          )}
        </div>
      </div>
      <h2>Where can people find your event?</h2>
      <div className="event--url">
        <p>{props.user?.slug}.{process.env.ROOT_DOMAIN}/e/</p>
        <input
          className={urlInUse ? "url--in--use--input" : ""}
          placeholder="event-name"
          value={eventNameUrl}
          onChange={(e) => setEventNameUrl(maskString(e.target.value))}
          onBlur={(e) => handleUrl(eventNameUrl)}
        />
        {urlInUse && (
          <small className="url--in--use">This URL is already in use</small>
        )}
      </div>
      {/* <div className="row">
        <FloatingButton
          title={event.status === "PUBLISHED" ? "Publish!" : "Next"}
          onClick={() => {
            if (event?.status === "PUBLISHED")
              return window.location.assign(
                `//${props.user?.slug}.{process.env.ROOT_DOMAIN}/e/${event.event_url}`
              );
            setStep(1);
          }}
        />
      </div> */}
    </div>
  );
}
