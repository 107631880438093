import React from "react";
import PropTypes from "prop-types";

import Button from "../../../forms/Button";
import LoginServicesProfile from "./LoginServicesProfile";
import SendMessageProfile from "./SendMessageProfile";

import {amountCentsToStringUSD} from "../../../../utils/currency";

class PublicServicesProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      user: [],
      showLogin: false,
      showMessageModal: true,
    };
    this.renderTherapistName = this.renderTherapistName.bind(this);
    this.renderServices = this.renderServices.bind(this);
  }

  componentDidMount() {
    const { user_services } = this.props.user;
    this.setState({
      services: user_services.length > 0 ? user_services : [{ name: "" }],
    });
  }

  renderTherapistName() {
    const { first_name, last_name, display_name } = this.props.user;
    return display_name == null || display_name == ""
      ? `${first_name} ${last_name}`
      : `${display_name}`;
  }

  renderServices() {
    const { user_services } = this.props.user;
    return user_services.map((service, i) => {
      return (
        <div key={i}>
          <p className="standard_label" style={{ margin: "0 5px 10px 5px" }}>
            {service.name}
          </p>
          {service.description?.split("\n").map((line, i) => 
            !!line && <p key={i} className="text-12 tw-p-4">{line}</p>
          )}
          {!service.hide_cost && <p className="standard_label" style={{ margin: "0 5px 10px 5px" }}>
            Rate
          </p>}
          {!service.hide_cost && <div style={{ margin: "0 5px 25px 5px" }}>
            <p className="standard_label" style={{ fontWeight: 400 }}>
              {amountCentsToStringUSD(service.cost, false, true)} / {service.duration} {service.duration_type}
            </p>
            {service.sliding_scale && (
              <p
                className="standard_label"
                style={{ fontWeight: 400, fontStyle: "italic" }}
              >
                sliding scale available
              </p>
            )}
          </div>}
          <div
            style={{
              border: "2px solid rgba(10, 33, 117, 0.1)",
              margin: "0 5px 25px 5px",
            }}
          ></div>
        </div>
      );
    });
  }

  render() {
    return (
      <React.Fragment>
          <SendMessageProfile
            user={this.props.user}
            currentUser={this.props.currentUser}
            placeholder={this.props.placeholder}
          />
      </React.Fragment>
    );
  }
}

export default PublicServicesProfile;
