import React from "react"
import PropTypes from "prop-types"
import Languages from './DetailSections/Languages'
import IdentityExpertise from './DetailSections/IdentityExpertise'
import Certifications from './DetailSections/Certifications'
import Education from './DetailSections/Education'
import Experience from './DetailSections/Experience'
import Location from './DetailSections/Location'

class DetailsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
      timeout: null,
    }

    this.toggleEditModal.bind(this)
    this.getExperience = this.getExperience.bind(this)
  }

  toggleEditModal(id) {
    if (!this.props.liveProfile) {
      this.props.toggleEditModal(id)
    }
  }

  getExperience() {
    return this.props.user.user_caretypes.map((caretype, i) => {
      if (caretype.practicing_since == ("" || null || undefined)) {
        return null
      } else {
        return caretype.practicing_since
      }
    })
  }

  render() {
    const user = this.props.user
    if (this.props.liveProfile &&
      this.getExperience().every(i => i == null) &&
      user.languages.length <= 0 &&
      user.identity_expertises.length <= 0 &&
      user.user_certifications.length <= 0 &&
      user.user_educations.length <= 0) {
      return null
    }

    return (
      <div className="detailsContainer">
        <Experience
          user={this.props.user}
          isCurrentUser={this.props.isCurrentUser}
          updateUser={this.props.updateUser}
          loadUser={this.props.loadUser}
          toggleEditModal={this.props.toggleEditModal}
          liveProfile={this.props.liveProfile}
        />
        <Education
          user={this.props.user}
          isCurrentUser={this.props.isCurrentUser}
          updateUser={this.props.updateUser}
          loadUser={this.props.loadUser}
          toggleEditModal={this.props.toggleEditModal}
          liveProfile={this.props.liveProfile}
        />
        <Certifications
          user={this.props.user}
          isCurrentUser={this.props.isCurrentUser}
          updateUser={this.props.updateUser}
          loadUser={this.props.loadUser}
          toggleEditModal={this.props.toggleEditModal}
          liveProfile={this.props.liveProfile}
        />
        <IdentityExpertise
          user={this.props.user}
          isCurrentUser={this.props.isCurrentUser}
          updateUser={this.props.updateUser}
          loadUser={this.props.loadUser}
          toggleEditModal={this.props.toggleEditModal}
          liveProfile={this.props.liveProfile}
        />
        <Languages
          user={this.props.user}
          isCurrentUser={this.props.isCurrentUser}
          updateUser={this.props.updateUser}
          loadUser={this.props.loadUser}
          toggleEditModal={this.props.toggleEditModal}
          liveProfile={this.props.liveProfile}
        />
        <Location
          user={this.props.user}
          isCurrentUser={this.props.isCurrentUser}
          updateUser={this.props.updateUser}
          loadUser={this.props.loadUser}
          toggleEditModal={this.props.toggleEditModal}
          liveProfile={this.props.liveProfile}
        />
      </div>
    )
  }
}

export default DetailsContainer
