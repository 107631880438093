import React, {useState, useEffect} from "react";

import ToggleSwitchComponent from "../components/ToggleSwitchComponent";

export default function ToggleInput(props) {
  const gap = props.gap || "4";
  const gapClass = `tw-gap-${gap}`;

  let {
    label,
    defaultValue,
    onChange,
  } = props;

  return <div className={`tw-flex tw-my-2 ${gapClass} tw-items-center tw-justify-start`}>
    <div className={`tw-flex ${gapClass}`}>
      <ToggleSwitchComponent
        checked={defaultValue}
        toggleSwitch={onChange}
      />
    </div>
    {label && <p className={props.labelClasses || "text-16"}>{label}</p>}
  </div>;
}
