export const amountCentsToStringUSD = (amount, stripDollarSign = false, stripCents = false) => {
  const amountUSD = amount / 100.0;
  const stringUSD = amountUSD.toLocaleString("en-US", {
    style: "currency", currency: "USD"
  });
  if (stripDollarSign) {
    return stringUSD.replace("$", "");
  }
  if (stripCents) {
    return stringUSD.substr(0, stringUSD.lastIndexOf("."));
  }
  return stringUSD;
};

export const stringUSDToAmountCents = (stringUSD) => {
  const amountCents = Math.round(Number(stringUSD.replace("$", "").replace(",", "")) * 100);
  return amountCents;
}
