import React, {useEffect, useState} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faUser} from "@fortawesome/free-solid-svg-icons";
import {asyncApiRequest} from "../../../utils/apiRequest";

const Status = {
  Empty: 0,
  Incomplete: 1,
  Complete: 2,
  Submitted: 3,
};

export default function MailingListContainer({userId, currentUser}) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formStatus, setFormStatus] = useState(currentUser ? Status.Complete : Status.Empty);

  const validateEmail = () => {
    var re = /\S+@\S+\.\S{2,}/;
    return re.test(email);
  };

  const validateNames = () => {
    return !!firstName && !!lastName;
  }

  useEffect(() => {
    if (!!validateEmail && validateNames()) {
      setFormStatus(Status.Complete);
    } else if (!!email) {
      setFormStatus(Status.Incomplete);
    }
  }, [email, firstName, lastName]);

  const renderNameInputs = () => {
    return (
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-gap-2 tw-p-4 tw-items-center dark-purple-text tw-min-w-0 tw-flex-1">
          <FontAwesomeIcon
            icon={faUser} size="lg"
          />
          <input
            placeholder="First name"
            className="tw-min-w-0"
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div className="tw-flex tw-gap-2 tw-p-4 tw-items-center dark-purple-text tw-min-w-0 tw-flex-1">
          <input
            placeholder="Last name"
            className="tw-min-w-0"
            onChange={e => setLastName(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const onGuestJoinClick = async () => {
    await asyncApiRequest("/create_user_contact", "POST", {
      user_id: userId,
      first_name: firstName,
      last_name: lastName,
      tags: ["Mailing list"],
      email: email,
    });
    setFormStatus(Status.Submitted);
  };

  const onUserJoinClick = async () => {
    await asyncApiRequest("/create_user_contact", "POST", {
      user_id: userId,
      email: currentUser.email,
      tags: ["Mailing list"],
    });
    setFormStatus(Status.Submitted);
  };

  const renderForm = () => {
    if (!!currentUser) {
      const enableJoinButton = currentUser.id !== userId;

      return <button
        className={"tw-p-2 tw-text-center " + (
          enableJoinButton ? "dark-purple-text" : "tw-text-gray"
        )}
        onClick={onUserJoinClick}
        disabled={!enableJoinButton}
      >Join my mailing list!</button>;
    }

    const enableJoinButton = formStatus === Status.Complete;

    return (
      <>
      <div className="tw-flex tw-gap-2 tw-p-4 tw-items-center dark-purple-text">
        <FontAwesomeIcon
          icon={faEnvelope} size="lg"
        />
        <input
          className="tw-grow"
          placeholder="email address"
          onChange={e => setEmail(e.target.value)}
        />
        <button
          className={enableJoinButton ? "dark-purple-text" : "tw-text-gray"}
          onClick={enableJoinButton ? onGuestJoinClick : undefined}
          disabled={!enableJoinButton}
        >Join</button>
      </div>
      {formStatus !== Status.Empty && renderNameInputs()}
      </>
    );
  };

  return (
    <div className="dark-purple-button-border tw-flex tw-flex-col tw-w-full">
      {(formStatus === Status.Submitted) ? (
        <p className="tw-p-2 dark-purple-text tw-text-center">See you in your inbox!</p>
      ) : renderForm()}
    </div>
  );
};
