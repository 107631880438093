import React, { useState, useEffect } from "react"
import TextInput from "../../forms/TextInput"
import Button from "../../forms/Button"
import Notice from "../../components/Notice"

export default function UpdatePassword() {
  const [showAlert, setShowAlert] = useState(false)
  const [message, setMessage] = useState()
  const [isValidPayload, setisValidPayload] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [password, setPassword] = useState({
    password: "",
    password_confirmation: ""
  })

  useEffect(() => {
    if (Object.keys(password).every((key) => password[key])) {
      setisValidPayload(true)
    } else {
      setisValidPayload(false)
    }

    if (isValidPayload && (password.password === password.password_confirmation)) {
      setIsValidPassword(true)
    } else {
      setIsValidPassword(false)
    }
  }, [password]);

  function setAlert(message) {
    setMessage(message)
    setShowAlert(true)
    setTimeout(() => setShowAlert(false), 3000)
    setPassword({
      password: "",
      password_confirmation: ""
    })
  }

  function updatePassword(payload) {
    $.ajax({
      url: `../update_user_password`,
      dataType: 'JSON',
      type: 'POST',
      data: payload,
      header: {
        'Content-Type': 'application/json'
      },
      success: (data) => {
        setAlert(data.message)
      },
      error: function (xhr, status, err) {
        setAlert(data.message)
      }.bind(this)
    });
  }

  function handleChange(key, value) {
    setPassword(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  return (
    <div className="UpdatePassword">
      {showAlert && <Notice message={message} />}
      <div className="UpdatePassword-section">
        <p className="UpdatePassword-title">New password</p>
        <TextInput
          attribute={'password'}
          placeholder=""
          type="password"
          value={password?.password}
          handleChange={handleChange}
          handleBlur={() => { }}
          className="UpdatePassword-input"
          style={{ width: '-webkit-fill-available' }}
        />
      </div>
      <div className="UpdatePassword-section">
        <p className="UpdatePassword-title">Confirm new password</p>
        <TextInput
          attribute={'password_confirmation'}
          placeholder=""
          type="password"
          value={password?.password_confirmation}
          handleChange={handleChange}
          handleBlur={() => { }}
          className="UpdatePassword-input"
          style={{ width: '-webkit-fill-available' }}
        />
      </div>
      <div className="UpdatePassword-section">
        <Button
          icon={showAlert ? "faSpinner" : ""}
          spin={showAlert}
          buttonClass={`UpdatePassword-update-btn ${isValidPayload && isValidPassword ? "" : "UpdatePassword-update-btn-disabled"}`}
          disabled={isValidPayload && isValidPassword ? false : true}
          text="Update password"
          handleClick={() => updatePassword({newPassword: password.password})}
        />
      </div>
    </div>
  )
}
