import React from "react"
import PropTypes from "prop-types"
import SelectButton from '../forms/SelectButton';

class EthnicityOnboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ethnicities: [],
      userEthnicities: [],
      user: [],
      loaded: false,
      timeout: null
    }
    this.loadEthnicities = this.loadEthnicities.bind(this)
    this.createEthnicityArray = this.createEthnicityArray.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.renderChoices = this.renderChoices.bind(this)
  }

  loadEthnicities() {
    $.ajax({
      url: `../get_ethnicities`,
      dataType: 'JSON',
      type: 'POST',
      data: {},
      header: {
        'Content-Type': 'application/json'
      },
      success: (data) => {
        this.setState({
          ethnicities: JSON.parse(data.data.ethnicities),
          loaded: true,
        }, () => this.createEthnicityArray())
      },
      error: function(xhr, status, err) {
        console.log(err)
      }.bind(this)
    });
  }

  createEthnicityArray() {
    return this.props.user.ethnicities.map((ethnicity, i) => {
      let array = this.state.userEthnicities
      if(!array.includes(ethnicity.id)){
        array.push(ethnicity.id)
        this.setState({
          userEthnicities: array
        })
      }
    })
  }

  componentDidMount() {
    this.loadEthnicities()
    this.createEthnicityArray()
  }

  handleClick(value) {
    let data = {
      ethnicity_id: value
    }
    if(this.state.userEthnicities.includes(value)) {
      //remove user_ethnicity
      $.ajax({
        url: `../remove_user_ethnicity`,
        dataType: 'JSON',
        type: 'POST',
        data: data,
        header: {
          'Content-Type': 'application/json'
        },
        success: (data) => {
        },
        error: function(xhr, status, err) {
          console.log(err)
        }.bind(this)
      });
      //remove from array
      let array = this.state.userEthnicities
      let index = array.indexOf(value);
      array.splice(index, 1);
      this.setState({
        userEthnicities: array
      })
    } else {
      //create user_ethnicity
      $.ajax({
        url: `../create_user_ethnicity`,
        dataType: 'JSON',
        type: 'POST',
        data: data,
        header: {
          'Content-Type': 'application/json'
        },
        success: (data) => {
        },
        error: function(xhr, status, err) {
          console.log(err)
        }.bind(this)
      });
      //add to array
      let array = [...this.state.userEthnicities, value]
      this.setState({
        userEthnicities: array
      })
    }
  }

  renderChoices() {
    return this.state.ethnicities.map((ethnicity, i) => {
      return(
        <div style={{paddingTop: 10}} key={i}>
          <SelectButton
            text={ethnicity.name}
            value={ethnicity.id}
            selected={this.state.userEthnicities.includes(ethnicity.id)}
            onClick={this.handleClick}
          />
        </div>
      )
    })
  }

  render () {
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{paddingBottom: 10}}>What is your race or ethnicity?</h3>
          <h4 className="standard_label" style={{paddingBottom: 40, opacity: .5}}>Select as many as apply</h4>
          {this.renderChoices()}
        </div>
      </React.Fragment>
    );
  }
}

export default EthnicityOnboard
