import React from "react";
import PropTypes from "prop-types";
import TextInput from "../forms/TextInput";

class ContactOnboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      loaded: false,
      venmo: "",
      instagram: "",
      facebook: "",
      twitter: "",
      youtube: "",
      website: "",
      tiktok: "",
      timeout: null,
    };
    this.updateUser = this.updateUser.bind(this);
  }

  componentDidMount() {
    this.setState({
      venmo: this.props.currentUser.venmo === null ? "" : this.props.currentUser.venmo,
      facebook:
        this.props.currentUser.facebook === null ? "" : this.props.currentUser.facebook,
      twitter: this.props.currentUser.twitter === null ? "" : this.props.currentUser.twitter,
      youtube: this.props.currentUser.youtube === null ? "" : this.props.currentUser.youtube,
      instagram:
        this.props.currentUser.instagram === null ? "" : this.props.currentUser.instagram,
      website: this.props.currentUser.website === null ? "" : this.props.currentUser.website,
      tiktok: this.props.currentUser.tiktok === null ? "" : this.props.currentUser.tiktok,
    });
  }

  updateUser(attribute, value) {
    this.setState({
      [attribute]: value,
    });

    const { timeout } = this.state;
    clearTimeout(timeout);
    this.setState({
      timeout: setTimeout(() => {
        let data = {
          venmo: this.state.venmo,
          instagram: this.state.instagram,
          facebook: this.state.facebook,
          twitter: this.state.twitter,
          youtube: this.state.youtube,
          website: this.state.website,
          tiktok: this.state.tiktok,
        };
        this.props.updateUser(data);
      }, 500),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{ paddingBottom: 40 }}>
            Where can people find you on the Internet?
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h4
              className="standard_label"
              style={{ width: 150, color: "#232424" }}
            >
              Website
            </h4>
            <TextInput
              type="text"
              autofocus={true}
              value={this.state.website}
              attribute={"website"}
              handleChange={this.updateUser}
              placeholder="http://"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h4
              className="standard_label"
              style={{ width: 150, color: "#232424" }}
            >
              Instagram
            </h4>
            <div className="input--onboard">
              <span className="contact_onboarding_at">@</span>
              <TextInput
                type="text"
                autofocus={true}
                value={this.state.instagram}
                attribute={"instagram"}
                handleChange={this.updateUser}
                placeholder="handle"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h4
              className="standard_label"
              style={{ width: 150, color: "#232424" }}
            >
              Twitter
            </h4>
            <div className="input--onboard">
              <span className="contact_onboarding_at">@</span>
              <TextInput
                type="text"
                autofocus={true}
                value={this.state.twitter}
                attribute={"twitter"}
                handleChange={this.updateUser}
                placeholder="handle"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h4
              className="standard_label"
              style={{ width: 150, color: "#232424" }}
            >
              TikTok
            </h4>
            <div className="input--onboard">
              <span className="contact_onboarding_at">@</span>
              <TextInput
                type="text"
                autofocus={true}
                value={this.state.tiktok}
                attribute={"tiktok"}
                handleChange={this.updateUser}
                placeholder="handle"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h4
              className="standard_label"
              style={{ width: 150, color: "#232424" }}
            >
              YouTube
            </h4>
            <div className="input--onboard">
              <span className="contact_onboarding_at">@</span>
              <TextInput
                type="text"
                autofocus={true}
                value={this.state.youtube}
                attribute={"youtube"}
                handleChange={this.updateUser}
                placeholder="handle"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h4
              className="standard_label"
              style={{ width: 150, color: "#232424" }}
            >
              Facebook
            </h4>
            <div className="input--onboard">
              <span className="contact_onboarding_at">@</span>
              <TextInput
                type="text"
                autofocus={true}
                value={this.state.facebook}
                attribute={"facebook"}
                handleChange={this.updateUser}
                placeholder="handle"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h4
              className="standard_label"
              style={{ width: 150, color: "#232424" }}
            >
              Venmo
            </h4>
            <div className="input--onboard">
              <span className="contact_onboarding_at">@</span>
              <TextInput
                type="text"
                autofocus={true}
                value={this.state.venmo}
                attribute={"venmo"}
                handleChange={this.updateUser}
                placeholder="handle"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactOnboard;
