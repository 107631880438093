import React, { useState, useEffect } from "react";
import StepsIndexContext from "../context/StepsIndexContext";
import EventContext from "../context/EventContext";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faExternalLinkAlt,
  faEllipsisH,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import ErrorBoundary from "../components/ErrorBoundary";
import EventTheBasicsContent from "../components/EventTheBasicsContent";
import EventTheSparkleContent from "../components/EventTheSparkleContent";
import EventTheDeetsContent from "../components/EventTheDeetsContent";
import SubMenuModal from "../components/SubMenuModal";

function CreateEventContainer(props) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const eventInitialState = {
    id: id,
    title: "",
    status: "DRAFT",
    date_start_time: "",
    date_end_time: "",
    summary: "",
    topics: "",
    event_url: "",
    event_place: {
      title: "",
      url: "",
    },
    info: null,
    amount: 0,
    income_received: null,
    event_limit: 0,
    thumbnail: null,
    created_at: "",
    updated_at: "",
    place_public: false,
  };
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [event, setEvent] = useState(eventInitialState);
  const [openSubMenu, setOpenSubMenu] = useState();
  const steps = [
    {
      id: 0,
      title: "The Basics",
      content: (
        <EventTheBasicsContent
          user={props.user}
          updateUserEvent={(field, value) => updateUserEvent(field, value)}
        />
      ),
    },
    {
      id: 1,
      title: "The Deets",
      content: (
        <EventTheDeetsContent
          user={props.user}
          updateUserEvent={(field, value) => updateUserEvent(field, value)}
        />
      ),
    },
    {
      id: 2,
      title: "The Sparkle",
      content: (
        <EventTheSparkleContent
          user={props.user}
          updateUserEvent={(field, value) => updateUserEvent(field, value)}
        />
      ),
    },
  ];

  const getUserEvent = (id) => {
    $.ajax({
      url: `/get_user_event`,
      dataType: "JSON",
      type: "POST",
      data: { id: id, user_id: props.user.id },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        const [{ ...event }] = JSON.parse(data.event);
        setEvent(event);
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const maskString = (value) => {
    return value.toLowerCase().replace(/[^a-z0-9-]/g, "-");
  };

  const updateUserEvent = (field, value) => {
    let data = {};
    if (field === "title" && !event?.event_url) {
      data.event_url = maskString(value);
    }

    if (event?.id) {
      data.id = event?.id;
    }
    data[field] = value;

    if (event?.id) {
      $.ajax({
        url: `/update_user_event`,
        dataType: "JSON",
        type: "POST",
        data: data,
        header: { "Content-Type": "application/json" },
        success: (data) => {
          return data;
        },
        error: (xhr, status, err) => {
          return err;
        },
      });
    } else {
      data.status = "DRAFT";
      $.ajax({
        url: `/create_user_event`,
        dataType: "JSON",
        type: "POST",
        data: data,
        header: { "Content-Type": "application/json" },
        success: (data) => {
          setEvent(data.data.event);
        },
        error: (xhr, status, err) => {
          return err;
        },
      });
    }
  };

  const handleArchive = (event_id) => {
    $.ajax({
      url: `/update_user_event`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: event_id,
        status: "DRAFT",
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        window.location.assign("/dashboard/list-event");
        return data;
      },
      error: (xhr, status, err) => {
        return err;
      },
    });
  };

  const handleDelete = (event_id) => {
    $.ajax({
      url: `/delete_user_event`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: event_id,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        window.location.assign("/dashboard/list-event");
        return data;
      },
      error: (xhr, status, err) => {
        setMessage(JSON.parse(xhr?.responseText)?.message || "Internal error");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      },
    });
  };

  const renderSubMenu = (event) => {
    const item = {
      id: event.id,
      status: event.status,
      income_received: event.income_received,
      mouseX: event.mouseX,
      mouseY: event.mouseY,
    };

    return (
      <SubMenuModal
        item={item}
        handleDelete={handleDelete}
        handleArchive={handleArchive}
        handleClose={() => setOpenSubMenu(null)}
      />
    );
  };

  useEffect(() => {
    if (id && event === eventInitialState) {
      getUserEvent(id);
    }
  }, [id]);

  useEffect(() => {
    props.onFinish();
  }, []);

  return (
    <div className="createContainer">
      {openSubMenu && renderSubMenu(openSubMenu)}
      <div className="createHeaderContainer">
        <div className="stackableContainerStyle">
          <h1>Create an Event</h1>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="view--live--button"
            onClick={() => {
              window.location.assign(
                `/dashboard/send-message?event=${event.id}`
              );
            }}
          >
            <FontAwesomeIcon icon={faPaperPlane} style={{ color: "#733DFF" }} />
            <span>Send a message</span>
          </div>
          {event.status === "PUBLISHED" && (
            <div
              className="view--live--button"
              onClick={() => {
                const url = `//${props.user?.slug}.${process.env.ROOT_DOMAIN}/e/${event?.event_url}`;
                window.open(url, "_blank");
              }}
            >
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ color: "#733DFF" }}
              />
              <span>View live</span>
            </div>
          )}

          <FontAwesomeIcon
            icon={faEllipsisH}
            style={{ color: "#733dff", marginRight: "15px" }}
            onMouseDown={(e) => {
              setOpenSubMenu({
                ...event,
                mouseX: e.clientX,
                mouseY: e.clientY,
              });
            }}
          />
          <div
            className="xButton"
            onClick={() => {
              navigate("/dashboard/create/list-events");
              props.refreshUserData();
            }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ color: "#fff" }} />
          </div>
        </div>
      </div>
      <div className="createEventContent">
        <EventContext.Provider value={[event, setEvent]}>
          <StepsIndexContext.Provider value={[step, setStep]}>
            <div className={"createEventContentSteps" + (event?.id ? "" : " disabled")}>
              {steps.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className="stepTitles">
                      <p>{item.title}</p>
                      <div
                        className={
                          index <= step
                            ? "stepMarker stepMarkerDone"
                            : "stepMarker"
                        }
                        onClick={() => {
                          setStep(index);
                          document
                            .getElementById(`step${index}`)
                            .scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                        }}
                      />
                    </div>
                    <div
                      className={
                        index <= step - 1
                          ? "separator separatorDone"
                          : "separator"
                      }
                    ></div>
                  </div>
                );
              })}
            </div>
            <div>
              {steps?.map((step, index) => {
                return <div key={index}>{step.content}</div>;
              })}
            </div>
          </StepsIndexContext.Provider>
        </EventContext.Provider>
      </div>
    </div>
  );
}

export default function CreateEventContainerEB(props) {
  return <ErrorBoundary>
    <CreateEventContainer {...props} />
  </ErrorBoundary>;
}
