import React, {useEffect, useState} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelopeOpen,
  faTimes,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {asyncApiRequest} from "../../utils/apiRequest";

function SearchResults(results) {
  // upcoming: build results view
  return;
}

export function ClientThanks(_) {
  return <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center">
    <p style={{fontSize: "3rem", lineHeight: "normal"}}>{"💌"}</p>
    <p className="tw-font-bold dark-accent-text tw-text-4xl tw-text-wrap">{
      "Watch your email!"
    }</p>
    <p className="font-16 tw-font-semibold">{
      "We'll be in touch when we find a good match."
    }</p>
    <p className="font-16 tw-font-semibold">{
      "Thanks for signing up!"
    }</p>
    <button className="dark-accent-button" onClick={
      () => window.location.assign("https://instagram.com/tabuwellness")
    }>Follow us on IG!</button>
  </div>;
}


const onSubmit = (email, name, cids, l, gids, eids, ieids) => {
  if (!email || !validateEmail(email)) {
    return Promise.reject("No email provided");
  }
  return asyncApiRequest(
    "/create_client_user", "POST", {
      email: email,
      name: name,
      cids: cids,
      l: l,
      gids: gids,
      eids: eids,
      ieids: ieids,
    }
  );
};

const validateEmail = (email) => {
  var re = /\S+@\S+\.\S{2,}/;
  return re.test(email);
};

function EmailIcon({email}) {
  const getIcon = () => {
    if (!email) {
      return {
        icon: faTimes,
        color: "rgba(0, 0, 0, 0)",
      }
    }
    if (!validateEmail(email)) {
      return {
        icon: faTimes,
        color: "#fcc"
      };
    }
    return {icon: faCheck, color: "rgba(0, 128, 0, 0.3)"};
  };

  const icon = getIcon();

  return <FontAwesomeIcon
    icon={icon.icon}
    style={icon.color && {color: icon.color}}
    size="xs"
  />;
}

export default function ClientSearch(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [submitted, setSubmitted] = useState(false);

  if (!!props.results) {
    return SearchResults(props.results);
  }

  const containerClasses = (
    "tw-w-full tw-flex tw-flex-col tw-gap-3 tw-items-center " +
    "md:tw-max-w-md tw-rounded-lg tw-bg-white tw-p-8 tw-box-border "
  );

  const inputClasses = (
    "light-accent-border tw-flex tw-items-center tw-gap-2 tw-p-2 " +
    "tw-grow"
  );

  const renderForm = () => (
    <>
    <p className="tw-font-bold dark-text tw-text-4xl tw-text-wrap">{
      "We're working on ourselves, too."
    }</p>
    <p className="font-16 tw-font-semibold">{
      "There aren't any providers matching your search–yet!"
    }</p>
    <p className="font-16 tw-font-semibold">{
      "How can we reach you once we've found a match?"
    }</p>
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      <div className={inputClasses}>
        <FontAwesomeIcon
          icon={faUser}
          className="dark-accent-text"
          size="xs"
        />
        <input
          style={{flexGrow: 1}}
          placeholder="Name"
          onChange={x => setName(x.target.value)}
        />
      </div>
      <div className={inputClasses}>
        <FontAwesomeIcon
          icon={faEnvelopeOpen}
          className="dark-accent-text"
          size="xs"
        />
        <input
          style={{flexGrow: 1}}
          placeholder="Email"
          onChange={x => setEmail(x.target.value)}
        />
        <EmailIcon email={email} />
      </div>
    </div>
    <button
      onClick={() => onSubmit(email, name, props.concernIds, props.location, props.genderIds, props.ethnicityIds, props.identityExpertiseIds).then(setSubmitted(true))}
      className="dark-accent-button">
      Let me know!
    </button>
    </>
  );

  return <div className={containerClasses}>
    <FontAwesomeIcon icon={faSearch} size="4x" className="accent-text" />
    {submitted ? renderThanks() : renderForm() }
  </div>;
}
