import React, { useContext, useState, useEffect } from "react";
import PdfFile from "../../../assets/images/pdf-file.png";
import AudioFile from "../../../assets/images/audio-file.png";
import { apiBase } from "../../utils/apiRequest";
import FilesDragAndDrop from "./FilesDragAndDrop";
import FloatingButton from "./FloatingButton";
import FileMetadataContext from "../context/FileMetadataContext";
import StepsIndexContext from "../context/StepsIndexContext";
import UserDownloadContext from "../context/UserDownloadContext";
import { get } from "jquery";
import InputMask from "react-input-mask";

/*
WHEN WE LOAD THE FILE (TITLE & SIZE) FROM THE DATABASE,
update the FilesDragAndDrop component with the metadata

WHEN THE FilesDragAndDrop 
*/

export default function TheGoodsContent(props) {
  const [fileMetadata, setFileMetadata] = useState(null);
  const [step, setStep] = useContext(StepsIndexContext);
  const [userDownload, setUserDownload] = useContext(UserDownloadContext);
  const [kindOfContent, setKindOfContent] = useState('');
  const [kindOfContentState, setKindOfContentState] = useState([
    {
      id: 0,
      title: "File",
      content: "ex. e-book, worksheet, guide, checklist",
      icon: PdfFile,
      isSelected: false,
      accept: "*/*",
    },
    {
      id: 1,
      title: "Audio",
      content: "e.g., mini lesson, guided meditation",
      icon: AudioFile,
      isSelected: false,
      accept: "audio/*",
    },
  ]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  }

  const deleteRemoteFile = async () => {
    await apiBase("../delete_download_file", "POST", {
      id: userDownload.id,
    });
  }

  // when the file metadata is loaded from the database,
  // or a new file is uploaded, update the UI state.
  useEffect(() => {
    if (!userDownload) {
      console.log("cleared file metadata");
      setFileMetadata(null);
      return;
    }

    const meta = {
      name: userDownload?.download_file_title,
      size: userDownload?.file_size,
      pages: userDownload?.number_of_pages,
      duration: userDownload?.time_duration,
    };

    console.log("loaded file metadata:", meta);
    setFileMetadata(meta);
  }, [
    userDownload?.download_file_title,
    userDownload?.file_size,
    userDownload?.number_of_pages,
    userDownload?.time_duration
  ]);

  const postFile = async (file, id, title, size) => {
    var formData = new FormData();
    formData.append("file", file);
    // remove legacy download_file
    formData.append("download_file", null);
    formData.append("download_file_title", title);
    formData.append("file_size", size);
    formData.append("id", id);

    $.ajax({
      url: "../update_user_download",
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        console.log(data);
        const [{ ...download }] = JSON.parse(data.download);

        setUserDownload((prevUserDownload) => {
          return {
            ...prevUserDownload,
            thumb_url: download?.thumb_url,
          };
        });
      },
    });
  }

  const onUploadFile = async (file) => {
    if (!file) {
      // clear the file metadata UI state after deleting the file
      console.log("deleting remote file");
      deleteRemoteFile();
      setUserDownload((prevUserDownload) => {
        return {
          ...prevUserDownload,
          download_file: null,
          download_file_title: null,
          file_size: null,
        };
      });
      return;
    }

    if (!userDownload?.id) {
      console.error("no user download ID populated before attempting to upload file");
      return;
    }

    // update metadata in database
    const size = Math.round(file.size / 1024);
    const title = file.path ?? file.name;
    // update database
    postFile(file, userDownload.id, title, size);
    // update UI state - the 'UserDownloadContext' modification will be propagated back down to
    // 'FileMetadataContext' in the above 'useEffect' hook.
    console.log(`uploaded new file: '${title}' (${size})`);
    await setUserDownload((prevUserDownload) => {
      return {
        ...prevUserDownload,
        download_file_title: title,
        file_size: size,
      }
    });
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    if (userDownload?.kind_content) {
      setKindOfContent(userDownload?.kind_content);
      setKindOfContentState((prevKind) =>
        prevKind.map((item) =>
          item.title === userDownload?.kind_content
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        )
      );
    }
  }, [userDownload?.kind_content]);

  async function handleKindOfContentSelect(name) {
    props.updateUserDownload("kind_content", name);
    setKindOfContent(name);
    await setUserDownload((prevUserDownload) => {
      return {
        ...prevUserDownload,
        kind_content: name,
      };
    });
    // when changing the kind of content, delete the previous file
    onUploadFile(null);
  }

  async function handleNextButton() {
    setStep(1);
  }

  const handleOnChangeInput = (value, target) => {
    setUserDownload((prevUserDownload) => {
      return {
        ...prevUserDownload,
        [target]: value,
      };
    });
  };

  const maskInteger = (value) => {
    return value.replace(/[^$0-9\.]/g, "");
  };

  const timeMask = "12:34";
  const formatCharsTime = {
    1: "[0-9]",
    2: "[0-9]",
    3: "[0-5]",
    4: "[0-9]",
  };

  return (
    <div className="stepContent">
      <h2 id="step0">What kind of content?</h2>
      <div className="contentBody">
        {kindOfContentState.map((item) => {
          return (
            <div
              key={item.id}
              className={
                item.isSelected ? "theGoodsCard selected" : "theGoodsCard"
              }
              onClick={() => handleKindOfContentSelect(item.title)}
            >
              <div>
                <img src={item.icon} className="theGoodsImage" />
              </div>
              <div className="theGoodsContent">
                <h2>{item.title}</h2>
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <h2>Upload your download!</h2>
        <p>Max file size: 5 GB</p>
        <FileMetadataContext.Provider value={[fileMetadata, setFileMetadata]}>
          <div>
            <FilesDragAndDrop
              acceptedFiles={kindOfContentState.find(
                (item) => item.isSelected === true
              )}
              onUploadFile={onUploadFile}
            />
          </div>
          {kindOfContent === "File" && (
            <div className="number--pages">
              <h2>Number of pages</h2>
              <input
                placeholder="0"
                value={fileMetadata?.pages || ""}
                disabled={!fileMetadata}
                onChange={(e) =>
                  handleOnChangeInput(
                    maskInteger(e.target.value),
                    "number_of_pages"
                  )
                }
                onBlur={(e) =>
                  props.updateUserDownload(
                    "number_of_pages",
                    e.target.value
                  )
                }
              />
            </div>
          )}
          {kindOfContent === "Audio" && (
            <div className="time--duration">
              <h2>Time duration</h2>
              <InputMask
                placeholder="00:00"
                mask={timeMask}
                value={fileMetadata?.duration || ""}
                disabled={!fileMetadata}
                formatChars={formatCharsTime}
                onChange={(e) =>
                  handleOnChangeInput(e.target.value, "time_duration")
                }
                onBlur={(e) =>
                  props.updateUserDownload("time_duration", e.target.value)
                }
              ></InputMask>
            </div>
          )}
        </FileMetadataContext.Provider>
      </div>
    </div>
  );
}
