import React, {useState} from "react"
import PropTypes from "prop-types"

const SelectTextInput = props => {
  const getEmptyClass = () => "onboard_select_text_field";
  const getFullClass = () => props.classWhenNonEmpty ?
    `${getEmptyClass()} ${props.classWhenNonEmpty}` :
    getEmptyClass();

  const [className, setClassName] = useState(props.defaultValue ? getFullClass() : getEmptyClass());
  
  return (
    <input
      className={className}
      type={props.type}
      autoFocus={props.autofocus ? "autofocus" : ""}
      defaultValue={props.defaultValue}
      value={props.value}
      placeholder={props.placeholder}
      onChange={(event) => {
        setClassName(event.target.value ? getFullClass() : getEmptyClass());
        props.handleChange(event.target.value);
      }}
    ></input>
  );
}

export default SelectTextInput;
