import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import NavBar from "../components/NavBar";
import NavBarDownlodas from "../components/NavBarDownlodas";
import DownloadRow from "../components/DownloadRow";
import PaymentModal from "../payment_modal/PaymentModal";
import Notice from "../components/Notice";
import UserCard from "../components/UserCard";
import Celebration from "../components/Celebration";

import {ProductType, ProductSpec} from "../../utils/product";
import {apiBase} from "../../utils/apiRequest";

import parseHtml from "html-react-parser";

export default function DownloadsContainer(props) {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [downloads, setDownloads] = useState([]);
  const [myDownloads, setMyDownloads] = useState();
  const [downloadIndex, setDownloadIndex] = useState(0);
  const [paymentModalState, setPaymentModalState] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const celebration = query.get("celebration");
  const [celebrationState, setCelebrationState] = useState(celebration);

  const handleBuy = (item) => {
    setPaymentModalState({
      show: true,
      productSpec: new ProductSpec(ProductType.Download, item.id),
      downloadTitle: item.download_title,
    });
  };

  const getUserDownload = (id) => {
    return new Promise((resolve, reject) => {
      const endpoint = "/get_user_download";
      $.ajax({
        url: endpoint,
        dataType: "JSON",
        type: "POST",
        data: { id: id, user_id: props.user?.user?.id },
        header: { "Content-Type": "application/json" },
        success: (data) => {
          const [{ ...download }] = JSON.parse(data.download);

          resolve(download);
        },
        error: (xhr, status, err) => {
          reject(err);
        },
      });
    });
  };

  const getMyDownloads = () => {
    $.ajax({
      url: `../get_my_downloads`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        setMyDownloads(JSON.parse(data.downloads));
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  };

  const getDownlodasFile = async (downloads) => {
    let downloadArray = [];
    const downloadsByID = downloads.filter(
      (download) => download.download_url === props.downloadUrl
    );
    // note: this ternary defaults to displaying all downloads in case the
    // provided URL at /d/download_url doesn't match any of the downloads owned
    // by this profile
    const filteredDownloads =
      downloadsByID.length > 0 ? downloadsByID : downloads;
    for (let i = 0; i < filteredDownloads.length; i++) {
      const element = filteredDownloads[i];

      const responseData = await getUserDownload(element.id);

      downloadArray.push({
        ...element,
        ...responseData,
      });
    }

    setDownloads(downloadArray);
  };

  const getUserDownloads = () => {
    const endpoint = "/get_user_downloads";
    $.ajax({
      url: endpoint,
      dataType: "JSON",
      type: "POST",
      data: {
        user_id: props.user?.user?.id,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        const downloadArray = JSON.parse(data.downloads);
        getDownlodasFile(
          downloadArray.filter((download) => {
            return download?.download_status === "PUBLISHED";
          })
        );
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const loadCurrentUser = async () => {
    const response = await apiBase("/get_current_user", "GET");
    let user = response?.data?.user && JSON.parse(response.data.user);
    if (user) {
      setCurrentUser(user);
      getMyDownloads();
    }
  };

  useEffect(() => {
    loadCurrentUser();
    getUserDownloads();

    if (celebrationState) {
      setTimeout(() => {
        setCelebrationState(false);
      }, 5000);
    }
  }, []);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const renderTags = (downloadTags) => {
    if (downloadTags) {
      return (
        <div className="tags--content">
          <h2>Topics</h2>
          {downloadTags?.map((tag, i) => {
            return (
              <p
                className="tags--download"
                style={{ marginBottom: 8, fontSize: "14px" }}
                key={i}
              >
                {tag}
              </p>
            );
          })}
        </div>
      );
    }
  };

  const handleNextButton = () => {
    setDownloadIndex(downloadIndex + 1);
  };

  const renderModalFinale = (chargeCode) => {
    if (!paymentModalState.downloadTitle) {
      return <p>Something went wrong loading your content. Please refresh the page.</p>
    }
    return (
      <button
        style={{marginTop: 20}}
        className={"profile_overview_button_primary_less"}
        onClick={() => {window.open(`/download_content?charge_code=${chargeCode}`)}}
      >
        <p style={{color: "white"}}>
          Download <i>{paymentModalState.downloadTitle}</i>
        </p>
      </button>
    );
  }

  const handleBackButton = () => {
    setDownloadIndex(downloadIndex - 1);
  };

  const onPaymentSuccess = (modalState, chargeCode) => {
    let myNewDownloads = myDownloads || [];
    let myCopy = downloads.filter((download) =>
      download.id === modalState.productSpec.id
    );
    if (!myCopy) {
      console.log(`couldn't find download ID ${paymentModalState.productSpec.id}`);
      return;
    }
    myCopy[0].file_url = `/download_content?charge_code=${chargeCode}`;
    myNewDownloads.push(myCopy[0]);
    setMyDownloads(myNewDownloads)
  };

  return (
    <div className="content--body">
      <div className="downloadPage">
        {celebrationState && <Celebration />}
        {showAlert && <Notice message={message} />}
        {currentUser && <NavBar user={currentUser} />}
        {currentUser && <div className="separator" />}
        {paymentModalState?.show && (
          <PaymentModal
            productSpec={paymentModalState.productSpec}
            currentUser={currentUser}
            renderModalFinale={renderModalFinale}
            onClose={() => {
              setPaymentModalState({show: false});
              loadCurrentUser();
            }}
            onSuccess={(chargeCode) => {onPaymentSuccess(paymentModalState, chargeCode)}}
          />
        )}
        {downloads?.length > 0 && (
          <div>
            <DownloadRow
              downloads={downloads}
              isCurrentUser={props.isCurrentUser}
              myDownloads={myDownloads}
              handleNextButton={handleNextButton}
              handleBackButton={handleBackButton}
              handleBuy={handleBuy}
            />
          </div>
        )}
        <div className="about">
          <div className="about--download">
            <h2>Description</h2>
            {downloads?.length > 0 && (
              <div className="about--download--info">
                {downloads[downloadIndex]?.download_info && parseHtml(downloads[downloadIndex]?.download_info)}
              </div>
            )}
            {downloads[downloadIndex]?.download_tags &&
              renderTags(
                typeof downloads[downloadIndex]?.download_tags === "string"
                  ? JSON.parse(downloads[downloadIndex]?.download_tags)
                  : downloads[downloadIndex]?.download_tags
              )}
          </div>
          <div className="about--therapist">
            <h2>Created by</h2>
            <Link to={`/`}>
              <UserCard user={props.user?.user}/>
            </Link>
          </div>
        </div>
      </div>
      <div className="download--footer">
        {!currentUser && <NavBarDownlodas />}
      </div>
    </div>
  );
}
