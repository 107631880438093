import React, {useState, useEffect} from "react";

import {asyncApiRequest} from "../../../utils/apiRequest";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

export const getBioPairs = async (bio) => {
  const resp = await asyncApiRequest("/get_bio_prompts");
  const prompts = JSON.parse(resp.data.bio_prompts);
  const pairs = [];
};

export default function BioPrompts({bio}) {
  const [prompts, setPrompts] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [page, setPage] = useState(0);

  const fetchBioPrompts = async () => {
    const resp = await asyncApiRequest("/get_bio_prompts");
    setPrompts(JSON.parse(resp.data.bio_prompts));
  };

  useEffect(() => {
    fetchBioPrompts();
  }, []);

  useEffect(() => {
    const newPairs = [];
    Object.keys(prompts).forEach((i) => {
      const prompt = prompts[i];
      const entry = bio[prompt.id];
      if (entry) {
        newPairs.push({
          prompt: prompt.prompt,
          response: entry,
        });
      }
    });
    setPairs(newPairs);
  }, [prompts, bio]);

  if (pairs.length === 0) {
    return null;
  }

  const nextPage = () => setPage((page + 1) % pairs.length);
  const prevPage = () => setPage(page == 0 ? pairs.length - 1 : page - 1);

  const renderPageButtons = () => {
    return <div className="tw-flex tw-justify-between tw-items-center">
      <button className="dark-accent-background tw-p-1 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center"
        style={{borderRadius: "40px", backgroundColor: (prevPage ? null : "lightgray")}}
        onClick={prevPage}>
        <FontAwesomeIcon icon={faArrowLeft} className="tw-text-white" size="xs" />
      </button>
      <button className="dark-accent-background tw-p-1 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center"
        style={{borderRadius: "40px", backgroundColor: (nextPage ? null : "lightgray")}}
        onClick={nextPage}>
        <FontAwesomeIcon icon={faArrowRight} className="tw-text-white" size="xs" />
      </button>
    </div>;
  }

  return <div className={
    "tw-flex tw-flex-col tw-p-4 tw-rounded-lg light-accent-background dark-shadow " +
    "dark-border tw-gap-2"
  }>
    <p
      style={{fontSize: "22px"}} className="tw-font-bold tw-leading-snug"
    >{pairs[page].prompt}</p>
    <p
      className="tw-font-semibold font-16 tw-leading-normal"
    >{pairs[page].response}</p>
    {renderPageButtons()}
  </div>
}
