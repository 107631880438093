import React, {useEffect, useState} from "react";

import CurrencyInput from "../../components/CurrencyInput";
import EditLocation from "../../profile_forms/EditLocation";
import MultiSelectForm from "../../forms/MultiSelectForm";
import ResponsiveModal from "../../components/ResponsiveModal";
import TextInput from '../../forms/TextInput';
import TypeOrSelectForm from "../../forms/TypeOrSelectForm";

import {amountCentsToStringUSD, stringUSDToAmountCents} from "../../../utils/currency";

import {
  mutateCurrentUserApproachStyles,
  mutateCurrentUserEthnicities,
  mutateCurrentUserGenders,
  mutateCurrentUserInsurances,
  saveUserChanges,
  updateCurrentUser,
} from "../../../queries/user";
import {
  getApproachStyles,
  getEthnicities,
  getGenders, 
  getIdentityExpertises,
  getInsurances,
  getSpecialties,
} from "../../../queries/user_attributes";

import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

function NextButton({nextPage, text}) {
  return <button
    onClick={nextPage}
    className="dark-accent-button tw-w-full md:tw-w-fit"
    style={{minWidth: "9rem"}}
  >{text || "Next"}</button>;
}

function BackButton({prevPage}) {
  return <button
    onClick={prevPage}
    className="dark-accent-white-button tw-w-full md:tw-w-fit"
    style={{minWidth: "9rem"}}
  >Back</button>;
}

export default function MarketplacePrefsModal({user, close}) {
  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(parseInt(params.get("modalPage")) || 0);
  const [approachStyles, setApproachStyles] = useState([]);
  const [genders, setGenders] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [identityExpertises, setIdentityExpertises] = useState([]);
  const [ethnicities, setEthnicities] = useState([]);

  useEffect(() => {
    (async () => {
      getApproachStyles().then((resp) => setApproachStyles(JSON.parse(resp.data.approach_styles)));
    })();
    (async () => {
      getGenders().then((resp) => setGenders(JSON.parse(resp.data.genders)));
    })();
    (async () => {
      getSpecialties().then((resp) => setSpecialties(JSON.parse(resp.data.specialties)));
    })();
    (async () => {
      getIdentityExpertises().then((resp) => setIdentityExpertises(JSON.parse(resp.data.identity_expertises)));
    })();
    (async () => {
      getEthnicities().then((resp) => setEthnicities(JSON.parse(resp.data.ethnicities)));
    })();
  }, []);

  const queryClient = useQueryClient();

  const nextPage = () => setPage(page + 1);
  const prevPage = () => setPage(page - 1);

  const pageContainerStyles = "tw-flex tw-flex-col tw-items-start tw-gap-3 tw-w-full md:tw-px-8 tw-box-border";
  const headingStyles = "tw-text-4xl tw-text-wrap tw-font-bold dark-text";
  const labelStyles = "tw-font-semibold text-14 dark-text";
  const subLabelStyles = "text-12 dark-text";

  const insuranceQuery = useQuery({
    queryKey: ["insurances"],
    queryFn: getInsurances
  });

  const userApproachStylesMutation = useMutation({
    mutationFn: mutateCurrentUserApproachStyles,
    onSuccess: () => {
      queryClient.invalidateQueries(
        {queryKey: ["current_user"]}
      );
    },
  });

  const userGendersMutation = useMutation({
    mutationFn: mutateCurrentUserGenders,
    onSuccess: () => {
      queryClient.invalidateQueries(
        {queryKey: ["current_user"]}
      );
    },
  });

  const userEthnicitiesMutation = useMutation({
    mutationFn: mutateCurrentUserEthnicities,
    onSuccess: () => {
      queryClient.invalidateQueries(
        {queryKey: ["current_user"]}
      );
    },
  });

  const userInsurancesMutation = useMutation({
    mutationFn: mutateCurrentUserInsurances,
    onSuccess: () => {
      queryClient.invalidateQueries(
        {queryKey: ["current_user"]}
      );
    },
  });

  const renderApproachPage = () => {
    return <div className={pageContainerStyles}>
      <p className={headingStyles}>First things first...</p>
      <p className={labelStyles}>How would you describe your approach/styles?</p>
      <p className={subLabelStyles}>Select your top two adjectives so we can help narrow down matches!</p>
      <div className="tw-w-full">
        <MultiSelectForm
          onSelect={(id, selected) => {
            userApproachStylesMutation.mutate({
              action: selected ? "delete" : "create",
              payload: {id: id},
            })
          }}
          choices={approachStyles}
          selections={user.approach_styles || []}
        />
      </div>
      <NextButton nextPage={nextPage} />
    </div>;
  };

  const renderIdentityPage = () => {
    return <div className={pageContainerStyles}>
      <p className={headingStyles}>Now moving onto your identity</p>
      <p className={labelStyles}>What is your gender?</p>
      <p className={subLabelStyles}>Select as many as apply</p>
      <div className="tw-w-full">
        <MultiSelectForm
          onSelect={(id, selected) => {
            userGendersMutation.mutate({
              action: selected ? "delete" : "create",
              payload: {gender_id: id},
            })
          }}
          choices={genders}
          onCustomEntry={(gender) => {
            updateCurrentUser({additional_gender: gender});
          }}
          customEntry={user.additional_gender}
          selections={user.genders || []}
          placeholder="somethin' else"
        />
      </div>
      <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
        <BackButton prevPage={prevPage} />
        <NextButton nextPage={nextPage} />
      </div>
    </div>;
  };

  const renderEthnicityPage = () => {
    return <div className={pageContainerStyles}>
      <p className={headingStyles}>Now moving onto your identity</p>
      <p className={labelStyles}>What is your ethnicity?</p>
      <p className={subLabelStyles}>Select as many as apply</p>
      <div className="tw-w-full tw-my-1">
        <MultiSelectForm
          onSelect={(id, selected) => {
            userEthnicitiesMutation.mutate({
              action: selected ? "delete" : "create",
              payload: {ethnicity_id: id},
            })
          }}
          choices={ethnicities}
          selections={user.ethnicities || []}
          placeholder="somethin' else"
        />
      </div>
      <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
        <BackButton prevPage={prevPage} />
        <NextButton nextPage={nextPage} />
      </div>
    </div>;
  };

  const renderLocationPage = () => {
    return <div className={pageContainerStyles}>
      <p className={headingStyles}>Location</p>
      <EditLocation
        containerStyles="tw-w-full"
        location={user.user_locations[0] || {}}
        changeLocation={(k, v) => {
          const prevLocation = user.user_locations?.length ? user.user_locations[0] : {};
          const newLocation = {...prevLocation};
          newLocation[k] = v;
          saveUserChanges({
            user_locations: [newLocation],
          }).then(() => queryClient.invalidateQueries(
            {queryKey: ["current_user"]}
          ));
      }} />
      <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
        <BackButton prevPage={prevPage} />
        <NextButton nextPage={nextPage} />
      </div>
    </div>;
  };

  const renderBirthdayPage = () => {
    return <div className={`${pageContainerStyles} tw-gap-8 tw-my-8`}>
      <p className={headingStyles}>What is your birthday?</p>
      <p className={subLabelStyles}>Helps potential clients filter and us to celebrate you!</p>
      <TextInput
        type="date"
        autofocus={true}
        debounceDelayMs={1000}
        defaultValue={user.birthday || ""}
        attribute={'birthday'}
        handleChange={(_, bday) => {
          updateCurrentUser({birthday: bday}).then(() => queryClient.invalidateQueries(
            {queryKey: ["current_user"]}
          ));
        }}
      />
      <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
        <BackButton prevPage={prevPage} />
        <NextButton nextPage={nextPage} />
      </div>
    </div>;
  };

  const updateSpecialties = (newPrimaries, newExtras) => {
    saveUserChanges({
      primary_specialties: newPrimaries,
      extra_specialties: newExtras
    }).then(() => queryClient.invalidateQueries(
      {queryKey: ["current_user"]}
    ));
  };

  const renderPracticePage = () => {
    return <div className={pageContainerStyles}>
      <p className={headingStyles}>What clients are the best fit for you?</p>
      <p className={labelStyles}>What challenges are they dealing with?</p>
      <p className={subLabelStyles}>Please select up to <b>5 specialties</b></p>
      <div className="tw-w-full tw-my-1">
        <TypeOrSelectForm
          options={specialties.filter(s => !s["private"])}
          selections={user.primary_specialties}
          placeholder="Type or select specialties"
          setSelections={
            (newPrimaries) => {
              const newExtras = user.extra_specialties.filter(x => !newPrimaries.find(y => y.name === x.name));
              updateSpecialties(newPrimaries, newExtras);
            }
          }
          transforms={{
            to: (x => ({value: x.id, label: x.name})),
            from: (x => ({id: x.value, name: x.label})),
          }}
          maxEntries={5}
        />
      </div>
      <p className={labelStyles}>Any additional concerns you treat?</p>
      <p className={subLabelStyles}>Select as many as apply. The more you can narrow down, the easier time your ideal client will have identifying you as a good fit for their needs.</p>
      <div className="tw-w-full tw-my-1">
        <TypeOrSelectForm
          options={specialties.filter(s => !s["private"])}
          selections={user.extra_specialties}
          placeholder="Type or select additional concerns"
          setSelections={
            (newExtras) => {
              const newPrimaries = user.primary_specialties.filter(x => !newExtras.find(y => y.name === x.name));
              updateSpecialties(newPrimaries, newExtras);
            }
          }
          transforms={{
            to: (x => ({value: x.id, label: x.name})),
            from: (x => ({id: x.value, name: x.label})),
          }}
        />
      </div>
      <p className={labelStyles}>What identities or experiences do you have that strengthen the fit for your ideal client?</p>
      <p className={subLabelStyles}>Select as many as apply. The more you can narrow down, the less overwhelming and more clear it will be for potential clients.</p>
      <div className="tw-w-full tw-my-1">
        <TypeOrSelectForm
          options={identityExpertises.filter(i => !i["private"])}
          selections={user.identity_expertises}
          placeholder="Type or select identities or experience expertise"
          setSelections={(sel) => {
            saveUserChanges({
              identity_expertises: sel,
            }).then(() => queryClient.invalidateQueries(
              {queryKey: ["current_user"]}
            ));
          }}
          transforms={{
            to: (x => ({value: x.id, label: x.name})),
            from: (x => ({id: x.value, name: x.label})),
          }}
        />
      </div>
        <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
          <BackButton prevPage={prevPage} />
          <NextButton nextPage={nextPage} />
        </div>
    </div>;
  };

  const renderBlurbPage = () => {
    return <div className={pageContainerStyles}>
      <p className={headingStyles}>Greet potential clients!</p>
      <p className={labelStyles}>When clients see your profile as a potential provider, what do you want to tell them?</p>
      <p className={subLabelStyles}>This is a brief hello and welcome to potential clients. Show a little personality and let them know what they can hope to experience working with you. 1-2 sentences.</p>
      <TextInput
        debounceDelayMs={1000}
        defaultValue={user.marketplace_intro}
        className="tw-flex tw-px-2 tw-py-3 text-14 light-accent-border tw-mb-1 tw-w-full tw-box-border"
        handleChange={(_, x) => {
          updateCurrentUser(
            {marketplace_intro: x}
          ).then(() => queryClient.invalidateQueries(
            {queryKey: ["current_user"]}
          ));
        }}
      />
      <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
        <BackButton prevPage={prevPage} />
        <NextButton text="Done" nextPage={close} />
      </div>
    </div>;
  }

  const renderPricingPage = () => {
    if (insuranceQuery.isLoading) return <p>Loading insurances</p>;
    if (insuranceQuery.isError) return null;

    const insurances = JSON.parse(insuranceQuery.data.data.insurances);

    return <div className={`${pageContainerStyles} tw-gap-8 tw-my-8`}>
      <p className={headingStyles}>Pricing & Insurance</p>
      <p className={labelStyles}>Let prospective clients know how much they can expect to pay</p>
      <div className="tw-w-full tw-my-1">
        <TypeOrSelectForm
          options={insurances.filter(s => !s["private"])}
          selections={user.insurances}
          placeholder="Type or select insurance providers"
          setSelections={(sel) => {
            saveUserChanges({
              insurances: sel,
            }).then(() => queryClient.invalidateQueries(
              {queryKey: ["current_user"]}
            ));
          }}
          transforms={{
            to: (x => ({value: x.id, label: x.name})),
            from: (x => ({id: x.value, name: x.label})),
          }}
          maxEntries={5}
        />
      </div>
      <div className="tw-flex tw-my-2 tw-gap-4 tw-w-full tw-justify-between tw-items-center">
        <p className={subLabelStyles}>Min. price</p>
        <TextInput
          key="min price"
          debounceDelayMs={1000}
          defaultValue={amountCentsToStringUSD(user.min_price || 0, true)}
          placeholder="$ 0.00"
          className="tw-flex tw-px-2 tw-py-3 light-accent-border tw-mb-1"
          handleChange={(_, x) => {
            updateCurrentUser(
              {min_price: stringUSDToAmountCents(x)}
            ).then(() => queryClient.invalidateQueries(
              {queryKey: ["current_user"]}
            ));
          }}
        />
        <p className={subLabelStyles}>Max. price</p>
        <TextInput
          key="max price"
          debounceDelayMs={1000}
          defaultValue={amountCentsToStringUSD(user.max_price || 0, true)}
          placeholder="$ 0.00"
          className="tw-flex tw-px-2 tw-py-3 light-accent-border tw-mb-1"
          handleChange={(_, x) => {
            updateCurrentUser(
              {max_price: stringUSDToAmountCents(x)}
            ).then(() => queryClient.invalidateQueries(
              {queryKey: ["current_user"]}
            ));
          }}
        />
      </div>
      <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
        <BackButton prevPage={prevPage} />
        <NextButton nextPage={nextPage} />
      </div>
    </div>;
  };

  return <ResponsiveModal onClose={close}>
    {page === 0 && renderApproachPage()}
    {page === 1 && renderIdentityPage()}
    {page === 2 && renderEthnicityPage()}
    {page === 3 && renderLocationPage()}
    {page === 4 && renderBirthdayPage()}
    {page === 5 && renderPricingPage()}
    {page === 6 && renderPracticePage()}
    {page === 7 && renderBlurbPage()}
  </ResponsiveModal>;
}
