import React, {useEffect, useState} from "react";

export default function Modal(props) {
  return (
    <>
    <div className={props.disableScroll ? "" : "cleanScroll"} style={{
      position: "fixed",
      left: 0,
      right: 0,
      top: "10vh",
      margin: "auto",
      maxWidth: "min(80%, 700px)",
      maxHeight: "80%",
      background: "white",
      borderRadius: "10px",
      overflow: props.disableScroll ? "visible" : "auto",
      zIndex: "43",
    }}>
      <div style={{
        padding: "1em 5vw",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        {props.renderContent()}
      </div>
    </div>
    <div style={{
        position: "fixed",
        inset: 0,
        backgroundColor: "rgb(10, 33, 117, 0.8)",
        zIndex: "42",
      }}
      onClick={props.onClose}
    >
    </div>
    </>
  );
};
