import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AutoCompleteInput from "../components/AutoCompleteInput";
import { apiBase } from "../../utils/apiRequest";
import FloatingButton from "../components/FloatingButton";
import { useLocation } from "react-router-dom";

export default function SendMessageContainer(props) {
  const { user } = props;
  const [emails, setEmails] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const eventId = query.get("event");

  const handleRemoveEmail = (email) => {
    setEmails(emails.filter((item) => item !== email));
  };

  const handleSubmit = async () => {
    const { first_name, last_name, display_name } = user;
    const defaultName =
      display_name == null || display_name == ""
        ? `${first_name.trim()} ${last_name.trim()}`
        : `${display_name}`;

    const jsonBody = {
      emails: emails.join(","),
      subject: subject,
      message: message,
      from: `${defaultName} <${process.env.SENDGRID_FROM_EMAIL || "uinventions@gmail.com"}>`,
      eventId: eventId,
    };

    console.log(jsonBody);

    const response = await apiBase("../send_message", "post", jsonBody);

    console.log(response);
  };

  const getContacts = async () => {
    const response = await apiBase("../get_user_contacts", "GET");

    const users = JSON.parse(response?.users);

    if (users?.length) {
      setContactsList(
        users.map((user) => {
          return { name: user.email };
        })
      );
    }
  };

  const getAttendees = async (eventId) => {
    const response = await apiBase(
      `../get_attendees?eventId=${eventId}`,
      "GET"
    );

    const users = JSON.parse(response?.users);

    if (users?.length) {
      setEmails(
        users.map((user) => {
          return user.email;
        })
      );
    }
  };

  useEffect(() => {
    getContacts();

    if (eventId) {
      getAttendees(eventId);
    }
  }, []);

  return (
    <div className="createContainer">
      <div className="createHeaderContainer">
        <div className="stackableContainerStyle">
          <h1>What message would you like to send?</h1>
        </div>
      </div>

      <div className="sendMessageContainer">
        <h2>Send to</h2>
        <div className="container--emails">
          {emails?.map((email, i) => (
            <div className="email" key={i}>
              <p>{email}</p>
              <div className="x--mark" onClick={() => handleRemoveEmail(email)}>
                <FontAwesomeIcon icon={faTimes} style={{ color: "#fff" }} />
              </div>
            </div>
          ))}
          <AutoCompleteInput
            options={contactsList}
            placeholder="Type or select email addresses"
            handleSelectedValue={(value) => {
              if (!emails.includes(value))
                setEmails((prevState) => [...prevState, value]);
            }}
          />
        </div>
        <h2>Subject</h2>
        <input
          className="subject--input"
          placeholder="Type your subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />

        <h2>Message</h2>

        <div>
          <ReactQuill
            className="messageEditor"
            theme="snow"
            value={message}
            onChange={setMessage}
          />
        </div>

        <div className="buttons">
          <div style={{ marginRight: "-1.5rem" }}>
            <FloatingButton title="Send" onClick={() => handleSubmit()} />
          </div>
        </div>
      </div>
    </div>
  );
}
