import { divide } from "lodash";
import React from "react";

export default function FloatingButton(props) {
  const { title, onClick, disabled, secondaryButton } = props;
  return (
    <div>
      {secondaryButton ? (
        <button
          className={
            disabled
              ? "floatingButton secondaryButton floatingButtonDisabled"
              : "floatingButton secondaryButton"
          }
          onClick={onClick}
          disabled={disabled}
        >
          <h2>{title}</h2>
        </button>
      ) : (
        <button
          className={
            disabled
              ? "floatingButton floatingButtonDisabled"
              : "floatingButton"
          }
          onClick={onClick}
          disabled={disabled}
        >
          <h2>{title}</h2>
        </button>
      )}
    </div>
  );
}
