import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default function ResponsiveModal({children, onClose}) {
  const backgroundClasses = "tw-fixed tw-inset-0 tw-flex tw-flex-col tw-justify-end md:tw-justify-center tw-items-center";
  const containerClasses = (
    "tw-top-auto tw-bg-white tw-flex tw-flex-col tw-items-center tw-h-fit " +
    "tw-overflow-y-auto tw-box-border tw-p-8 tw-w-full " +
    "accent-border tw-m-[4px] accent-shadow " +
    "md:tw-px-16 md:tw-max-w-[40rem] tw-rounded-t-lg " +
    "tw-max-h-[80%] "
  );

  return <div style={{
      backgroundColor: "rgba(255, 255, 255, 0.9", zIndex: 73
    }} className={backgroundClasses}
    onClick={onClose}
  >
    <div className={containerClasses} onClick={(e) => e.stopPropagation()}>
      <button
        onClick={onClose}
        className="tw-rounded-full tw-self-end tw-bg-black tw-h-5 tw-w-5 tw-flex tw-items-center tw-justify-center">
        <FontAwesomeIcon icon={faTimes} className="tw-text-white tw-p-1" />
      </button>
      {children}
    </div>
  </div>;
}
