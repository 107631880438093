import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Notice from "./Notice";
import FileContext from "../context/FileContext";

export default function ImageDragAndDrop(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [fileLabel, setFileLabel] = useState("");
  const [file, setFile] = useContext(FileContext);

  const { thumbUrl } = props;

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/jpeg,image/png",
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((item) => {
        setFile(item);
        return Object.assign(item, {
        });
      });
    },
    onDropRejected: (rejectedFiles) => {
      setMessage("The selected format is invalid!");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    },
    maxSize: 2097152,
  });

  return (
    <div>
      {showAlert && <Notice message={message} />}
      {thumbUrl ? (
        <div className="ImageDragAndDrop">
          <div {...getRootProps()} className="ImageFileUploaded">
            <input {...getInputProps()} />
            <div className="fileContent">
              <img src={thumbUrl} alt="thumbnail" />
            </div>
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className="ImageDragAndDrop">
          <input {...getInputProps()} />
          <div className="ImageDragAndDrop__area">
            <h2>Drop your image or</h2>
            <FontAwesomeIcon icon={faPlusCircle} className="icon-cog" />
          </div>
        </div>
      )}
    </div>
  );
}
