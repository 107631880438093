import React from "react"
import PropTypes from "prop-types"
import SelectButton from '../forms/SelectButton';

class GenderOnboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      genders: [],
      userGenders: [],
      user: [],
      loaded: false,
      timeout: null
    }
    this.updateUserGender = this.updateUserGender.bind(this)
    this.loadGenders = this.loadGenders.bind(this)
    this.createGenderArray = this.createGenderArray.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.renderChoices = this.renderChoices.bind(this)
  }

  loadGenders() {
    $.ajax({
      url: `../get_genders`,
      dataType: 'JSON',
      type: 'POST',
      data: {},
      header: {
        'Content-Type': 'application/json'
      },
      success: (data) => {
        this.setState({
          genders: JSON.parse(data.data.genders),
          loaded: true,
        }, () => this.createGenderArray())
      },
      error: function(xhr, status, err) {
        console.log(err)
      }.bind(this)
    });
  }

  createGenderArray() {

    return this.props.user.genders.map((gender, i) => {
      let array = this.state.userGenders
      if(!array.includes(gender.id)){
        array.push(gender.id)
        this.setState({
          userGenders: array
        })
      }
    })
  }

  componentDidMount() {
    this.loadGenders()
    this.createGenderArray()
  }

  updateUserGender(gender_id) {
    this.setState({
      last_name: value
    })

    const { timeout } = this.state;
      clearTimeout(timeout);
      this.setState({
        timeout: setTimeout(() => {
          let data = {
            last_name: value
          }
          this.props.updateUser(data)
        }, 500)
      })
  }

  handleClick(value) {
    let data = {
      gender_id: value
    }
    if(this.state.userGenders.includes(value)) {
      //remove user_gender
      $.ajax({
        url: `../remove_user_gender`,
        dataType: 'JSON',
        type: 'POST',
        data: data,
        header: {
          'Content-Type': 'application/json'
        },
        success: (data) => {
        },
        error: function(xhr, status, err) {
          console.log(err)
        }.bind(this)
      });
      //remove from array
      let array = this.state.userGenders
      let index = array.indexOf(value);
      array.splice(index, 1);
      this.setState({
        userGenders: array
      })
    } else {
      //create user_gender
      $.ajax({
        url: `../create_user_gender`,
        dataType: 'JSON',
        type: 'POST',
        data: data,
        header: {
          'Content-Type': 'application/json'
        },
        success: (data) => {
        },
        error: function(xhr, status, err) {
          console.log(err)
        }.bind(this)
      });
      //add to array
      let array = [...this.state.userGenders, value]
      this.setState({
        userGenders: array
      })
    }
  }

  renderChoices() {
    return this.state.genders.map((gender, i) => {
      return(
        <div style={{paddingTop: 10}} key={i}>
          <SelectButton
            text={gender.name}
            value={gender.id}
            selected={this.state.userGenders.includes(gender.id)}
            onClick={this.handleClick}
          />
        </div>
      )
    })
  }

  render () {
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{paddingBottom: 10}}>What is your gender?</h3>
          <h4 className="standard_label" style={{paddingBottom: 40, opacity: .5}}>Select as many as apply</h4>
          {this.renderChoices()}
        </div>
      </React.Fragment>
    );
  }
}

export default GenderOnboard
