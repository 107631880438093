import React from "react";
import PropTypes from "prop-types";
import SubscribedMembershipCard from "./SubscribedMembershipCard";
import getPreview from "../../utils/getPreview";
import { format, parseISO } from "date-fns";

class AllMyMemberships extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      formOpen: false,
      formProduct: [],
      memberships: [],
      downloads: [],
      events: [],
    };
    this.getMemberships = this.getMemberships.bind(this);
    this.renderMemberships = this.renderMemberships.bind(this);
  }

  getMemberships() {
    $.ajax({
      url: `../get_my_memberships`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        this.setState({
          memberships: JSON.parse(data.data.subscriptions),
        });
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  }

  getDownloads() {
    $.ajax({
      url: `../get_my_downloads`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        this.setState({
          downloads: JSON.parse(data.downloads),
        });
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  }

  getEvents() {
    $.ajax({
      url: `../get_my_events`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        if (data)
          this.setState({
            events: JSON.parse(data.events),
          });
      },
      error: function (xhr, status, err) {
        console.log(err);
      }.bind(this),
    });
  }

  componentDidMount() {
    this.getMemberships();
    this.getDownloads();
    this.getEvents();
  }

  renderMemberships() {
    if (this.state.memberships.length < 1) {
      return <p style={{ marginTop: 10 }}>No active subscriptions</p>;
    }
    return this.state.memberships.map((membership, i) => {
      return <SubscribedMembershipCard key={i} membership={membership} />;

      return null;
    });
  }

  userDownload(file, fileName) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = file;
    a.download = fileName;
    a.click();
  }

  renderDownloads() {
    if (this.state.downloads?.length < 1) {
      return <p style={{ marginTop: 10 }}>You don't have any downloads</p>;
    }
    return this.state.downloads.map((download, i) => {
      return (
        <div
          key={i}
          className="event--card--user--dashboard"
          onClick={() => {
            const url = `${process.env.APP_URL.replace(
              "app.",
              `${download?.slug}.`
            )}/d/${download?.download_url}`;
            window.location.assign(url);
          }}
        >
          {download.thumb_url && (
            <div className="card--thumbnail">
              <img src={download.thumb_url} alt="thumbnail" />
            </div>
          )}
          <div className="card--info">
            <p>{download.download_title}</p>
          </div>
        </div>
      );
    });
  }

  renderEvents() {
    if (this.state.events?.length < 1) {
      return <p style={{ marginTop: 10 }}>You don't have any events</p>;
    }
    return this.state.events.map((event, i) => {
      return (
        <div
          key={i}
          className="event--card--user--dashboard"
          onClick={() => {
            const url = `${process.env.APP_URL.replace(
              "app.",
              `${event?.slug}.`
            )}/e/${event?.event_url}`;
            window.location.assign(url);
          }}
        >
          {event.thumb_url && (
            <div className="card--thumbnail">
              <img src={event.thumb_url} alt="thumbnail" />
            </div>
          )}
          <div className="card--info">
            <div className="start--date--time">
              <p>
                {format(
                  parseISO(event.date_start_time),
                  "EEE, MMM dd | hh:mm a OOO"
                )}
              </p>
            </div>
            <p>{event.title}</p>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div style={{ margin: "auto", width: "100%", padding: "5%" }}>
          <h3>My memberships</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {this.renderMemberships()}
          </div>
        </div>
        <div style={{ margin: "auto", width: "100%", padding: "5%" }}>
          <h3>My downloads</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            {this.renderDownloads()}
          </div>
        </div>
        <div
          style={{ margin: "auto", width: "100%", padding: "5%" }}
          className="my--events"
        >
          <h3>My events</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            {this.renderEvents()}
          </div>
        </div>
      </div>
    );
  }
}

export default AllMyMemberships;
