import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEllipsisH,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Notice from "../components/Notice";
import { format, parseISO } from "date-fns";
import SubMenuModalEvents from "../components/SubMenuModalEvents";
import DashboardCards from "../components/DashboardCards";
import event from "../../../assets/images/event.png";
import emoji from "../../../assets/images/emoji.png";
import {amountCentsToStringUSD} from "../../utils/currency";

export default function ListEventContainer(props) {
  const { onFinish } = props;
  const [userEvents, setUserEvents] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [openSubMenu, setOpenSubMenu] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleDelete = (event_id) => {
    setUserEvents((prevUserEvent) =>
      prevUserEvent.filter((item) => item.id !== event_id)
    );
    $.ajax({
      url: `/delete_user_event`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: event_id,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        // getUserEvents();
        return data;
      },
      error: (xhr, status, err) => {
        setMessage(JSON.parse(xhr?.responseText)?.message || "Internal error");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      },
    });
  };

  const getUserEvents = () => {
    $.ajax({
      url: `/get_user_events`,
      dataType: "JSON",
      type: "POST",
      data: {
        user_id: props.user.id,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        setUserEvents(JSON.parse(data.events));
        setLoading(false);
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const handleArchive = (event_id) => {
    $.ajax({
      url: `/update_user_event`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: event_id,
        status: "DRAFT",
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        return data;
      },
      error: (xhr, status, err) => {
        return err;
      },
    });

    setUserEvents((prevUserEvent) =>
      prevUserEvent.map((item) =>
        item.id === event_id ? { ...item, event_status: "DRAFT" } : { ...item }
      )
    );
  };

  const renderSubMenu = (event) => {
    const item = {
      id: event.id,
      status: event.status,
      income_received: event.income_received,
      mouseX: event.mouseX,
      mouseY: event.mouseY,
    };

    return (
      <SubMenuModalEvents
        item={item}
        handleDelete={handleDelete}
        handleArchive={handleArchive}
        handleClose={() => setOpenSubMenu(null)}
      />
    );
  };

  const handleOpenLive = (event_url, event_id) => {
    const item = event_url || event_id;

    const url = `${process.env.APP_URL.replace(
      "app.",
      `${props.user.slug}.`
    )}/e/${item}`;

    window.location.assign(url);
  };

  const rederZeroState = () => {
    return (
      <div>
        <div className="createHeaderContainer">
          <div className="stackableContainerStyle">
            <h1>Let’s get started!</h1>
          </div>
        </div>
        <div className="dashboardCards">
          <DashboardCards
            icon={event}
            title="Set up your next event!"
            content="Whether it’s a one-time workshop or an ongoing series, connect with your community online and IRL!"
            button="Create an event"
            action={() => window.location.replace(`/dashboard/create/event`)}
          />
          <DashboardCards
            icon={emoji}
            title="Will that be cash or card?"
            content="Connect with Stripe so you can accept payments and start making money with Tabu Wellness."
            button={props.user?.stripe_user_id ? "Connected" : "Connect Stripe"}
            disabled={!!props.user?.stripe_user_id}
            action={() => handleConnectStripe()}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    getUserEvents();
    onFinish();
  }, []);

  return (
    <div className="createContainer">
      {showAlert && <Notice message={message} />}
      {openSubMenu && renderSubMenu(openSubMenu)}
      {loading ? (
        <div className="loading--ring">
          <div className="lds-dual-ring"></div>
        </div>
      ) : userEvents.length > 0 ? (
        <>
          <div className="createHeaderContainer">
            <div className="stackableContainerStyle">
              <h1>Your events</h1>
            </div>
            <Link to="/dashboard/create/event">
              <div className="createEventButton">
                <FontAwesomeIcon
                  style={{
                    color: "#fff",
                  }}
                  icon={faEdit}
                />
                <span>Create an event</span>
              </div>
            </Link>
          </div>
          <div className="listEventContent">
            <table className="listTable">
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Event Date</th>
                  <th>Status</th>
                  <th>Price</th>
                  <th>Income Received</th>
                  <th></th>
                </tr>
                {userEvents.map((event, i) => {
                  return (
                    <tr key={i} className="body--tr">
                      <td
                        className="event--title"
                        onClick={() => {
                          navigate(`/dashboard/create/event?id=${event?.id}`);
                        }}
                      >
                        {event?.title}
                      </td>
                      <td
                        onClick={() => {
                          navigate(`/dashboard/create/event?id=${event?.id}`);
                        }}
                      >
                        {event?.date_start_time &&
                          format(
                            parseISO(event?.date_start_time),
                            "MMM dd, yyyy"
                          )}
                      </td>
                      <td
                        onClick={() => {
                          navigate(`/dashboard/create/event?id=${event?.id}`);
                        }}
                      >
                        {event?.status === "PUBLISHED" && (
                          <div className="status">
                            <p>{event?.status}</p>
                          </div>
                        )}

                        {event?.status !== "PUBLISHED" && (
                          <div className="status--draft">
                            <p>{event?.status || "DRAFT"}</p>
                          </div>
                        )}
                      </td>
                      <td
                        onClick={() => {
                          navigate(`/dashboard/create/event?id=${event?.id}`);
                        }}
                      >
                        {amountCentsToStringUSD(event?.amount || 0)}
                      </td>
                      <td
                        onClick={() => {
                          navigate(`/dashboard/create/event?id=${event?.id}`);
                        }}
                      >
                        ${event ? ((event.income_received / 100.0).toLocaleString("en-EN", {minimumFractionDigits: 2, maximumFractionDigits: 2})) : 0}
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>
                          <FontAwesomeIcon
                            icon={faEllipsisH}
                            style={{ color: "#733dff", marginRight: "15px" }}
                            onMouseDown={(e) => {
                              setOpenSubMenu({
                                ...event,
                                mouseX: e.clientX,
                                mouseY: e.clientY,
                              });
                            }}
                          />
                          {event.status === "PUBLISHED" && (
                            <div
                              onClick={() => {
                                return handleOpenLive(
                                  event.event_url,
                                  event.id
                                );
                              }}
                              style={{ marginRight: "10px" }}
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ color: "#733DFF" }}
                              />
                            </div>
                          )}
                          <div
                            onClick={() =>
                              window.location.assign(
                                `/dashboard/send-message?event=${event.id}`
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              style={{ color: "#733DFF" }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </thead>
            </table>
          </div>
        </>
      ) : (
        rederZeroState()
      )}
    </div>
  );
}
