import React, {useState, useEffect} from "react";

import Editor, {TextInput, formPadding, textInputClasses} from "../components/Editor";
import ContentSection from "../profile/sections/ContentSection";
import {LinkContentCard} from "../components/ContentCard";
import MultiForm from '../forms/MultiForm';

import {updateCurrentUser, updateUserProfileLinks} from "../../queries/user";

function LinkSectionPreview({title, links}) {
  return <ContentSection
    title={title}
    showEmpty={true}
    style={{padding: 0, margin: 0}}
    flexDirection="column"
    cardRenderer={li => <LinkContentCard key={li.id} li={li} />}
    content={links}
    key="profileLinksSection"
  />
}

export default function EditProfileLinks({user}) {
  const [links, setLinks] = useState(user.user_profile_links || []);
  const [linksCleared, setLinksCleared] = useState(0);
  const [changesMade, setChangesMade] = useState(false);
  const [changesRecentlySaved, setChangesRecentlySaved] = useState(false);
  const [title, setTitle] = useState(user.profile_links_section_title || "My links");

  const saveProfileLinks = async () => {
    await updateCurrentUser({
      profile_links_section_title: title,
    });
    await updateUserProfileLinks(links);
    setChangesMade(false);
  }

  const renderLinkEditForm = (i) => {
    const link = links[i] || {
      id: i,
      title: "",
      url: "",
      details: "",
    };

    const changeLink = (field, value) => {
      let newLinks = [...links];
      link[field] = value;
      newLinks[i] = link;
      setLinks(newLinks);
      setChangesMade(true);
    };

    const renderTextInput = (field, title, placeholder) => {
      return <TextInput
        key={field}
        label={title}
        placeholder={placeholder}
        defaultValue={link[field]}
        onChange={x => changeLink(field, x)}
      />;
    };

    const key = (i > 0) ? i : `0_${linksCleared}`;
    return <div key={key} className={`tw-flex tw-flex-col ${formPadding} tw-mt-6 tw-w-full`}>
      {renderTextInput("url", "URL", "https://")}
      {renderTextInput("title", "Give it a name", "Title")}
      {renderTextInput("details", "Add a description, discount code, or call to action", "Details")}
    </div>;
  };

  return <Editor
    title="Links"
    changesMade={changesMade}
    changesRecentlySaved={changesRecentlySaved}
    saveChanges={saveProfileLinks}
    sections={[{
      title: "Your personal links",
      render: () => (
        <div className={`tw-flex tw-flex-col ${formPadding}`}>
          <p className="text-16">Point your audience to other places you exist on the Internet and/or your favorite things.</p>
          <TextInput
            label="What do you want to call this section?"
            defaultValue={user.profile_links_section_title}
            placeholder={"My links"}
            onChange={x => {setTitle(x); setChangesMade(true)}}
          />
          <MultiForm
            entries={links}
            maxCount={10}
            renderForm={renderLinkEditForm}
            addButtonClassName="tw-flex tw-py-1 tw-px-2 tw-gap-2 tw-justify-center tw-rounded-lg thick-dark-accent-border tw-w-fit dark-accent-shadow"
            deleteFn={(i) => {
              if (i === 0 && links.length === 1) {
                // this just forces re-rendering of the first form
                setLinksCleared(linksCleared + 1);
              }
              let newLinks = links.slice(0, i).concat(links.slice(i + 1));
              setLinks(newLinks);
              setChangesMade(true);
            }}
            addButtonText="Add a link"
          />
        </div>
      ),
    }]}
    renderPreview={() => <LinkSectionPreview title={title} links={links} />}
  />;
}
