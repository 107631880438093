import React from "react";
import SlugChanger from "../components/SlugChanger";

class SlugOnboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      slug: this.props.currentUser?.slug,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{ paddingBottom: 40 }}>
            Make it yours.
          </h3>
          <h4
            className="standard_label"
            style={{ paddingBottom: 40, opacity: 0.5 }}
          >
            Choose a custom URL for your profile
          </h4>

          <SlugChanger
           slug={this.state.slug}
           onError={() => this.props.blockNextButton(true)}
           onSuccess={() => this.props.blockNextButton(false)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default SlugOnboard;
