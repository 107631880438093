import {asyncApiRequest} from "../utils/apiRequest";

export const csrf = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

export const getCurrentUser = async () => {
  const response = await $.ajax({
    url: "/get_current_user",
    dataType: "JSON",
    type: "POST",
  });

  if (!response?.data?.user) {
    console.error("failed to fetch current user");
    return;
  }

  return JSON.parse(response?.data?.user);
};

export const updateCurrentUser = async (payload) => {
  return asyncApiRequest("/update_user", "POST", payload);
};

export const mutateCurrentUserCareType = async (payload) => {
  switch (payload.action) {
    case "create":
      return asyncApiRequest("/create_user_caretype", "POST", payload.payload);
    case "update":
      return asyncApiRequest("/update_user_caretype", "POST", payload.payload);
    case "delete":
      return asyncApiRequest("/delete_user_caretype", "POST", payload.payload);
    default:
      return Promise.reject("unknown action");
  }
};

export const mutateCurrentUserPronouns = async (payload) => {
  switch (payload.action) {
    case "create":
      return asyncApiRequest("/create_user_pronoun", "POST", payload.payload);
    case "delete":
      return asyncApiRequest("/remove_user_pronoun", "POST", payload.payload);
    default:
      return Promise.reject("unknown action");
  }
};

export const mutateCurrentUserInsurances = async (payload) => {
  switch (payload.action) {
    case "create":
      return asyncApiRequest("/create_user_insurance", "POST", payload.payload);
    case "delete":
      return asyncApiRequest("/remove_user_insurance", "POST", payload.payload);
    default:
      return Promise.reject("unknown action");
  }
};

export const mutateCurrentUserApproachStyles = async (payload) => {
  switch (payload.action) {
    case "create":
      return asyncApiRequest("/create_user_approach_style", "POST", payload.payload);
    case "delete":
      return asyncApiRequest("/remove_user_approach_style", "POST", payload.payload);
    default:
      return Promise.reject("unknown action");
  }
};

export const mutateCurrentUserEthnicities = async (payload) => {
  switch (payload.action) {
    case "create":
      return asyncApiRequest("/create_user_ethnicity", "POST", payload.payload);
    case "delete":
      return asyncApiRequest("/remove_user_ethnicity", "POST", payload.payload);
    default:
      return Promise.reject("unknown action");
  }
};

export const mutateCurrentUserGenders = async (payload) => {
  switch (payload.action) {
    case "create":
      return asyncApiRequest("/create_user_gender", "POST", payload.payload);
    case "delete":
      return asyncApiRequest("/remove_user_gender", "POST", payload.payload);
    default:
      return Promise.reject("unknown action");
  }
};

export const mutateCurrentUserEducation = async (payload) => {
  switch (payload.action) {
    // "update" handles creation and updating on the backend for education
    case "create":
    case "update":
      return asyncApiRequest("/update_user_education", "POST", payload.payload);
    case "delete":
      return asyncApiRequest("/delete_user_education", "POST", payload.payload);
    default:
      return Promise.reject("unknown action");
  }
};

export async function saveUserChanges(changes) {
  if (changes.user_caretypes) {
    await asyncApiRequest("/update_user_caretypes", "POST", {
      user_caretypes: changes.user_caretypes
    });
    delete changes.user_caretypes;
  }
  if (changes.pronouns) {
    await asyncApiRequest("/update_user_pronouns", "POST", {
      pronouns: changes.pronouns
    });
    delete changes.pronouns;
  }
  if (changes.primary_specialties) {
    await asyncApiRequest("/update_user_primary_specialties", "POST", {
      specialties: changes.primary_specialties
    });
    delete changes.primary_specialties;
  }
  if (changes.extra_specialties) {
    await asyncApiRequest("/update_user_extra_specialties", "POST", {
      specialties: changes.extra_specialties
    });
    delete changes.extra_specialties;
  }
  if (changes.insurances) {
    await asyncApiRequest("/update_user_insurances", "POST", {
      insurances: changes.insurances
    });
    delete changes.insurances;
  }
  if (changes.identity_expertises) {
    await asyncApiRequest("/update_user_identity_expertises", "POST", {
      identity_expertises: changes.identity_expertises
    });
    delete changes.identity_expertises;
  }
  if (changes.user_educations) {
    await asyncApiRequest("/update_user_educations", "POST", {
      educations: changes.user_educations,
    });
    delete changes.user_educations;
  }
  if (changes.user_certifications) {
    await asyncApiRequest("/update_user_certifications", "POST", {
      certifications: changes.user_certifications,
    });
    delete changes.user_certifications;
  }
  if (changes.languages) {
    await asyncApiRequest("/update_user_languages", "POST", {
      languages: changes.languages,
    });
    delete changes.languages;
  }
  if (changes.user_locations) {
    await asyncApiRequest("/update_user_locations", "POST", {
      locations: changes.user_locations,
    });
    delete changes.user_locations;
  }
  if (Object.keys(changes).length > 0) {
    return asyncApiRequest("/update_user", "POST", changes);
  }
};

export async function updateUserProfileLinks(links) {
  return asyncApiRequest("/update_user_profile_links", "POST", {links: links});
};

export function uploadAvatar(image, onSuccess) {
  let formData = new FormData();
  formData.append("avatar", image);
  return $.ajax({
    url: "/update_user_avatar",
    dataType: 'JSON',
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData,
    success: (response) => {
      onSuccess(JSON.parse(response.user).avatar_url);
    },
  });
}

export function removeAvatar(onSuccess) {
  return $.ajax({
    url: "/remove_user_avatar",
    dataType: "JSON",
    type: "POST",
    success: (_) => {
      onSuccess();
    },
  });
};

export function uploadPhoto(endpoint, image, onSuccess) {
  let formData = new FormData();
  formData.append("cover_photo", image);
  return $.ajax({
    url: endpoint,
    dataType: 'JSON',
    type: 'POST',
    processData: false,
    contentType: false,
    data: formData,
    success: (response) => {
      onSuccess(JSON.parse(response.user));
    },
  });
}

export function uploadCoverPhoto(image, onSuccess) {
  return uploadPhoto("/update_user_cover_photo", image, (resp) => onSuccess(resp.cover_photo_url));
}

export function removeCoverPhoto(onSuccess) {
  return $.ajax({
    url: "/remove_user_cover_photo",
    dataType: "JSON",
    type: "POST",
    success: (_) => {
      onSuccess();
    },
  });
};
