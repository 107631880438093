import React, {useEffect, useState} from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import {getCurrentUser} from "../../queries/user";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

const defaultMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInputInner = ({ ...inputProps }) => {
  const currencyMask = createNumberMask(defaultMaskOptions);
  const queryCurrentUser = useQuery({queryKey: ["current_user"], queryFn: getCurrentUser})
  const [showTooltip, setShowTooltip] = useState(false);

  const getStripeConnected = () => {
    if (queryCurrentUser.isLoading || queryCurrentUser.isError) {
      return false;
    }
    return !!queryCurrentUser.data.stripe_user_id;
  }

  const onHover = () => {
    if (!inputProps.skipStripeCheck && !getStripeConnected()) {
      setShowTooltip(true);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      const timeout = setTimeout(() => setShowTooltip(false), 2000);

      return () => clearTimeout(timeout);
    }
  }, [showTooltip]);

  return (
    <div onMouseOver={onHover} onClick={onHover}>
      {showTooltip && (
        <div className="tooltip" style={{marginTop: "-3em"}}>
          {"Stripe must be connected to sell digital content"}
        </div>
      )}
      <MaskedInput disabled={!getStripeConnected()} mask={currencyMask} {...inputProps} />
    </div>
  );
};

const queryClient = new QueryClient();

const CurrencyInput = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CurrencyInputInner {...props} />
    </QueryClientProvider>
  );
}

export default CurrencyInput;
