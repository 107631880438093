import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faTiktok,
  faPinterest,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import { faPlus, faLink, faPen } from "@fortawesome/free-solid-svg-icons";

export default function SocialContainer(props) {
  const { user } = props;

  if (!user) {
    return null;
  }

  if (
    !user.instagram &&
    !user.twitter &&
    !user.facebook &&
    !user.pinterest &&
    !user.spotify &&
    !user.tiktok &&
    !user.youtube &&
    !user.website
  ) {
    return null;
  }

  return (
    <div className="tw-flex tw-gap-2 tw-w-full tw-justify-center tw-flex-wrap">
      {user?.instagram && (
        <div
          className="imageIconBox"
          onClick={() =>
            window.open(
              `https://www.instagram.com/${user.instagram}`,
              "_blank"
            )
          }
        >
          <FontAwesomeIcon icon={faInstagram} size="lg" />
        </div>
      )}
      {user?.twitter && (
        <div
          className="imageIconBox"
          onClick={() =>
            window.open(`https://twitter.com/${user.twitter}`, "_blank")
          }
        >
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </div>
      )}
      {user?.facebook && (
        <div
          className="imageIconBox"
          onClick={() =>
            window.open(`https://www.facebook.com/${user.facebook}`, "_blank")
          }
        >
          <FontAwesomeIcon icon={faFacebookF} size="lg" />
        </div>
      )}
      {user?.pinterest && (
        <div
          className="imageIconBox"
          onClick={() =>
            window.open(`https://www.pinterest.com/${user.pinterest}`, "_blank")
          }
        >
          <FontAwesomeIcon icon={faPinterest} size="lg" />
        </div>
      )}
      {user?.spotify && (
        <div
          className="imageIconBox"
          onClick={() =>
            window.open(`https://open.spotify.com/${user.spotify}`, "_blank")
          }
        >
          <FontAwesomeIcon icon={faSpotify} size="lg" />
        </div>
      )}
      {user?.tiktok && (
        <div
          className="imageIconBox"
          onClick={() =>
            window.open(`https://www.tiktok.com/@${user.tiktok}`, "_blank")
          }
        >
          <FontAwesomeIcon icon={faTiktok} size="lg" />
        </div>
      )}
      {user?.youtube && (
        <div
          className="imageIconBox"
          onClick={() =>
            window.open(`https://www.youtube.com/${user.youtube}`, "_blank")
          }
        >
          <FontAwesomeIcon icon={faYoutube} size="lg" />
        </div>
      )}
      {user?.website && (
        <div
          className="imageIconBox"
          onClick={() => window.open(user.website, "_blank")}
        >
          <FontAwesomeIcon icon={faLink} size="lg" />
        </div>
      )}
    </div>
  );
}
