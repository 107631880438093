import React, {useState, useEffect} from "react";

import ErrorBoundary from "../components/ErrorBoundary";
import TableView from "../components/TableView";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookMedical, faEdit} from "@fortawesome/free-solid-svg-icons";
import {amountCentsToStringUSD} from "../../utils/currency";

import {Link} from "react-router-dom";

function ListServices({user}) {
  const renderCreateButton = () => {
    return <Link
      to="/dashboard/create/service"
    >
      <button className="dark-accent-button">
        <div className="tw-flex tw-gap-2 tw-items-center">
          <FontAwesomeIcon icon={faEdit} className="tw-text-white" size="xs" />
          <p className="tw-text-white">Create a service</p>
        </div>
      </button>
    </Link>;
  }

  const renderEmptyState = () => {
    return <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-p-16 tw-gap-8 tw-box-border">
      <FontAwesomeIcon icon={faBookMedical} style={{color: "#733dff"}} size="4x" />
      <p className="text-20 tw-font-semibold">No services yet!</p>
      {renderCreateButton()}
    </div>
  }

  return <div className="tw-w-full tw-p-2 md:tw-p-16 tw-box-border">
    <TableView
      content={user.user_services}
      fields={[{
        title: "Name",
        value: sv => sv.name,
      }, {
        title: "Cost",
        value: sv => amountCentsToStringUSD(sv.cost),
      }, {
        title: "# sessions",
        value: sv => sv.n_sessions,
      },{
        title: "Treatment techniques",
        value: sv => sv.treatment_techniques.map(x => (
          <span
            style={{fontSize: "10px"}}
            className="tw-p-1 tw-m-1 dark-border light-accent-background tw-rounded-lg"
          >{x.name}</span>
        )),
      }]}
      emptyState={renderEmptyState()}
      rowLinkFn={i => `/dashboard/create/service?id=${user.user_services[i].id}`}
    />
  </div>;
}

export default function ListServicesContainer(props) {
  return <ErrorBoundary>
    <ListServices user={props.user} />
  </ErrorBoundary>;
}
