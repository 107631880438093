import React, { useState, useEffect } from "react";
import TextInput from "../forms/TextInput";

import { asyncApiRequest } from "../../utils/apiRequest";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCheck,
  faTimes,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";

const _ROOT_DOMAIN = "tabuwellness.com";

const SlugStatus = {
  // on page load, slug field represents current slug
  Unchanged: 0,
  // after 'Save my URL' click
  Updating: 1,
  // failed (incl. taken)
  Failed: 2,
  // update complete
  Saved: 3,
}

export default function SlugChanger(props) {
  const [slug, setSlug] = useState(props.slug);
  const [savedSlug, setSavedSlug] = useState(props.slug);
  const [slugStatus, setSlugStatus] = useState(SlugStatus.Unchanged);
  const [errorMessage, setErrorMessage] = useState(
    "something went wrong with the URL update"
  );
  const [slugInputWidth, setSlugInputWidth] = useState(`${props.slug.length}ch`);

  const containerClass = props.containerClass || "slugChanger--container";
  const inputContainerClass = props.inputContainerClass || "slugChanger--input--container";
  const inputClass = props.inputClass || "slugChanger--input";
  const textClass = props.textClass || "slugChanger--text";

  const onSlugSuccess = () => {
    setSavedSlug(slug);
    setSlugStatus(SlugStatus.Saved);
    if (props.onSuccess) {
      props.onSuccess();
    }
  }

  const onSlugFailure = (response, _, __) => {
    let error = response.responseJSON?.message;
    console.error(`slug update failed: ${error}`);
    setErrorMessage(error);
    setSlugStatus(SlugStatus.Failed);
    if (props.onError) {
      props.onError();
    }
  }

  const renderStatus = () => {
    const statusClass = props.statusClass || "slugChanger--status";

    let icon = undefined;
    let iconStyle = {
      padding: "4px",
      color: "#0A2175",
    };
    let message = "";

    switch (slugStatus) {
      case SlugStatus.Updating:
        icon = faCogs;
        message = "Updating URL";
        break;
      case SlugStatus.Failed:
        icon = faTimes;
        message = errorMessage;
        break;
      case SlugStatus.Unchanged:
      case SlugStatus.Saved:
        icon = faCheck;
        message = "Saved";
        break;
    }

    return (
      <div className={statusClass}>
        <FontAwesomeIcon
         style={iconStyle}
         icon={icon}
        />
        <p>{message}</p>
      </div>
    );
  }

  const updateSlug = () => {
    const body = {slug: slug, update_slug: true};
    asyncApiRequest("/update_user", "POST", body, onSlugSuccess, onSlugFailure);
  }

  useEffect(() => {
    let length = 4;
    if (slug.length > length) {
      length = slug.length;
    }
    setSlugInputWidth(`${length}ch`);
  }, [slug]);

  useEffect(() => {
    const timeout = setTimeout(updateSlug, 1000);
    setSlugStatus(SlugStatus.Updating);
    return () => clearTimeout(timeout);
  }, [slug]);

  return (
    <div className={containerClass}>
      <div className={inputContainerClass}>
        <TextInput
         type="text"
         value={slug}
         editable={true}
         attribute={"slug"}
         handleChange={(_, value) => {
           setSlug(value);
           if (props.onError) {
             props.onError();
           }
         }}
         className={`${textClass} ${inputClass}`}
         style={{width: slugInputWidth}}
        />
        {renderStatus()}
      </div>
        <p className={textClass}>.{_ROOT_DOMAIN}</p>
    </div>
  );
}
