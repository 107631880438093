import React, { useEffect } from "react";
import PropTypes from "prop-types";

const Modal = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ margin: 0 }}>
      <div
        id="modalForm"
        className="modalForm"
        onKeyDown={(e) => {
          const { key } = e;
          if (key === "Escape") props.onClose();
        }}
        tabIndex="0"
      >
        {props.children}
      </div>
      <div
        onClick={() => props.onClose()}
        style={{
          top: 0,
          left: 0,
          position: "absolute",
          width: "100%",
          height: "1708px",
          display: "flex",
          justifyContent: "center",
          zIndex: 39,
          backgroundColor: "#0A2175",
          opacity: "0.8",
          backdropFilter: "blur(4px)",
        }}
      ></div>
    </div>
  );
};

export default Modal;
