import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Notice from "../components/Notice";
import SubMenuModal from "../components/SubMenuModal";
import DashboardCards from "../components/DashboardCards";
import download from "../../../assets/images/Download.png";
import emoji from "../../../assets/images/emoji.png";
import {amountCentsToStringUSD} from "../../utils/currency";
import ErrorBoundary from "../components/ErrorBoundary";

function ListDownloadContainer(props) {
  const { onFinish } = props;
  const [userDownloads, setUserDownloads] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [openSubMenu, setOpenSubMenu] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleDelete = (download_id) => {
    setUserDownloads((prevUserDownload) =>
      prevUserDownload.filter((item) => item.id !== download_id)
    );
    $.ajax({
      url: `/delete_user_download`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: download_id,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        getUserDownloads();
        return data;
      },
      error: (xhr, status, err) => {
        setMessage(JSON.parse(xhr?.responseText)?.message || "Internal error");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      },
    });
  };

  const getUserDownloads = () => {
    $.ajax({
      url: `/get_user_downloads`,
      dataType: "JSON",
      type: "POST",
      data: {
        user_id: props.user.id,
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        setUserDownloads(JSON.parse(data.downloads));
        setLoading(false);
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const handleOpenLive = (download_url, download_id) => {
    const item = download_url || download_id;

    const url = `//${props.user.slug}.${process.env.ROOT_DOMAIN}/d/${item}`;
    window.open(url, "_blank");
  };

  const handleArchive = (download_id) => {
    $.ajax({
      url: `/update_user_download`,
      dataType: "JSON",
      type: "POST",
      data: {
        id: download_id,
        download_status: "DRAFT",
      },
      header: { "Content-Type": "application/json" },
      success: (data) => {
        return data;
      },
      error: (xhr, status, err) => {
        return err;
      },
    });

    setUserDownloads((prevUserDownload) =>
      prevUserDownload.map((item) =>
        item.id === download_id
          ? { ...item, download_status: "DRAFT" }
          : { ...item }
      )
    );
  };

  const renderSubMenu = (download) => {
    const item = {
      id: download.id,
      status: download.download_status,
      income_received: download.income_received,
      mouseX: download.mouseX,
      mouseY: download.mouseY,
    };
    return (
      <SubMenuModal
        item={item}
        handleDelete={handleDelete}
        handleArchive={handleArchive}
        handleClose={() => setOpenSubMenu(null)}
      />
    );
  };

  const rederZeroState = () => {
    return (
      <div>
        <div className="createHeaderContainer">
          <div className="stackableContainerStyle">
            <h1>Let’s get started!</h1>
          </div>
        </div>
        <div className="dashboardCards">
          <DashboardCards
            icon={download}
            title="Sell your digital content!"
            content="We’re picking up what you're putting down-load. 😜 It's time to bring your expertise to the masses."
            button="Create a download"
            action={() => window.location.replace(`/dashboard/create/download`)}
          />
          <DashboardCards
            icon={emoji}
            title="Will that be cash or card?"
            content="Connect with Stripe so you can accept payments and start making money with Tabu Wellness."
            button={props.user?.stripe_user_id ? "Connected" : "Connect Stripe"}
            disabled={!!props.user?.stripe_user_id}
            action={() => handleConnectStripe()}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    getUserDownloads();
    onFinish();
  }, []);

  return (
    <div className="createContainer">
      {showAlert && <Notice message={message} />}
      {openSubMenu && renderSubMenu(openSubMenu)}
      {loading ? (
        <div className="loading--ring">
          <div className="lds-dual-ring"></div>
        </div>
      ) : userDownloads.length > 0 ? (
        <>
          <div className="createHeaderContainer">
            <div className="stackableContainerStyle">
              <h1>Your downloads</h1>
            </div>
            <Link to="/dashboard/create/download">
              <div className="createDownloadButton">
                <FontAwesomeIcon
                  style={{
                    color: "#fff",
                  }}
                  icon={faEdit}
                />
                <span>Create a download</span>
              </div>
            </Link>
          </div>
          <div className="listDownloadContent">
            <table className="listTable">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th className="hide--mobile">Type</th>
                  <th>Status</th>
                  <th>Price</th>
                  <th>Income Received</th>
                  <th></th>
                </tr>
                {userDownloads.map((download, i) => {
                  return (
                    <tr key={i} className="body--tr">
                      <td
                        className="download--title"
                        onClick={() => {
                          navigate(
                            `/dashboard/create/download?id=${download?.id}`
                          );
                        }}
                      >
                        {download?.download_title}
                      </td>
                      <td
                        className="hide--mobile"
                        onClick={() => {
                          navigate(
                            `/dashboard/create/download?id=${download?.id}`
                          );
                        }}
                      >
                        {download?.kind_content}
                      </td>
                      <td
                        onClick={() => {
                          navigate(
                            `/dashboard/create/download?id=${download?.id}`
                          );
                        }}
                      >
                        {download?.download_status === "PUBLISHED" && (
                          <div className="status">
                            <p>{download?.download_status}</p>
                          </div>
                        )}

                        {download?.download_status !== "PUBLISHED" && (
                          <div className="status--draft">
                            <p>{download?.download_status || "DRAFT"}</p>
                          </div>
                        )}
                      </td>
                      <td
                        onClick={() => {
                          navigate(
                            `/dashboard/create/download?id=${download?.id}`
                          );
                        }}
                      >
                        {amountCentsToStringUSD(download?.amount || 0)}
                      </td>
                      <td
                        onClick={() => {
                          navigate(
                            `/dashboard/create/download?id=${download?.id}`
                          );
                        }}
                      >
                        ${download ? ((download.income_received / 100.0).toLocaleString("en-EN", {minimumFractionDigits: 2, maximumFractionDigits: 2})) : 0}
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>
                          <FontAwesomeIcon
                            icon={faEllipsisH}
                            style={{ color: "#733dff", marginRight: "15px" }}
                            onMouseDown={(e) => {
                              setOpenSubMenu({
                                ...download,
                                mouseX: e.clientX,
                                mouseY: e.clientY,
                              });
                            }}
                          />
                          {download.download_status === "PUBLISHED" && (
                            <div
                              onClick={() =>
                                handleOpenLive(
                                  download.download_url,
                                  download.id
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ color: "#733DFF" }}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </thead>
            </table>
          </div>
        </>
      ) : (
        rederZeroState()
      )}
    </div>
  );
}
export default function ListDownloadContainerEB(props) {
  return <ErrorBoundary>
    <ListDownloadContainer {...props} />
  </ErrorBoundary>;
}
