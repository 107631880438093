import React from "react";
import imageAvatar from "../../../assets/images/image_avatar.png";
import imageFind from "../../../assets/images/image_find.png";
import { apiBase } from "../../utils/apiRequest";

import onboardImage from "../../../assets/images/profile_setup.png";

class UserTypeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      user_types: [
        {
          type: "THERAPIST",
          label: "I am a clinician or coach",
          logo: imageAvatar,
        },
        {
          type: "CLIENT",
          label: "I am seeking care, content, or community",
          logo: imageFind,
        },
        /* {
          type: "PROVIDER",
          label: "I am a service provider for practitioners",
          logo: imageTools,
        }, */
      ],
    };

    this.updateUser = this.updateUser.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  async updateUser(data) {
    const response = await apiBase("../update_user", "POST", data);

    return response;
  }

  async handleClick(type) {
    if (type === "THERAPIST") {
      await this.updateUser({
        user_type: "THERAPIST",
      });

      await this.updateUser({
        is_provider: true,
      });

      return window.location.assign("../therapist_onboard");
    }
    await this.updateUser({
      user_type: type,
    });

    return window.location.assign("../");
  }

  render() {
    return (
      <div style={{
        display: "flex", minHeight: "100vh", justifyContent: "space-between"
      }} className="onboarding_steps">
        <div className="userTypeSelectorContent">
          <h2>Which of these best describes you?</h2>
          {this.state?.user_types?.map((userType, index) => {
            return (
              <div
                className="userType"
                key={index}
                onClick={() => this.handleClick(userType.type)}
              >
                <img src={userType.logo} alt={userType.type.toLowerCase()} />
                <span>{userType.label}</span>
              </div>
            );
          })}
        </div>
        <div className="onboarding_side_pane">
          <img src={onboardImage} style={{marginTop: "auto", width: "100%"}}/>
        </div>
      </div>
    );
  }
}

export default UserTypeContainer;
