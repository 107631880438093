export const ZOOM_INFO_LOCAL_STORAGE_KEY = "tabu/zoomInfo";

import {parseISO} from "date-fns";

export const getZoomInfo = () => {
  let zoomInfo = localStorage.getItem(ZOOM_INFO_LOCAL_STORAGE_KEY);
  if (!zoomInfo) {
    return null;
  }
  zoomInfo = JSON.parse(zoomInfo);
  const expiry = parseISO(zoomInfo.expiry);
  const now = new Date().getTime();
  if (now > expiry) {
    console.log("Zoom token has expired");
    localStorage.removeItem("tabu/zoomInfo");
    return null;
  }
  return zoomInfo;
};

export const isEventUsingZoom = (place) => {
  if (!place || !place["title"] === "Online") {
    return false;
  }

  const usingZoom = place["using_zoom"];
  return (usingZoom && usingZoom !== "false");
}
