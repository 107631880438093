import React from "react";

import DropdownSelect from '../forms/DropdownSelect'
import MultiForm from '../forms/MultiForm';

import {getCareTypes} from "../../queries/user_attributes";
import {mutateCurrentUserCareType} from "../../queries/user";

import {
  useQuery,
} from "@tanstack/react-query";

const MAX_CARE_TYPES = 3;

export default function ProfessionForm(props) {
  const {userCareTypes, userCareTypesMutation, abbreviatedForm} = props;

  const queryCareTypes = useQuery({
    queryKey: ["care_types"],
    queryFn: getCareTypes,
  });

  const loading = !userCareTypes || queryCareTypes.isLoading;
  if (loading) { return <p>Loading...</p>; }

  if (queryCareTypes.isError) {
    return (
      <h1>Error fetching care types: {queryCareTypes.error.message}</h1>
    );
  }

  const careTypes = JSON.parse(queryCareTypes.data.data.care_types);

  const getOrdinal = (i) => {
    switch (i) {
      default:
        return "";
      case 1:
        return "secondary";
      case 2:
        return "tertiary";
    }
  };

  const renderCareTypeSelectionForm = (i) => {
    let careSubtypes = userCareTypes[i] && careTypes.find(
      x => x.id === userCareTypes[i].care_type_id
    ).care_subtypes;

    const selectCareType = (item) => {
      if (userCareTypes.length > i) {
        userCareTypesMutation.mutate({
          action: "update", payload: {
            id: userCareTypes[i].id,
            care_type_id: item.id,
            care_type_name: item.name,
          }
        });
      } else {
        userCareTypesMutation.mutate({
          action: "create", payload: {
            care_type_id: item.id,
            care_type_name: item.name,
          }
        });
      }
    };

    const isCareTypeSelected = userCareTypes[i] != undefined ? userCareTypes[i].care_type.name : ''

    const selectCareSubtype = (item) => {
      userCareTypesMutation.mutate({
        action: "update", payload: {
          id: userCareTypes[i].id,
          care_subtype_id: item.id,
          care_subtype_name: item.name,
        }
      });
    }

    const isCareSubtypeSelected = userCareTypes[i]?.care_subtype != null ?
      userCareTypes[i].care_subtype.name : ''

    const showSubtype = userCareTypes.length > i && !!careSubtypes?.length;

    if (abbreviatedForm) {
      return <div className="tw-flex tw-gap-8 tw-grow">
        <div className="tw-flex tw-flex-col tw-gap-8 tw-grow tw-box-border">
          <p className="tw-font-light" style={{fontSize: "12px"}}>Profession</p>
          <DropdownSelect
            options={careTypes}
            optionName={'name'}
            selectOption={selectCareType}
            selected={isCareTypeSelected}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-8 tw-grow">
          {showSubtype && <div className="tw-flex tw-flex-col tw-gap-8 tw-box-border">
            <p className="tw-font-light" style={{fontSize: "12px"}}>Description (optional)</p>
            <DropdownSelect
              options={careSubtypes}
              optionName={'name'}
              selectOption={selectCareSubtype}
              selected={isCareSubtypeSelected}
            />
          </div>}
        </div>
      </div>
    }

    return (
      <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <h3 className="standard_label" style={{paddingBottom: 40}}
        >What is your {getOrdinal(i)} profession?</h3>
          <DropdownSelect
            options={careTypes}
            optionName={'name'}
            selectOption={selectCareType}
            selected={isCareTypeSelected}
          />

          {showSubtype && <div style={{marginTop: 40}}>
            <h3
              className="standard_label" style={{paddingBottom: 10}}
            >What kind of {userCareTypes[i].care_type.name} are you?</h3>
            <h4
              style={{marginBottom: 40}} className="halfPurple"
            >Optional, add for more specificity</h4>
            <DropdownSelect
              options={careSubtypes}
              optionName={'name'}
              selectOption={selectCareSubtype}
              selected={isCareSubtypeSelected}
            />
          </div>}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={abbreviatedForm ? "" : "onboard_field_section"} style={{
        display: "flex",
        gap: "20px",
        width: "100%",
      }}>
        <div style={{width: "100%"}}>
          <MultiForm
            entries={userCareTypes}
            maxCount={MAX_CARE_TYPES}
            renderForm={renderCareTypeSelectionForm}
            addButtonClassName={abbreviatedForm &&
              "tw-flex tw-rounded-lg tw-w-fit tw-cursor-pointer " +
              "tw-justify-center tw-px-2 tw-py-1 tw-gap-2 " +
              "dark-purple-border dark-purple-text "
            }
            addButtonText="Add profession"
            deleteFn={(i) => {
              return userCareTypesMutation.mutate({
                action: "delete", payload: {
                  id: userCareTypes[i].id,
                },
              });
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
