import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCloudDownloadAlt, faWallet} from "@fortawesome/free-solid-svg-icons";

export default function MyCollectionContainer(props) {
  const { onFinish } = props;
  const [myDownloads, setMyDownloads] = useState();
  const [myEvents, setMyEvents] = useState();

  const getDownloads = () => {
    $.ajax({
      url: `../get_my_downloads`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        setMyDownloads(JSON.parse(data.downloads));
      },
      error: function (xhr, status, err) {
        console.log(err);
      },
    });
  };

  const getEvents = () => {
    $.ajax({
      url: `../get_my_events`,
      dataType: "JSON",
      type: "GET",
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        setMyEvents(JSON.parse(data.events));
      },
      error: function (xhr, status, err) {
        console.log(err);
      },
    });
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const getPreview = (thumbnail) => {
    const file = dataURLtoFile(thumbnail, "thumbnail_preview");

    return URL.createObjectURL(file);
  };

  const renderEmptyState = () => {
    return <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-p-16 tw-gap-8 tw-box-border">
      <p className="tw-text-4xl">{"👀"}</p>
      <p className="text-20 tw-font-semibold">Your collection is empty!</p>
      <p className="text-16">{"Anything you purchase on providers' profiles will show up here."}</p>
    </div>
  };

  useEffect(() => {
    onFinish();
    getDownloads();
    getEvents();
  }, []);

  return (
    <div className="myCollectionContainer">
      <div className="createHeaderContainer">
        <div className="stackableContainerStyle">
          <h1>Your collection</h1>
        </div>
      </div>
      <div className="myCollection--content">
        {myDownloads?.length > 0 && (
          <div className="row--list">
            <h2>Downloads</h2>
            <div className="rowScroll">
              {myDownloads?.map((download, i) => {
                return (
                  <div key={i} className="download--card">
                    <div className="download--card--thumbnail">
                      <img src={download.thumb_url} onClick={() => {
                        const url = `${process.env.APP_URL.replace(
                          "app.",
                          `${download?.slug}.`
                        )}/d/${download?.download_url}`;
                        window.location.assign(url);
                      }} alt="thumbnail" />
                    </div>
                    <div className="download--card--button--position">
                      <div className="download--card--button">
                        <a
                          href={download.file_url}
                          download={download.download_file_title}
                          style={{
                            fontSize: "14px",
                            color: "#0a2175",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCloudDownloadAlt}
                            style={{ color: "#fff", fontSize: "22px" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="download--card--title">
                      <h2>{download.download_title}</h2>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {myEvents?.length > 0 && (
          <>
            <h1 />
            <div className="row--list">
              <h2>Events</h2>
              <div className="rowScroll">
                {myEvents?.map((event, i) => {
                  return (
                    <div
                      key={i}
                      className="download--card"
                      onClick={() => {
                        const url = `${process.env.APP_URL.replace(
                          "app.",
                          `${event?.slug}.`
                        )}/e/${event?.event_url}`;
                        window.location.assign(url);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="download--card--thumbnail">
                        <img src={event.thumb_url} alt="thumbnail" />
                      </div>
                      <div className="download--card--button--position"></div>
                      <div className="download--card--title">
                        <h2>{event.title}</h2>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {myEvents?.length === 0 && renderEmptyState()}
      </div>
    </div>
  );
}
