import React from "react"
import PropTypes from "prop-types"

const SelectButton = props => (
  <button
    className={props.selected ? "select_button_selected" : "select_button"}
    onClick={() => props.onClick(props.value)}
  >{props.text}</button>
)

export default SelectButton;
