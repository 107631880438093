import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format, parseISO } from "date-fns";

export default function IncomeTimelineChart({
  getDigitalDownloadIncomeFromMonth, getEventIncomeFromMonth
}) {
  const subtractMonths = (numOfMonths, date = new Date()) => {
    date.setMonth(date.getMonth() - numOfMonths);

    return date;
  };

  const Mydata = [
    {
      name: format(subtractMonths(4), "MMM, yyyy"),
      "Digital Download": getDigitalDownloadIncomeFromMonth(4),
    },
    {
      name: format(subtractMonths(4), "MMM, yyyy"),
      "Event": getEventIncomeFromMonth(4),
    },
    {
      name: format(subtractMonths(3), "MMM, yyyy"),
      "Digital Download": getDigitalDownloadIncomeFromMonth(3),
    },
    {
      name: format(subtractMonths(3), "MMM, yyyy"),
      "Event": getEventIncomeFromMonth(3),
    },
    {
      name: format(subtractMonths(2), "MMM, yyyy"),
      "Digital Download": getDigitalDownloadIncomeFromMonth(2),
    },
    {
      name: format(subtractMonths(2), "MMM, yyyy"),
      "Event": getEventIncomeFromMonth(2),
    },
    {
      name: format(subtractMonths(1), "MMM, yyyy"),
      "Digital Download": getDigitalDownloadIncomeFromMonth(1),
    },
    {
      name: format(subtractMonths(1), "MMM, yyyy"),
      "Event": getEventIncomeFromMonth(1),
    },
    {
      name: format(new Date(), "MMM, yyyy"),
      "Digital Download": getDigitalDownloadIncomeFromMonth(0),
    },
    {
      name: format(new Date(), "MMM, yyyy"),
      "Event": getEventIncomeFromMonth(0),
    },
  ];

  return (
    <div className="timeline--chart">
      <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
        <BarChart data={Mydata}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Digital Download" fill="#F19E80" />
          <Bar dataKey="Event" fill="#E80F19" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
