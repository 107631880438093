import React, {useState, useEffect} from "react";

import Editor, {TextInput, formPadding, textInputClasses} from "../components/Editor";
import ImageUpload from "../components/ImageUpload";
import {ProfileAbout} from "../profile/ProviderProfileContainer";

import EditLocation from "../profile_forms/EditLocation";
import DropdownSelect from '../forms/DropdownSelect';
import MultiForm from '../forms/MultiForm';
import NormalSelect from "../profile_forms/NormalSelect";
import TypeOrSelectForm from "../forms/TypeOrSelectForm";

import formatCareType from "../../utils/care_types";

import {
  useQuery,
} from '@tanstack/react-query';

import {
  getDegreeTypes,
  getIdentityExpertises,
  getLanguages,
  getSpecialties,
} from "../../queries/user_attributes";

import {asyncApiRequest} from "../../utils/apiRequest";
import {saveUserChanges, uploadCoverPhoto, removeCoverPhoto} from "../../queries/user";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

const years = [];
const currentYear = (new Date()).getFullYear();
let id = 0;
for (let i = currentYear; i >= currentYear - 70; i--) {
  years.push({id: id++, year: `${i}`});
}

function EditBioPrompts({bio, bioPrompts, changeField}) {
  const [page, setPage] = useState(0);

  if (!bioPrompts?.length) {
    return <p>Loading bio prompts...</p>;
  }

  const updateBio = (promptId, response) => {
    const newBio = {...bio};
    newBio[promptId] = response;
    changeField("bio", newBio);
  };

  const nextPage = () => setPage((page + 1) % bioPrompts.length);
  const prevPage = () => setPage(page == 0 ? bioPrompts.length - 1 : page - 1);

  const prompt = bioPrompts[page];

  const renderPageButtons = () => {
    return <div className="tw-flex tw-justify-between tw-items-center">
      <button className="dark-accent-background tw-p-1 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center"
        style={{borderRadius: "40px", backgroundColor: (prevPage ? null : "lightgray")}}
        onClick={prevPage}>
        <FontAwesomeIcon icon={faArrowLeft} className="tw-text-white" size="xs" />
      </button>
      <button className="dark-accent-background tw-p-1 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center"
        style={{borderRadius: "40px", backgroundColor: (nextPage ? null : "lightgray")}}
        onClick={nextPage}>
        <FontAwesomeIcon icon={faArrowRight} className="tw-text-white" size="xs" />
      </button>
    </div>;
  }

  return <div className={
    "tw-flex tw-flex-col tw-p-4 tw-rounded-lg light-accent-background dark-shadow " +
    "dark-border tw-gap-2"
  }>
    <p
      style={{fontSize: "22px"}} className="tw-font-bold tw-leading-snug"
    >{prompt.prompt}</p>
    <TextInput
      key={prompt.id}
      defaultValue={bio[prompt.id]}
      onChange={x => updateBio(prompt.id, x)}
      classes="tw-font-semibold font-16 tw-leading-normal"
      useTextArea={true}
    />
    {renderPageButtons()}
  </div>
}

export default function EditProfileAbout(props) {
  const [user, setUser] = useState(props.user);
  const [userChanges, setUserChanges] = useState({});
  const [userChangesRecentlySaved, setUserChangesRecentlySaved] = useState(false);
  const [bioPrompts, setBioPrompts] = useState({});

  const userChangesMade = Object.keys(userChanges).length > 0;

  const querySpecialties = useQuery({
    queryKey: ["specialties"],
    queryFn: getSpecialties,
  });

  const queryIdentityExpertises = useQuery({
    queryKey: ["identity_expertises"],
    queryFn: getIdentityExpertises,
  });

  const queryDegreeTypes = useQuery({
    queryKey: ["degree_types"],
    queryFn: getDegreeTypes,
  });

  const queryLanguages = useQuery({
    queryKey: ["languages"],
    queryFn: getLanguages,
  });

  const saveChanges = async () => {
    if (!userChangesMade) {
      return;
    }
    await saveUserChanges(userChanges);
    setUserChanges({});
    setUserChangesRecentlySaved(true);
  };

  const changeField = (fieldName, value) => {
    setUser((prevUser) => {
      let u = {...prevUser};
      u[fieldName] = value;
      return u;
    });
    setUserChanges((prevChanges) => {
      let changes = {...prevChanges};
      changes[fieldName] = value;
      return changes;
    });
  };

  useEffect(() => {
    if (userChangesRecentlySaved) {
      setTimeout(() => setUserChangesRecentlySaved(false), 3000);
    }
  }, [userChangesRecentlySaved]);

  const fetchBioPrompts = async () => {
    const resp = await asyncApiRequest("/get_bio_prompts");
    setBioPrompts(JSON.parse(resp.data.bio_prompts));
  };

  useEffect(() => {
    fetchBioPrompts();
  }, []);

  const renderPhoto = () => {
    const coverPhotoUrl = user.cover_photo_url;
    const ASPECT = 1.0;

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="text-12">{
        "Show off your personality a bit with an inviting photo. 📸"
      }<br />{
        "We recommend a high resolution square image (1:1 ratio)"
      }</p>
      <ImageUpload
        height="300px"
        width="300px"
        key={`imageUpload_${coverPhotoUrl}`}
        thumbUrl={coverPhotoUrl}
        uploadFn={(image) => uploadCoverPhoto(image, (coverPhotoUrl) => {
          changeField("cover_photo_url", coverPhotoUrl);
        })}
        deleteFn={() => removeCoverPhoto(() => changeField("cover_photo_url", null))}
        aspectRatio={ASPECT}
      />
    </div>;
  };

  const renderMultiSelect = (
    title, labelElement, placeholder, optionQuery, data_name,
    selections, setSelections, transforms, maxEntries
  ) => {
    if (optionQuery.isLoading) return <p>Loading {title} options...</p>;

    let options;
    if (!optionQuery.isError) {
      options = optionQuery.data.data[data_name];
      options = options && JSON.parse(options).filter(x => !x.private);
    }
    if (!options) return <p>Error loading {title} options</p>;

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="tw-font-semibold text-16">{title}</p>
      {labelElement}
      <TypeOrSelectForm
        options={options}
        placeholder={placeholder}
        selections={selections}
        setSelections={setSelections}
        transforms={transforms}
        maxEntries={maxEntries}
      />
    </div>;
  };

  const transformSpecialtyToOption = (specialty) => {
    return {
      value: specialty.id,
      label: specialty.name,
    }
  };

  const transformSpecialtyFromOption = (option) => {
    return {
      id: option.value,
      name: option.label,
      private: option.value === option.label,
    };
  };

  const transformIdentityToOption = (identity) => {
    return {
      value: identity.id,
      label: identity.name,
    };
  };

  const transformIdentityFromOption = (option) => {
    return {
      id: option.value,
      name: option.label,
      private: option.value === option.label,
    };
  };

  const renderFocusAreas = () => {
    return <div className={`tw-flex tw-flex-col ${formPadding} tw-pt-3`}>
      {renderMultiSelect(
        "Specialties",
        <p className="text-12">
          Please select up to <span className="tw-font-bold">5 specialties</span> to
          help potential clients understand your expertise.
        </p>,
        "Type or select specialties",
        querySpecialties,
        "specialties",
        user.primary_specialties,
        (newPrimaries) => {
          const newExtras = user.extra_specialties.filter(x => !newPrimaries.find(y => y.name === x.name));
          changeField("extra_specialties", newExtras);
          changeField("primary_specialties", newPrimaries)
        },
        {to: transformSpecialtyToOption, from: transformSpecialtyFromOption},
        5,
      )}
      {renderMultiSelect(
        "Additional concerns I treat",
        <p className="text-12">
          Select as many as apply. The more you can narrow down,
          the easier time your ideal client will have identifying
          you as a good fit for their needs.  
        </p>,
        "Type or select additional concerns",
        querySpecialties,
        "specialties",
        user.extra_specialties,
        (newExtras) => {
          const newPrimaries = user.primary_specialties.filter(x => !newExtras.find(y => y.name === x.name));
          changeField("primary_specialties", newPrimaries)
          changeField("extra_specialties", newExtras);
        },
        {to: transformSpecialtyToOption, from: transformSpecialtyFromOption},
      )}
      {renderMultiSelect(
        "Identity or experience expertise",
        <p className="text-12">
          Select as many as apply. The more you can narrow down,
          the less overwhelming and more clear it will be for potential clients.
        </p>,
        "Type or select identities or experience expertise",
        queryIdentityExpertises,
        "identity_expertises",
        user.identity_expertises,
        (x) => changeField("identity_expertises", x),
        {to: transformIdentityToOption, from: transformIdentityFromOption},
      )}
    </div>;
  };

  const renderYearsOfExperience = () => {
    return <div className={`tw-flex tw-flex-col ${formPadding} tw-py-4 tw-grow`}>
      <p className="tw-font-semibold text-20">Experience</p>
      {user.user_caretypes.map((careType, i) => (
        <div key={i} className={`tw-flex tw-flex-col ${formPadding}`}>
        <p className="text-16 tw-font-semibold">
          Practicing as <span className="tw-font-bold">{
            formatCareType(careType, true)
          }</span> since
        </p>
        <DropdownSelect
          options={years}
          optionName={'year'}
          selectOption={(item) => {
            const careTypes = user.user_caretypes;
            careTypes[i].practicing_since = item.year;
            changeField("user_caretypes", careTypes);
          }}
          selected={careType.practicing_since || ""}
        />
        </div>
      ))}
    </div>;
  };

  const handleTextInput = (index, text) => {
    const educations = user.user_educations;
    educations[index] = {...educations[index], school: text};
    if (!educations[index].degree_type) {
      educations[index].degree_type = {name: "", id: 0}
    }
    changeField("user_educations", educations);
  };

  const updateDegreeType = (degreeTypes, selection, index) => {
    const educations = user.user_educations;
    const degreeType = degreeTypes.find(x => x.value === selection.value);
    educations[index] = {
      ...educations[index],
      degree_type_id: degreeType.value,
      degree_type: {
        id: degreeType.value,
        name: degreeType.label,
      },
    }
    if (!educations[index].school) {
      educations[index].school = "";
    }
    changeField("user_educations", educations);
  };

  const renderDegreeTypeDropdown = (i) => {
    if (queryDegreeTypes.isLoading || queryDegreeTypes.isError) {
      return null;
    }
    const degreeTypes = JSON.parse(queryDegreeTypes.data.data.degree_types)?.map(
      ({id, name}) => ({
         value: id,
         label: name,
       }));

    let value = undefined;
    let degreeTypeId = user.user_educations[i]?.degree_type_id;
    if (degreeTypeId) {
      value = degreeTypes.find(x => x.value === degreeTypeId);
    }
    return (
      <NormalSelect
        options={degreeTypes}
        optionName={"name"}
        attribute={i}
        placeholder={"Type of degree"}
        onChange={(selection) => updateDegreeType(degreeTypes, selection, i)}
        value={value}
      />
    );
  };

  const renderEducationForm = (i) => {
    return (
      <div className={`tw-flex tw-flex-col ${formPadding} tw-py-4 tw-w-full`}>
        <p className="text-16 tw-font-semibold">Name of school or program</p>
        <input
          className={`${textInputClasses} tw-grow`}
          defaultValue={user.user_educations[i]?.school}
          onChange={x => handleTextInput(i, x.target.value)}
          placeholder={"Name of school or program"}
        />
        <p className="text-16 tw-font-semibold">Type of degree</p>
        <div>{renderDegreeTypeDropdown(i)}</div>
      </div>
    );
  };

  const renderEducation = () => {
    return <div className={`tw-flex tw-flex-col tw-gap-2 tw-w-full tw-mb-6`}>
      <p className="tw-font-semibold text-20">Education</p>
      <MultiForm
        entries={user.user_educations || []}
        maxCount={3}
        renderForm={renderEducationForm}
        addButtonClassName="tw-flex tw-py-1 tw-px-2 tw-gap-2 tw-justify-center tw-rounded-lg thick-dark-accent-border tw-w-fit dark-accent-shadow"
        deleteFn={(i) => {
          if (!user.user_educations) {
            return;
          }
          let educations = user.user_educations.slice(0, i).concat(user.user_educations.slice(i + 1));
          changeField("user_educations", educations);
        }}
        addButtonText="Add school"
      />
    </div>;
  };

  const renderCertificationForm = (i) => {
    return <div className={`tw-grow tw-pr-8 ${formPadding} tw-py-4 tw-w-full`}>
      <TextInput
        label="Name of certification"
        defaultValue={user.user_certifications[i]?.name}
        placeholder="ex. Member of CA Marriage Therapists"
        onChange={(x) => {
          const certifications = user.user_certifications || [];
          certifications[i] = {
            user_id: user.id,
            name: x,
          };
          changeField("user_certifications", certifications);
        }}
      />
    </div>;
  };

  const renderCertifications = () => {
    return <div className={`tw-flex tw-flex-col tw-gap-2 tw-w-full tw-mb-6`}>
      <p className="tw-font-semibold text-20">Certifications</p>
      <MultiForm
        entries={user.user_certifications || []}
        maxCount={10}
        renderForm={renderCertificationForm}
        addButtonClassName="tw-flex tw-py-1 tw-px-2 tw-gap-2 tw-justify-center tw-rounded-lg thick-dark-accent-border tw-w-fit dark-accent-shadow"
        deleteFn={(i) => {
          if (!user.user_certifications) {
            return;
          }
          let certifications = user.user_certifications.slice(0, i).concat(user.user_certifications.slice(i + 1));
          changeField("user_certifications", certifications);
        }}
        addButtonText="Add certification"
      />
    </div>;
  };

  const renderLanguage = () => {
    if (queryLanguages.isLoading) return <p>Loading...</p>;
    if (queryLanguages.isError) return <p>Failed to retrieve languages</p>;

    const languages = JSON.parse(queryLanguages.data.data.languages);

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="tw-font-semibold text-20">Language</p>
      <p className="tw-font-semibold text-16">What language(s) do you speak with clients?</p>
      <p className="tw-font-light text-12">Select as many as apply</p>
      <TypeOrSelectForm
        options={languages}
        selections={user.languages}
        placeholder="Type or select languages"
        setSelections={x => changeField("languages", x)}
        transforms={{
          to: (x => ({value: x.id, label: x.name})),
          from: (x => ({id: x.value, name: x.label})),
        }}
        maxEntries={10}
      />
    </div>;
  };

  const renderBackground = () => {
    return <div className={`tw-flex tw-flex-col ${formPadding} tw-pt-3 tw-items-start`}>
      {renderYearsOfExperience()}
      {renderEducation()}
      {renderCertifications()}
      {renderLanguage()}
    </div>;
  };

  const sections = [{
    title: "Cover Photo",
    render: renderPhoto,
  }, {
    title: "Niche",
    render: () => (
      <div className="tw-w-full">
        <TextInput 
          label="Who do you help and how do you help them?"
          defaultValue={user.bio && user.bio["brief"]}
          placeholder="I help X group do Y so they can Z"
          onChange={x => {
            const newBio = {...user.bio, brief: x}
            changeField("bio", newBio);
          }}
          useTextArea={true}
        />
      </div>
    ),
  }, {
    title: "Focus areas",
    render: renderFocusAreas,
  }, {
    title: "Background & Details",
    render: renderBackground,
  }, {
    title: "Location",
    render: () => <EditLocation location={user.user_locations[0] || {}} changeLocation={(k, v) => {
      const prevLocation = user.user_locations?.length ? user.user_locations[0] : {};
      const newLocation = {...prevLocation};
      newLocation[k] = v;
      changeField("user_locations", [newLocation]);
    }}
    />
  }, {
    title: "Personality prompts",
    render: () => (
      <div className="tw-w-full">
        <EditBioPrompts
          bio={user.bio || {}} bioPrompts={bioPrompts} changeField={changeField}
        />
      </div>
    ),
  }];

  if (!user) {
    return <p>Loading...</p>;
  }

  return <Editor
    title="About"
    changesMade={userChangesMade}
    changesRecentlySaved={userChangesRecentlySaved}
    saveChanges={saveChanges}
    sections={sections}
    renderPreview={() => <ProfileAbout user={user} currentUser={user} />}
  />;
};
