import React from "react";

export default function ZoomSupport() {
  return (
    <div className="zoom--info--container">
      <div className="zoom--info">
        <h1>Zoom Support</h1>
        <hr />
        <p>Having issues connecting Zoom with Tabu Wellness events?</p>
        <p>Take a look at the <a href="/zoom_documentation">zoom documentation</a> first.</p>
        <p>Then contact our <a href={`mailto:zoom@${process.env.ROOT_DOMAIN}`}>support line</a> via email.</p>
        <p>Be sure to include:</p>
        <p>• your Tabu Wellness username</p>
        <p>• the title or URL of your event</p>
        <hr />
        <h3>Support Team SLA</h3>
        <p>{"We're available to support your Zoom integration issues between 9 AM - 5 PM PST."}</p>
        <p>We will get back to you within 2 business days or less.</p>
      </div>
    </div>
  );
}
