import React, {useEffect, useState} from "react";

import SelectButton from "../forms/SelectButton";
import SelectTextInput from "../forms/SelectTextInput";

export default function MultiSelectForm(props) {
  const {choices, selections, onSelect, onCustomEntry, placeholder} = props;

  const [customEntry, setCustomEntry] = useState(props.customEntry);
  const [updateTimeout, setUpdateTimeout] = useState();

  useEffect(() => {
    if (!onCustomEntry) {
      return;
    }

    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }

    if (customEntry === props.customEntry) {
      return;
    }

    const timeout = setTimeout(
      () => props.onCustomEntry(customEntry), 500
    )
    setUpdateTimeout(timeout);

    return () => {clearTimeout(updateTimeout); setUpdateTimeout(null);}
  }, [customEntry]);

  const isSelected = (optionId) => {
    return selections.find(x => x.id === optionId);
  };

  const renderChoices = () => {
    return choices.map((option, i) => {
        return(
          <div style={{paddingTop: 10}} key={i}>
            <SelectButton
              text={option.name}
              value={option.id}
              selected={isSelected(option.id)}
              onClick={() => onSelect(option.id, isSelected(option.id))}
            />
          </div>
        )
    })
  }

  const renderCustomEntryInput = () => {
    return (
      <SelectTextInput
        type="text"
        autofocus={true}
        classWhenNonEmpty="select_button_selected"
        defaultValue={props.customEntry}
        handleChange={setCustomEntry}
        placeholder={placeholder}
      />
    );
  };

  return (
      <>
        {renderChoices()}
        {props.onCustomEntry && renderCustomEntryInput()}
     </>
  );
};
