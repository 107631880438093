import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptop,
  faMapMarkerAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { format, parseISO } from "date-fns";
import { getTimeZone } from "../../utils/time";
import { isEventUsingZoom } from "../../utils/zoom";

const ICON_STYLE = { color: "#733dff", marginRight: "2px", height: "auto" };

function EventLocation({eventPlace}) {
  if (!eventPlace) {
    return null;
  }

  const renderLiveStreamIcon = () => {
    return (
      <a class="eventLiveStreamIcon" data-tool-tip="Live stream available">
      <FontAwesomeIcon
        icon={faVideo}
        style={{...ICON_STYLE, marginTop: "2px", zIndex: 73}}
      />
      </a>
    );
  }

  let location = undefined;
  let icon = undefined;
  if (eventPlace.title === "Online") {
    if (isEventUsingZoom(eventPlace)) {
      location = "Zoom";
    } else {
      location = "Online";
    }
    icon = faLaptop
  } else if (eventPlace.title === "IRL") {
    if (eventPlace.public_place) {
      location = eventPlace["location_address"]["formatted_address"];
    } else {
      location = "Location shown upon registration";
    }
    icon = faMapMarkerAlt;
  }
  return (
    <div className="eventLocationContainer">
      <FontAwesomeIcon
        icon={icon}
        style={ICON_STYLE}
      />
      {eventPlace.live_stream && renderLiveStreamIcon()}
      <p>{location}</p>
    </div>
  );
};

function EventTime({time}) {
  if (!time) {
    return null;
  }

  try {
    const dateString = format(
      parseISO(time),
      "EEE, MMM dd | hh:mm a"
    );

    let formatted = `${dateString} ${getTimeZone(parseISO(time))}`

    return (
      <div className="eventTime"><p>{formatted}</p></div>
    );
  } catch (e)  {
    console.log("failed to render event time:", time);
    return null;
  }
};

export { EventLocation, EventTime };
