import React from "react"
import PropTypes from "prop-types"
import TextInput from '../forms/TextInput';

class AdjectivesOnboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: [],
      loaded: false,
      adjectives: this.props.currentUser.adjectives === null ? [] : this.props.currentUser.adjectives,
      adjective_1: '',
      adjective_2: '',
      adjective_3: '',
      timeout: null
    }
    this.updateUser = this.updateUser.bind(this)
  }

  componentDidMount() {
    if (this.state.adjectives !== null || "") {
      if (!Array.isArray(this.state.adjectives)) {
        this.state.adjectives = JSON.parse(this.state.adjectives)
      }
      if (this.state.adjectives.length > 0) {
        this.setState({
          adjective_1: this.state.adjectives[0],
          adjective_2: this.state.adjectives[1],
          adjective_3: this.state.adjectives[2]
        })
      }
    }
  }

  updateUser(key, value) {
    if (key === 'adjective_1') {
      this.setState({
        adjective_1: value
      })
    } else if (key === 'adjective_2') {
      this.setState({
        adjective_2: value
      })
    } else if (key === 'adjective_3') {
      this.setState({
        adjective_3: value
      })
    }

    const { timeout } = this.state;
      clearTimeout(timeout);
      this.setState({
        timeout: setTimeout(() => {
          let data = {
            adjectives: [this.state.adjective_1, this.state.adjective_2, this.state.adjective_3]
          }
          this.props.updateUser(data)
        }, 500)
      })
  }

  render () {
    return (
      <React.Fragment>
        <div className="onboard_field_section">
          <h3 className="standard_label" style={{paddingBottom: 10}}>How would your clients describe you?</h3>
          <h4 className="standard_label" style={{paddingBottom: 40, opacity: .5}}>Write in 3 adjectives that best describe you</h4>
          <TextInput
            type="text"
            autofocus={true}
            value={this.state.adjective_1}
            attribute={'adjective_1'}
            handleChange={this.updateUser}
            placeholder="adjective"
          />
          <TextInput
            type="text"
            value={this.state.adjective_2}
            attribute={'adjective_2'}
            handleChange={this.updateUser}
            placeholder="adjective"
          />
          <TextInput
            type="text"
            value={this.state.adjective_3}
            attribute={'adjective_3'}
            handleChange={this.updateUser}
            placeholder="adjective"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default AdjectivesOnboard
