import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faTrash,
  faFile,
  faHeadphonesAlt,
} from "@fortawesome/free-solid-svg-icons";
import Notice from "./Notice";
import FileMetadataContext from "../context/FileMetadataContext";
import FileNameContext from "../context/FileNameContext";
import FileSizeContext from "../context/FileSizeContext";

export default function FilesDragAndDrop({ acceptedFiles, onUploadFile }) {
  const [fileMetadata, setFileMetadata] = useContext(FileMetadataContext);
  const [enabled, setEnabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();

  const dropzoneConfig = {
    multiple: false,
    onDrop: (upload) => {
      upload.map((item) => {
        onUploadFile(item);
        setEnabled(false);  // update UI state
        return;  // only a single file upload is supported
      });
    },
    onDropRejected: (rejectedFiles) => {
      setMessage("The selected format is invalid!");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    },
    disabled: !enabled,
    maxSize: process.env.MAX_UPLOAD_SIZE_BYTES,
  }

  if (acceptedFiles?.accept !== '*/*') {
    dropzoneConfig.accept = acceptedFiles?.accept
  }

  const { getRootProps, getInputProps } = useDropzone(dropzoneConfig);

  useEffect(() => {
    // when the kind of content (and, therefore, the accepted files filter) is changed,
    // clear any uploaded file state.
    setEnabled(!!acceptedFiles);
  }, [acceptedFiles]);

  return (
    <div>
      {showAlert && <Notice message={message} />}
      {fileMetadata?.name && fileMetadata?.size ? (
        <div className="FilesDragAndDrop">
          <div className="fileUploaded" style={{ cursor: "default" }}>
            <div className="fileWrap">
              {acceptedFiles?.title === "File" && (
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    fontSize: "25px",
                    marginRight: "15px",
                    color: "#0A2175",
                  }}
                />
              )}

              {acceptedFiles?.title === "Audio" && (
                <FontAwesomeIcon
                  icon={faHeadphonesAlt}
                  style={{
                    fontSize: "25px",
                    marginRight: "15px",
                    color: "#0A2175",
                  }}
                />
              )}

              <div className="fileContent">
                {fileMetadata?.name && (
                  <h2>
                    {fileMetadata?.name
                      .split(".", fileMetadata.name.split(".").length - 1)
                      .join(".").length < 30
                      ? fileMetadata.name
                          .split(".", fileMetadata.name.split(".").length - 1)
                          .join(".")
                      : `${fileMetadata.name
                          .split(".", fileMetadata.name.split(".").length - 1)
                          .join(".")
                          .slice(0, 25)}...`}
                  </h2>
                )}
                <p>
                  {fileMetadata?.name
                    ?.split(".")
                    [fileMetadata.name.split(".").length - 1]?.toUpperCase()}{" "}
                  • {fileMetadata.size} KB
                </p>
              </div>
            </div>
            <FontAwesomeIcon
              icon={faTrash}
              className="icon-cog"
              onClick={() => {
                onUploadFile(null);
                setFileUploaded(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className="FilesDragAndDrop">
          <input {...getInputProps()} />
          <div
            className={
              enabled
                ? "FilesDragAndDrop__area"
                : "FilesDragAndDrop__area disabled"
            }
          >
            <h2>Drop your file or</h2>
            <FontAwesomeIcon icon={faPlusCircle} className="icon-cog" />
          </div>
        </div>
      )}
    </div>
  );
}
