import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CreateCards from "../components/CreateCards";

export default function CreateContainer(props) {
  const { cards, onFinish } = props;

  useEffect(() => {
    onFinish();
  }, []);

  return (
    <div className="createContainer">
      <div className="createHeaderContainer">
        <div className="stackableContainerStyle">
          <h1>What would you like to create?</h1>
        </div>
      </div>
      <div className="createCards">
        {cards.map((card) => {
          return (
            <div key={card.id}>
              <Link to={card.route}>
                <CreateCards
                  title={card.title}
                  icon={card.icon}
                  desc={card.desc}
                  click={() => card.click(card.id)}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
