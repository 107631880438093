import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const AutoSave = (props) => {
  return (
    <section className="autosave--content">
      {props.saving && (
        <>
          <p>Saving</p>
          <section className="loading--save">
            <section className="loading--save--animation" />
          </section>
        </>
      )}

      {props.saved && (
        <>
          <p>Saved</p>
          <div className="saved">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        </>
      )}
    </section>
  );
};

export default AutoSave;
