import React from "react";

const ToggleSwitchComponent = (props) => {
  return (
    <label className="toggle--switch--component">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={() => props.toggleSwitch()}
      />
      <span className="switch" />
    </label>
  );
};

export default ToggleSwitchComponent;
