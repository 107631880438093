import React, { useState, useContext, useEffect } from "react";
import {
  faMapPin,
  faLaptop,
  faMapMarkerAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactQuill from "react-quill";
import zoom from "../../../assets/images/zoom.png";
import EventContext from "../context/EventContext";
import tokenVerify from "../../utils/checkZoomToken";
import ZoomDetailsModal from "./ZoomDetailsModal";
import { parseISO } from "date-fns";
import SearchLocationInput from "./SearchLocationInput";
import ToggleSwitchComponent from "./ToggleSwitchComponent";
import {
  isEventUsingZoom,
  getZoomInfo,
} from "../../utils/zoom";
import Button from "../forms/Button";

export default function EventTheDeetsContent(props) {
  const [event, setEvent] = useContext(EventContext);
  const [zoomPollInterval, setZoomPollInterval] = useState();
  const [zoomInfo, setZoomInfo] = useState(getZoomInfo());
  const [deleteZoomModal, setDeleteZoomModal] = useState(false);
  const [waitingZoom, setWaitingZoom] = useState(false);
  const [eventInfo, setEventInfo] = useState(undefined);
  const [eventLink, setEventLink] = useState("");
  const [usingZoom, setUsingZoom] = useState(false);
  const [locationAddress, setLocationAddress] = useState("");
  const [liveStream, setLiveStream] = useState("");
  const [openZoomDetails, setOpenZoomDetails] = useState(false);
  const [eventTakePlaceState, setEventTakePlaceState] = useState([
    {
      id: 0,
      title: "Online",
      icon: faLaptop,
      isSelected: false,
    },
    {
      id: 1,
      title: "IRL",
      icon: faMapPin,
      isSelected: false,
    },
  ]);

  const updateZoomToken = (url) => {
    $.ajax({
      url: `/connect_zoom_user`,
      dataType: "JSON",
      type: "GET",
      header: { "Content-Type": "application/json" },
      success: (data) => {
        window.open(url, "_blank");
        setWaitingZoom(true);
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const checkForZoomInfo = () => {
    const zoomInfo = getZoomInfo();
    if (zoomInfo) {
      setZoomInfo(zoomInfo);
      setWaitingZoom(false);
    }
  }

  useEffect(() => {
    if (waitingZoom) {
      setZoomPollInterval(setInterval(checkForZoomInfo, 1000));
    } else {
      clearInterval(zoomPollInterval);
    }
    return () => {
      clearInterval(zoomPollInterval);
    }
  }, [waitingZoom]);

  const deleteZoomMeeting = async (meetingId) => {
    if (!zoomInfo) {
      console.log("no Zoom token - can't delete meeting");
      return;
    }

    return $.ajax({
      url: `/zoom_delete_meeting?meetingId=${meetingId}`,
      dataType: "JSON",
      type: "POST",
      data: {
        access_token: zoomInfo.access_token,
      },
      header: {
        "Content-Type": "application/json",
      },
    });
  };

  const handleCreateZoomMeeting = async (zoomInfo) => {
    if (!zoomInfo) {
      $.ajax({
        url: `/connect_zoom_user`,
        dataType: "JSON",
        type: "GET",
        header: { "Content-Type": "application/json" },
        success: (data) => updateZoomToken(data.zoomUrl),
        error: (xhr, status, err) => {
          console.log(err);
        },
      });
      return;
    }

    const duration = getDuration();
    const jsonBody = {
      agenda: event.title,
      default_password: false,
      duration: duration,
      pre_schedule: false,
      start_time: event.date_start_time,
      template_id: "Dv4YdINdTk+Z5RToadh5ug==",
      timezone: "UTC",
      topic: event.title,
      type: 2,
    };

    $.ajax({
      url: "/zoom_create_meeting",
      dataType: "JSON",
      type: "POST",
      data: {
        body: jsonBody,
        access_token: zoomInfo.access_token,
      },
      header: {
        "Content-Type": "application/json",
      },
      success: (data) => {
        handleUpdateEvent("event_place", {
          ...event.event_place,
          zoomConfigApi: data.response,
        });
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  const handleUpdateEvent = (field, value) => {
    setEvent((previousValue) => {
      return {
        ...previousValue,
        [field]: value,
      };
    });
    props.updateUserEvent(field, value);
  };

  const handleEventTakePlace = (title) => {
    handleUpdateEvent("event_place", { title: title });
    setEventTakePlaceState((prevState) =>
      prevState.map((item) =>
        item.title === title
          ? { ...item, isSelected: true }
          : { ...item, isSelected: false }
      )
    );
  };

  const getDuration = () => {
    return parseInt(
      Math.abs(
        parseISO(event?.date_end_time).getTime() -
          parseISO(event?.date_start_time).getTime()
      ) /
        (1000 * 60)
    ) || 60;
  };

  const updateZoomStamps = async () => {
    if (!canModifyZoomMeeting) {
      return;
    }

    const duration = getDuration();
    const zoomConfigApi = event.event_place.zoomConfigApi;
    if (zoomConfigApi.start_time === event.date_start_time &&
      zoomConfigApi.duration === duration) {
      return;
    }

    $.ajax({
      url: `/zoom_update_meeting?meetingId=${zoomConfigApi.id}`,
      dataType: "JSON",
      type: "POST",
      data: {
        access_token: zoomInfo.access_token,
        body: {
          duration: duration,
          start_time: event.date_start_time,
          timezone: "UTC",
        },
      },
      header: {
        "Content-Type": "application/json",
      },
    });
  };


  useEffect(() => {
    if (deleteZoomModal || openZoomDetails) {
      window.scrollTo(0, 0);
    }
  }, [deleteZoomModal, openZoomDetails]);

  useEffect(() => {
    if (event?.event_place?.title) {
      setEventTakePlaceState((prevState) =>
        prevState.map((item) =>
          item.title === event?.event_place?.title
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false }
        )
      );
    }

    if (event?.event_place?.url !== eventLink)
      setEventLink(event?.event_place?.url);

    let isUsingZoom = isEventUsingZoom(event?.event_place);
    if (isUsingZoom != usingZoom) {
      setUsingZoom(isUsingZoom);
    }

    if (isUsingZoom) {
      (async () => {
        updateZoomStamps();
      })();
    }

    if (event?.event_place?.location_address !== locationAddress)
      setLocationAddress(event?.event_place?.location_address);

    if (event?.event_place?.live_stream !== liveStream)
      setLiveStream(event?.event_place?.live_stream);

    const newEventInfo = event?.info || "";
    if (newEventInfo !== eventInfo) {
      setEventInfo(event?.info);
    }
  }, [event]);

  const canModifyZoomMeeting = usingZoom && event?.event_place?.zoomConfigApi?.id && zoomInfo?.access_token;

  const renderDeleteZoomModal = () => {
    if (!event?.event_place?.zoomConfigApi?.id) {
      console.log("no Zoom meeting to delete");
      return null;
    }

    return (
      <div style={{ margin: 0 }}>
        <div className="zoom--details--modal">
          <div className="content">
            <div className="header">
              <h3>Delete Zoom meeting</h3>
            </div>
            <p>Changing the event location will delete the associated Zoom meeting.</p>
            <p>{("If you've already shared the event externally, " +
                 "be sure to inform attendees of the new link.")}</p>
            <div style={{
              marginTop: "auto",
              paddingTop: "40px",
              display: "flex",
              gap: "20px",
            }}>
              <Button
                buttonClass="button_modal_outline"
                text="Cancel"
                style={{height: "50px"}}
                handleClick={() => setDeleteZoomModal(false)}
              />
              <Button
                buttonClass="button_modal_primary"
                text="Delete it!"
                style={{height: "50px"}}
                handleClick={async () => {
                  deleteZoomMeeting(event.event_place.zoomConfigApi.id);
                  deleteZoomModal();
                  setDeleteZoomModal(false);
                }}
              />
            </div>
          </div>
        </div>
        <div
          onClick={() => setDeleteZoomModal(false)}
          className="zoom--details--outside"
        ></div>
      </div>
    );
  };

  return (
    <>
      {openZoomDetails && (
        <ZoomDetailsModal
          onClose={() => setOpenZoomDetails(false)}
          zoomInfo={zoomInfo}
          meetingId={event?.event_place?.zoomConfigApi.id}
        />
      )}
      {deleteZoomModal && renderDeleteZoomModal()}
      <div
        className={
          openZoomDetails || deleteZoomModal
            ? "eventsStepContent overflow--hidden"
            : "eventsStepContent"
        }
      >
        <h2 id="step1">Where will your event take place?</h2>
        <div className="event--place">
          {eventTakePlaceState.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  item.isSelected
                    ? "event--place--card selected"
                    : "event--place--card"
                }
                onClick={() => {
                  if (!item.isSelected) {
                    if (item.title !== "Online" && canModifyZoomMeeting) {
                      setDeleteZoomModal(() => {
                        return () => {
                          handleEventTakePlace(item.title);
                        }
                      });
                    } else {
                      handleEventTakePlace(item.title);
                    }
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{ color: "#0A2175" }}
                />
                <h2>{item.title}</h2>
              </div>
            );
          })}
        </div>
        {eventTakePlaceState[0].isSelected && (
          <div className="online--event">
            {!usingZoom && (
              <>
                <h2>Add the link</h2>
                <p>URL</p>
                <input
                  placeholder="http://"
                  defaultValue={eventLink || ""}
                  onChange={(e) => setEventLink(e.target.value)}
                  onBlur={() =>
                    handleUpdateEvent("event_place", {
                      ...event.event_place,
                      url: eventLink,
                    })
                  }
                />
              </>
            )}
            {usingZoom && (
              <>
                <h2>Add Zoom details</h2>
                <div className="zoom--buttons">
                  {(!event?.event_place?.zoomConfigApi || !zoomInfo?.access_token) ? (
                    <>
                      <button
                        onClick={() => {
                          if (!waitingZoom)
                            return handleCreateZoomMeeting(
                              zoomInfo
                            );
                        }}
                      >
                        {waitingZoom ? (
                          <div className="loading--ring--small--alt">
                            <div className="lds-dual-ring"></div>
                          </div>
                        ) : (
                          <>
                            <h2>Connect</h2>
                            <img src={zoom} />
                          </>
                        )}
                      </button>
                    </>
                  ) : (
                    <div className="zoom--details">
                      <button
                        onClick={() => {
                          setOpenZoomDetails(true);
                        }}
                      >
                        <p>Manage Zoom details</p>
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="hosting--buttons">
              {!usingZoom && (
                <>
                  <small>or</small>
                  <button
                    onClick={() => {
                      setUsingZoom(true);
                      handleUpdateEvent("event_place", {
                        title: "Online",
                        url: null,
                        using_zoom: true,
                        zoomConfigApi: null,
                      });
                    }}
                  >
                    <h2>I'm hosting on Zoom</h2>
                  </button>
                </>
              )}
              {usingZoom && (
                <>
                  <small>or</small>
                  <button
                    onClick={() => {
                      if (canModifyZoomMeeting) {
                        setDeleteZoomModal(() => {
                          return () => {
                            handleUpdateEvent("event_place", {title: "Online"});
                          }
                        });
                      } else {
                        handleUpdateEvent("event_place", {title: "Online"});
                      }
                    }}
                  >
                    <h2>Add a link</h2>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {eventTakePlaceState[1].isSelected && (
          <div className="irl--event">
            <h2>Let’s get specific</h2>
            <p>Event location</p>
            <div className="irl--input">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ color: "#0A2175", marginRight: "10px" }}
              />
              <SearchLocationInput
                placeholder="Location or address"
                onChange={() => null}
                onSelect={(addressObject) =>
                  handleUpdateEvent("event_place", {
                    ...event.event_place,
                    location_address: addressObject,
                  })
                }
                initialValue={locationAddress}
              />
            </div>
            <div className="content--row">
              <div className="toggle">
                <ToggleSwitchComponent
                  checked={!event?.public_place}
                  toggleSwitch={() => {
                    handleUpdateEvent("public_place", !event?.public_place);
                  }}
                />
                <div style={{ 
                  width: "fit-contents",
                  height: "40px",
                  marginLeft: "1rem",
                  textAlign: "center",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}>
                  <p>Private location (revealed after registration)</p>
                </div>
              </div>
            </div>

            <p>Livestream link if applicable</p>
            <div className="irl--input">
              <FontAwesomeIcon
                icon={faVideo}
                style={{ color: "#0A2175", marginRight: "10px" }}
              />
              <input
                value={liveStream}
                onChange={(e) => setLiveStream(e.target.value)}
                placeholder="http://"
                onBlur={() =>
                  handleUpdateEvent("event_place", {
                    ...event.event_place,
                    live_stream: liveStream,
                  })
                }
              />
            </div>
          </div>
        )}
        <h2>Tell us more about your event</h2>
        <div onBlur={() => handleUpdateEvent("info", eventInfo)}>
          <ReactQuill
            className="textEditor"
            theme="snow"
            key={`quill${!!eventInfo}`}
            defaultValue={eventInfo}
            onChange={(info) => {
              setEventInfo(info);
              handleUpdateEvent("info", info)
            }}
            placeholder="Why are you hosting this event? Who is your ideal attendee? What will they learn or experience during the event? What can attendees expect?"
          />
        </div>
        {/* <div className="buttons">
          <FloatingButton
            secondaryButton={true}
            title="Back"
            onClick={() => setStep(0)}
          />
          <FloatingButton
            title={event.status === "PUBLISHED" ? "Publish!" : "Next"}
            onClick={() => {
              if (event?.status === "PUBLISHED")
                return window.location.assign(
                  `//${props.user?.slug}.{process.env.ROOT_DOMAIN}/e/${event.event_url}`
                );
              setStep(2);
            }}
          />
        </div> */}
      </div>
    </>
  );
}
