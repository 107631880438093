import React, { useState, useEffect } from "react";

import LicensureDetails from "./LicensureDetails";
import TextInput from "../../forms/TextInput";
import NormalSelect from "../../profile_forms/NormalSelect";
import MultiSelect from "../../profile_forms/MultiSelect";
import Modal from "../../forms/Modal";
import Button from "../../forms/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";

import {getCurrentUser, updateCurrentUser} from "../../../queries/user";

import {
  useQuery,
} from '@tanstack/react-query'

export default function MyAccount(props) {
  const { user } = props;
  const [showModal, setShowModal] = useState(undefined);
  const [timeZone, setTimeZone] = useState([{}]);
  const [professionsOptions, setProfessionsOptions] = useState([{}]);
  const [userProfession, setUserProfession] = useState([{}]);
  const [userLicense, setUserLicense] = useState([{}]);

  const queryCurrentUser = useQuery({queryKey: ["current_user"], queryFn: getCurrentUser})

  useEffect(() => {
    $.ajax({
      url: `../get_all_timezones`,
      dataType: "JSON",
      type: "GET",
      success: (data) => {
        setTimeZone(
          data.data.map((timeZone) => ({ value: timeZone, label: timeZone }))
        );
      },
      error: (xhr, status, err) => {
        console.log(err, "Error");
      },
    });
  }, []);

  useEffect(() => {
    $.ajax({
      url: `../get_caretypes`,
      dataType: "JSON",
      type: "GET",
      success: (data) => {
        const care_types = JSON.parse(data?.data?.care_types);
        setProfessionsOptions(
          care_types.map((profession) => ({
            value: profession.id,
            label: profession.name,
          }))
        );
      },
      error: (xhr, status, err) => {
        console.log(err, "Error");
      },
    });
  }, [props.user.user_caretypes]);

  useEffect(() => {
    setUserProfession(
      user.user_caretypes.map((profession) => {
        return {
          ...professionsOptions.find(
            (option) => option.value == profession.care_type_id
          ),
          id: profession.id,
        };
      })
    );
  }, [professionsOptions]);

  useEffect(() => {
    if (user?.user_licenses?.length > 0) {
      setUserLicense(user.user_licenses.map((license) => license));
    } else {
      setUserLicense([
        {
          id: uuidv4(),
          license_type: "",
          license_state: "",
          license_number: "",
          expiration_date: "",
        },
      ]);
    }
  }, [user.user_licenses]);

  if (queryCurrentUser.isLoading) { return <p>Loading...</p> }
  if (queryCurrentUser.isError) { return <p>Failed to load user data: {queryCurrentUser.error.message}</p> }

  function handleMultiSelect(newValue, actionMeta) {
    const selectedOptions = [];

    for (let i = 0; i < userProfession.length; i++) {
      let professionArray = professionsOptions.find(
        (item) => item.value === userProfession[i].value
      );
      if (professionArray) {
        selectedOptions.push({ ...professionArray, id: userProfession[i].id });
      }
    }

    if (actionMeta.action === "select-option") {
      updateUserCareType({ care_type_id: newValue.slice(-1)[0].value });
    } else if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      const removedObject = selectedOptions.filter(
        ({ value: id1 }) => !newValue.some(({ value: id2 }) => id2 === id1)
      );
      updateUserCareType({ id: removedObject[0].id });
    }
  }

  function updateUserCareType(payload) {
    if (userProfession.find((profession) => profession.id === payload.id)) {
      $.ajax({
        url: `../delete_user_caretype`,
        dataType: "JSON",
        type: "POST",
        data: payload,
        header: { "Content-Type": "application/json" },
        success: () => {
          props.loadUser();
        },
        error: (xhr, status, err) => {
          console.log(err);
        },
      });
    } else {
      $.ajax({
        url: `../create_user_caretype`,
        dataType: "JSON",
        type: "POST",
        data: payload,
        header: { "Content-Type": "application/json" },
        success: () => {
          props.loadUser();
        },
        error: (xhr, status, err) => {
          console.log(err);
        },
      });
    }
  }

  function handleBlur(field, value) {
    const data = { [field]: value };
    props.updateUser(data);
  }

  function handleChange(key, value) {
    props.changeUserData(key, value);
  }

  function addLicense(e) {
    const newUserLicense = [
      ...userLicense,
      {
        id: uuidv4(),
        license_type: "",
        license_state: "",
        license_number: "",
        expiration_date: "",
      },
    ];
    setUserLicense(newUserLicense);
  }

  function removeLicense(id) {
    setUserLicense((prevState) =>
      prevState.filter((object) => object.id !== id)
    );
  }

  const deleteUserAccount = () => {
    setShowModal(false);
    $.ajax({
      url: `/delete_user_profile`,
      type: "DELETE",
      header: { "Content-Type": "application/json" },
      success: (response) => {
        window.location = "/";
      },
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
  };

  function renderModal() {
    if (showModal === "delete") {
      return renderDeleteModal();
    }
    return null;
  }

  function renderDeleteModal() {
    return (
      <Modal>
        <div className="myAccountDeletionModal">
          <div onClick={() => setShowModal(false)}>
            <FontAwesomeIcon icon={faTimesCircle} size="lg" />
          </div>
          <div className="myAccountModalContent">
            <h1>Are you sure you want to delete your account?</h1>
            <p>
              This is a permanent action and will delete all data associated
              with your account.
            </p>
            <div className="myAccountModalBtn">
              <Button
                buttonClass="button_primary_outline button_primary_outline_shadow"
                text="No, don't delete my account"
                handleClick={() => setShowModal(false)}
              />
              <Button
                buttonClass="button_primary button_primary_shadow"
                text="Yes, delete my account"
                handleClick={deleteUserAccount}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="myAccount">
      {showModal && renderModal()}
      <p className="myAccountTitle">ACCOUNT DETAILS</p>
      <div className="myAccountSection myAccountInputSection">
        <div className="myAccountInputBox">
          <p className="myAccountTitle">First Name</p>
          <TextInput
            placeholder=""
            type="text"
            value={user?.first_name}
            attribute={"first_name"}
            handleChange={handleChange}
            handleBlur={(attr, inputValue) => handleBlur(attr, inputValue)}
            className="myAccountInput"
            style={{ width: "-webkit-fill-available" }}
          />
        </div>
        <div className="myAccountInputBox">
          <p className="myAccountTitle">Last Name</p>
          <TextInput
            placeholder=""
            type="text"
            value={user?.last_name}
            attribute={"last_name"}
            handleChange={handleChange}
            handleBlur={(attr, inputValue) => handleBlur(attr, inputValue)}
            className="myAccountInput"
            style={{ width: "-webkit-fill-available" }}
          />
        </div>
      </div>
      <div className="myAccountSection myAccountInputSection">
        <div className="myAccountInputBox">
          <p className="myAccountTitle">Display Name</p>
          <TextInput
            placeholder=""
            type="text"
            value={
              user?.display_name || `${user?.first_name} ${user?.last_name}`
            }
            attribute={"display_name"}
            handleChange={handleChange}
            handleBlur={(attr, inputValue) => handleBlur(attr, inputValue)}
            className="myAccountInput"
            style={{ width: "-webkit-fill-available" }}
          />
        </div>
        <div className="myAccountInputBox">
          <p className="myAccountTitle">Email address</p>
          <TextInput
            placeholder=""
            type="text"
            value={user?.email}
            attribute={"email"}
            handleChange={handleChange}
            handleBlur={(attr, inputValue) => handleBlur(attr, inputValue)}
            className="myAccountInput"
            style={{ width: "-webkit-fill-available" }}
          />
        </div>
      </div>
      <div className="myAccountSection myAccountInputSection">
        <div className="myAccountInputBox">
          <p className="myAccountTitle">Location</p>
          <TextInput
            placeholder="City or zip code"
            type="text"
            value={user?.location || ""}
            attribute={"location"}
            handleChange={handleChange}
            handleBlur={(attr, inputValue) => handleBlur(attr, inputValue)}
            className="myAccountInput"
            style={{ width: "-webkit-fill-available" }}
          />
        </div>
        <div className="myAccountInputBox">
          <p className="myAccountTitle">Time zone</p>
          <NormalSelect options={timeZone}
            value={
              user.timezone
                ? [{ value: `${user.timezone}`, label: `${user.timezone}` }]
                : ""
            }
            placeholder=""
            onChange={(type) => {
              handleChange("timezone", type.value);
              handleBlur("timezone", type.value);
            }}
          />
        </div>
      </div>
      <hr className="myAccountDivise" />
      <p className="myAccountTitle">THERAPIST LICENSURE DETAILS</p>
      {userLicense.map((license) => (
        <LicensureDetails
          key={license.id ? license.id : uuidv4()}
          userLicense={license}
          loadUser={props.loadUser}
          removeLicense={removeLicense}
        />
      ))}
      <Button
        buttonClass="myAccountAddButton"
        icon="faPlus"
        text="Add license"
        handleClick={addLicense}
      />
      <hr className="myAccountDivise" />
      <div className="myAccountSection">
        <p className="myAccountTitle">DELETE ACCOUNT</p>
        <div className="myAccountDeleteSection">
          <Button
            buttonClass="myAccountDeleteButton"
            icon="faUserSlash"
            text="Delete my account"
            handleClick={() => setShowModal("delete")}
          />
          <span>
            You <b>cannot</b> undo this action
          </span>
        </div>
      </div>
    </div>
  );
}
