import React, {useState, useEffect, useRef} from "react";

export default function ContentSection(props) {
  const {
    title, flexDirection, cardRenderer, filters, content,
  } = props;
  const [selectedFilter, setSelectedFilter] = useState(0);
  const containerRef = useRef();

  let scrollStyle = {};
  switch (flexDirection) {
    case "row":
      scrollStyle = "tw-row tw-overflow-x-auto tw-gap-3";
      break;
    case "column":
      scrollStyle = "tw-flex-col tw-overflow-y-auto";
      break;
  }

  const renderCards = () => {
    let cards = [];
    let filteredContent = content;

    const filter = filters ? filters[selectedFilter] : null;
    if (filter) {
      filteredContent = content.filter(filter.fn);
      if (!filteredContent?.length) {
        return filter.emptyState;
      }
    }

    for (let i = 0; i < filteredContent.length; i++) {
      const item = filteredContent[i];
      cards.push(cardRenderer(item));
    }
    return cards;
  };

  if (!content) {
    return <p>Loading...</p>;
  } else if (!content?.length && !props.showEmpty) {
    return null;
  }

  const renderTitleContainer = () => {
    const selectedStyle = {
      background: "#f3eeff",
      border: "1px solid #0a2175",
      boxShadow: "2px 4px 0px #0a2175",
    };
    const deselectedStyle = {
      border: "1px solid rgba(0, 0, 0, 0)",
    };

    if (filters) {
      return <div className="tw-flex tw-justify-between tw-w-full tw-box-border tw-pr-4">
        <p className="tw-text-xl">{title}</p>
        <div className="tw-flex tw-gap-2">
          {filters.map((filter, i) => {
            let style = {borderRadius: "40px"};
            if (i === selectedFilter) {
              style = {...style, ...selectedStyle};
            } else {
              style = {...style, ...deselectedStyle};
            }

            return <button
              className="tw-flex tw-justify-center tw-py-1 tw-px-2"
              style={style}
              key={i}
              onClick={() => setSelectedFilter(i)}
            >
              <p
                className="tw-font-semibold text-12"
              >{filter.name}</p>
            </button>
          })}
        </div>
      </div>;
    }
    return <p className="tw-text-xl">{title}</p>;
  };

  return (
    <div id={props.id || title} ref={containerRef} className={
        `tw-py-4 tw-w-full tw-pl-8 md:tw-pl-16 tw-flex tw-flex-col tw-gap-2 tw-box-border ${props.classes || ""}`
      }
      style={props.style}
      onMouseEnter={props.onMouseEnter}
    >
      {renderTitleContainer()}
      <div className={`tw-flex ${scrollStyle} `} style={{paddingBottom: "8px"}}>
      {renderCards()}
      </div>
    </div>
  );
};
