import React, { useEffect, useState } from "react";
import Button from "../forms/Button";
import DashboardCards from "../components/DashboardCards";
import hand from "../../../assets/images/hand.png";
import emoji from "../../../assets/images/emoji.png";
import defaultPhoto from "../../../assets/images/default_photo.png";

export default function OverviewContainer(props) {
  const { user, onFinish } = props;
  const [stripeUrl, setStripeUrl] = useState();

  function handleConnectStripe() {
    window.location.replace(stripeUrl);
  }

  useEffect(() => {
    $.ajax({
      url: `/connect_stripe_user`,
      dataType: "JSON",
      type: "GET",
      header: { "Content-Type": "application/json" },
      success: (data) => setStripeUrl(data.stripeUrl),
      error: (xhr, status, err) => {
        console.log(err);
      },
    });
    onFinish();
  }, []);

  return (
    <div className="overviewContainer">
      <div className="overviewHeaderContainer">
        <div className="stackableContainerStyle">
          <img src={user.avatar_url || defaultPhoto} />
          <h1>Welcome back, {user.first_name}!</h1>
        </div>
        <div className="creatSomethingBtn">
          <Button
            buttonClass="button_primary_outline"
            text="Create something!"
            icon="faEdit"
            handleClick={() => window.location.assign(`/dashboard/create`)}
          />
        </div>
      </div>
      <div className="dashboardCards">
        <DashboardCards
          icon={hand}
          title="Make a splash!"
          content="Attract the right clients and community members by letting people know who you are."
          button="Complete your profile"
          action={() => window.location.replace(`/dashboard/edit-profile`)}
        />
        <DashboardCards
          icon={emoji}
          title="Ready to get paid?"
          content="Connect with Stripe so you can accept payments and start makin’ it rain."
          button={user.stripe_user_id ? "Connected" : "Connect Stripe"}
          disabled={!!user.stripe_user_id}
          action={() => handleConnectStripe()}
        />
      </div>
    </div>
  );
}
