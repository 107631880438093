import React from "react";
import PropTypes from "prop-types";
import defaultPhoto from "../../../assets/images/default_photo.png";

class SubscribedMembershipCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };

    this.renderFrequency = this.renderFrequency.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
  }

  renderFrequency(interval) {
    switch (interval) {
      case "month":
        return "/mo";
        break;
      case "year":
        return "/yr";
        break;
      default:
        return "";
    }
  }

  renderPrice() {
    const { membership } = this.props;
    if (membership.price) {
      return `$${membership.price.amount}${this.renderFrequency(
        membership.price.interval
      )}`;
    }
    return "Free";
  }

  render() {
    const { membership } = this.props;
    return (
      <div
        onClick={() =>
          window.location.replace(`../${membership.product.user.slug}`)
        }
        className="subscribed_membership_card"
        style={{ width: 200, height: 200 }}
      >
        <img
          src={membership.product.user.avatar_url || defaultPhoto}
          style={{
            width: "100%",
            height: 140,
            objectFit: "cover",
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          }}
        />
        <div style={{ padding: 10, paddingLeft: 20 }}>
          <h3>{membership.product.user.first_name}</h3>
          <p className="lightPurple thin">{this.renderPrice()}</p>
        </div>
      </div>
    );
  }
}

export default SubscribedMembershipCard;
