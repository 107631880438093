import React, {useState, useEffect} from "react";

import Editor, {TextInput, formPadding, textInputClasses} from "../components/Editor";
import ImageUpload from "../components/ImageUpload";
import ButtonSelectForm from "../forms/ButtonSelectForm";
import RadioSelectForm, {YES_NO} from "../forms/RadioSelectForm";
import ToggleInput from "../forms/ToggleInput";
import NormalSelect from "../profile_forms/NormalSelect";
import TypeOrSelectForm from "../forms/TypeOrSelectForm";
import Service from "../profile/Service";

import {generateSlug} from "../../utils/slug";
import {asyncApiRequest} from "../../utils/apiRequest";
import {amountCentsToStringUSD, stringUSDToAmountCents} from "../../utils/currency";

import {uploadPhoto} from "../../queries/user";
import {
  getInsurances,
  getSpecialties,
  getTreatmentTechniques,
} from "../../queries/user_attributes";

import {
  useQuery,
} from '@tanstack/react-query';

const DURATIONS = [
  "minute",
  "hour",
].map(d => ({value: d, label: d}));

export default function ServiceEditor(props) {
  const params = new URLSearchParams(window.location.search);
  const serviceId = params.get("id");

  const {user} = props;

  const [sv, setSv] = useState(props.services.find(s => s.id == serviceId) || {});
  const [changes, setChanges] = useState({});
  const [changesRecentlySaved, setChangesRecentlySaved] = useState(false);
  const [slugWasEmpty, setSlugWasEmpty] = useState(!sv.slug);
  const [showNsessions, setShowNsessions] = useState(sv.n_sessions >= 2);

  const verb = sv?.id ? "Edit" : "Create";

  const saveChanges = async () => {
    const {id} = await asyncApiRequest("/update_user_service", "POST", {...changes, id: sv.id});
    if (!sv.id) {
      changeField("id", id);
    }
    setChanges({});
    setChangesRecentlySaved(true);
  };

  useEffect(() => {
    if (changesRecentlySaved) {
      setTimeout(() => setChangesRecentlySaved(false), 3000);
    }
  }, [changesRecentlySaved]);

  const changeField = (fieldName, value) => {
    if (sv[fieldName] === value) {
      return;
    }
    setSv((prevService) => {
      let sv = {...prevService};
      sv[fieldName] = value;
      return sv;
    });
    setChanges((prevChanges) => {
      let changes = {...prevChanges};
      changes[fieldName] = value;
      return changes;
    });
  };

  const renderDetails = () => {
    const insuranceQuery = useQuery({
      queryKey: ["insurances"],
      queryFn: getInsurances
    });

    if (insuranceQuery.isLoading) return <p>Loading insurances</p>;
    if (insuranceQuery.isError) return null;

    const insurances = JSON.parse(insuranceQuery.data.data.insurances);

    return <div className="tw-flex tw-flex-col tw-gap-4">
      <ButtonSelectForm label="Type" options={[{
          buttonLabel: "Session",
          getter: () => sv.n_sessions === 1,
          setter: () => {
            changeField("n_sessions", 1);
            setShowNsessions(false);
          },
        }, {
          buttonLabel: "Package",
          getter: () => sv.n_sessions > 1,
          setter: () => {
            sv.n_sessions <= 1 && changeField("n_sessions", 2);
            setShowNsessions(true);
          }
      }]} />
      {(showNsessions) && <TextInput
        key={showNsessions}
        label="Number of sessions"
        defaultValue={sv.n_sessions}
        onChange={n => changeField("n_sessions", Number(n) || 1)}
      />}
      <TextInput
        label="Cost per session"
        defaultValue={sv.cost ? amountCentsToStringUSD(sv.cost) : "$0.00"}
        placeholder="$0"
        onChange={x => changeField("cost", stringUSDToAmountCents(x))}
      />
      <div className="tw-flex tw-flex-col tw-gap-4">
        <p className="text-label">Length of session</p>
        <div className="tw-flex tw-gap-4">
          <TextInput
            defaultValue={sv.duration}
            onChange={d => changeField("duration", d)}
          />
          <NormalSelect
            defaultValue={DURATIONS.find(x => x.value === sv.duration_type)} options={DURATIONS}
            onChange={x => changeField("duration_type", x.value)}
          />
        </div>
      </div>
      <RadioSelectForm label="Sliding scale?" options={YES_NO}
        getter={() => sv.sliding_scale}
        onChange={x => changeField("sliding_scale", x)}
      />
      <div className="tw-flex tw-flex-col tw-gap-4">
        <p className="text-label">Insurance accepted</p>
        <TypeOrSelectForm
          options={insurances}
          selections={sv.insurances}
          placeholder="Type or select insurance options"
          setSelections={x => changeField("insurances", x)}
          transforms={{
            to: (x => ({value: x.id, label: x.name})),
            from: (x => ({id: x.value, name: x.label})),
          }}
          maxEntries={10}
        />
      </div>
    </div>
  };

  useEffect(() => {
    if (sv.name && slugWasEmpty) {
      changeField("slug", generateSlug(sv.name));
    }
  }, [sv.name]);

  const updateServiceName = (newName) => {
    changeField("name", newName);
  }

  const renderOverview = () => {
    const specialtiesQuery = useQuery({
      queryKey: ["specialties"],
      queryFn: getSpecialties,
    });

    const treatmentTechniquesQuery = useQuery({
      queryKey: ["treamtent_techniques"],
      queryFn: getTreatmentTechniques,
    });

    if (specialtiesQuery.isLoading) return <p>Loading specialties</p>;
    if (specialtiesQuery.isError) return null;
    const specialties = JSON.parse(specialtiesQuery.data.data.specialties);
    if (treatmentTechniquesQuery.isLoading) return <p>Loading treatment techniques</p>;
    if (treatmentTechniquesQuery.isError) return null;
    const treatmentTechniques = JSON.parse(treatmentTechniquesQuery.data.data.treatments);

    return <div className="tw-flex tw-flex-col tw-gap-4">
      <TextInput
        label="What is the name of your service?"
        defaultValue={sv.name}
        onChange={updateServiceName}
      />
      <TextInput
        label=<span className="text-label">Tell us more about your service <span className="tw-font-light">(optional)</span></span>
        defaultValue={sv.description}
        onChange={x => changeField("description",x )}
        useTextArea={true}
      />
      <span className="text-label">What topics are addressed? <span className="tw-font-light">(optional)</span></span>
      <p className="text-12 tw-font-light">Give your ideal client a sense of what they can expect to work through with you</p>
      <TypeOrSelectForm
        options={specialties.filter(s => !s["private"])}
        selections={sv.specialties}
        placeholder="Type or select topics"
        setSelections={x => changeField("specialties", x)}
        transforms={{
          to: (x => ({value: x.id, label: x.name})),
          from: (x => ({id: x.value, name: x.label})),
        }}
        maxEntries={10}
      />
      <span className="text-label">Treatment techniques <span className="tw-font-light">(optional)</span></span>
      <p className="text-12 tw-font-light">What techniques do you leverage in this service?</p>
      <TypeOrSelectForm
        options={treatmentTechniques}
        selections={sv.treatment_techniques}
        placeholder="Type or select treatment techniques"
        setSelections={x => changeField("treatment_techniques", x)}
        transforms={{
          to: (x => ({value: x.id, label: x.name})),
          from: (x => ({id: x.value, name: x.label})),
        }}
        maxEntries={10}
      />
    </div>;
  }

  const removeCoverPhoto = (onSuccess) => {
    return $.ajax({
      url: `/remove_service_cover_photo?id=${sv.id}`,
      dataType: "JSON",
      type: "POST",
      success: (_) => {
        onSuccess();
      },
    });
  };

  const renderPhoto = () => {
    const coverPhotoUrl = sv.cover_photo_url;
    const ASPECT = 4 / 3.0;

    return <div className={`tw-flex tw-flex-col ${formPadding}`}>
      <p className="text-18 tw-font-semibold">Add a thumbnail image!</p>
      <p className="text-12">{
        "Make it pop! Be sure to use a high quality image."
      }<br />{
        "It'll be cropped to a 4:3 ratio."
      }</p>
      <ImageUpload
        height="200px"
        width={`${200 * ASPECT}px`}
        key={`imageUpload_${coverPhotoUrl}`}
        thumbUrl={coverPhotoUrl}
        uploadFn={(image) => uploadPhoto(`/update_service_cover_photo?id=${sv.id}`, image, (resp) => {
          changeField("id", resp.id);
          changeField("cover_photo_url", resp.cover_photo_url);
        })}
        deleteFn={() => removeCoverPhoto(() => changeField("cover_photo_url", null))}
        aspectRatio={ASPECT}
      />
    </div>;
  };

  const renderVisibility = () => {
    const publishedProfile = Number(sv.availability) & 1;

    return <div className="tw-flex tw-flex-col tw-gap-4">
      {renderPhoto()}
      <p className="text-label">Where can people find your service?</p>
      <div className="tw-flex tw-items-center">
        <p className="tw-font-semibold text-16">{`https://${user.slug}.${process.env.ROOT_DOMAIN}/sv/`}</p>
        <input
          className="tw-w-0 tw-grow text-16"
          defaultValue={sv.slug}
          onChange={e => {changeField("slug", e.target.value); setSlugWasEmpty(false);}}
        />
      </div>
      <p className="text-label">Where do you want your service to show up?</p>
      <ToggleInput
        defaultValue={publishedProfile}
        label="On my profile"
        onChange={(_) => changeField("availability", Number(sv.availability) ^ 1)}
      />
    </div>;
  };

  const sections = [{
    title: "Overview",
    render: renderOverview,
  }, {
    title: "Details",
    render: renderDetails,
  }, {
    title: "Visibility",
    render: renderVisibility,
  }];

  return <Editor
    title={`${verb} your service`}
    changesMade={Object.keys(changes).length > 0}
    changesRecentlySaved={changesRecentlySaved}
    saveChanges={saveChanges}
    sections={sections}
    renderPreview={() => <Service sv={sv} user={user} currentUser={user} isPreview={true} />}
  />;
}
