import React from "react";

import {useNavigate} from "react-router-dom";

export default function TableView({fixedWidth, content, emptyState, fields, rowLinkFn, styles, classes}) {
  const cellClasses = "tw-p-2";
  const navigate = useNavigate();

  if (!content) {
    return null;
  }

  if (!content.length) {
    return emptyState;
  }

  const renderHeader = () => {
    return <tr>
      {fields.map((field, i) => (
        <td className={`${cellClasses} ${field.classes || ""}`} key={i}>
          <p className="text-12 tw-font-semibold" style={{
            color: "hsla(227, 84%, 25%, 0.5)",
          }}>{field.title.toUpperCase()}</p>
        </td>
      ))}
    </tr>;
  }

  const renderRow = (row, i) => {
    return <tr
      className="hover:tw-bg-[#f3eeff] tw-cursor-pointer"
      onClick={() => navigate(rowLinkFn(i))}
      style={{
        borderTop: "1px solid hsla(258, 100%, 97%, 1)",
      }}
      key={i}
    >
      {fields.map((field, j) => (
        <td className={`${cellClasses} ${field.classes || ""}`} key={j}>
          <p className="text-12 dark-text">{field.value(row) || ""}</p>
        </td>
      ))}
    </tr>;
  }

  return <table className={`table-view tw-w-full ${fixedWidth ? "tw-table-fixed" : "tw-table-auto"}`}>
    <thead>
      {renderHeader()}
    </thead>
    <tbody>
      {content.map((row, i) => renderRow(row, i))}
    </tbody>
  </table>;
};
