import React, {useState, useEffect} from "react";

import AdjectivesOnboard from "./AdjectivesOnboard";
import BirthdayOnboard from "./BirthdayOnboard";
import ContactOnboard from "./ContactOnboard";
import CredentialsOnboard from "./CredentialsOnboard";
import EducationOnboard from "./EducationOnboard";
import EthnicityOnboard from "./EthnicityOnboard";
import ExperienceOnboard from "./ExperienceOnboard";
import GenderOnboard from "./GenderOnboard";
import OnboardingProgress from "./OnboardingProgress";
import PhotoOnboard from "./PhotoOnboard";
import PreferredNameOnboard from "./PreferredNameOnboard";
import ProfessionOnboard from "./ProfessionOnboard";
import PronounOnboard from "./PronounOnboard";
import SlugOnboard from "./SlugOnboard";
import SpecialtiesOnboard from "./SpecialtiesOnboard";

import IntroContainer from "../profile/sections/IntroContainer";

import Button from "../forms/Button";
import Modal from "../forms/Modal";

import onboardImage from "../../../assets/images/profile_setup.png";

import {getCurrentUser, updateCurrentUser} from "../../queries/user";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


const queryClient = new QueryClient();

function TherapistOnboard() {
  const [enableNextButton, setEnableNextButton] = useState(true);
  // ?page=<page> can jump to a specific onboarding page
  const params = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(parseInt(params.get("page")) || 0);
  const [skipModal, setSkipModal] = useState(false);

  const queryCurrentUser = useQuery({queryKey: ["current_user"], queryFn: getCurrentUser})
  const mutateCurrentUser = useMutation({
    mutationFn: updateCurrentUser,
    onSuccess: () => {
      // invalidate and reload
      queryClient.invalidateQueries({queryKey: ["current_user"]});
    },
  });

  if (queryCurrentUser.isLoading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }
  
  if (queryCurrentUser.isError) {
    return (
      <div>
        <h1>Error: {queryCurrentUser.error.message}</h1>
      </div>
    );
  }

  const user = queryCurrentUser.data;

  const nextPage = () => {
    let nextPage = page + 1;
    if (nextPage >= pages.length) {
      endOnboarding();
      return;
    }
    setPage(nextPage);
    setSkipModal(false);
  };

  const pageProps = {
    currentUser: user,
    queryClient: queryClient,
    updateUser: (payload) => {
      if (payload) {
        mutateCurrentUser.mutate(payload);
      } else {
        queryClient.invalidateQueries({queryKey: ["current_user"]});
      }
    },
    nextPage: nextPage,
    blockNextButton: (block) => {setEnableNextButton(!block)},
  }

  const stages = [{
      title: "About You",
      pages: [
        <CredentialsOnboard {...pageProps} />,
        <ProfessionOnboard {...pageProps} />,
        <PreferredNameOnboard {...pageProps} />,
        <AdjectivesOnboard {...pageProps} />,
        <PronounOnboard {...pageProps} />,
      ],
    }, {
      title: "Your Background",
      pages: [
        <BirthdayOnboard {...pageProps} />,
        <EducationOnboard {...pageProps} />,
        <SpecialtiesOnboard {...pageProps} />,
      ],
    }, {
      title: "Finishing Touches",
      pages: [
        <ContactOnboard {...pageProps} />,
        <PhotoOnboard {...pageProps} />,
        <SlugOnboard {...pageProps} />,
      ],
    },
  ];
  const stageIndices = stages.map((stage) => stage.pages.length);
  const pages = stages.flatMap((stage) => stage.pages);
  const stageIndex = (() => {
    let sum = 0;
    for (let i = 0; i < stageIndices.length; i++) {
      sum += stageIndices[i];
      if (page <= sum - 1) {
        return i;
      }
    }
  })();

  const renderForm = () => {
    return pages[page];
  }

  const endOnboarding = () => {
    window.location.replace("/dashboard/edit-profile");
  };

  const renderSkipModal = () => {
    return (
      <Modal>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          onClick={() => setSkipModal(!skipModal)}
        >
          <FontAwesomeIcon
            style={{
              color: "#0A2175",
              cursor: "pointer",
              padding: 0,
              borderRadius: "100%",
            }}
            icon={faTimesCircle}
            size="lg"
          />
        </div>
        <div className="skipModalContent">
          <h1>
            Want to fast forward to the end or just skip this question?
          </h1>
          <p>
            Fast forwarding will save your existing responses and take you
            to your profile. The more you fill out now, the more complete
            your profile will be. If you have some time, we recommend
            going through the entire onboarding now.
          </p>
        </div>
        <div className="skipModalButtons">
          <div>
            <Button
              buttonClass={"button_primary_outline svg_skip"}
              text="Fast forward"
              handleClick={endOnboarding}
              style={{
                fontFamily: "Averta",
                fontWeight: 600,
                border: "2px solid #0A2175",
                color: "#0A2175",
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon="faFastForward"
            />
          </div>
          <div>
            <Button
              buttonClass={"button_primary svg_skip_question"}
              text="Skip this question"
              handleClick={nextPage}
              style={{
                fontFamily: "Averta",
                fontWeight: 600,
                color: "#FFFFFF",
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
                alignItems: "center",
              }}
              icon="faArrowRight"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderIntroPreview = () => {
    return (
      <div>
        <p style={{
          textAlign: "center",
          marginBottom: "1em",
        }}><b>This is how your introduction will appear on your profile:</b></p>
        <div className="onboarding_intro_preview">
          <IntroContainer
            user={user}
            onboardingPreview={true}
            onboardingPageType={pages[page].type}
          />
        </div>
      </div>
    );
  };

  const renderSidePane = () => {
    return (
      <div className="onboarding_side_pane">
        {stageIndex === 0 && renderIntroPreview()}
        <img src={onboardImage} style={{marginTop: "auto", width: "100%"}}/>
      </div>
    );
  }

  return (
    <div style={{
      display: "flex", minHeight: "100vh", justifyContent: "space-between"
    }} className="onboarding_steps">
      <div className="onboarding_form cleanScroll">
        {skipModal && renderSkipModal()}
        {stageIndex === 0 && (
          <div>
            <h1 className="onboard_title_text">
              Welcome, {user.first_name}!
            </h1>
            <h1 className="onboard_title_text teste">{`Let's create your profile`}</h1>
          </div>
        )}
        {stageIndex === 1 && (
          <div>
            <h1 className="onboard_title_text">
              Thanks, {user.first_name}!
            </h1>
            <h1 className="onboard_title_text">{`Let's get to know you a bit`}</h1>
          </div>
        )}
        {stageIndex === 2 && (
          <div>
            <h1 className="onboard_title_text">{`We're so close!`}</h1>
            <h1 className="onboard_title_text">{`Time for the finishing touches`}</h1>
          </div>
        )}
        <OnboardingProgress stages={stages} stageIndex={stageIndex} />
        {renderForm()}
        <div className="onboarding_button_section">
          {page != 0 && (
            <div>
                <Button
                  buttonClass="button_primary_outline"
                  text="Back"
                  handleClick={() => {
                    setPage(page - 1);
                  }}
                />
            </div>
          )}
          <div>
            <Button
              buttonClass="button_primary"
              text="Next"
              handleClick={nextPage}
              disabled={!enableNextButton}
            />
          </div>
        </div>
        {page < pages.length - 1 && (
          <div className="dark_linkable_text">
            <p
              style={{ color: "#733DFF" }}
              onClick={() => setSkipModal(!skipModal)}
            >
              Complete this later
            </p>
          </div>
        )}
      </div>
      {renderSidePane()}
    </div>
  );
}

export default function TherapistOnboardContainer() {
  return (
    <QueryClientProvider client={queryClient}>
      <TherapistOnboard />
    </QueryClientProvider>
  );
};
