import React from "react";
import EditIcon from "../../../components/EditIcon";

class IdentityExpertise extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
      timeout: null,
    }

    this.toggleEditModal = this.toggleEditModal.bind(this)
    this.renderExpertise = this.renderExpertise.bind(this)
  }

  toggleEditModal(id) {
    if (!this.props.liveProfile) {
      this.props.toggleEditModal(id)
    }
  }

  renderExpertise() {
    return (
      this.props.user.identity_expertises.map((expertise, i) => {
        return <li key={i} style={{ marginTop: 5 }}>{expertise.name}</li>
      }))
  }

  render() {
    if (this.props.liveProfile && this.props.user.identity_expertises.length <= 0) {
      return null
    }

    return (
      <div onClick={() => this.toggleEditModal('expertise')}>
        <h5 className="detailsSectionTitle">Identity or experience expertise</h5>
        <ul className="detailList">
          {this.renderExpertise()}
        </ul>
        {!this.props.liveProfile && <EditIcon />}
      </div>
    )
  }
}

export default IdentityExpertise
