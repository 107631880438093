import React from "react";

import {ZOOM_INFO_LOCAL_STORAGE_KEY} from "../../utils/zoom";

class AuthZoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  getAccessToken() {
    $.ajax({
      url: `../zoom_connected?code=${this.props?.zoomCode}`,
      dataType: "JSON",
      type: "GET",
      header: { "Content-Type": "application/json" },
      success: (data) => {
        console.log(`saving Zoom info: ${data.response}`);
        localStorage.setItem(ZOOM_INFO_LOCAL_STORAGE_KEY, data.response);
        window.close();
      },
      error: (xhr, status, err) => {
        console.log(err);
        this.setState({ error: true });
      },
    });
  }

  async componentDidMount() {
    this.getAccessToken();
  }

  render() {
    return (
      <div className="auth--container">
        {this.state.error ? (
          <div>
            <h1>Zoom authorization failed. <a href="/zoom_support">Click here to view our Zoom support page.</a></h1>
          </div>
        ) : (
          <div className="loading--ring">
            <div className="lds-dual-ring"></div>
          </div>
        )}
      </div>
    );
  }
}

export default AuthZoom;
